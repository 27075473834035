import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { fetchResponseAdapter } from "../../adapters/fetchAdapter"
import FooterComponent from "../../components/Footer"
import DetailSectionLabel from "../../components/General/DetailSectionLabel"
import { getAuthToken, unsetAuth } from "../../helpers/authHelper"
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad"
import { logoutAction } from "../../redux/features/auth"
import { AppStore } from "../../redux/store"
import { getLandlordProfileEndpoint } from './services/landlordService';
import WhiteModal from "../../components/WhiteModel"
import UploadAvatar from "../../components/UploadAvatar"
import { DEFAULT_AVATAR } from "../../constants/ProfileConstants"

const LandlordProfile = () => {
    const { country } = useSelector((store: AppStore) => store.country);
    const user = useSelector((store: AppStore) => store.auth)
    const token = getAuthToken(user)
    const { callEndpoint } = useCallApiAndLoad()
    const dispatch = useDispatch()
    const [apiData, setApiData] = useState<any>()
    const navigate = useNavigate()
    const [showAvatarModal, setShowAvatarModal] = useState(false)

    useEffect(() => {
        const loadBankInfo = async () => {
            const { status, data } = await callEndpoint(getLandlordProfileEndpoint(token));
            if (status === 401) {
                dispatch(logoutAction({}))
                unsetAuth()
                return navigate(`/app/${country}/auth/login`)
            }
            const response = fetchResponseAdapter(data)
            setApiData(response.data)
        }
        loadBankInfo()
            .catch(e => console.error(e))
    }, [])

    const toggleAvatarModal = () => {
        try {
            setShowAvatarModal((old) => !old);
            document.body.style.overflowY = "scroll";
        } catch (error) {
            console.log("something went wrong with bidder info");
        }
    };

    const handleEdit = () => {
        navigate(`/app/${country}/l/ld/update-profile`, {
            state: apiData
        })
    }

    return (
        <>
            {showAvatarModal && (
                <WhiteModal
                    onAction={toggleAvatarModal}
                    name="Foto de perfil"
                    body={
                        <UploadAvatar
                            realm="landlord"
                            onAction={toggleAvatarModal}
                        />
                    }
                />)
            }
            <section className='pt-24 w-full' >
                <div className="flex w-full items-center justify-center">
                    <div onClick={toggleAvatarModal} 
                        className="w-32 h-32 rounded-full border-green501 border-8  flex justify-center">
                        <div className="rounded-full">
                            <img
                                src={ apiData && apiData.avatar ? apiData.avatar : DEFAULT_AVATAR }
                                className="rounded-full w-28 h-28 border-green500 border-6"
                                alt="avatar"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // this prevents a checking looping
                                    currentTarget.src = DEFAULT_AVATAR;
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="text-center my-6 text-third text-lg">
                    Propietario
                </div>
                <div className="m-auto">
                    <div className="w-full my-6 text-third text-4xl font-bold text-center">
                        {apiData?.name + ' ' + apiData?.last_name}
                    </div>
                </div>
            </section>
            <hr />
            <section>
                <div className="h-screen">
                    <div className="m-auto w-full">
                        <div className="w-full my-10">
                            <div className="w-full flex justify-end lg:px-0 px-4">
                                <button className="text-green500" onClick={handleEdit}>
                                    Editar datos
                                </button>
                            </div>
                            <DetailSectionLabel
                                label="Nombres" value={apiData?.name} />
                            <DetailSectionLabel
                                label="Apellidos" value={apiData?.last_name} />
                            <DetailSectionLabel
                                label="Correo" value={apiData?.email} />
                            <DetailSectionLabel
                                label="Estado o departamento" value={apiData?.state} />
                            <DetailSectionLabel
                                label="Teléfono" value={apiData?.phone} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LandlordProfile
