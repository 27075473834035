import axios from "axios";
import { OnePayPayload } from "../../../models/paymentModel";

const loadAbort = () => {
  return new AbortController();
};

export const transferInvestorPaymentEndpoint = (
  form: any,
  token: string,
  transactionId: string,
  transactionDetailId: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/ts/v1/landlord/pre-sales/${transactionId}/transaction-details/${transactionDetailId}/transfer-pay`,
        data: form,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const onePayInvestorEndpoint = (
  payload: OnePayPayload,
  token: string,
  transactionId: string,
  transactionDetailId: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/ts/v1/landlord/pre-sales/${transactionId}/transaction-details/${transactionDetailId}/one-pay`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify(payload),
      }),
    controller,
  };
};

export const getLandlordCheckoutEndpoint = (
  token: string,
  transactionId: string,
  id: string,
  paymentMethod: string = ""
) => {
  const controller = loadAbort();
  const queryParams = paymentMethod ? `?method=${paymentMethod}` : "";
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/landlord/pre-sales/${transactionId}/transaction-details/${id}/checkout${queryParams}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getInvestorPrecheckoutEndpoint = (
  transactionId: string,
  tDetailId: string,
  token: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/landlord/pre-sales/${transactionId}/transaction-details/${tDetailId}/preckout`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getInvestorPaymentDetailEndpoint = (token: string, id: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/landlord/pre-sales/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getInvestorPaymentsEndpoint = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/landlord/pre-sales`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getInvestorBookingEndpoint = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/pre-sales/v1/landlord/pre-booking`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getInvestorBookingDetailEndpoint = (id: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/pre-sales/v1/landlord/pre-booking/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
