import axios from "axios";
import { RegisterUser } from "../models/RegistrationModel";

const loadAbort = () => {
  return new AbortController();
};

export const registrationEndpoint = (
  realm: string,
  registration: RegisterUser
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios.post(
        `${urlService}/user/v1/auth/${realm}/registration`,
        registration,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    controller,
  };
};

export const resendVerificationEndpoint = (realm: string, pid: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios.post(
        `${urlService}/user/v1/auth/${realm}/resend-verification/${pid}`
      ),
    controller,
  };
};

export const changeMailEndpoint = (pid: string, email: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios.post(`${urlService}/user/v1/auth/user/change-mail/${pid}`, {
        email,
      }),
    controller,
  };
};
