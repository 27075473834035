import { useEffect, useState } from "react";
import arrow_button from "../../../../assets/icons/arrow_button.svg";
import Button from "../../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppStore } from "../../../../redux/store";
import { getAuthToken, unsetAuth } from "../../../../helpers/authHelper";
import useCallApiAndLoad from "../../../../hooks/useCallApiAndLoad";
import { logoutAction } from "../../../../redux/features/auth";
import { fetchResponseAdapter } from "../../../../adapters/fetchAdapter";
import {
  getTenantOfferContractEndpoint,
  acceptContractEndpoint,
  sendCommentTenant,
} from "../../services/tenantService";
import { useToast } from "../../../../components/ToastAlerts";
import FormControlError from "../../../../components/Controls/FormControlError";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import FormControlInfo from "../../../../components/Controls/FormControlInfo";
import { IconInfoCircle } from "@tabler/icons-react";

interface Props {
  onAction: () => void;
  id: string;
}

interface Comment {
  comment: string;
}

const FormAccept = (props: Props) => {
  const { country } = useSelector((store: AppStore) => store.country);
  const { id } = props;
  const dispatch = useDispatch();
  const toast: any = useToast();
  const navigate = useNavigate();
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const { callEndpoint } = useCallApiAndLoad();
  const [pdf, setPdf] = useState<string>("");
  const [acceptCheck, setAcceptCheck] = useState<boolean>(false);
  const [acceptError, setAcceptError] = useState<boolean>(false);
  const [contractError, setContractError] = useState<boolean>(false);
  const [idContract, setContractId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showComment, setShowCommet] = useState<boolean>(false);
  

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Comment>({
    resolver: yupResolver(
      yup
        .object({
          comment: yup
            .string()
            .required("Es necesario escribir un comentario."),
        })
        .required()
    ),
  });

  useEffect(() => {
    let loadConsent = async () => {
      const { status, data } = await callEndpoint(
        getTenantOfferContractEndpoint(id, token)
      );
      setLoading(true);
      if (status === 401) {
        dispatch(logoutAction({}));
        unsetAuth();
        setLoading(false);
        return navigate(`/app/${country}/auth/login`);
        
      }
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        const pdfBase64 = response.data.contract ? response.data.contract : "";
        setPdf(pdfBase64);
        setContractId(response.data.contract_id);
        setLoading(false);
      } else {
        setContractError(true);
        setLoading(false);
      }
    };
    if (!contractError) {
      loadConsent().catch((e) => console.error(e));
    }
  }, [contractError]);

  const signContract = async () => {
    if (!acceptCheck) {
      setAcceptError(true);
      return;
    }
    const { status } = await callEndpoint(
      acceptContractEndpoint(idContract, token)
    );
    if (status === 401) {
      dispatch(logoutAction({}));
      unsetAuth();
      return navigate(`/app/${country}/auth/login`);
    }
    if (status === 200) {
      props.onAction();
      toast.open("Contrato firmado con exito", "success");
      setTimeout(() => {
        document.location.reload();
      }, 2000);
    }
  };

  const sendComment = async (form: Comment) => {
    const { status } = await callEndpoint(
      sendCommentTenant(idContract, token, form)
    );
    if (status === 401) {
      dispatch(logoutAction({}));
      unsetAuth();
      return navigate(`/app/${country}/auth/login`);
    }
    if (status === 201) {
      props.onAction();
      toast.open("El comentario fue enviado con exito", "success");
    }
  };
  console.log(contractError);

  return (
    <>
      {!loading && (
        <>
          {contractError && (
            <>
              <div className="bg-white overflow-y-auto w-full max-w-sm">
                <div className="px-4 py-6">
                  <div className="w-full mb-2 text-blackGrayScale text-2xl lg:text-2xl font-semibold text-left tracking-wide flex justify-start items-center">
                    <IconInfoCircle className="mr-2" color="#212121" />{" "}
                    Información
                  </div>
                  <p className="text-[#757575] text-sm">
                    El contrato se encuentra en proceso de ser generado, te
                    notificaremos cuando esté listo para ser revisado.
                  </p>
                </div>
              </div>
            </>
          ) } { !contractError  && (
            <form onSubmit={handleSubmit(sendComment)}>
              <div className="bg-white overflow-y-auto">
                <div className="px-4">
                  {showComment ? (
                    <div className="py-4">
                      <p className=" font-normal text-graySoft text-base mb-5">
                        Nos pondremos en contacto contigo lo más pronto posible,
                        para ayudar a hacer más rápido tu proceso describenos el
                        tipo de modificación que necesitas.
                      </p>
                      <div>
                        <label
                          className="text-base font-medium text-gray-700 mb-2 block"
                          htmlFor=""
                        >
                          Comentarios (opcional)
                        </label>
                        <textarea
                          {...register("comment")}
                          className=" border border-gray-300 w-full rounded-lg focus:outline-none p-3 resize-none"
                          cols={30}
                          rows={6}
                        ></textarea>
                        <FormControlError message={errors.comment?.message} />
                      </div>
                    </div>
                  ) : (
                    <div className="w-full my-4">
                      <div className="w-full flex flex-col items-center pb-2">
                        {pdf && pdf !== "" && (
                          <embed
                            className="w-full md:h-[500px] h-[360px]"
                            src={`data:application/pdf;base64,${pdf}`}
                          />
                        )}
                      </div>
                      <hr />
                      <div className="flex items-center my-4">
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          defaultChecked={acceptCheck}
                          onChange={() => {
                            setAcceptError(false);
                            setAcceptCheck((state) => !state);
                          }}
                          className="w-4 h-4 mr-2 text-blue-600 rounded border-gray300 focus:ring-primary focus:ring-2"
                        />
                        <label
                          htmlFor="default-checkbox"
                          className="text-base font-normal text-graySoft"
                        >
                          He leído y aceptado el contrato
                        </label>
                      </div>
                      {acceptError && (
                        <div className="pb-2">
                          <FormControlError
                            message={
                              "Debes seleccionar aceptar y leer el contrato"
                            }
                          />
                        </div>
                      )}
                      <div className="py-2 px-3 bg-tea-400 rounded-md">
                        <p className=" text-sm text-graySoft">
                          ¿Necesitas modificar tu contrato?{" "}
                          <span
                            onClick={() => setShowCommet(true)}
                            className="text-blueDark font-semibold ml-3 cursor-pointer"
                          >
                            Solicitar ayuda →
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="bg-white border-t border-gray300 w-full">
                {showComment ? (
                  <div className="w-full flex justify-between px-5 py-4 items-center">
                    <div onClick={() => setShowCommet(false)}>
                      <div className="text-blueForm text-base font-medium cursor-pointer">
                        Volver
                      </div>
                    </div>
                    <Button
                      type="submit"
                      className="bg-green500 py-2 px-1 text-white text-base rounded-full"
                    >
                      <span className="px-3 g text-base">Aceptar</span>
                    </Button>
                  </div>
                ) : (
                  <div className="w-full flex justify-between px-5 py-4 items-center">
                    <div onClick={() => props.onAction}>
                      <div className="text-blueForm text-base font-medium">
                        Cancelar
                      </div>
                    </div>
                    <Button
                      onClick={() => signContract()}
                      className="bg-green500 py-2 px-1 text-white text-base rounded-full"
                    >
                      <span className="mr-2 ml-6 g text-base">Aceptar</span>
                      <img className="inline" src={arrow_button} alt="" />
                    </Button>
                  </div>
                )}
              </div>
            </form>
          )}
        </>
      )}
    </>
  );
};

export default FormAccept;
