import { createSlice, current } from "@reduxjs/toolkit"
import { TenantFavorites } from "../../models/favoritesModel"

const initialState: TenantFavorites[] = []

export const favoritesSlice = createSlice({
    name: 'tenantFavorites',
    initialState,
    reducers: {
        loadAction: (state, action) => action.payload,
        pluckAction: (state, action) => {
            const { id } = action.payload;
            return current(state).filter((el) => el.id !== id);
        }
    }
})

export const { loadAction, pluckAction } = favoritesSlice.actions
export default favoritesSlice.reducer