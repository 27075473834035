import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unsetAuth } from "../../../helpers/authHelper";
import useCallApiAndLoad from "../../../hooks/useCallApiAndLoad";
import { AppStore } from "../../../redux/store";
import { useForm } from "react-hook-form";
import Button from "../../../components/Button";
import arrow_button from "../../../assets/icons/arrow_button.svg";
import {
  getScheduleList,
  updateScheduleList,
  getScheduleSelectedList,
} from "../services/Schedule";
import { useNavigate } from "react-router-dom";
import { fetchResponseAdapter } from "../../../adapters/fetchAdapter";
import { logoutAction } from "../../../redux/features/auth";

import "./sass/styles.scss";
import { useToast } from "../../../components/ToastAlerts";

interface Props {
  onAction: () => void;
  id: string;
}

const FormVisitLandlord = (props: Props) => {
  const { country } = useSelector((store: AppStore) => store.country);
  const [schedules, setSchedule] = useState<Array<any>>([]);
  const [schedulesSelected, setScheduleSelected] = useState<Array<any>>([]);
  const { callEndpoint } = useCallApiAndLoad();
  const [isLoadingData, setIsLoading] = useState<boolean>(false);
  const [isLoadingDataSelect, setIsLoadingSelect] = useState<boolean>(false);

  const { id, onAction } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast: any = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  useEffect(() => {
    const loadTenantProfile = async () => {
      setIsLoading(true);
      const { status, data } = await callEndpoint(getScheduleList(id));
      if (status === 401) {
        dispatch(logoutAction({}));
        unsetAuth();
        return navigate(`/app/${country}/auth/login`);
      }
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setSchedule(response.data || []);
        setIsLoading(false);
      }
    };
    loadTenantProfile().catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    const loadTenantProfile = async () => {
      setIsLoadingSelect(true);
      const { status, data } = await callEndpoint(getScheduleSelectedList(id));
      if (status === 401) {
        dispatch(logoutAction({}));
        unsetAuth();
        return navigate(`/app/${country}/auth/login`);
      }
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setScheduleSelected(response.data);
        setIsLoadingSelect(false);
      }
    };
    loadTenantProfile().catch((e) => console.error(e));
  }, []);

  const sendData = async (form: any) => {
    const formValues = Object.values(form);
    const filter = formValues.filter((item: any) => {
      return item !== false;
    });

    const { status } = await callEndpoint(updateScheduleList(id, filter));

    if (status === 200) {
      toast.open("Se ha actualizado los horarios de visita", "success");
      onAction();
    }

  };

  return (
    <div className="w-full text-center bg-white pt-2 px-5">
      <div className=" p-3 mt-3 text-center">
        <h2 className="text-base text-blueDark font-bold mb-2">
          Disponibilidad de visitas
        </h2>
        <p className=" text-graySoft text-base">
          Selecciona el horario disponible para visitas de tu propiedad.
        </p>
      </div>
      <hr className="my-3" />

      <form onSubmit={handleSubmit(sendData)}>
        <div className="bg-white w-full mt-4">
          <div className=" bg-gray-100 py-5 rounded-2xl">
            {!isLoadingDataSelect ? (
              <div className=" max-w-lg mx-auto">
                {schedulesSelected?.map((item: any, index: number) => (
                  <div
                    key={index}
                    className="w-full flex justify-between items-center py-2"
                  >
                    <p className="w-full md:w-1/5 text-left text-base text-graySoft font-semibold">
                      {item.day_digest}
                    </p>
                    <div className="flex justify-end items-center w-full md:w-2/5">
                      <label
                        className="checkbox text-base bg-gray-200 rounded-lg px-2 py-1"
                        htmlFor={item.id_am}
                      >
                        <p className="text-graySoft">{item.digest_am}</p>
                        <input
                          type="checkbox"
                          id={item.id_am}
                          className="mr-2"
                          value={item.id_am}
                          defaultChecked={item.checked_am}
                          {...register(item.id_am)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="flex justify-end items-center w-full md:w-2/5">
                      <label
                        className="checkbox text-base bg-gray-200 rounded-lg px-2 py-1"
                        htmlFor={item.id_pm}
                      >
                        <p className=" text-graySoft">{item.digest_pm}</p>
                        <input
                          type="checkbox"
                          id={item.id_pm}
                          className="mr-2"
                          value={item.id_pm}
                          defaultChecked={item.checked_pm}
                          {...register(item.id_pm)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>loading...</>
            )}
          </div>
          <div className="w-full flex justify-between px-4 py-4 items-center">
            <button>
              <div
                onClick={onAction}
                className="text-blueForm text-lg rounded-full p-2"
              >
                Cancelar
              </div>
            </button>
            <Button
              type="submit"
              className="relative w-40 bg-primary py-5 px-6 text-white text-base rounded-full flex justify-start items-center"
            >
              <span className="mr-2">Enviar</span>
              <img
                src={arrow_button}
                className="vertical-absolute-center right-3"
                alt=""
              />
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default FormVisitLandlord;
