import { useContext } from "react";
import { MainModalContext } from "../../context/MainModalContext";
// import FilterForm from "./FilterForm";
import "./MainModal.scss";
import React from "react";
interface IModal {
  children: React.ReactNode | JSX.Element | JSX.Element[] | React.ReactNode[];
  type: string;
}

const MainModal = ({ children, type = "default" }: IModal) => {
  const { modal, handleMainModal, modalContent } = useContext(MainModalContext);

  return (
    <>
      {modal && (
        <>
          {(() => {
            switch (type) {
              case "default":
                return (
                  <ModalDefault handleModal={handleMainModal}>
                    {children}
                  </ModalDefault>
                );
              case "complete":
                return (
                  <ModalComplete handleModal={handleMainModal}>
                    {children}
                  </ModalComplete>
                );
              default:
                return null;
            }
          })()}
        </>
      )}
    </>
  );
};

const ModalDefault = ({ children, handleModal }: any) => {
  return (
    <>
      <div className="modal">
        <div className="modal_content">
          <div className="flex justify-between p-5 bg-blueForm text-white font-semibold border rounded-t-lg">
            <button
              onClick={() => {
                handleModal();
                document.body.style.overflowY = "scroll";
              }}
            >
              <img
                src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/invest/close-modal.svg"
                alt="close_modal"
              />
            </button>
          </div>
          <div className="modal_body p-5 bg-white">{children}</div>
        </div>
      </div>
    </>
  );
};

const ModalComplete = ({ children, handleModal }: any) => {
  return (
    <>
      <div className="modal-complete">
        <div className="modal_content">
          <div className="flex justify-between p-4 text-white">
            <button
              onClick={() => {
                handleModal();
                document.body.style.overflowY = "scroll";
              }}
            >
              <img
                src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/close_modal.svg"
                alt="close_modal"
              />
            </button>
          </div>
          <div className="modal_body p-4">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainModal;
