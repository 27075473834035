import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const TenantProfileLoading = () => {
    return (<>
        <section className='pt-24' >
            <div className="flex w-full items-center justify-center">
                <Skeleton circle width={100} height={100} />
            </div>
            <div className="container flex items-center justify-center">
                <div className="leading-loose w-1/2 text-center my-6 text-third text-lg">
                    <Skeleton count={2} height={40} />
                </div>
            </div>
        </section>
        <hr />
        <section>
            <div className="container">
                <div className="m-auto w-full">
                    <div className="w-full my-10">
                    <div className="container flex items-center justify-center">
                        <div className="leading-loose w-full text-center my-6 text-third text-lg">
                            <Skeleton count={12} height={50} />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}

export default TenantProfileLoading
