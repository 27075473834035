
interface Props {
    label: string;
    value: string;
    bg?: boolean;
}

const DetailLabel = (props: Props) => {
    const { label, value, bg } = props
    return (
        <>
            <div className="lg:px-2 w-full lg:w-2/3">
                <div className={`w-full px-5 py-4 ${ bg ? 'bg-tea' : ''} rounded-lg`}>
                    <div className="w-full md:flex md:flex-start">
                        <div className="text-blueForm font-normal w-1/2"> { label } </div>
                        <div className="text-graySoft "> { value } </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailLabel;
