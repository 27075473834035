import React, { useCallback, useEffect, useState } from "react";
import PolygonMap from "../../components/PolygonMap/PolygonMap";
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import {
  polygonListEndpoint,
  polygonByParentEndpoint,
  polygonListEndpointExcept,
} from "./services/polygonMapService";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";
import "./scss/PolygonMap.scss";
import { AppStore } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import loadingLogo from "../../assets/fade-stagger-circles.svg";
import { PolygonApiResponse } from "./models/polygonModel";
import { hideFollowUpAction } from "../../redux/features/polygonsUserFollowUp";
import FollowUpUserForm from "./components/FollowUpUserForm";
import { Dialog } from "@mui/material";

const PolygonMapHome: React.FC = () => {
  const {
    level,
    parent,
    showCombination,
    excludePolygon,
    back,
    ne_coordinate,
    sw_coordinate,
  } = useSelector((store: AppStore) => store.levelsMap);
  const polygonsUserFollowUp = useSelector(
    (store: AppStore) => store.polygonsUserFollowUp
  );
  const { country } = useSelector((store: AppStore) => store.country)
  const [zoomData] = useState(9);
  const [loading, setLoading] = useState(false);
  const [metaPrice, setMetaPrice] = useState<any>({});

  const { callEndpoint } = useCallApiAndLoad();
  const dispatch = useDispatch();

  const [polygons, setPolygons] = useState<PolygonApiResponse[]>([]);

  const loadPolygons = useCallback(
    async (levelData: any) => {
      setLoading(true);
      const { status, data } = await callEndpoint(
        polygonListEndpoint(levelData)
      );
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setPolygons(response.data);
        setMetaPrice(response.metadata);
        setLoading(false);
      } else {
        setLoading(false);
      }
    },
    [callEndpoint]
  );

  const loadPolygonsParent = useCallback(
    async (parentData: any) => {
      if (!parentData) {
        return;
      }
      setLoading(true);
      const { status, data } = await callEndpoint(
        polygonByParentEndpoint(parentData)
      );
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setPolygons(response.data);
        setMetaPrice(response.metadata);
        setLoading(false);
      } else {
        setLoading(false);
      }
    },
    [callEndpoint]
  );

  const loadCombinatedPolygons = useCallback(
    async (parentData: any, levelData: any, exceptId: any) => {
      setLoading(true);
      const { status, data: parentResponse } = await callEndpoint(
        polygonByParentEndpoint(parentData)
      );
      if (status === 200) {
        const responseParent = fetchResponseAdapter(parentResponse);
        if (responseParent?.data?.length > 0) {
          setPolygons([...responseParent.data]);
          setMetaPrice(responseParent.metadata);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }

      //
      const { status: statusLevel, data } = await callEndpoint(
        polygonListEndpointExcept(levelData, ne_coordinate, sw_coordinate)
      );
      if (statusLevel === 200) {
        const response = fetchResponseAdapter(data);
        let nearPolygon: any[] = [];
        nearPolygon = response.data.map((pol: any) => ({
          ...pol,
          excludePaint: true,
        }));
        const excludePolygonList = nearPolygon.filter(
          (pol: any) => pol.id !== exceptId
        );
        setPolygons((currentState) => [...currentState, ...excludePolygonList]);
        setLoading(false);
        // setPolygons([])
      } else {
        setLoading(false);
      }
    },
    [callEndpoint]
  );

  useEffect(() => {
    if (showCombination === 0) {
      loadPolygons(level).catch((e) => console.error(e));
    }
  }, [level]);

  useEffect(() => {
    if (back === 1) {
      loadPolygons(level).catch((e) => console.error(e));
    }
  }, [back]);

  useEffect(() => {
    if (showCombination === 1) {
      loadCombinatedPolygons(parent, level, excludePolygon).catch((e) =>
        console.error(e)
      );
      // loadPolygonsParent(parent).catch((e) => console.error(e));
    } else {
      loadPolygonsParent(parent).catch((e) => console.error(e));
    }
  }, [level, showCombination, excludePolygon, parent]);

  return (
    <div className="w-full relative">
      {loading && (
        <div className="absolute right-5 top-5 z-10">
          <img className="w-20 h-auto" src={loadingLogo} alt="" />
        </div>
      )}

      <div className="h-screen relative">
        <PolygonMap
          zoom={zoomData}
          center={country === 'sv' ? { lat: 13.650005, lng: -88.899994 } : { lat: 14.645526, lng: -90.951534}}
          polygons={loading ? [] : [...polygons]}
          loading={loading}
          prices={metaPrice}
        />
      </div>

      
      <Dialog
        open={polygonsUserFollowUp.show_follow_up_modal}
        onClose={() => dispatch(hideFollowUpAction({}))}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <FollowUpUserForm
          onSuccessProp={() => dispatch(hideFollowUpAction({}))}
        />
      </Dialog>
    </div>
  );
};

export default PolygonMapHome;
