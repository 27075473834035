import axios from "axios";

const loadAbort = () => {
  return new AbortController();
};

export const getDocumentsCatalog = (country: string) => {
    const controller = loadAbort();
    return {
      call: (urlService: string) =>
        axios({
          method: "get",
          url: `${urlService}/user/v1/documents/config?countryCode=${country}`,
          headers: {
            "Content-Type": "application/json",
          },
        }),
      controller,
    };
  };

  export const sendDocuments = (token: string, payload: any) => {
    const controller = loadAbort();
    return {
      call: (urlService: string) =>
        axios({
          method: "post",
          url: `${urlService}/user/v1/tenant/document`,
          data: payload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }),
      controller,
    };
  };

  export const sendDocumentsLandlord = (token: string, payload: any) => {
    const controller = loadAbort();
    return {
      call: (urlService: string) =>
        axios({
          method: "post",
          url: `${urlService}/user/v1/landlord/document`,
          data: payload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }),
      controller,
    };
  };

  

  export const getExistDocuments = (token: string) => {
    const controller = loadAbort();
    return {
      call: (urlService: string) =>
        axios({
          method: "get",
          url: `${urlService}/user/v1/tenant/document`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }),
      controller,
    };
  };

  export const getExistDocumentsLd = (token: string) => {
    const controller = loadAbort();
    return {
      call: (urlService: string) =>
        axios({
          method: "get",
          url: `${urlService}/user/v1/landlord/document`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }),
      controller,
    };
  };
  

  