import React, { memo } from "react";
import NavBar from "../../components/NavBar/NavBar";
import FooterComponent from "../../components/Footer";

const Page404 = memo(() => {
  return (
    <>
      <NavBar />
      <div className="h-screen w-full bg-white flex justify-center items-center">
        <div className="w-full max-w-xl px-4 lg:px-0">
          <img
            src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/404.svg"
            alt=""
          />
          <h1 className="font-bold text-2xl lg:text-3xl text-[#343A40] text-center mt-10">
            ¡Oh no! Esta página no está disponible
          </h1>
          <p className="text-sm lg:text-base text-[#2C2E2B] text-center font-normal mt-4">
            Te invitamos a explorar otras secciones de nuestro sitio. <br /> ¡La
            propiedad de tus sueños está a un solo click de distancia!
          </p>
        </div>
      </div>
      <FooterComponent />
    </>
  );
});
Page404.displayName = "Page404";
export default Page404;
