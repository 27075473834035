import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAuthToken, unsetAuth } from "../../helpers/authHelper";
import { AppStore } from "../../redux/store";
import payway from "../../assets/icons/payments/payway.png";
import bank from "../../assets/icons/payments/cusca.png";
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";
import { getLandlordCheckoutEndpoint } from "./services/investorService";
import { logoutAction } from "../../redux/features/auth";
import { TransactionCheckout, TransferInfo } from "./models/investorModel";
import PropertyRow from "../../components/RentDetail";
import Modal from "../../components/Modal";
import ModalPayment from "../../components/Payments/ModalPayment";
import TransferPayment from "../../components/Payments/TransferPayment";
import Tooltip from "@mui/material/Tooltip";

import "./scss/Checkout.scss";
import { Dialog } from "@mui/material";

const InvestorPaymentCheckout = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  const { transaction_id = "", id: transaction_detail_id = "" } = useParams();
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const [data, setData] = useState<TransactionCheckout>();
  const [loading, setLoading] = useState<boolean>(false);
  const [payment, setPayment] = useState<string>("pay");
  const [showPayment, setShowPayment] = useState(false);
  const { callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const loadPaymentDetail = async () => {
      const paymentMethodMapper = {
        pay: "card",
        transfer: "wire_transfer",
      };
      setLoading(true);
      const { status, data } = await callEndpoint(
        getLandlordCheckoutEndpoint(
          token,
          transaction_id,
          transaction_detail_id,
          paymentMethodMapper[payment as keyof typeof paymentMethodMapper]
        )
      );
      if (status === 401) {
        dispatch(logoutAction({}));
        unsetAuth();
        setLoading(false);
        return navigate(`/app/${country}/auth/login`);
      }
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setData(response.data);
        setLoading(false);
        if (response.data.is_available_card_transactions === false) {
          setPayment("transfer");
        } else if (response.data.is_available_transfer_transactions === false) {
          setPayment("pay")
        }
      }
    };
    loadPaymentDetail().catch((e) => console.error(e));
  }, [payment]);

  const methodPay = (e: any) => {
    setPayment(e.target.value);
  };

  const togglePay = () => {
    try {
      setShowPayment((old) => !old);
      document.body.style.overflow = "scroll";
    } catch (error) {
      console.log("error");
    }
  };

  return (
    <>
      <div className="container">
        <div className="w-full py-28">
          <div className="m-auto w-full px-4 lg:px-0">
<h1 className="text-blackGrayScale text-2xl lg:text-2xl font-semibold text-left tracking-wide">
            Realiza tus pagos
          </h1>
          <p className=" text-lg font-medium text-gray600 mb-2 mt-2">
              Pago de {data?.preSaleDetail[0]?.label}
            </p>
          </div>
          <div className="w-full max-w-[960px] mx-auto">
            <div className="xl:pb-10">
              <div className="w-full px-4 lg:px-0 flex justify-center mx-auto mt-12">
                <div className="rounded-lg border border-[#E0E0E0] py-4 px-4 w-full flex flex-col lg:flex-row justify-start items-center">
                    <img className="rounded-lg h-32 w-full xl:w-80 object-cover object-center" src={data?.preSaleInfo.project_cover} alt="img-property" />
                    <div className="w-full xl:pl-6 py-4 lg:py-0">
                      <h2 className="text-2xl font-semibold text-[#212121]">{data?.preSaleInfo?.project_name}</h2>
                        <p className="font-semibold text-[#212121] text-sm xl:text-base">Valor de unidad: {data?.preSaleInfo.unit_price}</p>
                        <p className="text-sm xl:text-base font-semibold text-[#212121]">Direccion: <span className="font-normal">{data?.preSaleInfo.project_address}</span></p>
                    </div>
                </div>
              </div>
            </div>
            {!loading ? (
              <div className="w-full flex flex-wrap justify-center items-start px-4 ">
                <div className="w-full my-8 lg:my-0 mx-auto max-w-[450px] border border-tea-500 rounded-lg p-4 lg:px-7 px-4">
                  <div className="w-full py-4">
                    <p className="text-base text-[#212121] font-bold">
                      Detalle de pago
                    </p>
                  </div>
                  <hr className=" w-full border-tea-500" />
                  <div className="w-full py-4">
                    {data?.preSaleDetail.map((item) =>
                      item.is_total ? (
                        <div className="w-full mb-2 text-[#616161] flex justify-between">
                          <div>
                            <span> {item.label}</span>
                            {item?.description !== null && (
                              <>
                                <Tooltip
                                  className="cursor-pointer"
                                  title={item?.description}
                                  placement="top"
                                  enterTouchDelay={0}
                                  arrow
                                >
                                  <img
                                    className="w-5 h-5 "
                                    src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/invest/info.svg"
                                    alt=""
                                  />
                                </Tooltip>
                              </>
                            )}
                          </div>

                          <span> {item.value}</span>
                        </div>
                      ) : (
                        <div className="w-full mb-2 text-[#616161] flex justify-between">
                          <div className="flex justify-between items-center gap-1">
                            <span> {item.label}</span>
                            {item?.description !== null && (
                              <>
                                <Tooltip
                                  className="cursor-pointer"
                                  leaveDelay={100}
                                  title={item?.description}
                                  placement="top"
                                  enterTouchDelay={0}
                                  arrow
                                >
                                  <img
                                    className="w-4 h-4"
                                    src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/invest/info.svg"
                                    alt=""
                                  />
                                </Tooltip>
                              </>
                            )}
                          </div>

                          <span> {item.value}</span>
                        </div>
                      )
                    )}
                  </div>
                  <hr className=" w-full border-tea-500" />
                  <div className="mt-4 mb-2">
                    <div className="w-full bg-[#F2F6EF] px-4 rounded-full py-4 flex justify-between items-center">
                      <span className="text-[#212121] font-semibold">
                        Total a cancelar
                      </span>
                      <span className="text-[#212121] text-xl font-bold">
                        {data?.total}
                      </span>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="w-full my-4">
                      <p className="text-[#212121] text-lg font-semibold text-center">
                        Método de pago
                      </p>
                    </div>

                    {(data?.is_available_card_transactions && data?.is_available_transfer_transactions ) && (
                      <div className="w-full text-center pb-4 items-center flex flex-wrap justify-between">
                        <span className=" text-[#757575] font-medium text-base">
                          Seleccionar
                        </span>
                        <div className="flex justify-between items-center">
                          <div className="flex items-center justify-center">
                            <label className="customRadio">
                              Tarjeta
                              <input
                                type="radio"
                                value="pay"
                                name="radio"
                                defaultChecked={payment === "pay"}
                                onChange={(e) => methodPay(e)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>

                          <div className="ml-2 flex items-center justify-center">
                            <label className="customRadio">
                              Transferencia
                              <input
                                type="radio"
                                value="transfer"
                                name="radio"
                                defaultChecked={payment === "transfer"}
                                onChange={(e) => methodPay(e)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    )}

                    {payment !== "pay" && (
                      <TransferPayment
                        typeTransfer="pre-sales"
                        contract_id={transaction_id}
                        transaction_detail_id={transaction_detail_id}
                        projectId={data?.preSaleInfo?.project_id as string}
                        realm="landlord"
                      />
                    )}
                    {payment === "pay" && (
                      <div>
                        <div className="border border-tea-500 rounded-lg hover:shadow-sm pb-4 px-6">
                          <div className="w-full text-center my-6 ">
                          <p className="text-[#212121] font-semibold text-lg">Realizar Pago</p>
                            <img
                              className="w-48 mx-auto mt-3"
                              src={data?.cardProviderInfo?.provider_logo}
                              alt=""
                            />
                          </div>
                          <div className="w-full my-6 flex justify-center">
                            <button
                              onClick={togglePay}
                              className="bg-[#212121] py-3 w-full text-white text-base rounded-lg flex justify-center items-center group"
                            >
                              <span className="">
                                Realizar pago
                              </span>
                             
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="h-[20vh] justify-center items-center w-full bg-white hidden md:flex">
                <img
                  className="w-16"
                  src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/loading-propi.gif"
                  alt="loading"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={showPayment}
        onClose={togglePay}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalPayment
              onAction={togglePay}
              extraAction={togglePay}
              contractId={transaction_id}
              transactionId={transaction_detail_id}
              name="Pago de reserva"
              btnText="Pagar"
              realm="landlord"
            />
        </Dialog>
    </>
  );
};

export default InvestorPaymentCheckout;
