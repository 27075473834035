import React, { useState } from "react";

const useMainModal = () => {
  let [modal, setModal] = useState(false);
  let [modalContent, setModalContent] = useState("I'm the Modal Content");

  let handleMainModal = (content: any = false) => {
    setModal(!modal);
    if (content) {
      setModalContent(content);
    }
  };

  return { modal, handleMainModal, modalContent };
};
export default useMainModal