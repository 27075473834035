import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import moreIcon from "../../assets/icons/r-arrow.svg";
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import { getAuthToken, unsetAuth } from '../../helpers/authHelper';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { logoutAction } from '../../redux/features/auth';
import { AppStore } from '../../redux/store';
import { PaymentList } from './models/investorModel';
import { getInvestorPaymentsEndpoint } from './services/investorService';

const InvestorPayments = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const [data, setData] = useState<Array<PaymentList>>([]);
  const [checkAuto, setCheckAuto] = useState<boolean>(false)

  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const loadPayments = async () => {
      // TODO add new fields from backend and new table
      const { status, data } = await callEndpoint(
        getInvestorPaymentsEndpoint(token)
      );
      if (status === 401) {
        dispatch(logoutAction({}));
        unsetAuth();
        return navigate(`/app/${country}/auth/login`);
      }
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setData(response.data);
      }
    };
    loadPayments()
      .catch((e) => console.error(e));
  }, []);

  const gotToMarket = () => {
    window.open(`/${country}/venta/casas-y-apartamentos/proyectos`, '_self');
  }

  return (
    <div className="container mx-auto px-4 xl:px-0">
      <div className="w-full py-24">
        <div className="w-full mt-6 mb-2">
              <h1 className="text-blackGrayScale text-2xl lg:text-2xl font-semibold text-left tracking-wide">Gestiona tus proyectos de inversión </h1> 
            </div>
            <div className="w-full mb-6 mt-2">
          <p className=" text-lg font-medium text-gray600 mb-2"> Inversiones </p>
          </div>
       
        <div className="w-full my-8 xl:mt-20 mt-12">
          <div className='grid md:grid-cols-2 xl:grid-cols-3 gap-6'>
          {
            data && data.length > 0 ? (
              data.map((items, index) => {
                return (
                  <div key={index} className="card-property pt-4 px-4">
                      <div className="flex justify-between flex-wrap">
                        <div className="w-3/4 pr-2">
                          <h2 className="font-medium text-lg truncate text-blackGrayScale leading-tight">
                            {items.model_name}
                          </h2>
                          
                          <p className='text-sm text-[#616161]'>
                            {items?.project_address}
                          </p>
                        </div>
                        <div className="w-1/4">
                          <img
                            className="w-full h-20 object-cover object-center"
                            src={items?.project_cover}
                            alt=""
                          />
                        </div>
                        <div className="w-full mt-3">
                          <p className=" text-gray600 text-sm">
                            <span className="font-semibold">Fecha estimada de entrega: </span>{items?.estimate_delivered_at}
                          </p>
                        </div>
                        <div className="w-full mt-1">
                          <p className=" text-gray600 text-sm">
                          <span className="font-semibold">Valor total:</span> {items?.unit_price}
                          </p>
                        </div>
                        <div className="w-full mt-1">
                          <p className=" text-gray600 text-sm">
                          <span className="font-semibold">Unidad:</span> {items?.unit_name}
                          </p>
                        </div>
                        
                        <div className="w-full py-3 flex justify-center items-center border-t border-[#E0E0E0] mt-4">
                          <Link
                          to={`/app/${country}/l/ld/make-payment/${items.id}`}
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          <button className="text-blackGrayScale font-medium text-sm">
                            Ver detalle
                          </button>
                        </Link>
                        </div>
                      </div>
                    </div>
                )
              })
            ) : (<></>)
          }
          </div>
          

          {/* {data && data.length > 0 ? (
            data.map((items) => {
              return (
                <div key={items.id} className="border border-tea-500 mb-10 rounded">
                  <div className="hidden lg:flex justify-start py-6 px-4 items-center">
                    <div className="text-graySoft border-r border-tea-500 w-60">
                      <img
                        src={items.project_cover}
                        className="w-[220px] h-[120px] "
                        alt=""
                      />
                    </div>
                    <div className="flex justify-center items-center text-sm text-graySoft border-r border-tea-500 w-32">
                      {items.project_code}
                    </div>
                    <div className="flex text-center justify-center items-center text-sm text-graySoft border-r border-tea-500 w-36">
                      {items.project_address}
                    </div>
                    <div className="flex justify-center items-center text-sm text-graySoft border-r border-tea-500 w-36">
                      {items.unit_price}
                    </div>
                    <div className="flex justify-center items-center text-sm text-graySoft border-r border-tea-500 w-36">
                      {items.unit_name}
                    </div>
                    <div className="flex justify-center items-center text-sm text-graySoft border-r border-tea-500 w-48">
                      {items.estimate_delivered_at}
                    </div>

                    <div className="text-graySoft text-sm font-medium flex justify-end items-center">
                      <div className="text-center flex items-center ml-4 ">
                        <Link
                          to={`/app/${country}/l/ld/make-payment/${items.id}`}
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          <button className="duration-300 hover:translate-x-4 cursor-pointer text-sm flex justify-center items-center">
                            Ver detalle
                            <img className="ml-3" src={moreIcon} alt="" />
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  
                  <div className="block lg:hidden px-3 py-3 shadow-md">
                    <div className="w-full px-2 py-3 bg-gray300 rounded-md mb-2">
                      <span className="text-sm font-medium text-grayText">
                        Información de Proyecto
                      </span>
                      <p className="text-sm font-normal text-graySoft">
                      </p>
                    </div>

                    <div className="w-full px-2 py-3 bg-tea-100 rounded-sm flex justify-between">
                      <div className="w-1/2">
                        <span className="text-sm font-medium text-grayText">
                          ID
                        </span>
                        <p className="text-sm font-normal text-graySoft">
                          {items.project_code}
                        </p>
                      </div>
                      <div className="w-1/2">
                        <span className="text-sm font-medium text-grayText">
                          Dirección
                        </span>
                        <p className="text-sm font-normal text-graySoft">
                          {items.project_address}
                        </p>
                      </div>
                    </div>

                    <div className="w-full px-2 py-3 bg-white rounded-sm flex justify-between">
                      <div className="w-1/2">
                        <span className="text-sm font-medium text-grayText">
                          Precio
                        </span>
                        <p className="text-sm font-normal text-graySoft">
                          {items.unit_price}
                        </p>
                      </div>
                      <div className="w-1/2">
                        <span className="text-sm font-medium text-grayText">
                          Unidad
                        </span>
                        <p className="text-sm font-normal text-graySoft">
                          {items.unit_name}
                        </p>
                      </div>
                    </div>

                    <div className="w-full px-2 py-3 bg-tea-100 rounded-sm flex justify-between">
                      <div className="w-full">
                        <span className="text-sm font-medium text-grayText">
                          Fecha estimada de entrega
                        </span>
                        <p className="text-sm font-normal text-graySoft">
                          {items.estimate_delivered_at}
                        </p>
                      </div>
                    </div>
                    <div className="w-full flex justify-center mt-4">
                      <Link
                        to={`/app/${country}/l/ld/make-payment/${items.id}`}
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <p className=" text-sm font-medium text-graySoft flex items-center">
                          Ver detalle
                          <img className="ml-3" src={moreIcon} alt="" />
                        </p>
                      </Link>
                    </div>
                  </div>
                
                </div>
              );
            })
          ) : (
            <>
              <div className="w-full flex flex-col justify-center items-center bg-empty-state py-10 h-[250px]">
               <p className='lg:text-base text-sm text-graySoft'>Parece ser que no tienes inversiones.</p> 
                <div onClick={gotToMarket} className="border-b-[1.35px] ease-in-out duration-300 cursor-pointer hover:translate-x-4 border-orange w-fit  text-orange text-left flex items-center justify-start">
                Ver proyectos <span className="ml-2"><img src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/arrow-orange.svg" alt="" /></span>
              </div>
              </div>
            </>
          )} */}

        </div>
      </div>
    </div>
  )
}

export default InvestorPayments