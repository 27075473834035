import { useState } from "react";
import useCallApiAndLoad from "../../../hooks/useCallApiAndLoad";
import { fetchResponseAdapter } from "../../../adapters/fetchAdapter";
import { getDocumentsCountry } from "../../../services/offerService";

export interface DocumentData {
    id: string;
    document: string;
    code: string;
    country: string;
}
export const useDocuments = (): any => {
    const [documents, setDocuments] = useState([])
    const [loading, setLoading] = useState<boolean>(false)
    const { isLoading, callEndpoint } = useCallApiAndLoad()

    const getDataDocuments = async (code: string): Promise<void> => {
        setLoading(true) 
        try {
            const { status, data } = await callEndpoint(getDocumentsCountry(code))
            if (status === 200) {
                const response = fetchResponseAdapter(data)
                console.log(response)
                setDocuments(response?.data?.documents)
                setLoading(false)
            }
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    return {
        documents,
        getDataDocuments,
        loading
    }
}