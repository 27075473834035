import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { fetchResponseAdapter } from "../../adapters/fetchAdapter"
import FooterComponent from "../../components/Footer"
import { getAuthToken } from "../../helpers/authHelper"
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad"
import { AppStore } from "../../redux/store"
import { loadBanksEndpoint, updateBankInfoEndpoint } from './services/landlordService';
// form
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form';

import { UpdateBankAccountForm } from "./models/landlordModel"
import { listToSelectOptionAdapter } from "../../adapters/listsAdapter"
//assets
import Button from "../../components/Button"
import arrow_button from '../../assets/icons/arrow_button.svg';
import arrow_left from '../../assets/icons/arrow_left.svg';
import FormControlError from "../../components/Controls/FormControlError";

const UpdateBankInfo = () => {
    const { country } = useSelector((store: AppStore) => store.country);
    const location = useLocation()
    const user = useSelector((store: AppStore) => store.auth)
    const token = getAuthToken(user)
    const navigate = useNavigate()
    const { isLoading, callEndpoint } = useCallApiAndLoad()
    const [banksList, setBanksList] = useState<Array<any>>([])
    const [typesList, setTypesList] = useState<Array<any>>([])
    const [defaultForm, setDefaultForm] = useState<any>(location.state)
    const {
        register, handleSubmit, formState: { errors }, reset
    } = useForm<UpdateBankAccountForm>({
        resolver: yupResolver(yup.object({
            bank_id: yup.string()
                .required('Banco es requerido'),
            bank_headline: yup.string()
                .required('Titular de cuenta es requerido'),
            bank_account_type: yup.string()
                .required('Tipo de cuenta es requerido'),
            bank_account: yup.string()
                .required('Número de cuenta es requerido'),
        }).required())
    })

    useEffect(() => {
        const loadBanks = async () => {
            const { status, data } = await callEndpoint(loadBanksEndpoint())
            if (status === 200) {
                const response = fetchResponseAdapter(data)
                const {
                    banks,
                    types
                } = response.data
                setBanksList(listToSelectOptionAdapter(banks, 'id', 'bank_name'))
                setTypesList(listToSelectOptionAdapter(types, 'type_id', 'type'))
            }
        }
        loadBanks()
            .catch(e => console.error(e))
    }, [])

    useEffect(() => {
        if (defaultForm) {
            reset({
                bank_id: defaultForm.bank_id,
                bank_headline: defaultForm.bank_headline,
                bank_account_type: defaultForm.bank_account_type_code,
                bank_account: defaultForm.bank_account,
                company_name: defaultForm.company_name,
            })
        }
    }, [])

    const doUpdate = async (form: UpdateBankAccountForm) => {
        const { status } = await callEndpoint(updateBankInfoEndpoint(form, token))
        if (status === 200) {
            return navigate(`/app/${country}/l/ld/bank-info`)
        }
    }

    return (
        <form onSubmit={handleSubmit(doUpdate)}>
            <section className='pt-24' >
                <div className="w-full text-center my-6 text-third text-lg">
                    Actualiza tus datos bancarios
                </div>
                <div className="m-auto w-full">
                    <div className="w-full my-6 text-third text-4xl font-bold text-center">
                        Edita tus datos
                    </div>
                </div>
            </section>
            <hr />
            <section>
                <div className="m-auto w-full">
                    <div className="w-full my-10">
                        <div className="px-4 lg:px-2 w-full lg:w-2/3">
                            <div className="w-full flex-column justify-between items-center my-6">
                                <div className="w-full">
                                    <label className="text-blueForm font-regular">
                                        Nombre de la entidad bancaria
                                    </label>
                                    <select
                                        {...register("bank_id")}
                                        className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3">
                                        <option value="">Seleccione un banco...</option>
                                        {banksList.map(c => {
                                            const isSelected = c.value === defaultForm.bank_id
                                            return <option selected={isSelected} value={c.value}>{c.label}</option>
                                        })}
                                    </select>
                                    <FormControlError message={errors.bank_id?.message} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="px-4 lg:px-2 w-full lg:w-2/3">
                            <div className="w-full flex justify-between items-center my-6">
                                <div className="w-full">
                                    <label className="text-blueForm font-regular">
                                        Titular de la cuenta
                                    </label>
                                    <input
                                        type="text"
                                        placeholder='Titular de la cuenta'
                                        className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3"
                                        {...register("bank_headline")}
                                    />
                                    <FormControlError message={errors.bank_headline?.message} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="px-4 lg:px-2 w-full lg:w-2/3">
                            <div className="w-full flex justify-between items-center my-6">
                                <div className="w-full">
                                    <label className="text-blueForm font-regular">
                                        Número de cuenta
                                    </label>
                                    <input
                                        type="text"
                                        placeholder='Número de cuenta'
                                        className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3"
                                        {...register("bank_account")}
                                    />
                                    <FormControlError message={errors.bank_account?.message} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="px-4 lg:px-2 w-full lg:w-2/3">
                            <div className="w-full flex justify-between items-center my-6">
                                <div className="w-full">
                                    <label className="text-blueForm font-regular">
                                        Tipo de cuenta
                                    </label>
                                    <select
                                        {...register("bank_account_type")}
                                        className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3">
                                        <option value="">Seleccione un tipo de cuenta...</option>
                                        {typesList.map(c => {
                                            const isSelected = c.value === defaultForm.bank_account_type_code
                                            return <option selected={isSelected} value={c.value}>{c.label}</option>
                                        })}
                                    </select>
                                    <FormControlError message={errors.bank_account_type?.message} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="px-4 lg:px-2 w-full lg:w-2/3">
                            <div className="w-full flex justify-between items-center my-6">
                                <div className="w-full">
                                    <label className="text-blueForm font-regular">
                                        Empresa o persona natural
                                    </label>
                                    <input
                                        type="text"
                                        placeholder='Empresa o persona natural'
                                        className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3"
                                        {...register("company_name")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <hr />
            <section className='my-4'>
                <div className="flex justify-between items-center py-4 px-4">
                    <a onClick={() => navigate(-1)}
                        className='cursor-pointer flex justify-center items-center' >
                        <img className='mr-2' src={arrow_left} alt="" />
                        Regresar
                    </a>
                    <div className="w-56">
                        <Button
                            disabled={isLoading}
                            className="relative w-full bg-primary py-5 px-4 my-4 text-white text-base rounded-full flex justify-center items-center">
                            <span className="mr-2">Continuar</span>
                            <img src={arrow_button} className="vertical-absolute-center right-3" alt="" />
                        </Button>
                    </div>
                </div>
            </section>
        </form>
    )
}

export default UpdateBankInfo
