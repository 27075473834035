import { useNavigate } from "react-router-dom";

import ModalOffer from "../../components/Offer/ModalOffer";
// hooks
import { useDispatch, useSelector } from "react-redux";
import { getAuthToken, setLastPath, unsetAuth } from "../../helpers/authHelper";
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import { useEffect, useState } from "react";
import FormVisit from "./components/FormVisit/FormVisit";
// helpers
import { AppStore } from "../../redux/store";
import { logoutAction } from "../../redux/features/auth";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";
// services
import { getTenantVisitsEndpoint } from "./services/tenantService";

import loading from "../../assets/loading.gif";
import { VisitListItem } from "./models/tenantModel";
import { setShowOfferProcessAction } from "../../redux/features/offer";
import { Dialog } from "@mui/material";
import CardVisit from "./components/CardVisit/CardVisit";

const TenantMyVisits = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const { callEndpoint } = useCallApiAndLoad();
  const [offers, setOffers] = useState<Array<any>>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Offer modal is managed by redux state, because of consent modal switch
  const offer = useSelector((store: AppStore) => store.offer);

  // Modales
  const [showDeal, setShowDeal] = useState<boolean>(false);
  const [showSchedule, setShowSchedule] = useState<boolean>(false);
  const [showReschedule, setShowReschedule] = useState<boolean>(false);
  const [showChangeOffer, setShowChangeOffer] = useState<boolean>(false);
  const [showAccept, setShowAccept] = useState<boolean>(false);
  const [singleOffert, setSingleOffer] = useState<VisitListItem | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const callback = (data: boolean, info?: any) => {
    try {
      console.log(info);
      setSingleOffer(info);
      if (info.type === "ofertar") {
        dispatch(setShowOfferProcessAction(data));
      } else if (info.type === "cambiar-ofertar") {
        setShowChangeOffer((data) => !data);
      } else if (info.type === "negociar") {
        setShowDeal((data) => !data);
      } else if (info.type === "reagendar") {
        setShowReschedule((data) => !data);
      } else if (info.type === "agendar") {
        setShowSchedule((data) => !data);
      } else if (info.type === "aceptar") {
        setShowAccept((data) => !data);
      }
      document.body.style.overflow = "";
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    const loadTenantProfile = async () => {
      setIsLoading(true);
      const { status, data } = await callEndpoint(
        getTenantVisitsEndpoint(token)
      );
      if (status === 401) {
        dispatch(logoutAction({}));
        setLastPath(`/app/${country}/l/tn/visits`);
        unsetAuth();
        return navigate(`/app/${country}/auth/login`);
      }
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setOffers(response.data.items || []);
        setIsLoading(false);
      }
    };
    loadTenantProfile().catch((e) => console.error(e));
  }, []);

  return (
    <>
      {/* <NavBar /> */}
      <div className="container mx-auto">
        <div className="w-full pt-32 pb-14">
          <div className="m-auto w-full px-4 lg:px-0">
            <div className="w-full mb-2 text-blackGrayScale text-2xl lg:text-2xl font-semibold text-left tracking-wide">
              Visitas Realizadas
            </div>
            <p className=" text-lg font-medium text-gray600 mb-2">
              Ve el detalle de tus visitas
            </p>
          </div>

          {isLoading ? (
            <div className="flex justify-center items-center pt-20">
              <img src={loading} alt="" width={60} />
            </div>
          ) : (
            <>
              {offers.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-14 px-6 md:px-0">
                  {offers.map((item) => (
                    <div key={`of-${item.property_info?.id}`}>
                      <CardVisit data={item} action={callback} />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="w-full max-w-md mx-auto py-10 px-10">
                  <img
                    className=" w-48 mx-auto"
                    src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/empty-offer.svg"
                    alt=""
                  />

                  <h1 className="text-2xl font-semibold text-[#212121] text-center mt-4">
                    Sin resultados
                  </h1>
                  <p className="text-[#757575] text-center text-base">
                    Lo sentimos, no encontramos ninguna propiedad en la que hayas realizado una visita
                  </p>
                  <a
                    href={`/app/${country}/marketplace?marketplace_type=for_rent`}
                    target="_blank"
                    className="flex justify-center items-center w-48 px-8 py-2 rounded-lg bg-[#212121] text-white font-medium text-sm mt-4 mx-auto"
                    rel="noreferrer"
                  >
                    Ver propiedades
                  </a>
                </div>
              )}
            </>
          )}
        </div>
        <hr className=" border-[0.5px] border-[#E0E0E0]" />
        <div className="w-full pt-6 pb-10">
          <p className="text-sm font-normal text-[#757575]">
            ¿Tienes alguna duda?
            <a
              className="text-[#212121] font-medium"
              href={`/${country}/ayuda`}
            >
              {" "}
              Visita el Centro de ayuda
            </a>
          </p>
        </div>
      </div>

      <Dialog
        open={offer.show_modal}
        onClose={() => callback(false, { type: "ofertar" })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalOffer
          name="Ofertar"
          btnText="Ofertar"
          property_id={singleOffert?.property_info?.id || ""}
          rentInfo={singleOffert?.property_info?.rent_info}
          parent="tenant-offers"
        />
      </Dialog>

      <Dialog
        open={showReschedule}
        onClose={() => callback(false, { type: "reagendar" })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <FormVisit
          onAction={() => callback(false, { type: "reagendar" })}
          id={singleOffert?.property_info?.id || ""}
          visit_id={singleOffert?.visit_info?.id}
          isUpdate
        />
      </Dialog>

      {/* <Dialog
        open={showAccept}
        onClose={() => callback(false, { type: "aceptar" })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <FormAccept
                  onAction={() => callback(false, { type: "aceptar" })}
                  id={singleOffert?.offer_info?.id || ''}
                />
      </Dialog>
      <Dialog
        open={showChangeOffer}
        onClose={() => callback(false, { type: "cambiar-ofertar" })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      <FormReOffer
                onAction={() => callback(false, { type: "cambiar-ofertar" })}
                id={singleOffert?.id || ''}
                property_info={singleOffert?.property_info || null}
                offer_info={singleOffert?.offer_info || null}
              />
        </Dialog>

        <Dialog
        open={showDeal}
        onClose={() => callback(false, { type: "negociar" })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      <FormDeal
                onAction={() => callback(false, { type: "negociar" })}
                id={singleOffert?.id || ''}
                property_info={singleOffert?.property_info || null}
                offer_info={singleOffert?.offer_info || null}
              />
        </Dialog>
      {offer.show_modal && (
        <Modal
          onAction={() => callback(false, { type: "ofertar" })}
          name="Ofertar"
          size="max-w-lg"
          body={
            <ModalOffer
              name="Ofertar"
              btnText="Ofertar"
              property_id={singleOffert?.property_info.id || ''}
              rentInfo={singleOffert?.property_info.rent_info}
              parent="tenant-offers"
            />
          }
        />
      )}
      

      {showReschedule && (
        <Modal
          onAction={() => callback(false, { type: "reagendar" })}
          name="Reagendar visita"
          size="max-w-lg"
          body={
            <>
              <FormVisit
                onAction={() => callback(false, { type: "reagendar" })}
                id={singleOffert?.id || ''}
                visit_id={singleOffert?.visit_info?.id}
                isUpdate
              />
            </>
          }
        />
      )}

      {showSchedule && (
        <Modal
          onAction={() => callback(false, { type: "agendar" })}
          name="Agendar visita"
          size="max-w-lg"
          body={
            <>
              <FormVisit
                onAction={() => callback(false, { type: "agendar" })}
                id={singleOffert?.id || ''}
              />
            </>
          }
        />
      )} */}
    </>
  );
};
export default TenantMyVisits;
