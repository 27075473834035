import { useContext } from "react";
import { ModalContext } from "../context/ModalContext";
import FilterForm from "./FilterForm";
import "../scss/styles.scss";
import { IconX } from "@tabler/icons-react"

const ModalFilter = () => {
  const { modal, handleModal, modalContent } = useContext(ModalContext);
  return (
    <>
      {modal && (
        <div className="modal">
        <div className="modal_content">
          <div className="flex justify-between p-5 bg-white rounded-t-lg">
            <h1 className="text-2xl text-[#212121] font-semibold">{modalContent}</h1>
            <button onClick={() => {
              handleModal()
              document.body.style.overflowY = "scroll";    
          }}>
            <IconX />
          </button>
          </div>
          <div className="modal_body pb-5 px-6 bg-white -mt-1">
            <FilterForm />
          </div>
        </div>
      </div>
      )}
    </>
  );
};

export default ModalFilter;
