import NavBar from "../../components/NavBar/NavBar";
import FooterComponent from "../../components/Footer";
import about1 from "../../assets/images/about1.svg";
import about2 from "../../assets/images/about2.svg";
import about3 from "../../assets/images/about3.svg";
import about4 from "../../assets/images/about4.svg";
import aboutMoment from "../../assets/images/about-moment.jpg";
import arrow_button from "../../assets/icons/arrow_button.svg";

import aboutbanner2 from "../../assets/images/about-banner-2.jpg";
const AboutUs = () => {
  return (
    <>
      <NavBar></NavBar>
      <div className="w-full text-white py-16 px-4 lg:px-0 lg:py-20 lg:pt-28 bg-tea ">
        <div className="w-full text-base md:text-lg text-green800 text-center">Misión</div>
        <div className="font-medium text-2xl md:text-4xl text-green800 text-center mb-6">
          Democratizar la industria inmobiliaria
        </div>
        <div className="w-full lg:w-2/5 text-sm md:text-base text-graySoft max-w-2xl mx-auto text-center mt-4">
          Somos una Proptech que busca democratizar el mercado de bienes y
          raíces, creando oportunidades para todos los participantes con un
          servicio end-to-end a través de eficiencia y transparencia para
          propietarios, inquilinos, agentes y desarrolladoras.
        </div>
      </div>
      <div className="bg-primary py-24 w-full px-4 lg:px-0">
        <div className="container mx-auto text-white font-medium text-center text-3xl">
          Valores Propi
        </div>
        <div className="container mx-auto mt-6 flex justify-center items-start flex-wrap lg:flex-nowrap">
          <div className="w-full sm:w-1/2 lg:w-1/4 px-4 my-4">
            <div className="w-full">
              <img src={about1} alt="" className="mx-auto" />
            </div>
            <div className="w-full text-center text-white my-2 font-semibold">
              Transparencia
            </div>
            <div className="lg:text-base text-sm text-center text-white">
              Brindamos toda la información que necesitas para tomar decisiones
              seguras, sin letras pequeñas.
            </div>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/4 px-4 my-4">
            <div className="w-full">
              <img src={about2} alt="" className="mx-auto" />
            </div>
            <div className="w-full text-center text-white my-2 font-semibold">
              Inconformidad
            </div>
            <div className="lg:text-base text-sm text-center text-white">
              Retamos lo establecido, preguntándonos si lo existente es lo mejor
              o podemos mejorarlo.
            </div>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/4 px-4 my-4">
            <div className="w-full">
              <img src={about3} alt="" className="mx-auto" />
            </div>
            <div className="w-full text-center text-white my-2 font-semibold">
              Mentalidad startup
            </div>
            <div className="lg:text-base text-sm text-center text-white">
              Nos encanta aprender de nuestros errores. Actuamos rápido, con
              sentido de urgencia, sin sacrificar calidad.
            </div>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/4 px-4 my-4">
            <div className="w-full">
              <img src={about4} alt="" className="mx-auto" />
            </div>
            <div className="w-full text-center text-white my-2 font-semibold">
              Obsesionados
            </div>
            <div className="lg:text-base text-sm text-center text-white">
              No descansaremos hasta brindarle valor a cada usuario, brindándole
              productos de nivel mundial.
            </div>
          </div>
        </div>
      </div>
      <div className="w-full py-24">
        <div className="container mx-auto flex justify-center items-center flex-wrap lg:flex-nowrap">
          <div className="w-full lg:w-1/2 flex justify-end my-2 px-6">
            <div className="">
              <img src={aboutMoment} alt="" />
            </div>
          </div>
          <div className="my-2 w-full lg:w-1/2 px-6 py-4 lg:py-0">
            <div className="text-green800 text-2xl md:text-4xl mt-5  mb-2">
              Es tu momento de vivir
            </div>
            <div className="my-4 text-graySoft text-sm lg:text-base ">
              Sabemos lo importante que es encontrar la casa perfecta para
              vivir. En Propi promovemos la equidad en cada interacción y
              utilizamos la tecnología para garantizar una experiencia segura en
              cada etapa del proceso de alquiler y venta.
            </div>
          </div>
        </div>
      </div>
      <div className="bg-tea-500 py-20 text-grayText px-4">
        <div className="w-full text-base md:text-lg text-green800 text-center">Bienes raíces sin burocracia</div>
        <div className="font-medium text-2xl md:text-4xl text-green800 text-center">
          Reinventamos la transacción más importante de tu vida
        </div>
      </div>
      <div className="w-full ">
        <img src={aboutbanner2} className="w-full object-cover" alt="" />
      </div>

      <div className="w-full bg-tea-500 py-16">
        <div className="w-full text-center text-userInfo text-2xl font-medium">
          ¿Quiéres hablar con nosotros?
        </div>
        <div className="w-full text-center text-graySoft font-normal mt-2 mb-6">
          ¡Escríbenos! Juntos cambiemos la manera de hacer bienes y raíces
        </div>
        <div className="w-full lg:w-auto flex justify-center items-center ">
          <button className="bg-orange py-2 pl-8 pr-2 w-48 text-white text-base rounded-full flex justify-end items-center group">
            <span className="mr-2">Contactar</span>
            <img
              className="inline group-hover:-translate-x-3 duration-300 ease-in-out"
              src={arrow_button}
              alt=""
            />
          </button>
        </div>
      </div>
      <FooterComponent></FooterComponent>
    </>
  );
};

export default AboutUs;
