// lib
import {
    Routes,
    Route,
} from 'react-router-dom'
import LoginDevs from '../pages/Developers/pages/Login/LoginDevs';
// components

const AuthDevsRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/login" element={<LoginDevs />} />
            </Routes>
        </>
    )
}
export default AuthDevsRoutes



