import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import './ItemNav.scss';
import { Fade } from "@mui/material";

interface ItemNavProps {
  name: string;
  options?: any;
}

const ItemNav = ({ options, name }: ItemNavProps) => {
  const [openOption, setOpenOption] = useState<null | HTMLElement>(null);
  const open = Boolean(openOption);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenOption(event.currentTarget);
  };
  const handleClose = () => {
    setOpenOption(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="menu-link"
      >
        {name}
        <i className={`gg-chevron-down`}></i>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={openOption}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          TransitionComponent={Fade}
        className="float-option"
      >
        {
            options.length > 0 && options.map((item: any, index: number) => (
                <MenuItem key={index} href={item.link} className="relative p-0">
                    <a className="w-full px-3 py-1" href={item.link} >
                        {item?.name}
                    </a>
                </MenuItem>
            ))
        }
        
      </Menu>
    </div>
  );
};

export default ItemNav;
