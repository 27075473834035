import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  level: 1,
  parent: null,
  showCombination: 0,
  excludePolygon: 0,
  back: 0,
  ne_coordinate: null,
  sw_coordinate: null
};

export const levelsMapSlice = createSlice({
  name: "levels-map",
  initialState,
  reducers: {
    clearLevelsAction: () => initialState,
    setLevelsAction: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

// provide actions
export const { setLevelsAction, clearLevelsAction } = levelsMapSlice.actions;
// provide reducer
export default levelsMapSlice.reducer;
