import { IProperty } from "../../models/PropertyModel";

import { IconBed, IconBath, IconCar, IconRuler } from "@tabler/icons-react";

import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SwitchPrice from "../SwitchPrice/SwitchPrice";
import { useSelector } from "react-redux";
import { AppStore } from "../../redux/store";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
const CardProperty: React.FC<IProperty> = ({
  property_name,
  property_city,
  property_code,
  property_id,
  property_price,
  property_type,
  gallery,
  characteristics,
  state_name = "",
  url_detail = "",
  is_showing_promotional_price_banner,
  property_old_price,
  property_type_value,
  available_at_date,
  property_status,
  exchange_property_price,
  property_price_currency_symbol,
  exchange_property_old_price,
  marketplace_type = "for_rent",
  property_sale_status,
}) => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { country } = useSelector((store: AppStore) => store.country);
  const handleDetail = () => {
    if (url_detail) {
      if (marketplace_type === "for_sale") {
        if (width > 1024) {
          window.open(`/${country}/venta/${url_detail}/${property_code}`);
        } else {
          window.open(`/${country}/venta/${url_detail}/${property_code}`, '_self');
        }
      } else {
        if (width > 1024) {
          window.open(`/${country}/alquiler/${url_detail}/${property_code}`);
        } else {
          window.open(`/${country}/alquiler/${url_detail}/${property_code}`, '_self');
        }
      }
    }
  };

  const [slideNumber, setSliderNumber] = useState(0);

  const newGallery = [...gallery];

  const prevSlide = () => {
    slideNumber === 0
      ? setSliderNumber(newGallery.length - 1)
      : setSliderNumber(slideNumber - 1);
  };

  const nextSlide = () => {
    slideNumber + 1 === newGallery.length
      ? setSliderNumber(0)
      : setSliderNumber(slideNumber + 1);
  };

  const [switchValue, setSwitchValue] = useState(false);

  const handleSwitchChange = (isChecked: boolean) => {
    setSwitchValue(isChecked);
    // Aquí puedes realizar otras acciones en función del cambio de valor
  };

  useEffect(() => {
    if (property_price_currency_symbol === "$") {
      setSwitchValue(true);
    } else {
      setSwitchValue(false);
    }
  }, [property_price_currency_symbol]);

  const setPrice = () => {
    return switchValue
      ? property_price_currency_symbol === "$"
        ? property_price
        : exchange_property_price
      : property_price_currency_symbol === "Q"
      ? property_price
      : exchange_property_price;
  };

  const setOldPrice = () => {
    return switchValue
      ? property_price_currency_symbol === "$"
        ? property_old_price
        : exchange_property_old_price
      : property_price_currency_symbol === "Q"
      ? property_old_price
      : exchange_property_old_price;
  };

  return (
    <>
      <div className="card cursor-pointer">
        <div
          className={`over-available ${
            marketplace_type === "for_sale"
              ? property_sale_status === "sold" ||
                property_sale_status === "sold_external"
                ? "block"
                : "hidden"
              : property_status === "taken" ||
                property_status === "rented_external"
              ? "block"
              : "hidden"
          }`}
          onClick={handleDetail}
        >
          {
            marketplace_type === "for_sale" ? <div className="message">
            <p className="font-semibold text-center">Propiedad vendida</p>
          </div> : <div className="message">
            <p className="font-semibold text-center">Propiedad alquilada</p>
            <p className=" font-normal text-center">
              Disponible en {available_at_date}{" "}
            </p>
          </div>
          }
          
        </div>
        <img
          className={`${
            is_showing_promotional_price_banner ? "showPromo" : "notShowPromo"
          } promo-tag`}
          src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/Tag-Promocion1.svg"
          alt=""
        />
        <div className="card__image">
          <div className="property-gallery-card h-full">
            <button className="previous" onClick={prevSlide}>
              <img
                src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/invest/arrow-left.svg"
                alt=""
              />
            </button>
            <button className="next" onClick={nextSlide}>
              <img
                src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/invest/arrow-right.svg"
                alt=""
              />
            </button>
            <div className="main relative">
              <img
                onClick={handleDetail}
                src={newGallery[slideNumber]}
                alt=""
              />
              {exchange_property_price !== null && (
                <div className="bt-bi">
                  <SwitchPrice
                    onChange={handleSwitchChange}
                    id={property_id}
                    defaultChecked={
                      property_price_currency_symbol === "$" ? true : false
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="card__content py-4" onClick={handleDetail}>
          <div className="flex justify-between items-center">
            <p
              className={`text-sm font-medium px-2 py-1 rounded-md ${
                marketplace_type === "for_sale"
                  ? "bg-[#FEE3D5] text-[#FA712D]"
                  : "bg-[#D5F0E3] text-[#26925B]"
              } `}
            >
              {property_type_value}
            </p>
            <div>
              <h2 className="text-base font-semibold text-graySoft">
                {setPrice()}
                <span
                  className={`text-base font-normal text-gray200 line-through ml-1 ${
                    is_showing_promotional_price_banner
                      ? "inline-block"
                      : "hidden"
                  }`}
                >
                  {setOldPrice()}{" "}
                </span>
              </h2>
            </div>
          </div>

          <h3
            className="text-base font-medium tracking-wide whitespace-nowrap text-ellipsis overflow-hidden text-gray800 mt-3"
            title={property_name}
          >
            {property_name}
          </h3>
          <h2 className="text-sm font-normal tracking-normal text-gray600 mb-3 mt-1">
            {state_name}
          </h2>
          {/* <div className="flex justify-between py-3">
            <p className="text-xs font-normal">{property_type}</p>
            <p className="text-xs font-bold">{property_price}</p>
          </div> */}
          <div className="flex justify-between">
            <div>
              {characteristics &&
                characteristics?.map((item: any, index: number) => (
                  <>
                    {item.id === "MT2" && (
                      <p
                        key={index}
                        className="text-sm font-medium flex justify-center items-center text-gray200"
                      >
                        <IconRuler color="#757575" width={16} />
                        {item.value} { property_type_value === "Terreno" && 'v²' }
                      </p>
                    )}
                  </>
                ))}
            </div>
            {
              property_type_value !== 'Terreno' && <div className="flex justify-between items-center gap-2 rounded-full px-2 py-1">
              {characteristics &&
                characteristics?.map((item: any, index: number) => (
                  <>
                    {item.id === "PRKN" && (
                      <p
                        key={index}
                        className="text-sm font-medium flex justify-center items-center text-gray200"
                      >
                        <IconCar color="#757575" width={16} />
                        {item.value}
                      </p>
                    )}

                    {item.id === "FBTH" && (
                      <p
                        key={index}
                        className="text-sm font-medium flex justify-center items-center text-gray200"
                      >
                        <IconBath color="#757575" width={16} />
                        {item.value}
                      </p>
                    )}
                    {item.id === "RMS" && (
                      <p
                        key={index}
                        className="text-sm font-medium flex justify-center items-center text-gray200"
                      >
                        <IconBed color="#757575" width={16} />
                        {item.value}
                      </p>
                    )}
                  </>
                ))}
            </div>}
            
          </div>
        </div>
      </div>
    </>
  );
};

export default CardProperty;
