import {
  IconCalendar,
  IconHeart,
  IconHelp,
  IconHome,
  IconHomeCog,
  IconHomeDollar,
  IconUserCircle,
} from "@tabler/icons-react";
import "./AccountCard.scss";

interface AccountDataProps {
  data: any;
}
const AccountCard = ({ data }: AccountDataProps) => {
  const getIcon = (icon: string) => {
    switch (icon) {
      case "user":
        return <IconUserCircle color="#212121" height={30} width={30} />;
      case "offer":
        return <IconHome color="#212121" height={30} width={30} />;
      case "buy":
        return <IconHomeDollar color="#212121" height={30} width={30} />;
      case "visit":
        return <IconCalendar color="#212121" height={30} width={30} />;
      case "favorite":
          return <IconHeart color="#212121" height={30} width={30} />;
      case "help":
        return <IconHelp color="#212121" height={30} width={30} />;
      case "rent":
        return <IconHomeCog color="#212121" height={30} width={30} />;
    }
  };

  const goToLink = (link: string) => {
    window.open(link, '_self')
  }

  return (
    <div className="card-account" onClick={() => goToLink(data?.link)}>
      {getIcon(data?.icon)}

      <div className="mt-3">
        <h1 className="text-lg font-semibold text-[#212121] mb-1">
          {data?.title}
        </h1>
        <p className=" text-sm lg:text-base font-normal text-[#757575]">
          {data?.description}
        </p>
      </div>
    </div>
  );
};

export default AccountCard;
