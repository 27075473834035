import { AuthUser,AuthDeveloper  } from "../models/authUserModel"

export const getAuth = () => {
    return localStorage.getItem('auth') || ''
}

export const setAuth = (user: AuthUser) => {
    localStorage.setItem('auth', JSON.stringify(user))
}
export const setAuthDeveloper = (user: AuthDeveloper) => {
    localStorage.setItem('authDev', JSON.stringify(user))
}

export const getAuthDeveloper = () => {
    return localStorage.getItem('authDev') || ''
}

export const unsetAuth = () => {
    localStorage.removeItem('auth')
}

export const getAuthToken = (user: AuthUser) => {
    return isLoggedIn(user) ? user.token : ''
}

export const isLoggedIn = (user: AuthUser) => {
    return user && user.token && user.realm
}

export const setLastPath = (path: string) => {
    localStorage.setItem('lpath', path)
}

export const getLastPath = () => {
    return localStorage.getItem('lpath') || ''
}

export const removeLastPath = () => {
    localStorage.removeItem('lpath')
}

export const customDelay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))