import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppStore } from "../../redux/store";
import { useNavigate } from "react-router-dom";

import TrashIcon from "../../assets/icons/trash.svg";
//import modals
import ModalTrash from "../../components/Modal";
import ModalTrashBody from "./components/ModalTrash";
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import {
  getTenantFavorites,
} from "./services/tenantService";
import { getAuthToken, unsetAuth } from "../../helpers/authHelper";
import { logoutAction } from "../../redux/features/auth";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";
import { loadAction } from "../../redux/features/favorites";
import { TenantFavorites } from "../../models/favoritesModel";
import EmptyTableSection from '../../components/EmptyTableSection';

const TenantFavs = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  const user = useSelector((store: AppStore) => store.auth)
  const favorites = useSelector((store: AppStore) => store.favorites)

  const token = getAuthToken(user)
  const [showTrash, setShowTrash] = useState(false);
  const { callEndpoint } = useCallApiAndLoad()
  const [pickedFavorite, setPickedFavorite] = useState('')
  const [ setPickedProperty ] = useState<any>({
    property_id: '',
    rent_info: []
  })
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const loadFavorites = async () => {
      const { status, data } = await callEndpoint(getTenantFavorites(token))
      if (status === 401) {
        dispatch(logoutAction({}))
        unsetAuth()
        return navigate(`/app/${country}/auth/login`)
      }
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        // setFavorites(response.data.items || [])
        dispatch(loadAction(response.data.items || []))
      }
    }
    loadFavorites()
      .catch(e => console.error(e))
  }, [])

  const handleOffer = (property: TenantFavorites) => {
    setPickedProperty({
      property_id: property.property_id,
      rent_info: [
        { is_total: true, 
          item: 'Cuota', 
          value: property.price 
        }
      ]
    })
    toggleOffer()
  }

  const toggleOffer = () => {
    try {
      document.body.style.overflow = "scroll";
    } catch (error) {
      console.log("error");
    }
  };

  const toggleTrash = () => {
    try {
      setShowTrash((old) => !old);
      document.body.style.overflow = "scroll";
    } catch (error) {
      console.log("error");
    }
  };

  return (
    <>
      {showTrash && (
        <ModalTrash
          size="max-w-lg"
          onAction={toggleTrash}
          name="Eliminar"
          body={
            <ModalTrashBody 
              onAction={toggleTrash}
              id={pickedFavorite} />
          }
        />
      )}
      <div className="h-full px-4 lg:px-0">
        <div className="w-full py-24">
          <div className="w-full text-center my-6 text-third text-lg">
            Propiedades guardadas
          </div>
          <div className="w-full my-6 text-third text-4xl font-bold text-center">
            Mis favoritos
          </div>
          <hr />
          <div className="w-full py-6 px-4 hidden mt-12 mb-4 lg:flex justify-start bg-graybgsoft border-bg-third border-2 rounded bg-plight">
            <div className="text-blueForm border-r-2 border-grayDark font-medium  w-56">
              Propiedad
            </div>

            <div className="text-blueForm border-r-2 border-grayDark font-medium pl-4 w-72">
              Dirección
            </div>
            <div className="text-blueForm border-r-2 border-grayDark font-medium px-4 w-40">
              Cuota
            </div>
          </div>
          {/* favorites data */}
          {
            favorites.length > 0 ?
            favorites.map((item: TenantFavorites) => (
              <>
                <div className="hidden lg:flex justify-start w-full  items-center border shadow-md hover:shadow-lg hover:ease-in-out ease-in-out duration-300 rounded-lg">
                  <div className="text-graysoft w-60">
                    <div className="w-full">
                      <div
                        className="bg-cover bg-center w-60 h-24 rounded-l-lg">
                        <img className="rounded-l-lg w-full h-full"
                          src={item?.cover} />
                      </div>
                    </div>
                  </div>

                  <div className="text-graySoft font-300   pl-4 w-72">
                    {item?.address}
                  </div>
                  <div className="text-graySoft font-300   px-4 w-40 border-x border-graySoft pr-4">
                    {item?.price}
                  </div>
                  <div className="flex justify-end xl:justify-center items-center float-right pl-4">
                    <div onClick={() => handleOffer(item)} 
                      className="py-2 px-20 cursor-pointer mx-2 text-center bg-white text-blueForm border border-blueForm rounded-full">
                      Hacer una oferta
                    </div>
                    <div className="text-graysoft pl-4 w-16 flex justify-center">
                      <button onClick={() => {
                        toggleTrash()
                        setPickedFavorite(item?.id)
                      }}>
                        <img src={TrashIcon} className="w-3 mx-2" alt="filter" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex lg:hidden border shadow-md my-4 rounded-lg items-center justify-between w-full p-4">
                  <div className="w-1/3">
                    <div className="w-full">
                      <div
                        className="bg-cover bg-center w-20 h-20 rounded-lg">
                        <img className="rounded-lg w-full h-full"
                          src={item?.cover} />
                      </div>
                    </div>
                  </div>
                  <div className="w-2/3">
                    <div className="w-full p-2 bg-tea-100">
                      <span className="text-blueForm font-medium">Cuota </span>
                      <span className="text-graySoftt ml-2">{item?.price}</span>
                    </div>
                    <div className="w-full my-4 text-graySoft">
                      {item?.address}
                    </div>
                  </div>
                </div>
              </>
            ))
            :
            <EmptyTableSection 
              message="Aún no tienes propiedades favoritas" 
              link={`/${country}/marketplace`}
              linkText="Alquilar"
            />
          }
        </div>
      </div>
    </>
  );
};

export default TenantFavs;
