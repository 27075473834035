import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setFiltersAction } from '../../../../redux/features/filters';

import arrowOrange from "../../../../assets/icons/arrow-orange.svg";
import LayoutGuestVisit from '../LayoutGuest/Layout';
import { AppStore } from '../../../../redux/store';

const StepSuccesVisit = memo(() => {
  const { country } = useSelector((state: AppStore) => state.country);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const goToMarket = () => {
    dispatch(
      setFiltersAction({
        min_price: null,
        max_price: null,
        rooms: null,
        bathrooms: null,
        type: null,
        is_furnished: null,
      })
    );
    navigate(`/${country}/marketplace`);
  };
  return (
    <LayoutGuestVisit image='https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/guest.png'>
      <div className="w-full max-w-lg">
        <img src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/guest-success.png" className=' object-cover w-96 mx-auto' alt="" />
        <div className='w-full flex flex-col justify-center items-center mt-5'>
          <span className='text-center text-grayText text-base'>Felicidades</span>
          <h1 className=' text-grayText font-bold text-2xl md:text-3xl'>¡Tu visita ha sido agendada!</h1>
          <p className=' text-graySoft text-base'>Nos pondremos en contacto contigo para gestionar la visita.</p>
          <div
            onClick={goToMarket}
            className="border-b ease-in-out duration-300 cursor-pointer hover:translate-x-4 border-orange w-fit  text-orange text-left flex items-center justify-start mt-5"
          >
            Sigue explorando
            <span className="ml-2">
              <img src={arrowOrange} alt="" />
            </span>
          </div>
        </div>
      </div>
    </LayoutGuestVisit>
  )
})

StepSuccesVisit.displayName = 'StepSuccesVisit'
export default StepSuccesVisit
