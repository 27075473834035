import axios from "axios";

const loadAbort = () => {
  return new AbortController();
};

export const polygonListEndpoint = (levelD?: any) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/address/v1/polygons?level=${levelD}`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const polygonListEndpointExcept = (
  levelD?: any,
  ne_coordinate?: string,
  sw_coordinate?: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/address/v1/polygons?level=${levelD}&ne=${ne_coordinate}&sw=${sw_coordinate}`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const polygonByParentEndpoint = (parent: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/address/v1/polygons?parent=${parent}`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};
