interface Props {
    message: string | undefined
    additionalClass?: string | undefined
}

const ErrorForm = ({ message, additionalClass = '' }: Props) => {
    if(!message) return null;
    return (
            <p className={`text-[#F44336] text-sm font-semibold ${additionalClass}`}>{ message }</p>
    )
}

export default ErrorForm
