import React, {useState, createContext, PropsWithChildren, useMemo} from "react";
import ModalFilter from "../components/ModalFilter";
import useModal from "../hooks/useModal";
export const ModalContext = createContext<any | null>({})

interface Props {
    
}

const ModalProvider: React.FC<PropsWithChildren<Props>> = ({ children }) => {
    
    let { modal, handleModal, modalContent } = useModal();
    let context = useMemo(() => ({
        modal,
        handleModal,
        modalContent 
    }), [modal, handleModal, modalContent])
    return (
        <ModalContext.Provider
            value={context}
        >
            {children}  
        </ModalContext.Provider>
    )
}
export default ModalProvider