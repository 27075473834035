import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import arrow_button from "../../../assets/icons/arrow_button.svg";

import { Controller, useForm } from "react-hook-form";
import FormControlError from "../../../components/Controls/FormControlError";
import useCallApiAndLoad from "../../../hooks/useCallApiAndLoad";
import { useSelector } from "react-redux";
import { AppStore } from "../../../redux/store";
import { getAuthToken } from "../../../helpers/authHelper";
import { SubscriptionPayPayload } from "../models/tenantModel";
import { SubscriptionPayEndpoint } from "../services/tenantService";
import { fetchResponseAdapter } from "../../../adapters/fetchAdapter";
import { PatternFormat } from "react-number-format";

interface Props {
  name?: string;
  btnText?: string;
  onAction: React.MouseEventHandler<HTMLButtonElement> | undefined;
  extraAction: () => void;
  contractId: string;
  transactionId: string;
}

interface SubsForm {
  name_card: string;
  number_card: string;
  month: string;
  year: string;
  cvv: string;
  name_user: string;
  lastname_user: string;
  // type_user: string;
  // name_legal: string;
}

const ModalSubscribe = (props: Props) => {
  const { country } = useSelector((store: AppStore) => store.country);
  const [sendButton, setSendButton] = useState<boolean>(false)
  const { callEndpoint } = useCallApiAndLoad();
  const [formError, setFormError] = useState("");
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SubsForm>({
    resolver: yupResolver(
      yup
        .object({
          name_card: yup.string().required("Nombre de la tarjeta es requerido"),
          number_card: yup.string().required("Es requerido"),
          month: yup.string().required("Es requerido"),
          year: yup.string().required("Es requerido"),
          cvv: yup.string().required("Es requerido"),
          name_user: yup.string().required("Es requerido"),
          lastname_user: yup.string().required("Es requerido"),
          // type_user: yup.string().required("Es requerido").nullable(),
          // name_legal: yup.string().required("Es requerido"),
        })

        .required()
    ),
  });

  const sendData = async (form: SubsForm) => {
    setSendButton(true)
    const payload: SubscriptionPayPayload = {
      cardInfo: {
        cardNumber: form.number_card,
        expireMonth: form.month,
        expireYear: form.year,
        cvv: form.cvv,
        cardName: form.name_card,
      },
      tenantInfo: {
        firstName: form.name_user,
        lastName: form.lastname_user,
      }
    }

    const { status, data } = await callEndpoint(
      SubscriptionPayEndpoint(payload, token, props.contractId, props.transactionId)
    );

    if (status === 201) {
      setSendButton(false)
      return navigate(`/app/${country}/l/tn/payment-completed`)
    } else {
      setSendButton(false)
    }
    const response = fetchResponseAdapter(data)
    setFormError(`(${response.code}) ${response.message}`)

  };

  return (
    <div className="w-full h-[500px] overflow-y-auto">
      <form onSubmit={handleSubmit(sendData)}>
        <div className="w-full bg-white px-4">
          <div className="w-full py-4 ">
            <p className="text-graySoft font-semibold text-sm tracking-normal">
              Datos de tarjeta
            </p>
            {formError && (
              <FormControlError message={formError} />
            )}
          </div>
          <div className="my-2">
            <label className="text-blueForm font-semibold text-sm">
              Nombre de la tarjeta
            </label>
            <input
              {...register("name_card")}
              type="text"
              placeholder="Ej: Tarjeta personal"
              className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 "
            />
            <FormControlError
              message={errors?.name_card?.message}
              additionalClass="mb-3"
            />
          </div>
          <div className="my-4">
            <label className="text-blueForm font-semibold text-sm">
              Número de la tarjeta
            </label>
            <Controller
              control={control}
              name="number_card"
              render={({ field: { onChange, value, onBlur } }) => (
                <PatternFormat
                  placeholder="000-000-000-000"
                  onValueChange={(v) => onChange(v.value)}
                  className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2"
                  format="#### #### #### ####"
                  onBlur={onBlur}
                />
              )}
            />

            <FormControlError
              message={errors?.number_card?.message}
              additionalClass="mb-3"
            />
          </div>
          <div className="w-full flex items-center justify-between flex-wrap">
            <div className="w-full lg:w-1/3 px-1 my-4 lg:my-0">
              <label className="text-blueForm font-semibold text-sm">Mes</label>
              <Controller
              control={control}
              name="month"
              render={({ field: { onChange, value, onBlur } }) => (
                <PatternFormat
                 placeholder="Ej: 09"
                  onValueChange={(v) => onChange(v.value)}
                  className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2"
                  format="##"
                  onBlur={onBlur}
                />
              )}
            />
            </div>
            <div className="w-full lg:w-1/3 px-1 my-4 lg:my-0">
              <label className="text-blueForm font-semibold text-sm">Año</label>
              <Controller
              control={control}
              name="year"
              render={({ field: { onChange, value, onBlur } }) => (
                <PatternFormat
                  placeholder="Ej: 2023"
                  onValueChange={(v) => onChange(v.value)}
                  className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2"
                  format="####"
                  onBlur={onBlur}
                />
              )}
            />
            </div>
            <div className="w-full lg:w-1/3 px-1 my-4 lg:my-0">
              <label className="text-blueForm font-semibold text-sm">
                CVV2
              </label>
              <Controller
              control={control}
              name="cvv"
              render={({ field: { onChange, value, onBlur } }) => (
                <PatternFormat
                  onValueChange={(v) => onChange(v.value)}
                  className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2"
                  format="###"
                  type="password"
                />
              )}
            />
            </div>
            <div className="w-full">
              <FormControlError
                message={errors?.month?.message}
                additionalClass="mb-3"
              />
              <FormControlError
                message={errors?.year?.message}
                additionalClass="mb-3"
              />
              <FormControlError
                message={errors?.cvv?.message}
                additionalClass="mb-3"
              />
            </div>
            <div className="py-4 bt-1 border-graySoft w-full">
              <div className="w-full py-4">
                <p className="text-graySoft font-semibold text-sm tracking-normal">Datos de subscripción
                </p>
              </div>
              <div className="my-2">
                <label className="text-blueForm font-semibold text-sm">
                  Primer nombre
                </label>
                <input
                  {...register("name_user")}
                  type="text"
                  placeholder="Escribe tu primer nombre"
                  className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 "
                />
                <FormControlError
                  message={errors?.name_user?.message}
                  additionalClass="mb-3"
                />
              </div>
              <div className="my-2 mt-4">
                <label className="text-blueForm font-semibold text-sm">
                  Primer apellido
                </label>
                <input
                  {...register("lastname_user")}
                  type="text"
                  placeholder="Escribe tu primer apellido"
                  className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 "
                />
                <FormControlError
                  message={errors?.lastname_user?.message}
                  additionalClass="mb-3"
                />
              </div>
              {/*<div className="w-full text-center  items-center  flex flex-wrap justify-between my-6">*/}
              {/*  <span className=" font-semibold text-base text-graySoft">Tipo de persona</span>*/}
              {/*  <div className="flex justify-between items-center">*/}
              {/*    <div className="flex items-center justify-center">*/}
              {/*    <label className="customRadio">*/}
              {/*    Jurídica*/}
              {/*            <input*/}
              {/*              type="radio"*/}
              {/*              value="juridica"*/}
              {/*              {...register("type_user")}*/}
              {/*            />*/}
              {/*            <span className="checkmark"></span>*/}
              {/*          </label>*/}
              {/*    </div>*/}
              {/*    <div className="ml-2 flex items-center justify-center">*/}
              {/*    <label className="customRadio">*/}
              {/*            Natural*/}
              {/*            <input*/}
              {/*              type="radio"*/}
              {/*              value="natural"*/}
              {/*              {...register("type_user")}*/}
              {/*            />*/}
              {/*            <span className="checkmark"></span>*/}
              {/*          </label>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <FormControlError*/}
              {/*    message={errors?.type_user?.message}*/}
              {/*    additionalClass="mb-3 w-full text-left"*/}
              {/*  />*/}
              {/*</div>*/}
              {/*<div className="my-2">*/}
              {/*  <label className="text-blueForm font-semibold text-sm">*/}
              {/*    Nombre de representante legal*/}
              {/*  </label>*/}
              {/*  <input*/}
              {/*    {...register("name_legal")}*/}
              {/*    type="text"*/}
              {/*    placeholder="Escribe el nombre completo "*/}
              {/*    className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 "*/}
              {/*  />*/}
              {/*  <FormControlError*/}
              {/*    message={errors?.name_legal?.message}*/}
              {/*    additionalClass="mb-3"*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        <hr className=" w-full border-tea-500" />
        <div className="bg-white w-full">
          <div className="w-full flex justify-between px-4 py-4 items-center">
            <button onClick={props.onAction}>
              <div className="text-blueForm text-base">Cancelar</div>
            </button>
            <button
              disabled={sendButton}
              type="submit"
              className={`${sendButton ? ' opacity-60' : ''} bg-primary py-2 pl-2 pr-2 w-auto text-white text-base rounded-full flex justify-end items-center`}
            >
              <span className="mr-2 ml-6">{props.btnText}</span>
              <img
                className="inline group-hover:-translate-x-3 duration-300 ease-in-out"
                src={arrow_button}
                alt=""
              />
            </button>
            {/* <Link
              to={"/l/tn/payment-completed"}
              onClick={() => {
                document.body.style.overflowY = "auto";
                document.body.style.overflowX = "hidden";
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              }}
              className="bg-primary py-2 pl-8 pr-2  text-white text-base rounded-full flex justify-end items-center group"
            >
              <span className="mr-2 ml-6">{props.btnText}</span>
              <img
                className="inline group-hover:-translate-x-3 duration-300 ease-in-out"
                src={arrow_button}
                alt=""
              />
            </Link> */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ModalSubscribe;
