// Importar React y el archivo de estilos al principio
import React, { ReactNode } from "react";
import "./MainButton.scss";

// Definir tipos de propiedades
type ButtonType = "button" | "submit" | "reset";

interface MainButtonProps {
  width?: string;
  topic?: string;
  background?: string;
  onAction?: () => void;
  type?: ButtonType;
  colorText?: string;
  icon?: ReactNode;
}

// Usar destructuración en los parámetros de la función
const MainButton: React.FC<MainButtonProps> = ({
  width = "w-full",
  topic = "Propilatam",
  onAction,
  background = "bg-white",
  type = "button",
  colorText = "text-[#717171]",
  icon,
}: MainButtonProps) => {
  // Utilizar template strings para las clases dinámicas
  const buttonClasses = `main-button ${width} ${background} ${colorText}`;

  return (
    <button onClick={onAction} className={buttonClasses} type={type}>
      {icon && <>{icon}</>}
      {topic}
    </button>
  );
};

export default MainButton;
