import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppStore } from '../redux/store';
import { isLoggedIn } from '../helpers/authHelper';

type Props = {
    children: JSX.Element
}

const PublicRouteDevelopers = ({ children }: Props) => {
    const user = useSelector((store: AppStore) => store.auth)
    const isLogged = isLoggedIn(user)
    const { country } = useSelector((store: AppStore) => store.country);
    return(
        !isLogged
            ? children 
            : <Navigate to={`/app/${country}/desarrolladoras/proyectos`} />    
    )
}

export default PublicRouteDevelopers