import React, { useState, useMemo } from "react";
import { createPortal } from "react-dom";

import { ToastContext } from "../ToastContext";
import ToastAlerts from "../ToastAlerts";

// Create a random ID
function generateUEID() {
  let first: any = (Math.random() * 46656) | 0;
  let second: any = (Math.random() * 46656) | 0;
  first = ("000" + first.toString(36)).slice(-3);
  second = ("000" + second.toString(36)).slice(-3);

  return first + second;
}

export const ToastProvider = (props: {
  children:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) => {
  const [toasts, setToasts] = useState<any>([]);
  const [type, setType] = useState<string>("");
  const open = (content: any, type: string) => {
    setType(type);
    setToasts((currentToasts: any) => [
      ...currentToasts,
      { id: generateUEID(), content },
    ]);
  };

  const close = (id: any) =>
    setToasts((currentToasts: any) =>
      currentToasts.filter((toast: { id: any }) => toast.id !== id)
    );
  const contextValue = useMemo(() => ({ open }), []);

  return (
    <ToastContext.Provider value={contextValue}>
      {props.children}

      {createPortal(
        <div className="toasts-wrapper">
          {toasts.map((toast: any) => (
            <ToastAlerts key={toast.id} close={() => close(toast.id)} type={type}>
              {toast.content}
            </ToastAlerts>
          ))}
        </div>,
        document.body
      )}
    </ToastContext.Provider>
  );
};
