import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    ipClient: ''
}

export const ipClientSlice = createSlice({
    name: 'ipClient',
    initialState,
    reducers: {
        setIpAction: (state, action) => {
            return {
                ...action.payload
            }
        }
    }
})

// provide actions
export const { setIpAction } = ipClientSlice.actions;
// provide reducer
export default ipClientSlice.reducer