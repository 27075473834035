import arrow_button from "../../assets/icons/arrow_button.svg";

// hooks
import { useDispatch, useSelector } from "react-redux";
import { getAuthToken, unsetAuth } from "../../helpers/authHelper";
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
// components

// helpers
import { AppStore } from "../../redux/store";
import { logoutAction } from "../../redux/features/auth";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";
import {
  getTenantPaymentDetailEndpoint,
  getTenantPrecheckoutEndpoint,
} from "./services/tenantService";
import { Precheckout, TransactionDetail } from "./models/tenantModel";
import CardInfoPay from "./components/CardInfoPay/CardInfoPay";
import Modal from "../../components/Modal";
import ModalSubscribe from "./components/ModalSubscribe";
import ModalUnSubscribe from "./components/ModalUnsubscribe";
import FormControlInfo from "../../components/Controls/FormControlInfo";

// services
const TenantPayment = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const { id = "" } = useParams();
  const [data, setData] = useState<TransactionDetail>();
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [showUnSubscribe, setShowUnSubscribe] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [currentContract, setcurrentContract] = useState<Precheckout>();
  const [currentTransactionId, setCurrentTransactionId] = useState<string>("");
  const { callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const loadPaymentDetail = async () => {
      const { status, data } = await callEndpoint(
        getTenantPaymentDetailEndpoint(token, id)
      );
      if (status === 401) {
        dispatch(logoutAction({}));
        unsetAuth();
        return navigate(`/app/${country}/auth/login`);
      }
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        // setPayments(response.data.items || [])
        setData(response.data);
      }
    };
    loadPaymentDetail().catch((e) => console.error(e));
  }, []);

  const onSelect = async (idCurrent: string) => {
    const { status, data } = await callEndpoint(
      getTenantPrecheckoutEndpoint(token, id, idCurrent)
    );
    if (status === 401) {
      dispatch(logoutAction({}));
      unsetAuth();
      return navigate(`/app/${country}/auth/login`);
    }
    if (status === 200) {
      const response = fetchResponseAdapter(data);
      // setPayments(response.data.items || [])
      setcurrentContract(response.data);
      setCurrentTransactionId(idCurrent);
    }
    if (selected.includes(idCurrent)) {
      setSelected([]);
      setcurrentContract(undefined);
    } else {
      setSelected([idCurrent]);
    }
  };

  const toggleSubscribe = () => {
    if (!currentTransactionId)
      return;

    try {
      setShowSubscribe((old) => !old);
      document.body.style.overflow = "scroll";
    } catch (error) {
      console.log("error");
    }
  };

  const toggleUnSubscribe = () => {
    try {
      setShowUnSubscribe((old) => !old);
      document.body.style.overflow = "scroll";
    } catch (error) {
      console.log("error");
    }
  };

  /* TO DO: functionality to select JUST ONE BY ONE of the month and apply the correct select style */
  return (
    <>
      <div className="container mx-auto">
        <div className="w-full pb-10 md:pb-24 pt-32">
          <h1 className="text-blackGrayScale text-2xl lg:text-2xl font-semibold text-left tracking-wide">
            Selecciona la cantidad de cuotas a cancelar
          </h1>
          <h1 className="w-full mt-2 mb-6">
            <p className="text-lg font-medium text-gray600 mb-2">Pagar cuotas</p> 
          </h1>
          <div className="w-full max-w-[960px] mx-auto">
            <div className="pb-10">
              <div className="w-full px-4 lg:px-0 flex justify-center mx-auto mt-12">
                <CardInfoPay
                  onActionDesactive={toggleUnSubscribe}
                  onAction={toggleSubscribe}
                  dataProperty={data?.propertyInfo}
                  dataContractResume={data?.contractResume}
                  is_subscribed={data?.contractResume.is_subscribed || false}
                />
              </div>
            </div>
            <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-start gap-5 px-4 md:px-0">
              <div className="w-full lg:w-1/2 lg:pr-6 border border-tea-500 rounded-lg px-5 py-6">
                <p className=" text-blackGrayScale text-base font-bold tracking-tight">
                  Detalle de cuotas
                </p>
                <div className="w-full flex flex-wrap justify-between items-start bg-tea-100 px-4 py-1 rounded-lg mt-4">
                  {data?.transactionDetail.map((month, index) => (
                    <div key={`td-${month}`} className="w-full flex justify-between my-3">
                      <div className="flex">
                        <div className="mr-10">
                          <label
                            className="customRadio text-sm text-graySoft"
                            data-variation={(month.is_paid || data.contractResume.is_subscribed) ? "disabled" : ""}
                          >
                            {`${month.label} ${month.due}`}
                            <input
                              type="radio"
                              disabled={month.is_paid || data.contractResume.is_subscribed}
                              value={month.id}
                              name="selectCuota"
                              onChange={(e) => onSelect(e.target.value)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <p
                          className={`text-sm font-normal text-graySoft ${month.is_paid ? " opacity-40 " : ""
                            }`}
                        >
                          Por pagar
                        </p>
                      </div>
                      <div>
                        <p
                          className={`text-sm font-normal text-primary ${month.is_paid ? " opacity-40 " : ""
                            } `}
                        >
                          {month.value}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <hr className=" w-full border-tea-500 my-4" />
                <div className="px-4">
                  <p className=" text-blackGrayScale text-base font-bold tracking-tight">
                    Cuotas pagadas
                  </p>
                  {data?.transactionDetailPaid.map(month => (
                    <div key={`transactionDetailPaid-${month}`}
                      className="w-full flex justify-between my-3">
                      <div className="flex">
                        <div className="mr-10">
                          <p className=" text-sm text-graySoft">{`${month.label} ${month.due}`}</p>
                        </div>
                        <p className="text-sm font-normal text-graySoft">
                          Pagada
                        </p>
                      </div>
                      <div>
                        <p className="text-sm font-normal text-graySoft">{month.value}</p>
                      </div>
                    </div>
                  ))}

                </div>
              </div>
              <div className="w-full my-8 lg:my-0 lg:w-1/2 border border-tea-500 rounded-md p-4">
                <div className="w-full py-4 border-b border-tea-500">
                  <p className=" text-blackGrayScale text-base font-bold">
                    Detalle a cancelar
                  </p>
                </div>
                {data?.contractResume.is_subscribed
                  ? (<FormControlInfo message="Pago automático activo, el pago se realiza entre el 1 y 5 de cada mes." />)
                  : currentContract ? (
                    <>
                      <div className="w-full py-4 border-b border-tea-500">
                        {currentContract?.preCheckout.map((item) =>
                          item.is_total ? (
                            <div className="w-full mb-2 text-graySoft flex justify-between">
                              <span className="text-blackGrayScale font-bold">
                                {item.label}
                              </span>
                              <span className="text-blackGrayScale font-bold">
                                {item.value}
                              </span>
                            </div>
                          ) : (
                            <div className="w-full mb-2 text-[#757575] flex justify-between">
                              <span>{item.label}</span>
                              <span>{item.value}</span>
                            </div>
                          )
                        )}
                      </div>

                      <div className="mt-4 mb-2">
                        <div className="w-full bg-[#F2F6EF] px-4 rounded-full py-3 flex justify-between items-center">
                          <span className=" text-blackGrayScale font-medium">
                            Subtotal
                          </span>
                          <span className="text-blackGrayScale text-2xl font-bold">
                            {currentContract?.total}
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-center">
                        <Link
                          to={`/app/${country}/l/tn/payment-detail/${id}/${selected[0]}`}
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                          className="bg-[#212121] py-3 px-4 w-full text-white text-base rounded-lg flex justify-end items-center group mt-2"
                        >
                          <span className="mx-auto">Continuar</span>
                          
                        </Link>
                      </div>
                    </>
                  ) : (
                    <div className="my-4 text-sm text-[#757575]">
                      {" "}
                      Selecciona una cuota para ver el detalle de pago{" "}
                    </div>
                  )
                }

              </div>
            </div>
          </div>
        </div>
      </div>

      {showSubscribe ? (
        <Modal
          onAction={toggleSubscribe}
          name="Pagar y subscribirse"
          size="max-w-lg"
          body={
            <ModalSubscribe
              onAction={toggleSubscribe}
              extraAction={toggleSubscribe}
              name="Pagar y subscribirse"
              btnText="Subscribirse"
              contractId={id}
              transactionId={currentTransactionId}
            />
          }
        />
      ) : null}

      {showUnSubscribe ? (
        <Modal
          onAction={toggleUnSubscribe}
          name="Desactivar pago automático"
          size="max-w-lg"
          body={
            <ModalUnSubscribe
              contractId={id}
              onAction={toggleUnSubscribe}
              extraAction={toggleUnSubscribe}
              name="Desactivar pago automático"
              btnText="Desactivar"
            />
          }
        />
      ) : null}
    </>
  );
};
export default TenantPayment;
