import React from "react"

export const currencyInputFormat = (e: React.ChangeEvent<HTMLInputElement>, 
    thousandSeparator: string = ',', decimalSeparator: string = '.') => {
    let { value } = e.target
    // any character different from a number is replaced with an empty string
    value = value.replace(/\D/g, "")
    // setting decimal separator
    value = value.replace(/(\d)(\d{2})$/, `$1${decimalSeparator}$2`)
    value = value.replace(/(?=(\d{3})+(\D))\B/g, thousandSeparator)
    e.target.value = value
    return e
}

export const parseNumberCurrencyValue = (value: string, thousandSeparator: string = ',') => {
    let valueWithOutSeparator = value.replaceAll(thousandSeparator, '')
    return Number.parseFloat(valueWithOutSeparator) 
}
