import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { isLoggedIn, setLastPath } from "../helpers/authHelper";
import { AppStore } from "../redux/store";
import { useEffect } from "react";

type Props = {
  children: JSX.Element;
};

const PrivateRoute = ({ children }: Props) => {
  const { country } = useSelector((store: AppStore) => store.country);
  const location = useLocation();
  const user = useSelector((store: AppStore) => store.auth);
  const isLogged = isLoggedIn(user);
  // This is used to save path to visit if user is not logged in
  useEffect(() => {
    if (!isLogged) {
      setLastPath(location.pathname);
    }
  }, [location]);
  return isLogged ? children : <Navigate to={`/app/${country}/auth/login`} />;
};

export default PrivateRoute;
