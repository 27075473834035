import { memo } from "react";
import "./SelectCountry.scss";
import useUserCountry from "../../hooks/useUserCountry";
import LoadingComponent from "../../components/Loading/Loading";
const SelectCountryPage = memo(() => {
  const { setCountry } = useUserCountry();

  const goToCountry = (country: string) => {
    setCountry(country);
    return window.open(`/${country}/`, "_self");
  };

  return (
    // TODO remove this when GT will be public

    <div className="country-page h-[100vh] flex items-center bg-primary">
      <div className="container mx-auto">
        <div className="max-w-xl mx-auto">
          <div className="flex justify-center items-center flex-col">
            <img
              src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/invest/propi.svg"
              alt="logo"
              className="w-auto md:w-48"
            />
            <h1 className="text-xl lg:text-3xl font-semibold text-white md:mt-8 mt-4">
              ¿Desde qué país nos visitas?
            </h1>
          </div>
          <div className="flex flex-wrap justify-between py-10 max-w-md mx-auto px-4 md:px-0 gap-4 md:gap-0">
            <div
              className="country_option"
              onClick={() => {
                goToCountry("sv");
              }}
            >
              <div className="flex flex-row items-center gap-4 md:gap-0 md:flex-col">
                <img
                  className="w-12 md:w-auto"
                  src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/el-salvador.svg"
                  alt="sv"
                />
                <p className="text-sm md:text-base font-medium text-white md:mt-4">
                  El Salvador
                </p>
              </div>
            </div>
            <div
              className="country_option"
              onClick={() => {
                goToCountry("gt");
              }}
            >
              <div className="flex flex-row items-center gap-4 md:gap-0 md:flex-col">
                <img
                  className="w-12 md:w-auto"
                  src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/guatemala.svg"
                  alt="gt"
                />
                <p className="text-sm md:text-base font-medium text-white md:mt-4">
                  Guatemala
                </p>
              </div>
            </div>
          </div>

          <p className="text-sm font-medium text-white text-center">
            Podrás cambiarlo dentro del sitio cuando quieras.
          </p>
        </div>
      </div>
    </div>
  );
});

SelectCountryPage.displayName = "SelectCountryPage";
export default SelectCountryPage;
