import axios from "axios";

const loadAbort = () => {
  return new AbortController();
};

export const loginEndpoint = (username: string, password: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/user/v1/auth/user/login`,
        data: JSON.stringify({ username, password }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const getDataDeveloper = (token: string) => {
  const controller = loadAbort(); 
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/user/v1/developer`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
      }),
    controller,
  };
}

export const requestChangePasswordEndpoint = (email: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/user/v1/auth/user/request-change-password`,
        data: JSON.stringify({ email }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const checkChangePasswordEndpoint = (id: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/user/v1/auth/user/get-change-password-request/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const changePasswordEndpoint = (id: string, password: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "put",
        url: `${urlService}/user/v1/auth/user/change-password/${id}`,
        data: JSON.stringify({ password }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const requestLoginByCodeEndpoint = (email: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/user/v1/auth/user/request-login-by-code`,
        data: JSON.stringify({ email }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const verifyLoginByCodeEndpoint = (id: string, code: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "put",
        url: `${urlService}/user/v1/auth/user/verify-login-by-code/${id}`,
        data: JSON.stringify({ code }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};
