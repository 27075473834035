import {
  IconAlertCircle,
  IconCheck,
  IconChevronRight,
  IconInfoSquareRounded,
  IconTrash,
  IconUpload,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppStore } from "../../redux/store";
import { useDocuments } from "./hooks/useDocuments";
import InputMask from "react-input-mask";
import { sendDocuments } from "../../services/documentService";
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import { getAuthToken } from "../../helpers/authHelper";
import { useNavigate } from "react-router-dom";
import { Snackbar } from "@mui/material";
import { formatDistanceToNow, isValid, parse } from "date-fns";
import { es } from "date-fns/locale";

interface Document {
  id: string;
  document: string;
  code: string;
  country: string;
  created_at: string;
  updated_at: string;
  input_mask: string | null;
  credit_evaluator_usage: boolean;
  bussiness_assigned: string[];
  require_document_number: boolean;
}

interface UploadedFile {
  file: File | null;
  id: string;
  text: string;
  isUploaded: boolean;
  document: string;
  code: string;
  input_mask: string;
  exists: boolean;
  error: string;
  require_document_number: boolean;
}

const DocumentsTn = () => {
  const { country } = useSelector((state: AppStore) => state.country);
  const {
    cataLogDocument,
    existsDocuments,
    loading,
    getDocumentsUser,
    getDocumentsExist,
  } = useDocuments();
  const { callEndpoint } = useCallApiAndLoad();
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const navigate = useNavigate();
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    event.stopPropagation();
    const newFiles = [...uploadedFiles];
    const file = event.target.files?.[0] || null;
    const index = newFiles.findIndex((file) => file.id === id);
    if (index !== -1) {
      newFiles[index].file = file;
      setUploadedFiles(newFiles);
    }
  };

  const handleTextChange = (text: string, id: string) => {
    const newFiles = [...uploadedFiles];
    const index = newFiles.findIndex((file) => file.id === id);
    if (index !== -1) {
      newFiles[index].text = text;
      setUploadedFiles(newFiles);
    }
  };

  const removeFile = (id: string) => {
    const newFiles = uploadedFiles.filter((file) => file.id !== id);
    setUploadedFiles(newFiles);
  };

  const formDataToJson = (formData: any) => {
    return Object.fromEntries(formData.entries());
  };

  const submitFile = async (id: string, code: string) => {
    const uploadedFile = uploadedFiles.find((file) => file.id === id);
    if (!uploadedFile) return;

    const { file, text, require_document_number } = uploadedFile;
    const existingDocument = existsDocuments.find(
      (doc: any) => doc.code === code
    );
    const documentNumber = existingDocument?.document_number || "";

    let error = "";

    if (!file && !existsDocuments.some((doc: any) => doc.code === code)) {
      error = "Debes seleccionar un archivo.";
    }

    // Verificar si el documento ya existe antes de agregar document_input al formulario
    if (
      require_document_number &&
      !existsDocuments.some((doc: any) => doc.code === code)
    ) {
      if (!text) {
        error = "Debes ingresar un texto para el documento.";
      }
    }

    const newFiles = [...uploadedFiles];
    const index = newFiles.findIndex((file) => file.id === id);
    if (index !== -1) {
      newFiles[index].error = error;
      setUploadedFiles(newFiles);
    }

    if (error) {
      return;
    }

    const formData = new FormData();
    formData.append("document_type", code);

    if (!existsDocuments.some((doc: any) => doc.code === code) && text) {
      formData.append("document_input", text);
    } else if (text) {
      formData.append("document_input", text);
    } else {
      if (!require_document_number) {
        formData.append("document_input", "NA");
      } else {
        formData.append("document_input", documentNumber);
      }
    }

    if (file) {
      formData.append("document", file);
    }

    try {
      // Llamada a la función para crear el documento
      await createDocument(formData);

      // Actualizar el estado para marcar el archivo como enviado
      const newFiles = [...uploadedFiles];
      const index = newFiles.findIndex((file) => file.id === id);
      if (index !== -1) {
        newFiles[index].isUploaded = true;
        setUploadedFiles(newFiles);
      }

      // Limpiar el error
      setError("");
    } catch (error) {
      // Manejar cualquier error que pueda ocurrir al enviar el documento
      setError("Error al enviar el documento. Por favor, inténtalo de nuevo.");
    }
  };

  useEffect(() => {
    if (cataLogDocument.length > 0) {
      const initialUploadedFiles = cataLogDocument.map(
        (document: Document) => ({
          file: null,
          id: document.id,
          text: "",
          isUploaded: false,
          name: document?.document,
          code: document?.code,
          input_mask: document?.input_mask,
          error: "",
          require_document: document?.require_document_number,
        })
      );
      setUploadedFiles(initialUploadedFiles);
    }
  }, [cataLogDocument]);

  useEffect(() => {
    getDocumentsUser(country);
    getDocumentsExist(token);
  }, [country]);

  const [existDocument, setExistDocument] = useState<boolean>(false);

  useEffect(() => {
    const combinedDocuments = cataLogDocument.map((document: any) => {
      const existsDocument = existsDocuments.find(
        (existsDocument: any) => existsDocument.code === document.code
      );

      if (existsDocument) {
        return { ...document, exists: true };
      } else {
        return { ...document, exists: false };
      }
    });
    setUploadedFiles(combinedDocuments);
  }, [cataLogDocument, existsDocuments]);

  // Estado para el manejo de errores
  const [error, setError] = useState<string>("");

  //CREATE DOCUMENT

  const createDocument = async (payload: any) => {
    const { status, data } = await callEndpoint(sendDocuments(token, payload));
    if (status === 201) {
      setOpenMessage(true);
      setTimeout(() => {
        return navigate(0);
      }, 3000);
    } else {
      document.body.style.overflowY = "scroll";
    }
  };

  const [openMessage, setOpenMessage] = useState(false);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessage(false);
  };

  return (
    <div className="py-20 xl:py-32 container mx-auto">
      <div className="m-auto w-full px-4 lg:px-0">
        <div className="w-full mt-6 mb-2 text-blackGrayScale text-2xl lg:text-2xl font-semibold text-left tracking-wide">
          Documentos requeridos
        </div>
        <p className=" text-lg font-medium text-gray600 mb-2">
          Documentación requerida para tramites.
        </p>
      </div>
      <div className="w-full mt-20">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-10 px-10">
          {uploadedFiles.map(
            ({
              file,
              id,
              text,
              isUploaded,
              document,
              code,
              input_mask,
              exists,
              error,
              require_document_number,
            }) => (
              <div className="" key={id}>
                <h2 className="text-[#212121] font-medium text-lg">
                  {" "}
                  {document}
                </h2>

                {existsDocuments.map((doc: any) => {
                  if (doc?.code === code) {
                    const updateDate = doc?.re_entry_at;

                    if (updateDate) {
                      const parsedDate = parse(
                        updateDate,
                        "dd/MM/yyyy",
                        new Date()
                      );
                      if (isValid(parsedDate)) {
                        return (
                          <p
                            className="text-sm font-medium text-[#757575] mb-4 flex justify-start items-center"
                            key={doc.code}
                          >
                            <IconChevronRight color="#757575" /> Necesitas
                            actualizar este documento{" "}
                            {formatDistanceToNow(parsedDate, {
                              addSuffix: true,
                              locale: es,
                            })}
                            *
                          </p>
                        );
                      }
                    }
                  }
                  return null; // Do not render anything if the date is empty or invalid
                })}

                <input
                  type="file"
                  accept=".pdf,image/*"
                  onChange={(e) => handleFileChange(e, id)}
                  className="w-full text-black text-base bg-gray-100 file:cursor-pointer cursor-pointer file:border-0 file:py-2.5 file:px-4 file:mr-4 file:bg-primary file:hover:bg-gray-700 file:text-white rounded"
                />
                {require_document_number && (
                  <div className="relative input-icon py-2">
                    {exists ? (
                      <div className="w-full mt-1">
                        <p className="font-semibold text-[#212121]">
                          Numero de documento
                        </p>
                        {/* Obtener el número del documento existente */}
                        {existsDocuments.map((doc: any) => {
                          if (doc?.code === code) {
                            // Obtener el número de documento
                            const documentNumber = doc?.document_number || "";

                            return (
                              <InputMask
                                key={doc.id}
                                className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 pl-10"
                                mask={input_mask}
                                alwaysShowMask={false}
                                onChange={(e) =>
                                  handleTextChange(e.target.value, id)
                                }
                                defaultValue={documentNumber}
                              />
                            );
                          }
                        })}
                      </div>
                    ) : (
                      <div className="w-full mt-1">
                        <p className="font-semibold text-[#212121]">
                          Numero de documento
                        </p>

                        <InputMask
                          className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 pl-10"
                          mask={input_mask}
                          alwaysShowMask={false}
                          onChange={(e) => handleTextChange(e.target.value, id)}
                        />
                      </div>
                    )}
                  </div>
                )}

                {file && (
                  <>
                    <p className="font-semibold text-[#212121] mb-3">
                      Documentos por subir
                    </p>
                    <div className="flex justify-start items-center mt-2">
                      <p className="text-[#757575] text-sm font-medium">
                        {file.name} -
                      </p>
                      <button onClick={() => removeFile(id)}>
                        <IconTrash color="#F44336" />
                      </button>
                    </div>
                  </>
                )}

                {exists && (
                  <>
                    <p className="font-semibold text-[#212121] mb-3">
                      Documentos subidos
                    </p>
                    {existsDocuments.map((doc: any) => {
                      if (doc.code === code) {
                        return (
                          <a
                            href={doc?.document_path}
                            target="_blank"
                            key={doc.id}
                            className="text-sm text-[#757575] flex justify-start items-center gap-2"
                            rel="noreferrer"
                          >
                            <IconCheck color="#757575" /> {doc.document}
                          </a>
                        );
                      }
                    })}
                  </>
                )}

                <button
                  className={`text-white bg-[#212121] rounded-lg px-4 py-3 w-full text-sm flex justify-center items-center gap-2 mt-4 ${
                    isUploaded ? "bg-gray-400 cursor-not-allowed" : ""
                  }`}
                  onClick={() => submitFile(id, code)}
                  disabled={isUploaded}
                >
                  <IconUpload color="#fff" />
                  {/* {isUploaded ? "Enviado" : "Subir documento"} */}
                  {exists ? "Actualizar documento" : "Subir documento"}
                </button>
                {error && (
                  <p className="text-medium text-base text-[#FF3D00] mt-2 flex justify-start items-center gap-1">
                    <IconAlertCircle color="#FF3D00" /> {error}
                  </p>
                )}
              </div>
            )
          )}
        </div>
      </div>
      {/* Mostrar el error si está presente */}
      <Snackbar
        open={openMessage}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleClose}
        message="Documento subido exitosamente."
      />
    </div>
  );
};

export default DocumentsTn;
