import arrow_button from "../../../assets/icons/arrow_button.svg";

// hooks
import { useDispatch, useSelector } from "react-redux";
import { getAuthToken, setLastPath, unsetAuth } from "../../../helpers/authHelper";
import useCallApiAndLoad from "../../../hooks/useCallApiAndLoad";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// components
import Modal from "../../../components/Modal";
import ModalSubscribe from "../components/ModalSubscribe";
import ModalPayment from "../../../components/Payments/ModalPayment";

// helpers
import { AppStore } from "../../../redux/store";
import { logoutAction } from "../../../redux/features/auth";
import { fetchResponseAdapter } from "../../../adapters/fetchAdapter";
import { getSalesCheckoutEndpoint } from "../services/tenantService";

import "../scss/TenantPaymentDetail.scss";
import TransferPayment from "../../../components/Payments/TransferPayment";
import { Dialog } from "@mui/material";

// services

const SalesPaymentCheckout = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  const [loading, setLoading] = useState<boolean>(false);
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const [data, setData] = useState<any>();
  const { contract_id = "" } = useParams();
  const { id = "" } = useParams();
  const [payment, setPayment] = useState<string>("pay");
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const { callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const loadPaymentDetail = async () => {
      const paymentMethodMapper = {
        pay: "card",
        transfer: "wire_transfer",
      };
      setLoading(true);
      const { status, data } = await callEndpoint(
        getSalesCheckoutEndpoint(
          token,
          contract_id,
          id,
          paymentMethodMapper[payment as keyof typeof paymentMethodMapper]
        )
      );
      if (status === 401) {
        dispatch(logoutAction({}));
        unsetAuth();
        setLastPath(`/app/${country}/l/tn/payment-detail/${contract_id}/${id}`);
        setLoading(false);
        return navigate(`/app/${country}/auth/login`);
      }
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setData(response.data);
        setLoading(false);
        console.log("data", response.data);
        if (response.data.is_available_card_transactions === false) {
          setPayment("transfer");
        }
      }
    };
    loadPaymentDetail().catch((e) => console.error(e));
  }, [payment]);

  const methodPay = (e: any) => {
    setPayment(e.target.value);
  };
  /* TO DO:  */
  // Dropzone config

  //Modales pago
  const toggleSubscribe = () => {
    try {
      setShowSubscribe((old) => !old);
      document.body.style.overflow = "scroll";
    } catch (error) {
      console.log("error");
    }
  };

  const togglePay = () => {
    try {
      setShowPayment((old) => !old);
      document.body.style.overflow = "scroll";
    } catch (error) {
      console.log("error");
    }
  };

  const [openModal, setOpenodal] = useState(false);

  const handleClickOpen = () => {
    setOpenodal(true);
  };

  const handleClose = () => {
    setOpenodal(false);
  };

  return (
    <>
      <div className="container">
        <div className="w-full py-24">
        <div className="m-auto w-full px-4 lg:px-0">
        <h1 className="text-blackGrayScale text-2xl lg:text-2xl font-semibold text-left tracking-wide">Realiza tus pagos</h1> 

            <p className=" text-lg font-medium text-gray600 mb-2">
              Pago de cuota
            </p>
            </div>

          <div className="w-full max-w-[960px] mx-auto">
          <div className="pb-2 lg:pb-10">
              <div className="w-full px-4 lg:px-0 flex justify-center mx-auto mt-12">
                <div className="rounded-lg border border-[#E0E0E0] py-4 px-4 w-full flex flex-col lg:flex-row justify-start items-center">
                    <img className="rounded-lg h-32 w-full object-cover object-center" src={data?.saleInfo?.property_cover} alt="img-property" />
                    <div className="w-full xl:pl-6 py-4 lg:py-0">
                        <h1 className="text-xl font-semibold">{data?.saleInfo?.property_name}</h1>
                        <p className="font-semibold text-[#212121] text-sm xl:text-base">#{data?.saleInfo?.property_code}</p>
                        <p className="text-sm xl:text-base font-semibold text-[#212121]">Direccion: <span className="font-normal">{data?.saleInfo?.property_address}</span></p>
                    </div>
                </div>
              </div>
            </div>
            {!loading ? (
              <div className="w-full flex flex-wrap justify-center items-start px-4 ">
                <div className="w-full my-8 lg:my-0 mx-auto max-w-[450px] border border-[#E0E0E0] rounded-lg p-4 lg:px-7 px-4">
                  <div className="w-full py-4">
                    <p className="text-base text-[#212121] font-bold">
                      Detalle de mensualidad
                    </p>
                  </div>
                  <hr className=" w-full border-tea-500" />
                  <div className="w-full py-4">
                    {data?.saleDetail.map((item: any) =>
                      item.is_total ? (
                        <div className="w-full mb-2 text-[#757575] flex justify-between">
                          <span> {item.label}</span>
                          <span> {item.value}</span>
                        </div>
                      ) : (
                        <div className="w-full mb-2 text-[#757575] flex justify-between">
                          <span> {item.label}</span>
                          <span> {item.value}</span>
                        </div>
                      )
                    )}
                  </div>
                  <hr className=" w-full border-tea-500" />
                  <div className="w-full py-4">
                    <div className="w-full mb-2 text-graySoft flex justify-between">
                      <p className="text-[#212121] font-bold tracking-wide">
                        Total de mensualidad
                      </p>
                      <span className="text-[#212121] font-bold">
                        {data?.total}
                      </span>
                    </div>
                    {/* <div className="w-full mb-2 text-graySoft flex justify-between">
                  <span>Cantidad de cuotas seleccionadas</span>
                  <span>2</span>
                </div> */}
                  </div>
                  <hr className=" w-full border-tea-500" />
                  <div className="mt-4 mb-2">
                    <div className="w-full bg-[#F2F6EF] px-4 rounded-full py-4 flex justify-between items-center">
                      <span className="text-[#212121] font-semibold">
                        Total a cancelar
                      </span>
                      <span className="text-[#212121] text-xl font-bold">
                        {data?.total}
                      </span>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="w-full my-4">
                      <p className="text-[#212121] text-lg font-semibold text-center">
                        Método de pago
                      </p>
                    </div>
                    {data?.is_available_card_transactions && (
                      <div className="w-full text-center pb-4 items-center flex flex-wrap justify-between">
                        <span className=" text-graySoft font-medium text-base">
                          Seleccionar
                        </span>
                        <div className="flex justify-between items-center">
                          {data?.is_available_card_transactions ? (
                            <div className="flex items-center justify-center">
                              <label className="customRadio">
                                Tarjeta
                                <input
                                  type="radio"
                                  value="pay"
                                  name="radio"
                                  onChange={(e) => methodPay(e)}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          ) : (
                            <></>
                          )}

                          <div className="ml-2 flex items-center justify-center">
                            <label className="customRadio">
                              Transferencia
                              <input
                                type="radio"
                                value="transfer"
                                name="radio"
                                onChange={(e) => methodPay(e)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    )}

                    {payment !== "pay" && (
                      <TransferPayment
                        typeTransfer="rent"
                        contract_id={contract_id}
                        transaction_detail_id={id}
                        realm="sales"
                      />
                    )}
                    {payment === "pay" && (
                      <div>
                        <div className="border border-tea-500 rounded-lg hover:shadow-sm pb-4 px-6">
                          <div className="w-full text-center my-6">
                            <p className="text-[#212121] font-semibold text-lg">Realizar Pago</p>
                            <img
                              className="w-48 mx-auto mt-3"
                              src={data?.cardProviderInfo?.provider_logo}
                              alt=""
                            />
                          </div>
                          <div className="w-full my-6 flex justify-center">
                            <button
                              onClick={handleClickOpen}
                              className="bg-[#212121] py-3 w-full text-white text-base rounded-lg flex justify-center items-center group"
                            >
                              <span className=" mr-3">
                                Realizar pago
                              </span>
                              
                            </button>
                          </div>
                        </div>
                        {data?.is_subscriptable ? (
                          <div className="border border-tea-500 rounded-lg hover:shadow-sm my-6 py-4 px-6">
                            <div className="w-full text-center  text-blueForm font-bold">
                              Suscripción
                            </div>
                            <div className="my-2 text-graySoft text-center px-4">
                              Realiza tu pago y paga automáticamente tus cuotas.
                            </div>
                            <div className="flex w-full mt-6 mb-3 justify-center lg:px-6">
                              <button
                                onClick={toggleSubscribe}
                                className="bg-primary py-2 pl-8 pr-2 text-white text-base rounded-full flex justify-end items-center group"
                              >
                                <span className="mx-auto mr-3">
                                  Pagar y suscribirse
                                </span>
                                <img
                                  className="inline group-hover:-translate-x-3 duration-300 ease-in-out"
                                  src={arrow_button}
                                  alt=""
                                />
                              </button>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="h-[20vh] justify-center items-center w-full bg-white hidden md:flex">
                <img
                  className="w-16"
                  src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/loading-propi.gif"
                  alt="loading"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {showSubscribe ? (
        <Modal
          onAction={toggleSubscribe}
          name="Pagar y subscribirse"
          size="max-w-lg"
          body={
            <ModalSubscribe
              onAction={toggleSubscribe}
              extraAction={toggleSubscribe}
              name="Pagar y subscribirse"
              btnText="Subscribirse"
              contractId={contract_id}
              transactionId={id}
            />
          }
        />
      ) : null}
      {showPayment && (
        <Modal
          onAction={togglePay}
          // name="Pago de cuota"
          size="max-w-lg"
          body={
            <ModalPayment
              onAction={togglePay}
              extraAction={togglePay}
              contractId={contract_id}
              transactionId={id}
              name="Pago de cuota"
              btnText="Pagar"
              realm="sales"
            />
          }
        />
      )}

<Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalPayment
              onAction={handleClose}
              extraAction={togglePay}
              contractId={contract_id}
              transactionId={id}
              name="Pago de cuota"
              btnText="Pagar"
              realm="sales"
            />
      </Dialog>  
    </>
  );
};
export default SalesPaymentCheckout;
