import background from '../assets/images/confirmation-background.png';
import logo from '../assets/images/logo_propi_white.svg';

const Forbidden = ({ message }: { message: string }) => {

    return (
        <section
            className="overflow-hidden bg-cover bg-center h-screen"
            style={{ "backgroundImage": `url(${background})` }}>

            <div className="flex flex-col items-center justify-center h-screen">
                <div className="text-center mb-6">
                    <img className="inline-block mb-5" src={logo} alt="Logo PropiLatam" />
                </div>
                <h2 className="text-white font-semibold text-4xl text-center mb-6">
                    {message}
                </h2>
                <h3 className="text-white font-normal text-l text-center mb-12">
                    Valida con el administrador del sistema cualquier operación que requieras
                </h3>
                
            </div>

        </section>
    )
}

export default Forbidden
