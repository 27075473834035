import axios from "axios";
import {
  SaveScheduleVisitForm,
  UpdateScheduleVisitForm,
} from "../models/visitModel";

const loadAbort = () => {
  return new AbortController();
};

export const getAvailableTimeVisitsEndpoint = (
  propertyId: string,
  day: string,
  token: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/properties/${propertyId}/available-time-visits/${day}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const scheduleVisitEndpoint = (
  form: SaveScheduleVisitForm,
  token: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/ts/v1/tenant/visits`,
        data: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const rescheduleVisitEndpoint = (
  form: UpdateScheduleVisitForm,
  visitId: string,
  token: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "put",
        url: `${urlService}/ts/v1/tenant/visits/${visitId}/reschedule`,
        data: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

//GUEST

export const getDatesAvailableVisits = (propertyId: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/properties/${propertyId}/available-dates-visits`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const validateGuestVisit = (form: any) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/user/v1/guest/visit`,
        data: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const validateGuestOTP = (form: any) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/user/v1/guest/validate-otp`,
        data: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const sendGuestOTP = (data: any) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/user/v1/guest/send-otp`,
        data: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const getHoursAvailable = (date: string, propertyId: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/properties/${propertyId}/available-time-visits/${date}`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const getDatesAvailableVisitsProperties = (projectId: string) => {
  const controller = loadAbort()
  return {
      call: (urlService: string) => 
        axios({
          method: 'get',
          url: `${urlService}/ts/v1/properties/${projectId}/available-dates-visits`,
          headers: { 
              'Content-Type': 'application/json'
          }
      }), 
      controller
  };
}
