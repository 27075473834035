import axios from "axios";
import { OfferForm, SignConsentForm } from "../models/offerModel";

const loadAbort = () => {
  return new AbortController();
};

export const prequalificateEndpoint = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/ts/v1/score/prequalification`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const consentRequestEndpoint = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/score/consent-request`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const saveVisitRequirementsEndpoint = (
  document: string | undefined,
  token: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/user/v1/tenant/save-offer-visit-requirements`,
        data: JSON.stringify({ document }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const createOfferEndpoint = (form: OfferForm, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/ts/v1/tenant/offers`,
        data: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const checkVisitRequirementsEndpoint = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/user/v1/tenant/check-offer-visit-requirements`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const checkSignedConsentEndpoint = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/score/check-signed-consent`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getPreviewConsentEndpoint = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/score/preview-consent`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const signConsentEndpoint = (
  scoreId: string,
  form: SignConsentForm,
  token: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "put",
        url: `${urlService}/ts/v1/score/sign-consent/${scoreId}`,
        data: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getDocumentsCountry = (code: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/user/v1/documents?countryCode=${code}`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};
