import { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import {
  FiltersStore,
  IPropertyItemMarketplace,
} from "../../models/PropertyModel";
import { MAP_STYLE } from "../../constants/MapConstants";
import useSuperCluster from "use-supercluster";
import ClusterIcon from "../Map/ClusterIcon/ClusterIcon";
import Pin from "../Map/Pin/Pin";
import CardProperty from "../CardProperty/Card";
import { Box, Modal } from "@mui/material";
import { useWindowSize } from "usehooks-ts";

import "./Map.scss";
import {
  cleanFormParams,
  replaceFormParams,
} from "../../pages/Marketplace/helpers/paramsHelpers";
import { useDispatch, useSelector } from "react-redux";
import { setFiltersAction } from "../../redux/features/filters";
import { AppStore } from "../../redux/store";
import { createSearchParams, useNavigate } from "react-router-dom";
import { SearchButton } from "../Map/SearchButton/SearchButton";

interface Props {
  total: number;
  zoom: number;
  center: { lat: number; lng: number };
  items: IPropertyItemMarketplace[];
  withSearchButton: boolean;
  loading: boolean;
  marketplace_type?: string;
}

const MapV2 = ({
  total,
  zoom,
  center,
  items,
  withSearchButton,
  loading,
  marketplace_type
}: Props) => {
  const mapContainerStyles = {
    width: "100%",
    height: "100%",
  };
  const mapOptions = {
    zoom,
    center,
    streetViewControl: false,
    mapTypeControl: false,
    gestureHandling: "greedy",
    styles: MAP_STYLE,
    minZoom: 13,
    maxZoom: 18,
    fullscreenControl: false,
    clickableIcons: false,
  };

  const { width } = useWindowSize();
  const filters = useSelector((store: AppStore) => store.filters);
  const [propertiesGroup, setPropertiesGroup] = useState<any>([]);
  const [currentCenter, setCurrentCenter] = useState(center);
  const [loadCenter, setLoadCenter] = useState(center);
  const [enabledButton, setEnabledButton] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [mapZoom, setMapZoom] = useState(zoom);
  const [bounds, setBounds] = useState<number[] | null>(null);
  const mapRef = useRef<any>(); // to handle a click on a cluster to update map

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Marker = ({ children }: any) => children;

  const mapPoints = () => {
    return items.map((property: IPropertyItemMarketplace) => ({
      type: "Feature",
      properties: {
        cluster: false,
        property_data: property,
      },
      geometry: {
        type: "Point",
        coordinates: [property.coordinates.lng, property.coordinates.lat],
      },
    }));
  };

  const { clusters, supercluster } = useSuperCluster({
    points: mapPoints(),
    bounds,
    zoom: mapZoom,
    options: {
      radius: 75,
      maxZoom: 18,
    },
  });

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "800px",
    height: "600px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: "10px",
    "@media (max-width: 768px)": {
      width: "100%",
      p: 2,
    },
  };

  const handleSearchButton = (forcedZoom: number = 0) => {
    const searchZoom = forcedZoom > 0 ? forcedZoom : mapZoom;
    setEnabledButton(false);
    // Eliminar location_id y location_type de la URL
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("location_id");
    searchParams.delete("location_type");
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    // window.open(newUrl, "_self");

    // Recargar la página

    const geoData = {
      lat: currentCenter.lat,
      lng: currentCenter.lng,
      zoom_search: searchZoom,
      location_id: null,
      location_type: null,
    };

    // Resto del código...
    setLoadCenter({
      lat: currentCenter.lat,
      lng: currentCenter.lng,
    });

    if (width < 768) {
      localStorage.setItem("option-map", "map");
    }

    dispatch(
      setFiltersAction({
        ...filters,
        lat: currentCenter.lat,
        lng: currentCenter.lng,
        zoom_search: searchZoom, // Actualizar zoom_search en la acción de los filtros
        location_id: null,
        location_type: null,
      })
    );

    const replaceParamsObject: any = {
      ...filters,
      ...geoData,
    };


    if (JSON.stringify(geoData) !== "{}") {
      const filterDataMapped: FiltersStore =
        replaceFormParams(replaceParamsObject);
      dispatch(setFiltersAction(filterDataMapped));
      const cleanedFilters = cleanFormParams(filterDataMapped);
      if (Object.keys(cleanedFilters).length > 0) {
        navigate(`?${createSearchParams(cleanedFilters)}`);
      }

      //window.location.reload();
    }
  };

  useEffect(() => {
    if (center && center !== currentCenter) {
      setCurrentCenter(center);
      setLoadCenter(center);
    }
  }, [center]);

  const compareByPropertyStatus = (a: any, b: any) => {
    let order: any = {
      published: 1,
      taken: 2,
      rented_external: 3,
    };

    let statusA: any = a?.properties?.property_data?.property_status;
    let statusB: any = b?.properties?.property_data?.property_status;

    if (order[statusA] < order[statusB]) {
      return -1;
    } else if (order[statusA] > order[statusB]) {
      return 1;
    } else {
      return 0;
    }
  };

  return (
    <div className="container-map" style={mapContainerStyles}>
      {enabledButton && <SearchButton onClick={handleSearchButton} />}
      <GoogleMapReact
        bootstrapURLKeys={{
          key:
            process.env.REACT_APP_MAPS_KEY ??
            "AIzaSyB0c1nq-isvWJWWam5CICmPt0oYgY1A_eg",
        }}
        center={loadCenter}
        defaultZoom={zoom}
        options={mapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChange={({ zoom, bounds, center }) => {
          setMapZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
          setCurrentCenter({
            lat: center.lat,
            lng: center.lng,
          });
        }}
        onZoomAnimationEnd={() => {
          setEnabledButton(true);
        }}
        onDragEnd={() => {
          setEnabledButton(true);
        }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster } = cluster.properties;
          if (isCluster) {
            return (
              <Marker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
              >
                <ClusterIcon
                  numProperties={cluster.properties.point_count}
                  handleClick={() => {
                    if (mapZoom >= 18) {
                      handleOpen();
                      setPropertiesGroup(
                        supercluster
                          .getLeaves(cluster.id, Infinity)
                          .sort(compareByPropertyStatus)
                      );
                    } else {
                      const expasionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(cluster.id),
                        18
                      );
                      if (mapRef.current) {
                        mapRef.current.setZoom(expasionZoom);
                        mapRef.current.panTo({ lat: latitude, lng: longitude });
                        // handleSearchButton(expasionZoom);
                        //
                      }
                    }
                  }}
                />
              </Marker>
            );
          }
          return (
            <Marker
              key={`mki-${cluster.properties.property_data.property_id}`}
              lat={latitude}
              lng={longitude}
            >
              <Pin property={cluster.properties.property_data} marketplace_type={marketplace_type} />
            </Marker>
          );
        })}
      </GoogleMapReact>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex justify-start gap-3 items-center mb-6">
            <button onClick={handleClose}>
              <img
                src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/close_modal.svg"
                alt="close"
              />
            </button>
            <p className=" font-medium text-[#212121] base xl:text-sm">
              Hay {propertiesGroup.length} propiedades en esta misma ubicación
            </p>
          </div>
          <div className="flex overflow-y-auto flex-wrap w-full cluster-properties">
            {propertiesGroup?.length > 0 &&
              propertiesGroup.map((item: any, index: number) => (
                <div className="w-full lg:w-1/2 p-2">
                  <CardProperty
                    property_status={
                      item?.properties?.property_data?.property_status as string
                    }
                    property_name={
                      item?.properties?.property_data.property_name as string
                    }
                    gallery={item?.properties?.property_data.gallery}
                    property_city={
                      item?.properties?.property_data.property_city as string
                    }
                    property_code={
                      item?.properties?.property_data.property_code as number
                    }
                    property_id={
                      `${item?.properties?.property_data.property_id}-c` as string
                    }
                    property_price={
                      item?.properties?.property_data.property_price as string
                    }
                    property_type_value={
                      item?.properties?.property_data.property_type_value as string
                    }
                    property_type={
                      item?.properties?.property_data.property_type as string
                    }
                    state_name={
                      item?.properties?.property_data.state_name as string
                    }
                    characteristics={
                      item?.properties?.property_data.characteristics
                    }
                    url_detail={item?.properties?.property_data.url_detail}
                    available_at_date={
                      item?.properties?.property_data?.available_at_date
                    }
                    property_old_price={item?.properties?.property_data?.property_old_price}
                    exchange_property_price={
                      item?.properties?.property_data?.exchange_property_price
                    }
                    property_price_currency_symbol={
                      item?.properties?.property_data?.property_price_currency_symbol
                    }
                    exchange_property_old_price={
                      item?.properties?.property_data?.exchange_property_old_price
                    }
                    marketplace_type={marketplace_type}
                    property_sale_status={item?.properties?.property_data?.property_sale_status}
                  />
                </div>
              ))}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default MapV2;
