import { useEffect, useState } from 'react'

import useCallApiAndLoad from '../../hooks/useCallApiAndLoad'
import { fetchResponseAdapter } from '../../adapters/fetchAdapter'

//assets
import arrow_left from '../../assets/icons/arrow_left.svg';

import { getAdminPropertyDetailEndpoint } from './services/PropertiesService';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../redux/store';
import { logoutAction } from '../../redux/features/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { unsetAuth, getAuthToken } from '../../helpers/authHelper';
import DetailLabel from '../../components/General/DetailLabel';

const PropertyDetail = () => {
    const { country } = useSelector((store: AppStore) => store.country);
    const { id = '' } = useParams()
    const user = useSelector((store: AppStore) => store.auth)
    const token = getAuthToken(user)
    const { isLoading, callEndpoint } = useCallApiAndLoad()
    const [data, setData] = useState<any>({})
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        const loadDetail = async () => {
            const { status, data } = await callEndpoint(getAdminPropertyDetailEndpoint(id, token))
            if (status === 401) {
                dispatch(logoutAction({}))
                unsetAuth()
                return navigate(`/app/${country}/auth/login`)
            }
            if (status === 200) {
                const response = fetchResponseAdapter(data)
                setData(response.data.items || [])
            }
            const response = fetchResponseAdapter(data)
            setData(response.data)
        }
        loadDetail()
            .catch(e => console.error(e))
    }, [])

    return (
        <>
            <section className='pt-16'>
                <div className="flex justify-between items-center py-4 px-4">
                    <a onClick={() => navigate(-1)}
                        className='cursor-pointer flex justify-center items-center' >
                        <img className='mr-2' src={arrow_left} alt="" />
                        Regresar
                    </a>
                </div>
            </section>
            <hr />
            <section>
                <div className="w-full text-center my-6 text-third text-lg">
                    Propiedad ID {data?.code}
                </div>
                <div className="m-auto w-full">
                    <div className="w-full my-6 text-third text-4xl font-bold text-center">
                        Administra tu propiedad
                    </div>
                </div>
            </section>
            <hr />
            <section className="pt-12 pb-12 flex flex-col items-center space-y-5 w-full" >
                <div className="lg:px-2 w-full lg:w-2/3">
                    <div className="w-full px-5 py-4">
                        <div className="w-full md:flex md:flex-start">
                            <div className="text-blueForm font-semibold w-1/2">
                                Información de propiedad
                            </div>
                        </div>
                    </div>
                </div>
                <DetailLabel label='Tipo de inmueble' value={data?.type} bg />
                <DetailLabel label='Mensualidad de alquiler' value={data?.monthly_price} />
                <DetailLabel label='Cantidad de habitaciones' value={data?.rooms} bg />
                <DetailLabel label='Cantidad de baños ' value={data?.bathrooms} />
                <DetailLabel label='Estacionamientos' value={data?.parking} bg />
                <DetailLabel label='Desde cuando puede ser rentado' value={data?.available_at} />
                <DetailLabel label='País' value={data?.country} bg />
                <DetailLabel label='Estado provincia o departamento' value={data?.state} />
                <DetailLabel label='Ciudad zona o territorio' value={data?.city} bg />
                <DetailLabel label='Calle' value={data?.street} />
                <DetailLabel label='Número de departamento' value={data?.property_number} bg />
                <DetailLabel label='Más información' value={data?.extra_info} />
                <DetailLabel label='Descripción' value={data?.description} bg />
                <DetailLabel label='Amenidades' value={
                    data && data.amenities && data.amenities.length
                        ? data.amenities.join(' ') : 'No disponible'
                } />

            </section>
        </>
    )
}

export default PropertyDetail