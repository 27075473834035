import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import { isLoggedIn } from "../helpers/authHelper"
import { AppStore } from "../redux/store"

type Props = {
    children: JSX.Element
}

const PrivateRouteDevelopers = ({ children }: Props) => {
    const user = useSelector((store: AppStore) => store.auth)
    const isLogged = isLoggedIn(user)
    const { country } = useSelector((store: AppStore) => store.country);
    console.log(isLogged)
    return(
        isLogged
            ? children 
            : <Navigate to={`/app/${country}/auth/desarrolladoras/login`} />    
    )
}

export default PrivateRouteDevelopers