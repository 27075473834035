import { createSlice } from "@reduxjs/toolkit";
import { PropertyStore } from '../../models/PropertyModel';

const initialState: PropertyStore = {
    id: '',
    location: {
        lat: null,
        lng: null
    }
}

export const propertySlice = createSlice({
    name: 'property',
    initialState,
    reducers: {
        setLocationAction: (state, action) => {
            return {
                ...action.payload
            }
        },
    }
})

// provide actions
export const { setLocationAction } = propertySlice.actions;
// provide reducer
export default propertySlice.reducer