export const replaceParams = (searchParams: any) => {
    return {
        is_new: searchParams.get('is_new')
            ? searchParams.get('is_new') === 'true' ? true : false 
            : null,
        is_furnished: searchParams.get('is_furnished')
            ? searchParams.get('is_furnished') === 'true' ? true : false 
            : null,
        has_white_goods: searchParams.get('has_white_goods')
            ? searchParams.get('has_white_goods') === 'true' ? true : false 
            : null,
        min_price: searchParams.get('min_price') 
            && !isNaN(Number(searchParams.get('min_price'))) ? Number(searchParams.get('min_price')) : null,
        max_price: searchParams.get('max_price') 
            && !isNaN(Number(searchParams.get('max_price'))) ? Number(searchParams.get('max_price')) : null,
        min_area: searchParams.get('min_area') 
            && !isNaN(Number(searchParams.get('min_area'))) ? Number(searchParams.get('min_area')) : null,
        max_area: searchParams.get('max_area') 
            && !isNaN(Number(searchParams.get('max_area'))) ? Number(searchParams.get('max_area')) : null,
        type: searchParams.get('type') || null,
        rooms: searchParams.get('rooms') 
            && !isNaN(Number(searchParams.get('rooms'))) ? Number(searchParams.get('rooms')) : null,
        bathrooms: searchParams.get('bathrooms') 
            && !isNaN(Number(searchParams.get('bathrooms'))) ? Number(searchParams.get('bathrooms')) : null,
        location_id: searchParams.get('location_id') || null,
        location_type: searchParams.get('location_type') || null,
        page: searchParams.get('page') 
        && !isNaN(Number(searchParams.get('page'))) ? Number(searchParams.get('page')) : null,
        take: searchParams.get('take') 
        && !isNaN(Number(searchParams.get('take'))) ? Number(searchParams.get('take')) : null,
        //New filters
        marketplace_type: searchParams.get('marketplace_type') || null,
        parking_spots: searchParams.get('parking_spots') 
            && !isNaN(Number(searchParams.get('parking_spots'))) ? Number(searchParams.get('parking_spots')) : null,
    }
}

export const replaceFormParams = (data: any) => {
    return {
        min_price: data.min_price !== "" && data.min_price > 0 
            ? Number(data.min_price) : null,
        max_price: data.max_price !== "" && data.max_price > 0 
            ? Number(data.max_price) : null,
        min_area: data.min_area !== "" && data.min_area > 0 
            ? Number(data.min_area) : null,
        max_area: data.max_area !== "" && data.max_area > 0 
            ? Number(data.max_area) : null,
        type: data.type !== "" ? data.type : null,
        is_new: null, // filter is not on screen
        is_furnished: data.is_furnished ? (data.is_furnished === "true" ? true : false) : null,
        has_white_goods: data.has_white_goods ? (data.has_white_goods === "true" ? true : false) : null,
        rooms: data.rooms ? Number(data.rooms) : null,
        bathrooms: data.bathrooms ? Number(data.bathrooms) : null,
        sort: data.sort && data.sort !== "" ? data.sort : null,
        lat: data.lat !== "" && !isNaN(data.lat) ? Number(data.lat) : null,
        lng: data.lng !== "" && !isNaN(data.lng) ? Number(data.lng) : null,
        zoom_search: data.zoom_search !== "" && !isNaN(data.zoom_search) ? Number(data.zoom_search) : null,
        location_id: data.location_id !== "" ? data.location_id : null,
        location_type: data.location_type !== "" ? data.location_type : null,
        page: data.page !== "" && data.page > 0 
            ? Number(data.page) : null,
            take: data.take !== "" && data.take > 0 
            ? Number(data.take) : null,
        // New

        marketplace_type: data.marketplace_type !== "" ? data.marketplace_type : null,
        parking_spots: data.parking_spots ? Number(data.parking_spots) : null,
        min_square_rods: data.min_square_rods !== "" && data.min_square_rods > 0 
            ? Number(data.min_square_rods) : null,
        max_square_rods: data.max_square_rods !== "" && data.max_square_rods > 0 
            ? Number(data.max_square_rods) : null,
    }
}

export const cleanFormParams = (data: any) => {
    let response: any = {}
    if (data.min_price && data.min_price > 0) {
        response.min_price = data.min_price;
      }
      if (data.max_price && data.max_price > 0) {
        response.max_price = data.max_price;
      }
      if (data.min_area && data.min_area > 0) {
        response.min_area = data.min_area;
      }
      if (data.max_area && data.max_area > 0) {
        response.max_area = data.max_area;
      }
      if (data.type !== null && data.type !== undefined) {
        response.type = data.type;
      }
      if (data.is_new) {
        response.is_new = data.is_new;
      }
      if (data.is_furnished !== null && data.is_furnished !== undefined) {
        response.is_furnished = data.is_furnished;
      }
      if (data.has_white_goods !== null && data.has_white_goods !== undefined) {
        response.has_white_goods = data.has_white_goods;
      }
      if (data.rooms !== null && data.rooms !== undefined) {
        response.rooms = data.rooms;
      }
      if (data.bathrooms !== null && data.bathrooms !== undefined) {
        response.bathrooms = data.bathrooms;
      }
      if (data.sort !== null && data.sort !== undefined) {
        response.sort = data.sort;
      }
      if (data.lat && data.lat !== 0) {
        response.lat = data.lat;
      }
      if (data.lng && data.lng !== 0) {
        response.lng = data.lng;
      }
      if (data.zoom_search && data.zoom_search > 0) {
        response.zoom_search = data.zoom_search;
      }
      if (data.location_id !== null && data.location_id !== undefined) {
        response.location_id = data.location_id;
      }
      if (data.location_type !== null && data.location_type !== undefined) {
        response.location_type = data.location_type;
      }
      if (data.page && data.page > 0) {
        response.page = data.page;
      }
      if (data.take && data.take > 0) {
        response.take = data.take;
      }
      // New filters

      if (data.marketplace_type !== null && data.marketplace_type !== undefined) {
        response.marketplace_type = data.marketplace_type;
      }
      if (data.parking_spots !== null && data.parking_spots !== undefined) {
        response.parking_spots = data.parking_spots;
      }

      if(data?.min_square_rods !== null && data?.min_square_rods > 0) response.min_square_rods = data?.min_square_rods
    if(data?.max_square_rods !== null && data?.max_square_rods > 0) response.max_square_rods = data?.max_square_rods
      
      
    return response
}

export const cleanLevels = (data: any) => {
  let response: any = {}
  if (data.level !== null && data.level !== undefined) {
    response.level = data.level;
  }
  
  return response;
}