import React from "react";
import { ClusterContainer } from "./styles";
import { AppStore } from "../../../redux/store";
import { useSelector } from "react-redux";

const ClusterIcon: React.FC<any> = ({ numProperties, handleClick }) => {
  const filters = useSelector((store: AppStore) => store.filters);
  return (
    <ClusterContainer
      className={`${
        filters?.marketplace_type === "for_sale" ? "bg-orange" : "bg-primary"
      }`}
      onClick={() => handleClick()}
    >
      <p>{numProperties}</p>
    </ClusterContainer>
  );
};

export default ClusterIcon;
