interface Props {
    register: any;
    name: string;
}

const Input = (props: Props) => {
    const { register, name } = props
    return (
        <div className="w-full border mt-2 border rounded-md p-2 flex items-center">
            <input
                type="text"
                {...register(name, { required: true })}
                className="border-none focus:ring-0 w-full focus:outline-none focus:border-none"
            />
        </div>
    )
}

export default Input
