import useAuth from "../../hooks/useAuth"
import './scss/NavBar.scss';

const NavBarInvestor = () => {

  const { handleLogout } = useAuth()

  return (
    <nav className="dropdown-menu bg-white lg:absolute lg:mt-[45px] lg:shadow-lg lg:rounded-[5px] w-full lg:w-fit lg:right-[15px] lg:z-[100] w-max">
      <ul className="w-max">
        <li className="w-fit">
          <ul className="w-fit">
            <li className="hover:bg-green200 hover:text-green700 text-sm lg:text-base py-2 lg:pl-2 lg:pr-6 rounded-t-lg">
              <a href="/l/ld/payments">Mis Inversiones</a>
            </li>
            <li className="hover:bg-green200 hover:text-green700 text-sm lg:text-base py-2 lg:pl-2 lg:pr-6">
              <a href="/l/ld/pre-booking">Mis pre-reservas</a>
            </li>
            <li className="hover:bg-green200 hover:text-green700 text-sm lg:text-base py-2 lg:pl-2 lg:pr-6">
              <a href="/l/ld/profile">Mis datos</a>
            </li>
            <li className="hover:bg-green200 hover:text-green700 text-sm lg:text-base py-2 lg:pl-2 lg:pr-6">
              <a href='/ayuda'>Obtener ayuda</a>
            </li>
            <li className="hover:bg-green200 hover:text-green700 text-sm lg:text-base py-2 lg:pl-2 lg:pr-6 rounded-b-lg">
              <button onClick={handleLogout}>Cerrar sesión</button>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  )
}

export default NavBarInvestor
