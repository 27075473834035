import styled from "styled-components";

export const SearchButtonStyled = styled.button `
  background-color: #212121;
  border-radius: 10px;
  box-shadow: 0 0 6px -1px rgba(90, 90, 90, 0.2),
    0 0 6px -1px rgba(3, 97, 228, 0.2);
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  height: 2.5rem;
  padding: 0 1.25rem;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  color: white;
  transform: translate(-50%, 10px);
  z-index: 1000;
`;