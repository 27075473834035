import '../../App.css'
import Button from '../../components/Button'
import * as Yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom'
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad'
import { useEffect, useState } from 'react'
import { 
    checkChangePasswordEndpoint, 
    changePasswordEndpoint 
} from './services/loginService';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter'
import Forbidden from '../../components/Forbidden'
import logo from '../../assets/images/iso_logo.svg';
import { ChangePasswordForm } from './models/loginModel';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormControlError from '../../components/Controls/FormControlError'
import { getPathByActionPathCode } from './helpers/loginRedirectHelper'
import { setLastPath } from '../../helpers/authHelper'
import { useSelector } from 'react-redux'
import { AppStore } from '../../redux/store'
import { IconLock } from '@tabler/icons-react'
import MainButton from '../../components/MainButton/mainButton'

const ChangePassword = () => {
  const {country} = useSelector((state: AppStore) => state.country);
    const { id = '', action_code = '' } = useParams()
    const { isLoading, callEndpoint } = useCallApiAndLoad()
    const [verification, setVerification] = useState({
        active: false
    })
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors } } = useForm<ChangePasswordForm>({
        resolver: yupResolver(Yup.object({
            password: Yup.string().required('Contraseña es requerida'),
            confirm_password: Yup.string()
                .required('Confirmación de Contraseña es requerida')
                .oneOf([Yup.ref('password'), null], 'Contraseñas deben ser iguales')
        }).required())
    })

    useEffect(() => {
        const checkAccontVerificaton = async () => {
            const { status, data } = await callEndpoint(checkChangePasswordEndpoint(id))
            if (status === 200) {
                const response = fetchResponseAdapter(data)
                setVerification(response.data)
            }
        }
        checkAccontVerificaton()
            .catch(e => console.error(e))
    }, [id])

    const doChangePassword = async (form: ChangePasswordForm) => {
        const { password } = form
        const { status, data } = await callEndpoint(changePasswordEndpoint(id, password))
        if(status === 200){
            if(action_code){
                const lPath = getPathByActionPathCode(action_code)
                if(lPath){
                    setLastPath(lPath)
                }
            }
            return navigate(`/app/${country}/auth/login`, { replace: true })
        }
        const { code, message } = data;
        setError(`Error(${code}): ${message}`)
    }

    if (isLoading) {
        return <h1>Verificando...</h1>
    }

    if (!verification.active) {
        return <Forbidden message='Lo sentimos, la acción que quiere realizar es inválida' />
    }

    return (
        <div className='bg-tea h-screen'>
            <div className="flex flex-col items-center justify-center h-screen">
                <div className="w-[440px] m-auto bg-white px-6 py-8 rounded-lg">
                    <section className="pt-0 pb-0">
                        <div className="text-center mb-6">
                        <img
                className="inline-block w-52"
                src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/v2/logo-propi.svg"
                alt="Logo PropiLatam"
              />
                        </div>
                        <h1 className="text-[#212121] font-semibold text-2xl text-center mb-4">
                            Nueva contraseña
                        </h1>
                    </section>
                    <h3 className='text-[#757575] font-light text-sm text-center mb-6'>
                        Escribe tu nueva contraseña
                    </h3>
                    <form onSubmit={handleSubmit(doChangePassword)} >
                    <div className="relative input-icon">
                    <IconLock color="#BDBDBD" />
                        <input
                            {...register("password")}
                            type='password'
                            placeholder='Password'
                            className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-[#757575] py-2 px-2 pl-10"
                        />
                    </div>
                        <FormControlError message={ errors.password?.message } />
                    <div className="relative input-icon">
                    <IconLock color="#BDBDBD" />
                        <input
                            {...register("confirm_password")}
                            type='password'
                            placeholder='Confirmar contraseña'
                            className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-[#757575] py-2 px-2 pl-10"
                        />
                    </div>
                        <FormControlError message={ errors.confirm_password?.message } />
                        <FormControlError message={ error } />
                        <div className="mt-6">
              <MainButton topic="Enviar" background="bg-[#212121]" colorText="text-white" type="submit" />
            </div>
                    </form>
                </div>
                
            </div>
        </div>
    )
}

export default ChangePassword
