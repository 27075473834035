import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

import { LandlordOffer, LandlordOfferProperty, rejectOfferForm } from '../../models/landlordModel';
import { REJECT_REASONS } from '../../helpers/RejectOfferReason';
import FormControlError from '../../../../components/Controls/FormControlError';
import SelectInput from '../../../../components/Controls/SelectInput';
import Button from '../../../../components/Button';
import arrow_button from '../../../../assets/icons/arrow_button.svg';
import FormLabel from '../../../../components/Controls/FormLabel';
import TextAreaInput from '../../../../components/Controls/TextAreaInput';
import useCallApiAndLoad from '../../../../hooks/useCallApiAndLoad';
import { AppStore } from '../../../../redux/store';
import { getAuthToken } from '../../../../helpers/authHelper';
import { rejectOfferEndpoint } from '../../services/LandlordOfferService';
import { useNavigate } from 'react-router-dom';

interface Props {
  onAction: () => void;
  property_info: LandlordOfferProperty;
  offer_info: LandlordOffer;
}

const FormReject = (props: Props) => {
  const {
    offer_info, property_info, onAction
  } = props
  const { callEndpoint } = useCallApiAndLoad()
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<rejectOfferForm>({
    resolver: yupResolver(
      yup.object().shape({
        reason_code: yup.string()
          .required('Razón es requerida')
      })
    ),
  });

  const doRejectOffer = async (form: rejectOfferForm) => {
    const {
      status
    } = await callEndpoint(rejectOfferEndpoint(property_info?.property_id,
      offer_info?.id, form, token))
    if (status === 200) {
      onAction()
      return navigate(0)
    }
  }

  return (
    <div className="w-full bg-white pt-2 px-5">
      <form onSubmit={handleSubmit(doRejectOffer)}>
        <div className='my-4'>
        <h1 className="w-full mb-4 text-blackGrayScale text-2xl lg:text-2xl font-semibold text-left tracking-wide">
               Rechazar oferta
      </h1>
          <p className="w-full text-[#757575] text-sm my-2">
          ¿Estás seguro de rechazar la oferta por {offer_info?.offer}?
          </p>
          <p className="text-sm font-medium text-[#212121] mt-4">Motivo</p>
          <SelectInput
            register={register}
            name={'reason_code'}
            empty_value="Seleccione un motivo de rechazo"
            list={REJECT_REASONS} />
          <FormControlError message={errors.reason_code?.message} />
        </div>
        <div className=''>
          <p className="text-sm font-medium text-[#212121]">Comentarios (opcional)</p>
          <TextAreaInput
            register={register}
            name={'comment_reject'} />
        </div>

        <div className="bg-white w-full py-6">
          <div className="w-full grid grid-cols-2 gap-6">
            <button type='button'>
              <div
                onClick={onAction}
                className="border border-[#212121] rounded-lg px-4 py-2 text-[#212121] hover:bg-[#212121] hover:text-white duration-100"
              >
                Cancelar
              </div>
            </button>
            <Button
              type="submit"
              className="px-4 py-2 rounded-lg bg-[#212121] hover:bg-primary duration-100 text-white"
            >
              Confirmar
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default FormReject
