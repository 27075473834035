import moreIcon from "../../assets/icons/r-arrow.svg";

// hooks
import { useDispatch, useSelector } from "react-redux";
import { getAuthToken, unsetAuth } from "../../helpers/authHelper";
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
// helpers
import { AppStore } from "../../redux/store";
import { logoutAction } from "../../redux/features/auth";
import { getTenantPaymentsEndpoint } from "./services/tenantService";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";

export enum ContractState {
  SIGNATURE_CONTRACT = "signature_contract",
  DEPOSIT_AND_FIRST_DUE = "deposit_and_first_due",
  MONTHLY_PAYMENT = "monthly_payment",
  RECURRENT_PAYMENT = "recurrent_payment",
  FINISH_CONTRACT = "finish_contract",
}

export type ValueOf<T> = T[keyof T];

interface IContracts {
  id: string;
  state: string;
  dues: string;
  monthly_price: string;
  pending_total: string;
  end_contract: string;
  propertyInfo: {
    id: string;
    address: string;
    cover: string;
  };
}

interface IcontractStateStyleitem {
  TagClass: string;
  TagLabel: string;
  TagWidth: string;
  TagBg?: string;
}
interface IcontractStateStyle {
  [key: string]: IcontractStateStyleitem;
}

const CONTRACT_STATUS: IcontractStateStyle = {
  [ContractState.DEPOSIT_AND_FIRST_DUE]: {
    TagClass: "primary",
    TagLabel: "Pagar depósito y mensualidad",
    TagWidth: "96",
    TagBg: "#D5F0E3",
  },
  [ContractState.FINISH_CONTRACT]: {
    TagClass: "Finalizar contrato",
    TagLabel: "primary",
    TagWidth: "auto",
    TagBg: "#D5F0E3",
  },
  [ContractState.SIGNATURE_CONTRACT]: {
    TagClass: "primary",
    TagLabel: "Firmar Contrato",
    TagWidth: "auto",
    TagBg: "#D5F0E3",
  },
  [ContractState.MONTHLY_PAYMENT]: {
    TagClass: "orange",
    TagLabel: "Pagar mensualidad",
    TagWidth: "5/6",
    TagBg: "#FEE3D5",
  },
  [ContractState.RECURRENT_PAYMENT]: {
    TagClass: "gray700",
    TagLabel: "Pago recurrente",
    TagWidth: "5/6",
    TagBg: "#F5F5F5",
  },
};

const TenantPayments = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const [data, setData] = useState<Array<IContracts>>([]);
  const [checkAuto, setCheckAuto] = useState<boolean>(false)

  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const loadPayments = async () => {
      // TODO add new fields from backend and new table
      const { status, data } = await callEndpoint(
        getTenantPaymentsEndpoint(token)
      );
      if (status === 401) {
        dispatch(logoutAction({}));
        unsetAuth();
        return navigate(`/app/${country}/auth/login`);
      }
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        // setPayments(response.data.items || [])
        setData(response.data.items);
      }
    };
    loadPayments().catch((e) => console.error(e));
  }, []);

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckAuto(event.target.checked)
  }

  const viewDetail = (propertyId: string) => {
    navigate(`/app/${country}/l/tn/make-payment/${propertyId}`);
  };

  return (
    <>
      {/* <NavBar /> */}
      <div className="container mx-auto px-4 xl:px-0">
        <div className="w-full py-24">
          <div className="w-full mt-6 mb-2">
              <h1 className="text-blackGrayScale text-2xl lg:text-2xl font-semibold text-left tracking-wide">Administra tus pagos</h1> 
            </div>
          <div className="w-full mb-6 mt-2">
          <p className=" text-lg font-medium text-gray600 mb-2"> Alquileres </p>
          </div>

          <div className="w-full my-8 xl:mt-20 mt-12">
              <div className=" grid md:grid-cols-2 xl:grid-cols-3 gap-6">

                {
                  data?.length > 0 ? (
                    data.map((items, index) => {
                      const { TagLabel, TagClass, TagBg } = 
                      CONTRACT_STATUS[items.state];
                      return (<div key={index} className="card-property pt-4 px-4">
                      <div className="flex justify-between flex-wrap">
                        <div className="w-3/4">
                          <span className={`py-1 px-2 rounded-lg bg-[${TagBg}] text-${TagClass} text-sm font-semibold`}>{TagLabel}</span>
                          <h2 className="font-medium text-lg truncate text-blackGrayScale leading-tight mt-4">
                            {items.propertyInfo?.address}
                          </h2>
                          <p className="text-base font-semibold text-blackGrayScale leading-tight">
                            {items?.monthly_price}
                          </p>
                        </div>
                        <div className="w-1/4">
                          <img
                            className="w-full h-full rounded-md object-cover object-center"
                            src={items.propertyInfo?.cover}
                            alt=""
                          />
                        </div>
                        <div className="w-full mt-3">
                          <p className=" text-gray600 text-sm">
                            <span className="font-semibold">Cuotas: </span>{items?.dues}
                          </p>
                        </div>
                        <div className="w-full mt-1">
                          <p className=" text-gray600 text-sm">
                          <span className="font-semibold">Total pendiente:</span> {items?.pending_total}
                          </p>
                        </div>
                        <div className="w-full mt-1">
                          <p className=" text-gray600 text-sm">
                          <span className="font-semibold">Vencimiento:</span> {items?.end_contract}
                          </p>
                        </div>
                        <div className="w-full py-3 flex justify-center items-center border-t border-[#E0E0E0] mt-4">
                          <button
                            onClick={() => viewDetail(items?.id)}
                            className="text-blackGrayScale font-medium text-sm"
                          >
                            Ver detalle
                          </button>
                        </div>
                      </div>
                    </div>);
                    })
                  ) : (<div className="w-full flex justify-center items-center h-screen bg-empty-state">
                  Parece ser que no tienes alquileres
                </div>)
                }
                    
              </div>


            
          </div>
        </div>
      </div>
    </>
  );
};
export default TenantPayments;
