import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuthToken, unsetAuth } from "../../helpers/authHelper";
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import { useNavigate, useParams } from "react-router-dom";
import { AppStore } from "../../redux/store";
import { logoutAction } from "../../redux/features/auth";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";
import { getInvestorBookingDetailEndpoint } from "./services/investorService";
import { Carousel } from "react-responsive-carousel";
import Tooltip from "@mui/material/Tooltip";
import country from "../../redux/features/country";

interface Detail {
  aditionalPayments: any;
  modelInfo: any;
  paymentsPlan: any;
  projectInfo: any;
}
const InvestorBookingDetail = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  const { id = "" } = useParams();
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const [data, setData] = useState<Detail>();

  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const loadPayments = async () => {
      // TODO add new fields from backend and new table
      const { status, data } = await callEndpoint(
        getInvestorBookingDetailEndpoint(id, token)
      );
      if (status === 401) {
        dispatch(logoutAction({}));
        unsetAuth();
        return navigate(`/app/${country}/auth/login`);
      }
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setData(response.data);
      }
    };
    loadPayments().catch((e) => console.error(e));
  }, []);

  const handleDetail = () => {
      window.open(
        `/app/${country}l/ld/pre-booking`,
        "_self"
      );
    
  };

  return (
    <>
      <div className="md:pt-28 pt-16 pb-10">
        <div className="container mx-auto px-4 md:px-16">
        <div className=" w-full flex justify-between items-start py-3">
            <div>
                <h2 className=" text-blueForm text-3xl font-semibold">
                {data?.modelInfo?.name}{" "}
                </h2>
                <p className=" text-graySoft text-base font-normal mt-2">
                    {data?.projectInfo?.name}
                </p>
            </div>
            
            <p className=" text-blueForm font-medium text-3xl">
              {" "}
              {data?.modelInfo?.base_price}{" "}
            </p>
          </div>
          <div className="w-full mt-10 mb-10 md:px-12">
            <Carousel
              showArrows
              showIndicators={false}
              className="h-full"
              showThumbs={false}
              showStatus={false}
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${
                      hasPrev ? "absolute" : "hidden"
                    } top-0 bottom-0 left-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <img
                      src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/right_arrow.svg"
                      alt=""
                    />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasNext: any) => {
                return (
                  <div
                    className={`${
                      hasNext ? "absolute" : "hidden"
                    } top-0 bottom-0 right-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <img
                      src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/left_arrow.svg"
                      alt=""
                    />
                  </div>
                );
              }}
            >
              {data?.modelInfo?.media?.gallery?.length > 0 &&
                data?.modelInfo?.media?.gallery?.map(
                  (photo: any, index: number) => (
                    <div key={index}>
                      <img
                        className="object-cover md:h-[440px] object-center"
                        src={photo}
                        alt="gallery-units"
                      />
                    </div>
                  )
                )}
            </Carousel>
          </div>
          {/* <div className="mt-3 w-full flex justify-between items-center py-3">
            <h2 className=" text-blueForm text-xl font-semibold">
              {data?.modelInfo?.name}{" "}
            </h2>
            <p className=" text-blueForm text-medium text-xl">
              {" "}
              {data?.modelInfo?.base_price}{" "}
            </p>
          </div> */}
          <div className="py-3 pb-5">
          <h1 className=" mb-2 text-blueDark font-bold text-xl">
              Amenidades
            </h1>
            <ul className="flex flex-wrap">
              {data?.modelInfo?.amenities?.length > 0 &&
                data?.modelInfo?.amenities?.map(
                  (amenity: any, index: number) => (
                    <li key={index} className="w-full md:w-1/3">
                      <p className="text-base text-graySoft">• {amenity}</p>
                    </li>
                  )
                )}
            </ul>
          </div>
          <div className="py-3 pb-5">
            <h1 className=" mb-2 text-blueDark font-bold text-xl">
              Plan de pagos
            </h1>
            <p className="text-base font-normal text-graySoft mb-4">
              Precios exclusivos con Propi
            </p>

            <div className="w-full">
              {data?.paymentsPlan?.length > 0 &&
                data?.paymentsPlan?.map((pay: any, index: number) => (
                  <p className="flex pb-6 justify-between items-baseline text-sm">
                    <span className="pr-1 text-darkSoft">{pay?.text}</span>
                    <div className="self-start ml-2">
                      {pay?.description !== null && (
                        <>
                          <Tooltip
                            className="cursor-pointer"
                            title={pay?.description}
                            placement="top"
                            enterTouchDelay={0}
                            arrow
                          >
                            <img
                              className="w-5 h-5 "
                              src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/invest/info.svg"
                              alt=""
                            />
                          </Tooltip>
                        </>
                      )}
                    </div>
                    <span className="flex-1 border-b border-gray-300 border-dashed mx-1" />
                    <span className="font-medium text-blueForm">
                      {pay?.value}
                    </span>
                  </p>
                ))}
            </div>
          </div>
          <div className="py-3">
            <h1 className=" mb-2 text-blueDark font-bold text-xl">
              Costos adicionales que debes tener en cuenta
            </h1>
            <p className="text-base font-normal text-graySoft mb-4">
              Según regulación de la ley Salvadoreña
            </p>
            <div className="w-full">
              {data?.aditionalPayments?.length > 0 &&
                data?.aditionalPayments?.map((pay: any, index: number) => (
                  <p className="flex pb-6 justify-between items-baseline text-sm">
                    <span className="pr-1 text-darkSoft">{pay?.text}</span>
                    <div className="self-start ml-2">
                      {pay?.description !== null && (
                        <>
                          <Tooltip
                            className="cursor-pointer"
                            title={pay?.description}
                            placement="top"
                            enterTouchDelay={0}
                            arrow
                          >
                            <img
                              className="w-5 h-5 "
                              src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/invest/info.svg"
                              alt=""
                            />
                          </Tooltip>
                        </>
                      )}
                    </div>
                    <span className="flex-1 border-b border-gray-300 border-dashed mx-1" />
                    <span className="font-medium text-blueForm">
                      {pay?.value}
                    </span>
                  </p>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InvestorBookingDetail;
