import { useState } from "react";
import { LandlordOffer, LandlordOfferProperty } from "../models/landlordModel";
import userInfo from "../../../assets/icons/userInfo.svg";

import { IconCheck, IconCurrencyDollar, IconHomeDollar, IconInfoCircle, IconX } from "@tabler/icons-react"

import BidderCard from "./BidderCard";
import "./scss/CardOffer.scss";
import { DEFAULT_AVATAR } from "../../../constants/ProfileConstants";
import ColorfulNameComponent from "./ColorfulName/ColorfulName";

interface DataPropertyOffer {
  data: {
    offer_info?: LandlordOffer;
    property_info?: LandlordOfferProperty;
  };
  action: (data: boolean, info?: any) => void;
}

const CardOffer = ({ data, action }: DataPropertyOffer) => {
  const [showBidder, setShowBidder] = useState<boolean>(false);

  const toggleBidder = () => {
    try {
      setShowBidder((old) => !old);
      document.body.style.overflowY = "scroll";
    } catch (error) {
      console.log("something went wrong with bidder info");
    }
  };

  const showModal = (type: string) => {
    action(true, {
      property_id: data.property_info?.property_id,
      offer_id: data.offer_info?.id,
      type: type,
      property_info: data?.property_info || {},
      offer_info: data?.offer_info || {},
    });
  };

  return (
    <div
      className={`card-offer w-full rounded-lg py-0 ${data.offer_info?.status === 'on_wait' ? 'shadow-green700 shadow-md' : ''}  relative ease-in-out duration-300 px-3`}
      data-variation={data.offer_info?.status === "signature" ? 'sign' : ''}
    >
      <div className="w-full flex items-center justify-between  flex-wrap h-full">
        <div className="w-full py-4 flex flex-wrap items-start justify-start">
          <div className=" relative mr-4">
            {
              data.offer_info && data.offer_info?.tenant_avatar ? <img
              src={
                data.offer_info && data.offer_info?.tenant_avatar
                  ? data.offer_info?.tenant_avatar
                  : DEFAULT_AVATAR
              }
              className="w-16 h-16 rounded-lg"
              alt=""
            /> : <ColorfulNameComponent
            firstName={data.offer_info?.tenant_name as string}
            lastName={data.offer_info?.tenant_lastname as string}
          />
            }
            
            
          </div>
          <div className=" ">
            <div className="w-full flex items-center relative user">
              <h1 className="text-[#212121] font-semibold text-lg">{data.offer_info?.tenant_name} {data.offer_info?.tenant_lastname}</h1>
              <button onClick={toggleBidder} className="mx-2">
                <IconInfoCircle color="#212121" width={25} />
              </button>
            </div>
            {
              data?.offer_info?.marketplace_type === 'on_rent' ? ( <span className="px-4 text-sm font-semibold bg-[#D5F0E3] text-[#26925B] rounded-full">Alquiler</span> ) : (<span className="px-4 text-sm font-semibold bg-[#FEE3D5] text-[#FA712D] rounded-full">Venta</span>)
            }
            {showBidder && (
              <BidderCard
                onAction={toggleBidder}
                tenantId={data.offer_info?.tenant_id || ""}
              />
            )}
            <div className="w-full text-sm py-2 font-medium">
              <p className="text-[#757575] text-sm">Oferta</p>
              <p className="text-[#212121] text-lg"><span className="font-bold text-primary">{data.offer_info?.offer}</span> </p> 
            </div>
            <div className="w-full text-graySoft text-sm my-2 text">
              <p className="text-[#757575]">A partir de: <span className="font-bold text-[#212121]">{data.offer_info?.estimate_moving_at}</span></p> 
            </div>
            {
              data?.offer_info?.marketplace_type === 'on_rent' && <div className="w-full text-graySoft text-sm mb-2 text">
              <p className="text-[#757575]">Por un período de: <span className="font-bold text-[#212121]">{data.offer_info?.period}</span></p> 
            </div>
            }
            
          </div>
          <hr className={`bg-gray-200 w-full ${data?.offer_info?.marketplace_type === 'on_rent' ? '' : 'mt-7'}`} />
          <div className="w-full flex items-center justify-end pt-3 h-full">
            <div className=" w-full ">
              <>
                {data.offer_info?.status &&
                  (() => {
                    switch (data.offer_info?.status) {
                      case "finished":
                          return ( <div>
                            <p className="font-semibold text-primary text-center">¡Tu propiedad ha sido alquilada!</p>
                          </div> );
                      case "on_wait":
                        return (
                          <>

                            <div className="w-full grid grid-cols-3 gap-2 ">
                            <button
                                onClick={() => showModal("negociate")}
                                className="py-2 text-xs text-center ease-in-out duration-300 cursor-pointer hover:bg-white hover:text-[#212121] bg-[#212121] text-white border border-[#212121] rounded-lg font-medium inline-block"
                              >
                                Negociar <IconHomeDollar width={20} className="inline-block" />
                              </button>
                              <button
                                onClick={() => showModal("accept")}
                                className="py-2 text-xs text-center ease-in-out duration-300 cursor-pointer hover:bg-[#212121] hover:text-white bg-green500 text-white rounded-lg font-medium inline-block"
                              >
                                Aceptar <IconCheck width={20} className=" inline-block" />
                              </button>
                              <button
                                onClick={() => showModal("reject")}
                                className="py-2 text-xs text-center ease-in-out duration-300 cursor-pointer bg-[#F44336] text-white rounded-lg hover:opacity-90 font-medium inline-block"
                              >
                                Rechazar <IconX width={20} className=" inline-block" />
                              </button>
                            </div>
                            <div className=" w-full items-center hidden">
                              <div className="rounded-full w-full bg-greenLight py-2  text-center font-medium text-green500">
                                Aceptada
                              </div>
                            </div>
                          </>
                        );
                      case "signature":
                        return (
                          <>
                            <div className="w-full flex justify-between items-center gap-2">
                              <div
                                className="py-2 px-4 text-sm text-center cursor-pointer bg-purple text-white rounded-full"
                              >
                                Aceptada
                              </div>
                              <div
                                onClick={() => showModal("signature")}
                                className="w-full py-2 px-4 text-sm text-center ease-in-out duration-300 cursor-pointer hover:bg-white hover:text-green500 bg-green500 text-white border border-green500 rounded-full"
                              >
                                Firmar
                              </div>
                            </div>
                          </>
                        );
                      case "rejected":
                        return (
                          <div className="w-full flex justify-between items-center">
                            <div className="w-full ml-1 py-2 px-4 text-sm mx-1 text-center  rounded-full border  bg-redFormValidation text-orange">
                              Rechazada
                            </div>
                          </div>
                        );
                      case "negotiating":
                        return (
                          <div className="w-full flex justify-between items-center">
                            <div className="w-full ml-1 py-2 px-4 text-sm mx-1 text-center rounded-full border bg-redFormValidation text-orange font-medium flex justify-center items-center">
                              Negociando <IconCurrencyDollar color="#FA712D" />
                            </div>
                          </div>
                        );
                      case "accepted":
                        return (
                          <div className="w-full flex justify-between items-center">
                            <div className="w-full ml-1 py-2 px-4 text-sm mx-1 text-center rounded-full border bg-greenLight text-primary">
                              Aceptada
                            </div>
                          </div>
                        );
                      default:
                        return null;
                    }
                  })()}
              </>
              {/*  */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardOffer;
