import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React, { memo, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import FormControlError from "../../../../components/Controls/FormControlError";
import logo from "../../../../assets/images/iso_logo.svg";
import { Link, useParams } from "react-router-dom";
import arrow_button from "../../../../assets/icons/arrow_button.svg";
import MaskInput from "../../../../components/Controls/MaskInput";
import { getOfferPeriods } from "../../../../helpers/OfferHelper";
import DateInput from "../../../../components/Controls/DateInput";
import SelectInput from "../../../../components/Controls/SelectInput";
import {
  createOfferEndpoint,
  createOtpEndpoint,
} from "../../services/offerServices";
import useCallApiAndLoad from "../../../../hooks/useCallApiAndLoad";
import { useToast } from "../../../../components/ToastAlerts";
import {
  getStorageOffer,
  setStorageOffer,
} from "../../../../helpers/guestHelper";
import "./StepOneOffer.scss";
import flag from "../../../../assets/icons/sv-flag.png";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { NumericFormat } from "react-number-format";
import { DocumentData, useDocuments } from "../../hooks/useDocuments";
import { useSelector } from "react-redux";
import { AppStore } from "../../../../redux/store";
import { MenuItem, Select } from "@mui/material";
interface OfferForm {
  first_name: string;
  last_name: string;
  email: string;
  area_code: string;
  phone: string;
  property_id: string;
  document_type: string;
  document_number: string;
  estimate_moving_at: string;
  period: number;
  price: number;
}
interface GuestForm extends Record<string, string> {
  guest_id: string;
}
const StepOneOffer = memo(({ helpers }: any) => {
  const {country} = useSelector((state: AppStore) => state.country);
  const toast: any = useToast();
  const [dialCode, setDialCode] = useState("");
  const [documentMask, setDocumentMask] = useState<string>("");

  const [validRequirements, setValidRequirements] = useState(false);
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const { getDataDocuments, loading, documents } = useDocuments();
  const { goToNextStep } = helpers;
  const { propertyId = "" } = useParams();
  let userPhone = "";
  const offerStorage = getStorageOffer();
  const offerGuestData: any = offerStorage && JSON.parse(offerStorage);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<OfferForm>({
    resolver: yupResolver(
      yup
        .object({
          first_name: yup.string().required("Nombre es requerido"),
          last_name: yup.string().required("Apellido es requerido"),
          email: yup.string().required("Correo es requerida"),
          phone: yup.string().required("Telefono es requerida"),
          area_code: yup.string(),
          price: yup
            .number()
            .min(1, "Precio de oferta inválido")
            .typeError("Precio de oferta es requerido")
            .required("Realiza una mejor oferta para tener más oportunidad."),
          ...(!validRequirements
            ? {
                document_number: yup
                  .string()
                  .required("El no. de documento es requerido"),
              }
            : {}),
          property_id: yup.string(),
          document_type: yup.string().required('Selecciona un tipo de documento'),
          estimate_moving_at: yup.string().required("Fecha es requerida"),
          period: yup.number().required("Periodo es requerido"),
        })

        .required()
    ),
  });
  useEffect(() => {
    if (offerStorage) {
      setValue("first_name", offerGuestData?.first_name);
      setValue("last_name", offerGuestData?.last_name);
      setValue("email", offerGuestData?.email);
      setValue("phone", offerGuestData?.phone);
      setValue("document_number", offerGuestData?.document_number);
      setValue("document_type", offerGuestData?.document_type);
      setValue("estimate_moving_at", offerGuestData?.estimate_moving_at);
      setValue("period", offerGuestData?.period);
      setValue("price", offerGuestData?.price);
    }
  }, [offerStorage]);

  const createOtp = async (guestId: GuestForm) => {
    const { status, data } = await callEndpoint(createOtpEndpoint(guestId));
    if (status === 201) {
      goToNextStep();
    } else {
      toast.open(data.message, "error");
    }
  };

  const createOffer = async (form: OfferForm) => {
    const { status, data } = await callEndpoint(createOfferEndpoint(form));

    if (status === 201) {
      userPhone = form.phone;
      const getGuestId: GuestForm = {
        guest_id: data.data.guest_id,
      };
      setStorageOffer({
        ...form,
        guest_id: data.data.guest_id,
      });
      await createOtp(getGuestId);
    } else {
      toast.open(data.message, "error");
    }
  };
  const sendData = async (form: OfferForm) => {
    const dataForm: OfferForm = {
      ...form,
      area_code: dialCode,
    };
    setStorageOffer(dataForm);
    await createOffer(dataForm);
  };

  useEffect(() => {
    getDataDocuments(country.toUpperCase());
  }, [country]);

  const handleDocumentTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedDocument = documents.find((doc: any) => doc.code === event.target.value);
    if (selectedDocument) {
      setDocumentMask(selectedDocument.input_mask);
    }
  };


  return (
    <div className="w-full stepOneOffer">
      <section className=" pb-0 ">
        <div className="text-center mb-6">
          <img
            className="inline-block cursor-pointer"
            src={logo}
            alt="Logo PropiLatam"
          />
        </div>
        <h1 className=" text-blueDark font-bold text-2xl lg:text-3xl text-center mb-2">
          ¡Queremos conocerte!
        </h1>
        <p className="text-center text-graySoft text-sm lg:text-base font-normal">
          Completa los siguientes campos:
        </p>
      </section>

      <form
        id="realiza-oferta-inquilino"
        className=" mx-auto mt-6 max-w-2xl lg:px-4 xl:px-4"
        onSubmit={handleSubmit(sendData)}
      >
        <h4 className="text-graySoft font-semibold text-xl lg:text-2xl  ">
          Haz una oferta
        </h4>
        <input type="hidden" value={propertyId} {...register("property_id")} />
        <input type="hidden" value="+503" {...register("area_code")} />
        <div className="w-full formOne flex flex-wrap overflow-y-auto">
          <div className="w-full px-0 md:px-2 xl:px-2">
            <div className="w-full text-sm mt-3 text-graySoft font-medium">
              Valor a ofertar
            </div>
            <input
              type="number"
              className="bg-white block py-3 px-2 w-full text-sm  rounded-md text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              {...register("price")}
            />
            {errors?.price?.message && (
              <p className=" text-redValidation text-sm font-normal mt-1">
                {errors?.price?.message}
              </p>
            )}
          </div>
          <div className="lg:w-1/2 w-full px-0 md:px-2 xl:px-2">
            <div className="w-full text-sm mt-3 text-graySoft font-medium">
              Nombre
            </div>
            <input
              type="text"
              className="bg-white block py-3 px-2 w-full text-sm rounded-md text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=""
              {...register("first_name")}
            />
            {errors?.first_name?.message && (
              <p className=" text-redValidation text-sm font-normal mt-1">
                {errors?.first_name?.message}
              </p>
            )}
          </div>
          <div className="lg:w-1/2 w-full px-0 md:px-2 xl:px-2">
            <div className="w-full text-sm text-graySoft font-medium mt-3">
              Apellido
            </div>

            <input
              type="text"
              className="bg-white block py-3 px-2 w-full text-sm rounded-md text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=""
              {...register("last_name")}
            />
            {errors?.last_name?.message && (
              <p className=" text-redValidation text-sm font-normal mt-1">
                {errors?.last_name?.message}
              </p>
            )}
          </div>
          <div className="lg:w-1/2 w-full px-0 md:px-2 xl:px-2">
            <div className="w-full text-sm mt-3 text-graySoft font-medium">
              Tipo de documento
            </div>
            {!loading && (
              <Controller
                name="document_type"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    onChange={(event: any) => {
                      onChange(event)
                      handleDocumentTypeChange(event);
                    }}
                    sx={{
                      width: "100%",
                      height: "50px",
                      borderColor: "#CFD4D9",
                      background: "white",
                    }}
                  >
                    {
                      documents.length > 0 && documents?.map((item: DocumentData, index: number) => (
                        <MenuItem value={item.code}>{item.document}</MenuItem>
                      ))
                    }
                  </Select>
                )}
              />
            )}

            <FormControlError
              message={errors?.document_type?.message}
              additionalClass="mb-3"
            />
          </div>
          <div className="lg:w-1/2 w-full px-0 md:px-2 xl:px-2">
            <div className="w-full text-sm text-graySoft font-medium mt-3">
              Número de documento
            </div>
            <Controller
              control={control}
              name="document_number"
              render={({ field: { ref } }) => (
                <MaskInput
                  ref={ref}
                  register={register}
                  name={"document_number"}
                  mask={documentMask}
                />
              )}
            />

            {errors?.document_number?.message && (
              <p className=" text-redValidation text-sm font-normal mt-1">
                {errors?.document_number?.message}
              </p>
            )}
          </div>
          <div className="lg:w-1/2 w-full px-0 md:px-2 xl:px-2">
            <div className="w-full text-sm text-graySoft font-medium mt-3">
              Correo Electrónico
            </div>

            <input
              type="email"
              className="bg-white block py-3 px-2 w-full text-sm rounded-md text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=""
              {...register("email")}
            />
            {errors?.email?.message && (
              <p className=" text-redValidation text-sm font-normal mt-1">
                {errors?.email?.message}
              </p>
            )}
          </div>
          <div className="lg:w-1/2 w-full px-0 md:px-2 xl:px-2">
            <div className="w-full text-sm text-graySoft font-medium mt-3">
              Número de teléfono
            </div>
            <div className="w-full">
              <PhoneInput
                onChange={(value, data: CountryData, event: any) => {
                  let phone = event?.target?.value;
                  if (phone) {
                    let cleanPhone = phone?.replace(/[\s()-]/g, "");
                    switch (data?.dialCode) {
                      case "503":
                        cleanPhone = cleanPhone.slice(-8);
                        break;
                      case "504":
                        cleanPhone = cleanPhone.slice(-8);
                        break;
                      case "52":
                        cleanPhone = cleanPhone.slice(-10);
                        break;
                      case "1":
                        cleanPhone = cleanPhone.slice(-10);
                        break;
                      case "507":
                        cleanPhone = cleanPhone.slice(-7);
                        break;
                      case "506":
                        cleanPhone = cleanPhone.slice(-8);
                        break;
                      case "502":
                        cleanPhone = cleanPhone.slice(-7);
                        break;
                      default:
                        return cleanPhone;
                    }
                    setValue("phone", cleanPhone);
                  }

                  setDialCode(`+${data?.dialCode}`);
                }}
                countryCodeEditable={false}
                enableSearch
                country={"sv"}
                onlyCountries={["sv", "gt", "cr", "pa", "us", "hn", "ca", "mx"]}
              />
            </div>

            {errors?.phone?.message && (
              <p className=" text-redValidation text-sm font-normal mt-1">
                {errors?.phone?.message}
              </p>
            )}
          </div>
          <div className="lg:w-1/2 w-full px-0 md:px-2 xl:px-2">
            <div className="w-full text-sm text-graySoft font-medium mt-3">
              ¿Cuándo deseas mudarte a la propiedad?
            </div>
            <DateInput
              register={register}
              name={"estimate_moving_at"}
              disablePastDates
            />
            {errors?.estimate_moving_at?.message && (
              <p className=" text-redValidation text-sm font-normal mt-1">
                {errors?.estimate_moving_at?.message}
              </p>
            )}
          </div>
          <div className="lg:w-1/2 w-full px-0 md:px-2 xl:px-2">
            <div className="w-full text-sm text-graySoft font-medium mt-3">
              ¿Cuánto tiempo deseas alquilar este inmueble?
            </div>
            <SelectInput
              list={getOfferPeriods()}
              register={register}
              name={"period"}
            />
            {errors?.period?.message && (
              <p className=" text-redValidation text-sm font-normal mt-1">
                {errors?.period?.message}
              </p>
            )}
          </div>
        </div>
        <div className="flex justify-end w-full my-4">
          <button
            type="submit"
            className="bg-green500 w-60 h-[62px] relative py-2 px-2 text-white text-base rounded-full group flex items-center justify-center"
          >
            <span className="mr-10 font-normal">Realizar Oferta</span>
            <img
              className="inline absolute right-[15px] float-right group-hover:-translate-x-3 duration-300 ease-in-out"
              src={arrow_button}
              alt=""
            />
          </button>
        </div>
      </form>
      {/* <div className="w-full max-h-[490px] overflow-y-auto">
      </div> */}
    </div>
  );
});

StepOneOffer.displayName = "StepOneOffer";
export default StepOneOffer;
