export interface UpdateLandlordProfileForm {
    name: string;
    last_name: string;
    country: string;
    state: string;
    phone: string;
}

export interface UpdateLandlordTypeForm {
    category_code: string;
    document: string;
    tax_document?: string;
    company_name?: string;

}

export interface UpdateBankAccountForm {
    bank_id: string;
    bank_headline: string;
    bank_account_type: string;
    bank_account: string;
    company_name?: string;
}

export enum LandlordTypes {
    Person = 'PERSON',
    Company = 'COMPANY'
}

export interface rejectOfferForm {
    reason_code: string;
    comment_reject?: string;
}
export interface negociateOfferForm {
    price_to_negociate: string;
    comment_negociate?: string;
    estimate_period: string;
    estimate_available_at: string;
}

export interface NegociateOfferFormApi {
    price_to_negociate: number;
    comment_negociate?: string;
    estimate_period: number;
    estimate_available_at: string;
}

export interface LandlordOffersList {
    propertyInfo: LandlordOfferProperty,
    offers: LandlordOffer[]
}

export interface LandlordOffer {
    id: string;
    tenant_id: string;
    tenant_avatar: string;
    tenant_name: string;
    offer: string;
    status: string;
    estimate_moving_at: string;
    period: string;
    tenant_lastname: string;
    marketplace_type: string;
}

export interface LandlordOfferProperty {
    property_id: string;
    address: string;
    cover: string;
    currency: string;
    rent_price: string;
    code: number;
    sale_price: string;
    property_name: string;
}

export interface LandlordPostulantInfo {
    first_name: string;
    avatar: string;
    state: string;
    age: string;
    profession: string;
    pet_size: string;
    gender: string;
    hobbies: string;
    social_media: string;
    score: number;
    summary: string;
    company_name: string;
    net_income: number;

}