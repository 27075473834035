import "../scss/PropertyGallery.scss";
import close from "../../../assets/icons/gallery-slider/close.svg";
import next from "../../../assets/icons/gallery-slider/next.svg";
import prev from "../../../assets/icons/gallery-slider/prev.svg";
import videoIcon from "../../../assets/images/videoicon.svg";
import galleryIcon from "../../../assets/images/galleryicon.svg";
import ModalVideo from "../components/ModalVideo";
import ModalVideoPro from "../components/ModalVideo360";

import { useState } from "react";
interface Props {
  cover?: string;
  gallery?: string[];
  video?: string;
  video360?: string;
}

const PropertyGallery = ({
  cover = "",
  gallery = [],
  video = "",
  video360 = "",
}: Props) => {

  const newGallery = [cover, ...gallery];
  const [slideNumber, setSliderNumber] = useState(0);
  const [showModalGallery, setShowModalGallery] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showVideo360, setShowVideo360] = useState(false);

  const handleOpenGallery = (index: number) => {
    setSliderNumber(index);
    setShowModalGallery(true);
    document.body.style.overflowY = "hidden";
  };

  const toggleVideo = () => {
    try {
      setShowVideo((old) => !old);
      if (showVideo) {
        document.body.style.overflowY = "scroll";
      } else {
        document.body.style.overflowY = "hidden";
      }
    } catch (error) {
      console.log("error");
    }
  };

  const toggleVideo360 = () => {
    try {
      setShowVideo360((old) => !old);
      if (showVideo360) {
        document.body.style.overflowY = "scroll";
      } else {
        document.body.style.overflowY = "hidden";
      }
    } catch (error) {
      console.log("error");
    }
  };

  const handleCloseGallery = () => {
    setShowModalGallery(false);
    document.body.style.overflowY = "scroll";
  };
  const prevSlide = () => {
    slideNumber === 0
      ? setSliderNumber(newGallery.length - 1)
      : setSliderNumber(slideNumber - 1);
  };
  const nextSlide = () => {
    slideNumber + 1 === newGallery.length
      ? setSliderNumber(0)
      : setSliderNumber(slideNumber + 1);
  };

  return (
    <>
      {showModalGallery && (
        <div className="sliderWrapImage">
          <button className="close" onClick={handleCloseGallery}>
            <img src={close} alt="" />
          </button>
          <button className="previous" onClick={prevSlide}>
            <img src={prev} alt="" />
          </button>
          <button className="next" onClick={nextSlide}>
            <img src={next} alt="" />
          </button>
          <div className="sliderWrapImage flex-col">
            <img src={newGallery[slideNumber]} alt="" />
            <p className="text-white text-base font-semibold mt-5">
              {slideNumber} / {newGallery?.length}
            </p>
          </div>
        </div>
      )}
      <div className="property-gallery-app flex w-full flex-wrap">
        {newGallery && (
          <div className="w-full lg:w-1/3 p-2 main bg-cover bg-center rounded-md relative">
            {video360 && video360?.length > 0 ? (
              <iframe
                className="rounded-xl"
                width={"100%"}
                height={"100%"}
                src={video360}
                title="YouTube video player"
                allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <img
                src={newGallery[0]}
                className=" object-cover object-center w-full h-36"
                alt=""
                onClick={() => handleOpenGallery(0)}
              />
            )}
          </div>
        )}
        
          {newGallery &&
            newGallery.slice(1, 2).map((image: any, index: number) => {
              if (index === 0) return null;
              return (
                <>
                  <div className="w-1/3 p-2 ">
                    <img
                      className="rounded-sm h-full object-cover object-center md:h-36 w-full"
                      src={image}
                      alt=""
                      onClick={() => handleOpenGallery(0)}
                    />
                  </div>
                </>
              );
            })}
          {newGallery && (
            <div className="w-1/3 p-2 relative flex justify-end items-end">
              {video && video?.length > 0 ? (
                <button
                  onClick={() => toggleVideo()}
                  className="mb-3 mr-3 absolute z-10 flex justify-center mx-1 bg-white items-center py-2 px-2 text-primary text-xs shadow-2xl rounded-full"
                >
                  <span className="mr-2">
                    <img src={videoIcon} alt="" />
                  </span>
                  Video
                </button>
              ) : (
                <></>
              )}
              <img
                className="rounded-sm h-full object-cover object-center md:h-36 w-full"
                src={newGallery[4]}
                alt=""
              />
            </div>
          )}
          {newGallery && (
            <div className="w-1/3 p-2">
              <img
                className="rounded-sm h-full object-cover object-center md:h-36 w-full"
                src={newGallery[5]}
                alt=""
                onClick={() => handleOpenGallery(0)}
              />
            </div>
          )}
          {newGallery && (
            <div className="w-1/3 p-2 relative flex justify-end items-end">
              <button
                onClick={() => handleOpenGallery(0)}
                className=" mb-2 absolute z-10 flex justify-center mx-1 bg-white items-center py-1 px-2 text-primary text-xs shadow-2xl rounded-full font-medium"
              >
                <span className="mr-2">
                  <img src={galleryIcon} alt="" />
                </span>
                Ver más fotos
              </button>
              <img
                className="rounded-sm h-full object-cover object-center md:h-36 w-full"
                src={newGallery[6]}
                alt=""
              />
            </div>
          )}
        
      </div>
      

      {showVideo ? (
        <ModalVideo video={video} onAction={toggleVideo}></ModalVideo>
      ) : (
        <></>
      )}
      {showVideo360 ? (
        <ModalVideoPro
          video360={video360}
          onAction={toggleVideo360}
        ></ModalVideoPro>
      ) : (
        <></>
      )}
    </>
  );
};
export default PropertyGallery;
