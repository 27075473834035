import axios from "axios";
import { TenantFavoriteform } from "../models/MarketplaceModel";

const loadAbort = () => {
  return new AbortController();
};

export const checkPropertyFavoriteEndpoint = (
  token: string,
  propertyId: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/rs/v1/tenant/favorites/properties/${propertyId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const tenantFavoriteEndpoint = (
  form: TenantFavoriteform,
  token: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/rs/v1/tenant/favorites`,
        data: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getDetailEndpoint = (id: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/rs/v1/marketplace/properties/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const getPropertiesSimilars = (id: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/rs/v1/marketplace/properties/${id}/similars`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};
