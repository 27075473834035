import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
//hooks
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
// adapters and helpers
import { getAuthToken, unsetAuth } from "../../helpers/authHelper";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";
// services and store
import { logoutAction } from "../../redux/features/auth";
import { AppStore } from "../../redux/store";
import {
  checkTypeEndpoint,
  updateTypeEndpoint,
} from "./services/landlordService";
// form
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { UpdateLandlordTypeForm, LandlordTypes } from "./models/landlordModel";
import Button from "../../components/Button";

//icon
import backArrow from "../../assets/icons/goback.svg";
import arrow_button from "../../assets/icons/arrow_button.svg";
import FormControlError from "../../components/Controls/FormControlError";
import MaskInput from "../../components/Controls/MaskInput";
import Input from "../../components/Controls/Input";

const CheckLandlordType = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [typeSelected, setTypeSelected] = useState('');
  const [error, setError] = useState('')
  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm<UpdateLandlordTypeForm>({
    resolver: yupResolver(
      yup
        .object({
          category_code: yup
            .string()
            .oneOf([LandlordTypes.Person, LandlordTypes.Company])
            .default(null)
            .nullable()
            .required("Selección es requerida"),
          document: yup
            .string()
            .required("Documento requerido"),
          ...(typeSelected === LandlordTypes.Company
            ? {
              company_name: yup
                .string()
                .required("Nombre de compañía o empresa es requerido"),
              tax_document: yup
                .string()
                .required("Documento requerido"),
            }
            : {}),
        })
        .required()
    ),
  });

  useEffect(() => {
    const check = async () => {
      const { status, data } = await callEndpoint(checkTypeEndpoint(token));
      if (status === 401) {
        dispatch(logoutAction({}));
        unsetAuth();
        return navigate(`/app/${country}/auth/login`);
      }
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        const { required = false } = response.data;
        if (!required) {
          return navigate(`/app/${country}/l/publish-properties`);
        }
      }
    };
    check().catch((e) => console.error(e));
  }, []);

  const handleChangeType = (e: any) => {
    setTypeSelected(e.target.value);
  };

  const doCheck = async (form: UpdateLandlordTypeForm) => {
    form.document = form.document.replaceAll('-', '')
    if(form.tax_document){
      form.tax_document = form.tax_document.replaceAll('-', '')
    }
    const { status, data } = await callEndpoint(
      updateTypeEndpoint(form, token)
    );
    if (status === 200) {
      return navigate(`/app/${country}/l/publish-properties`);
    }
    const { code, message } = data;
    setError(`Error(${code}): ${message}`);
  };

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <>
      <section className='pt-24 w-full'>
        <div className="">
          <form className="m-auto h-screen" onSubmit={handleSubmit(doCheck)}>
            <div className="w-full text-center my-6 text-third text-lg">
              Información requerida
            </div>
            <div className="w-full my-6 text-third text-4xl font-bold text-center">
              Completar información
            </div>
            <hr />
            <div className="w-full my-6">
              <FormControlError message={error} />
              <FormControlError message={errors.category_code?.message} />
              <div className="w-full text-center text-graySoft pt-2">
                Al ser tu primera publicación necesitamos confirmar tus datos
                antes de continuar
              </div>
              <div className="my-6 text-third text-lg text-center w-full">
                Representante legal:
              </div>
            </div>
            <div className="flex justify-center items-center flex-wrap lg:flex-nowrap">
              <label htmlFor={"1"}>
                <div
                  className={
                    typeSelected === ''
                      ? "border w-full lg:w-auto py-2 px-4 hover:cursor-pointer rounded-full lg:mx-2 text-blueForm border-blueForm"
                      : typeSelected === LandlordTypes.Company
                        ? "border w-full lg:w-auto py-2 px-4 hover:cursor-pointer rounded-full lg:mx-2 text-blueForm border-blueForm"
                        : "border w-full lg:w-auto py-2 px-4 rounded-full lg:mx-2 text-green700 bg-green200 hover:cursor-pointer border-green700 bg-green"
                  }
                >
                  Persona Natural
                </div>
              </label>
              <input
                {...register("category_code", {
                  onChange: (e: Event) => handleChangeType(e),
                })}
                id={"1"}
                type="radio"
                className="hidden"
                value={LandlordTypes.Person}
              />
              <label htmlFor={"2"}>
                <div
                  className={
                    typeSelected === ''
                      ? "border w-full lg:w-auto py-2 px-4 hover:cursor-pointer rounded-full lg:mx-2 text-blueForm border-blueForm" :
                      typeSelected === LandlordTypes.Company
                        ? "border w-full lg:w-auto py-2 px-4 rounded-full lg:mx-2 text-green700 bg-green200 hover:cursor-pointer border-green700 bg-green"
                        : "border w-full lg:w-auto py-2 px-4 hover:cursor-pointer rounded-full lg:mx-2 text-blueForm border-blueForm"
                  }
                >
                  Compañía o empresa
                </div>
              </label>
              <input
                {...register("category_code", {
                  onChange: (e: Event) => handleChangeType(e),
                })}
                id={"2"}
                type="radio"
                className="hidden"
                value={LandlordTypes.Company}
              /> <br />

            </div>

            <div className="w-full mt-2 flex justify-center items-center flex-column lg:flex-nowrap">
              <div className="w-full flex justify-center pb-4">
                <div className="w-1/2 lg:w-1/3 text-graySoft">
                  <div className="text-graySoft my-2">
                    Número de DUI
                  </div>
                  <Controller
                    control={control}
                    name="document"
                    render={({ field: { ref } }) => (
                      <MaskInput
                        ref={ref}
                        register={register}
                        name={"document"}
                        mask={"99999999-9"}
                      />
                    )}
                  />
                  <FormControlError message={errors.document?.message} />
                </div>
              </div>
            </div>

            {typeSelected === LandlordTypes.Company && (
              <>
                <div className="w-full mt-2 flex justify-center items-center flex-column lg:flex-nowrap">
                  <div className="w-full flex justify-center pb-4">
                    <div className="w-1/2 lg:w-1/3 text-graySoft">
                      <div className="text-graySoft">
                        Nombre de la compañía
                      </div>
                      <Input name="company_name" register={register} />
                      <FormControlError message={errors.company_name?.message} />
                    </div>
                  </div>
                </div>
                <div className="w-full mt-2 flex justify-center items-center flex-column lg:flex-nowrap">
                  <div className="w-full flex justify-center pb-4">
                    <div className="w-1/2 lg:w-1/3 text-graySoft">
                      <div className="text-graySoft">
                        Número de nit
                      </div>
                      <Controller
                        control={control}
                        name="tax_document"
                        render={({ field: { ref } }) => (
                          <MaskInput
                            ref={ref}
                            register={register}
                            name={"tax_document"}
                            mask={"9999-999999-999-9"}
                          />
                        )}
                      />
                      <FormControlError message={errors.tax_document?.message} />
                    </div>
                  </div>
                </div>
              </>
            )}
            <hr />
            <div className="w-full flex justify-between mt-16 px-4 ">
              <Link to="/" className="flex items-center">
                <i className="mr-2">
                  <img src={backArrow} alt="" />
                </i>
                <span>Regresar</span>
              </Link>
              <Button
                disabled={isLoading}
                className="bg-green500 py-2 px-2 text-white text-base rounded-full"
              >
                <span className="mr-2 ml-6">Crear Cuenta</span>
                <img className="inline" src={arrow_button} alt="" />
              </Button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default CheckLandlordType;
