import {Link, NavLink, useNavigate} from "react-router-dom";
import moreIcon from "../../assets/icons/r-arrow.svg";

interface Props {
  label: string;
  value: string;
  hasBubble?: boolean;
  link?: string;
  typelink: string;
}

const DetailSectionMenu = (props: Props) => {
  const navigate = useNavigate()
  const { label, value, hasBubble = false, link, typelink } = props;
  const goToTheLink = (currentLink: string, type: string) => {
    if (type === 'external') {
    window.open(currentLink, '_blank')
    } else {
      document.documentElement.scrollTop = 0;
      navigate(currentLink)
    }
    
  } 
  const linkType = (currentLink: string) => {
    return (<button
              onClick={() => goToTheLink(currentLink, typelink)}
              className="pt-1 duration-300 ease-in-out hover:scale-125 focus:outline-none" rel="noreferrer"
          >
            <img
                src={moreIcon}
                className="w-2 mx-2 cursor-pointer"
                alt="filter"
            />
          </button>)
          
      

  }

  return (
    <>
      <div className="px-3 w-full">
        <div className={`w-full py-5 `}>
          <div className="w-full flex flex-end justify-between items-center">
            <div className="text-gray800 text-sm font-normal w-1/2"> {label} </div>
            <div className="text-gray600 ">
              <div className="flex justify-between items-center">
                <span
                  className={
                    hasBubble
                      ? "border h-8 w-8 flex justify-center items-center text-sm font-medium rounded-full lg:mx-2 text-green700 bg-green200 hover:cursor-pointer"
                      : ""
                  }
                >
                  {value}
                </span>
                {(link) && (linkType(link))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="border-gray-200" />
    </>
  );
};

export default DetailSectionMenu;
