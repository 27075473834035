import { useState } from "react";
import { useSelector } from "react-redux";
import { fetchResponseAdapter } from "../../../adapters/fetchAdapter";
import arrow_button from "../../../assets/icons/arrow_button.svg";
import FormControlError from "../../../components/Controls/FormControlError";
import { getAuthToken } from "../../../helpers/authHelper";
import useCallApiAndLoad from "../../../hooks/useCallApiAndLoad";
import { AppStore } from "../../../redux/store";
import { unsuscribePaymentEndpoint } from "../services/tenantService";


interface Props {
  contractId: string;
  name?: string;
  btnText?: string;
  onAction: React.MouseEventHandler<HTMLButtonElement> | undefined;
  extraAction: () => void;
}

const ModalUnSubscribe = (props: Props) => {
  const [formError, setFormError] = useState("");
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const { callEndpoint } = useCallApiAndLoad();

  const handleEvent = async () => {
    setFormError('')
    const { status, data } = await callEndpoint(
      unsuscribePaymentEndpoint(token, props.contractId)
    );
    if (status === 200) {
      props.extraAction()
      return
    }
    const response = fetchResponseAdapter(data)
    setFormError(`(${response.code}) ${response.message}`)
  }

  return (
    <div className="w-full h-[200px] overflow-y-auto bg-white">
      <div className="w-full py-10 px-4">
        <p className=" text-graySoft font-normal text-base text-center">¿Estás seguro de desactivar el pago automático?</p>
        {formError && (
          <FormControlError message={formError} />
        )}
      </div>
      <hr className=" w-full border-tea-500" />
      <div className="bg-white w-full">
        <div className="w-full flex justify-between px-4 py-4 items-center">
          <button onClick={props.onAction}>
            <div className="text-blueForm text-base">Cancelar</div>
          </button>
          <button
            onClick={handleEvent}
            className="bg-primary py-2 pl-2 pr-2 w-auto text-white text-base rounded-full flex justify-end items-center"
          >
            <span className="mr-2 ml-6">{props.btnText}</span>
            <img
              className="inline group-hover:-translate-x-3 duration-300 ease-in-out"
              src={arrow_button}
              alt=""
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalUnSubscribe;
