import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { AppStore } from "../../redux/store"
//hooks - adapters
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad"
import { fetchResponseAdapter } from "../../adapters/fetchAdapter"
import { listToSelectOptionAdapter } from "../../adapters/listsAdapter"
import { getAuthToken } from "../../helpers/authHelper"
// services
import { UpdateLandlordProfileForm } from './models/landlordModel';
import { getCountriesEndpoint, getStatesEndpoint } from '../../services/publicService';
import { updateLandlordProfileEndpoint } from './services/landlordService';
// form
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form';
//assets
import arrow_button from '../../assets/icons/arrow_button.svg';
import arrow_left from '../../assets/icons/arrow_left.svg';
import Button from "../../components/Button"
import FormControlError from "../../components/Controls/FormControlError"
import DetailFormControlSection from "../../components/General/DetailFormControlSection"

const UpdateLandlordProfile = () => {
    const { country } = useSelector((store: AppStore) => store.country);
    const location = useLocation()
    const user = useSelector((store: AppStore) => store.auth)
    const token = getAuthToken(user)
    const [countries, setCountries] = useState<Array<any>>([])
    const [states, setStates] = useState<Array<any>>([])
    const [error, setError] = useState('')
    const { isLoading, callEndpoint } = useCallApiAndLoad()
    const [defaultForm, setDefaultForm] = useState<any>(location.state)
    const navigate = useNavigate()
    const {
        register, handleSubmit, formState: { errors }, reset
    } = useForm<UpdateLandlordProfileForm>({
        resolver: yupResolver(yup.object({
            name: yup.string()
                .required('Nombres son requeridos'),
            last_name: yup.string()
                .required('Apellidos son requeridos'),
            country: yup.string()
                .required('País es requerido'),
            state: yup.string()
                .required('Departamento es requerido'),
            phone: yup.string()
                .required('Teléfono es requerido'),
        }).required())
    })

    useEffect(() => {
        if (defaultForm) {
            reset({
                name: defaultForm.name,
                last_name: defaultForm.last_name,
                country: defaultForm.country_id,
                state: defaultForm.state_id,
                phone: defaultForm.phone,
            })
        }
    }, [])

    useEffect(() => {
        const callCatalogs = async () => {
            const countriesResponse = await callEndpoint(getCountriesEndpoint())
            if (countriesResponse.status !== 200) {
                setError('Ocurrio un error al momento de cargar los catalogos de paises')
                return;
            }
            const countriesData = fetchResponseAdapter(countriesResponse.data)
            setCountries(listToSelectOptionAdapter(countriesData.data, 'country_id', 'country_name'))
            if (defaultForm.country_id) {
                const statesResponse = await callEndpoint(getStatesEndpoint(defaultForm.country_id))
                if (statesResponse.status !== 200) {
                    setError('Ocurrio un error al momento de cargar los catalogos de estados')
                    return;
                }
                const statesData = fetchResponseAdapter(statesResponse.data)
                setStates(listToSelectOptionAdapter(statesData.data, 'state_id', 'state_name'))
            }
        }
        callCatalogs()
            .catch(e => console.error(e))
    }, [])

    const handleChangeCountry = async ({ target }: any) => {
        const { value = '' } = target
        setStates([])
        if (value) {
            const { status, data } = await callEndpoint(getStatesEndpoint(value))
            if (status === 200) {
                const statesResponse = fetchResponseAdapter(data)
                setStates(listToSelectOptionAdapter(statesResponse.data, 'state_id', 'state_name'))
                return
            }
        }
    }

    const doUpdate = async (form: UpdateLandlordProfileForm) => {
        setError('')
        const { status } = await callEndpoint(updateLandlordProfileEndpoint(form, token))
        if (status === 200) {
            return navigate(`/app/${country}/l/ld/profile`)
        }
    }

    return (
        <form onSubmit={handleSubmit(doUpdate)}>
            <section className='pt-24' >
                <div className="w-full text-center my-6 text-third text-lg">
                    Actualiza tus datos personales
                </div>
                <div className="m-auto ">
                    <div className="w-full my-6 text-third text-4xl font-bold text-center">
                        Edita tus datos
                    </div>
                </div>
            </section>
            <hr />
            <section>
                <div className="m-auto w-full">
                    <div className="w-full my-10">
                        <FormControlError message={error} />
                        <DetailFormControlSection label="Nombres" >
                            <>
                                <input
                                    type="text"
                                    placeholder='Ingresa tus nombres'
                                    className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3"
                                    {...register("name")}
                                />
                                <FormControlError message={errors.name?.message} />
                            </>
                        </DetailFormControlSection>
                        <DetailFormControlSection label="Apellidos" >
                            <>
                                <input
                                    type="text"
                                    placeholder='Ingresa tus apellidos'
                                    className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3"
                                    {...register("last_name")}
                                />
                                <FormControlError message={errors.last_name?.message} />
                            </>
                        </DetailFormControlSection>
                        <DetailFormControlSection label="Correo electrónico" >
                            <input
                                defaultValue={defaultForm?.email}
                                readOnly
                                disabled
                                type="text"
                                className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3"
                            />
                        </DetailFormControlSection>
                        <DetailFormControlSection label="País" >
                            <>
                                <select
                                    {...register("country", {
                                        onChange: (e: Event) => handleChangeCountry(e)
                                    })}
                                    className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3">
                                    <option value="">Seleccione un país...</option>
                                    {countries.map(c => {
                                        const isSelected = c.value === defaultForm.country_id
                                        return <option key={`lc-${c.value}`} selected={isSelected} value={c.value}>{c.label}</option>
                                    })}
                                </select>
                                <FormControlError message={errors.country?.message} />
                            </>
                        </DetailFormControlSection>
                        <DetailFormControlSection label="Estado o departamento" >
                            <>
                                <select
                                    {...register("state")}
                                    className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3">
                                    <option value="">Seleccione un estado...</option>
                                    {states.map(c => {
                                        const isSelected = c.value === defaultForm.state_id
                                        return <option key={`ls-${c.value}`}  selected={isSelected} value={c.value}>{c.label}</option>
                                    })}
                                </select>
                                <FormControlError message={errors.state?.message} />
                            </>
                        </DetailFormControlSection>
                        <DetailFormControlSection label="Teléfono" >
                            <>
                                <input
                                    type="text"
                                    placeholder='Ingrese su teléfono'
                                    className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3"
                                    {...register("phone")}
                                />
                                <FormControlError message={errors.phone?.message} />
                            </>
                        </DetailFormControlSection>
                    </div>
                </div>
            </section>
            <hr />
            <section className='my-4'>
                <div className="flex justify-between items-center py-4 px-4">
                    <a onClick={() => navigate(-1)}
                        className='cursor-pointer flex justify-center items-center' >
                        <img className='mr-2' src={arrow_left} alt="" />
                        Regresar
                    </a>
                    <div className="w-56">
                        <Button
                            disabled={isLoading}
                            className="relative w-full bg-primary py-5 px-4 my-4 text-white text-base rounded-full flex justify-center items-center">
                            <span className="mr-2">Continuar</span>
                            <img src={arrow_button} className="vertical-absolute-center right-3" alt="" />
                        </Button>
                    </div>
                </div>
            </section>
        </form>
    )
}

export default UpdateLandlordProfile
