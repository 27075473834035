import styled from "styled-components";

export const ClusterContainer = styled.div`
  align-items: center;
  border-radius: 260px;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  transform: translate(50%, 80%);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  width: 28px;
  height: 28px;
  z-index: 0;

  &:hover {
    background: #0F1125;
    transition-duration: 0.3s;
  }
  
`;
