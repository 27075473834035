import axios from "axios";
import { OnePayPayload } from "../../../models/paymentModel";
import {
  SubscriptionPayPayload,
  UpdateTenantProfileForm,
} from "../models/tenantModel";

const loadAbort = () => {
  return new AbortController();
};

export const getTenantOfferContractEndpoint = (
  offerId: string,
  token: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/tenant/offers/${offerId}/contract`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getTenantOffersEndpoint = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/tenant/offer-visits?page=1&take=15`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getNewTenantOffersEndpoint = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/tenant/offers?page=1&take=15`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getTenantVisitsEndpoint = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/tenant/visits?page=1&take=15`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getTenantProfileEndpoint = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/user/v1/tenant`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updateTenantProfileEndpoint = (
  form: UpdateTenantProfileForm,
  token: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "put",
        url: `${urlService}/user/v1/tenant`,
        data: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getTenantFavorites = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/rs/v1/tenant/favorites`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const deleteTenantFavorite = (favoriteId: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "delete",
        url: `${urlService}/rs/v1/tenant/favorites/${favoriteId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getTenantPaymentsEndpoint = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/contracts`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};


// PAYMENT SALES

export const getSalesPayments = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/tenant/sales`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getSalesPaymentDetailEndpoint = (token: string, id: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/tenant/sales/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getSalesPrecheckoutEndpoint = (
  token: string,
  contractID: string,
  id: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/tenant/sales/${contractID}/transaction-details/${id}/precheckout`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};


export const getSalesCheckoutEndpoint = (
  token: string,
  contractID: string,
  id: string,
  paymentMethod: string = ""
) => {
  const controller = loadAbort();
  const queryParams = paymentMethod ? `?method=${paymentMethod}` : "";
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/tenant/sales/${contractID}/transaction-details/${id}/checkout${queryParams}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};


export const onePaySalesEndpoint = (
  payload: OnePayPayload,
  token: string,
  contractId: string,
  transactionDetailId: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/ts/v1/tenant/sales/${contractId}/transaction-details/${transactionDetailId}/one-pay`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify(payload),
      }),
    controller,
  };
};

export const transferSalePaymentEndpoint = (
  form: any,
  token: string,
  contractId: string,
  tId: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/ts/v1/tenant/sales/${contractId}/transaction-details/${tId}/transfer-pay`,
        data: form,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};



// TENANT

export const getTenantPaymentDetailEndpoint = (token: string, id: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/contracts/${id}/transaction-detail`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};




export const getTenantPrecheckoutEndpoint = (
  token: string,
  contractID: string,
  id: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/contracts/${contractID}/transaction-detail/${id}/precheckout`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
export const getTenantCheckoutEndpoint = (
  token: string,
  contractID: string,
  id: string,
  paymentMethod: string = ""
) => {
  const controller = loadAbort();
  const queryParams = paymentMethod ? `?method=${paymentMethod}` : "";
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/contracts/${contractID}/transaction-detail/${id}/checkout${queryParams}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
export const negociateOfferEndpoint = (
  offerId: string,
  token: string,
  form: any
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "put",
        url: `${urlService}/ts/v1/tenant/offers/${offerId}/negociate`,
        data: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const reOfferLandord = (offer_id: string, token: string, form: any) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "put",
        url: `${urlService}/ts/v1/tenant/offers/${offer_id}/reoffer`,
        data: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const sendOffertLandord = (token: string, form: any) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/ts/v1/tenant/offers`,
        data: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

// New contracts

export const acceptContractEndpoint = (contractId: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "put",
        url: `${urlService}/ts/v1/tenant-contract/${contractId}/accept`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const sendCommentTenant = (
  contractId: string,
  token: string,
  dataComment: any
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/ts/v1/tenant-contract/${contractId}/comment`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify(dataComment),
      }),
    controller,
  };
};

export const onePayEndpoint = (
  payload: OnePayPayload,
  token: string,
  contractId: string,
  transactionDetailId: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/ts/v1/contracts/${contractId}/transaction-detail/${transactionDetailId}/one-pay`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify(payload),
      }),
    controller,
  };
};

export const SubscriptionPayEndpoint = (
  payload: SubscriptionPayPayload,
  token: string,
  contractId: string,
  transactionDetailId: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/ts/v1/contracts/${contractId}/transaction-detail/${transactionDetailId}/subscription-pay`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify(payload),
      }),
    controller,
  };
};

export const unsuscribePaymentEndpoint = (
  token: string,
  contractId: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "put",
        url: `${urlService}/ts/v1/contracts/${contractId}/unsuscribe`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const transferPaymentEndpoint = (
  form: any,
  token: string,
  contractId: string,
  tId: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/ts/v1/contracts/${contractId}/transaction-detail/${tId}/transfer-pay`,
        data: form,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
