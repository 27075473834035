interface Props {
    register: any;
    name: string;
    disablePastDates?: boolean | undefined
}

const DateInput = (props: Props) => {
    const {
        register, 
        name, 
        disablePastDates = false } = props
    let minDate = disablePastDates
        ? new Date().toISOString().split('T')[0]
        : ''
    return (
        <div className="w-full border rounded-md px-2 py-1 flex bg-white items-center">
            <input
                type="date"
                className="border-none focus:ring-0 w-full focus:outline-none focus:border-none text-[#757575]"
                min={minDate}
                {...register(name)}
            />
        </div>
    )
}

export default DateInput
