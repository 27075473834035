import axios, { AxiosPromise } from "axios";

const loadAbort = () => {
  return new AbortController();
};

export const uploadAvatarEndpoint = (
  form: any,
  token: string,
  realm: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "put",
        url: `${urlService}/user/v1/${realm}/avatar`,
        data: form,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const checkAuthEndpoint = (token: string) => {
  const controller = loadAbort();
  return {
    call: axios.get("http://localhost:5000/api/v1/verify/user", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    controller,
  };
};

export const logoutEndpoint = (
  token: string = "",
  rft: string = "",
  realm: string = ""
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/user/v1/auth/${realm}/logout`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          rft: rft,
        },
      }),
    controller,
  };
};

export const getCountriesEndpoint = () => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/rs/v1/country-state-cities/countries`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const getStatesEndpoint = (countryId: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/rs/v1/country-state-cities/countries/${countryId}/states`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const getCitiesEndpoint = (countryId: string, stateId: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/rs/v1/country-state-cities/countries/${countryId}/states/${stateId}/cities`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const searchData = (searchData: any) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/es/search`,
        data: searchData,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${process.env.REACT_APP_SEARCH_TOKEN}`,
        },
      }),
    controller,
  };
};

export const getContentPage = (idContent: string) => {
  const controller = loadAbort();
  const fullUrl = `${process.env.REACT_APP_API_CONTENTFUL}/${idContent}`;
  const call = (): AxiosPromise<any> => {
    return axios({
      method: "get",
      url: fullUrl,
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${process.env.REACT_APP_AUTH_CONTENTFUL}`
      }
    });
  };
  return {
    call,
    controller,
  };
};
