import React, {memo} from 'react'
import './ToastAlerts.scss';

import { useTimeout } from './hooks/useTimeOut';
interface alertsProps {
    children: JSX.Element
    close: () => void
    type: string
}

const ToastAlerts = memo(({children, close, type}: alertsProps) => {
    useTimeout(close, 4000);

    return (
        <div className='toast my-3' data-variation={type}>
            <div className='flex justify-between' >
                <div className='toast__text'>{children}</div> 
                <button onClick={close} className="toast__close-btn">
                    x
                </button>
            </div>
        </div>
    )
})

ToastAlerts.displayName = 'ToastAlerts'
export default ToastAlerts