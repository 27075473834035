import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import Button from "../../components/Button";
import { useState } from "react";
import { RequestLoginByCodeForm } from "./models/loginModel";
import logo from "../../assets/images/iso_logo.svg";
import FormControlError from "../../components/Controls/FormControlError";
import { requestLoginByCodeEndpoint } from "./services/loginService";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";
import { AppStore } from "../../redux/store";
import { useSelector } from "react-redux";
import { IconMail } from "@tabler/icons-react";
import MainButton from "../../components/MainButton/mainButton";

const LoginByCode = () => {
  const { country } = useSelector((state: AppStore) => state.country);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RequestLoginByCodeForm>({
    resolver: yupResolver(
      yup
        .object({
          email: yup
            .string()
            .email("Valor debe ser un formato de correo válido")
            .required("Correo Electrónico es requerido"),
        })
        .required()
    ),
  });

  const handleRequestLoginByCode = async (form: RequestLoginByCodeForm) => {
    const { email } = form;
    const { status, data } = await callEndpoint(
      requestLoginByCodeEndpoint(email)
    );
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      const { request_id } = response.data;
      return navigate(
        `/app/${country}/auth/verify-login-by-code/${request_id}`
      );
    } else {
      setError(response.message || "Ocurrio un error");
    }
  };

  return (
    <div className="overflow-hidden bg-cover bg-center h-screen bg-[#EBF1E8]">
      <div className="flex flex-col items-center justify-center h-screen px-4 md:px-0">
        <div className="max-w-md m-auto bg-white px-6 py-8 rounded-lg">
          <section className="pt-0 pb-0 ">
            <div className="text-center mb-6">
              <img
                className="inline-block w-52"
                src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/v2/logo-propi.svg"
                alt="Logo PropiLatam"
              />
            </div>
            <h1 className="text-[#212121] font-semibold text-2xl text-center mb-4">
              Solicitud de código de acceso para iniciar sesión
            </h1>
          </section>
          <p className="text-[#757575] font-light text-sm text-center mb-8">
            Enviaremos un correo con el código de ingreso es requerido que
            tengas una cuenta activa con nosotros, ingresa tu cuenta de correo.
          </p>
          <form onSubmit={handleSubmit(handleRequestLoginByCode)}>
            <div className="relative input-icon">
              <IconMail color="#BDBDBD" />
              <input
                type="text"
                placeholder="Correo electrónico"
                className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 pl-10"
                {...register("email")}
              />
            </div>

            <FormControlError message={errors.email?.message} />
            <FormControlError message={error} />
            <div className="mt-6">
              <MainButton topic="Solicitar" background="bg-[#212121]" colorText="text-white" type="submit" />
            </div>
            
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginByCode;
