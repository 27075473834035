import React, { memo } from "react";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import './Loading.scss'

const LoadingComponent = (props: any) => {
    return (
        <div className="loading-container" data-variation={props?.height}>
            <Player
                speed={1}
                autoplay
                loop
                src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/loading-propi1-verde.json"
                style={{ height: '100px', width: '100px' }}
            />
        </div>
    )
}

export default LoadingComponent;