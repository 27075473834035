import React from "react";
import { SearchButtonStyled } from "./styles";

export const SearchButton: React.FC<any> = ({ onClick }) => {
  return (
    <SearchButtonStyled onClick={onClick}>
      Buscar aquí
    </SearchButtonStyled>
  );
};


