import { useDispatch } from "react-redux";
import { setCountryAction } from "../redux/features/country";

const DEFAULT_COUNTRY = "SV";
export const SV = "SV";
export const GT = "GT";

const availableCountries = [SV, GT];

export interface CountryStorage {
  country: string;
}

export const useUserCountry = () => {
  const dispatch = useDispatch();

  const setCountry = (countryCode: string): CountryStorage | null => {
    if (!availableCountries.includes(countryCode.toUpperCase())) {
      console.error(
        `Current country is not included in availables countries: ${countryCode}`
      );
      return null;
    }
    const storage: CountryStorage = {
      country: countryCode,
    };
    dispatch(setCountryAction(storage));
    const countryJson = JSON.stringify(storage);
    localStorage.setItem("c", countryJson);
    return storage;
  };

  const getCurrentCountry = (): CountryStorage | null => {
    const currentCountry = localStorage.getItem("c") || null;
    if (!currentCountry) {
      return {
        country: DEFAULT_COUNTRY,
      };
    }
    const { country: countryCode } = JSON.parse(currentCountry);
    return {
      country: countryCode,
    };
  };

  const getServiceUrlCountry = (): string => {
    const currentCountry = getCurrentCountry();
    const domainSv = process.env.REACT_APP_API_URL || "na-sv";
    const domainGt = process.env.REACT_APP_API_URL_GT || "na-gt";
    if (!currentCountry) {
      console.error("Current country process was not found");
      return "";
    }
    return currentCountry.country.toUpperCase() === SV ? domainSv : domainGt;
  };

  return {
    setCountry,
    getCurrentCountry,
    getServiceUrlCountry,
  };
};

export default useUserCountry;
