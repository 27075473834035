import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import PropertyCard from '../../components/PropertyDetail'
import { propertyDetailResponseAdapter } from './adapters/propertyDetailAdapter';
import { ScheduleVisitForm } from './models/MarketplaceModel';
import {
    getDetailEndpoint,
} from './services/MarketplaceService';
import {
    checkVisitRequirementsEndpoint,
    saveVisitRequirementsEndpoint,
    prequalificateEndpoint
} from '../../services/offerService'
import { AppStore } from '../../redux/store';
import { getAuthToken, unsetAuth } from '../../helpers/authHelper';
import Button from '../../components/Button';
import arrow_button from '../../assets/icons/arrow_button.svg';
import arrow_left from '../../assets/icons/arrow_left.svg';
import FormControlError from '../../components/Controls/FormControlError';
import { logoutAction } from '../../redux/features/auth';
import FormControlInfo from '../../components/Controls/FormControlInfo';
import { scheduleVisitEndpoint } from '../../services/visitsService';
import { SaveScheduleVisitForm } from '../../models/visitModel';

const TenantVisits = () => {
    const { country } = useSelector((store: AppStore) => store.country);
    const { id = '' } = useParams()
    const { isLoading, callEndpoint } = useCallApiAndLoad()
    const user = useSelector((store: AppStore) => store.auth);
    const token = getAuthToken(user)
    const [property, setProperty] = useState<any>()
    const [error, setError] = useState('')
    const [validRequirements, setValidRequirements] = useState(false)
    const [hoursList] = useState<Array<any>>([])
    const [form, setForm] = useState<ScheduleVisitForm>({
        property_id: '',
        date: '',
        start_time: 0,
        document: ''
    })
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        const loadDetail = async () => {
            const { status, data } = await callEndpoint(getDetailEndpoint(id));
            if (status === 200) {
                const response = fetchResponseAdapter(data);
                const propertyData = propertyDetailResponseAdapter(response.data);
                setProperty(propertyData)
                handleChange({
                    target: {
                        name: 'property_id',
                        value: id
                    }
                })
            } else {
                setError('Ocurrió un error al momento de cargar el detalle');
            }
        };
        const checkVisitRequirements = async () => {
            const { status, data } = await callEndpoint(checkVisitRequirementsEndpoint(token));
            if (status === 401) {
                dispatch(logoutAction({}))
                unsetAuth()
                return navigate(`/app/${country}/auth/login`)
            }
            if (status === 200) {
                const response = fetchResponseAdapter(data);
                const { is_valid } = response.data;
                setValidRequirements(is_valid)
            }
        }
        loadDetail()
            .catch((e) => console.error(e));
        checkVisitRequirements()
            .catch((e) => console.error(e));
    }, []);

    const handleChange = ({ target }: any) => {
        setError('')
        setForm((state) => ({
            ...state,
            [target.name]: target.name === 'start_time' ? parseInt(target.value) : target.value
        }))
    }

    const handleSubmit = async () => {
        const { date, start_time, property_id, document = '' } = form
        if (!date || !start_time) {
            setError('Debes seleccionar una fecha y hora para agendar tu visita')
            return
        }

        if (!validRequirements && document) {
            const {
                status: docStatus
            } = await callEndpoint(saveVisitRequirementsEndpoint(document, token))
            if (docStatus === 201) {
                setValidRequirements(true)
                callEndpoint(prequalificateEndpoint(token))
                    .then()
                    .catch()
            }
        }

        const saveScheduleVisitForm: SaveScheduleVisitForm = {
            date, start_time, property_id
        }
        const {
            status
        } = await callEndpoint(scheduleVisitEndpoint(saveScheduleVisitForm, token))
        switch (status) {
            case 401:
                dispatch(logoutAction({}))
                unsetAuth()
                return navigate(`/app/${country}/auth/login`)
            case 409:
                setError(`Error: Ya posees una visita activa para esta propiedad`)
                break;
            case 201:
                return navigate(`/app/${country}/l/tn/visit/success`)
            default:
                setError(`Error(0): Lo sentimos por momento no podemos agendar visitas a esta propiedad`)
                break;
        }
    }

    return (
        <>
            <section className='pt-24' >
                <div className="container">
                    <div className="m-auto w-full">
                        <div className="w-full my-6 text-third text-4xl font-bold text-center">
                            Agenda tu visita
                        </div>
                    </div>
                </div>
            </section>
            <hr />
            <section className='py-8' >
                <div className="w-full text-center mb-3 text-md font-semibold text-lg text-blueDark">
                    Selecciona una fecha
                </div>
                <div className="w-full text-center mb-6 text font-regular">
                    Selecciona una fecha y hora disponible en que puedas realizar tu visita.
                </div>
                <div className='flex justify-center'>
                    <div className="lg:px-2 w-full lg:w-2/3">
                        <PropertyCard
                            id={property?.code}
                            address={property?.state_city}
                            price={''}
                            image={property?.cover}
                        />
                    </div>
                </div>
                <div className='w-full flex flex-wrap mt-10'>
                    <div className='w-full content-center md:w-1/2'>
                        
                    </div>
                    <div className='w-full md:w-1/2'>
                        <div className='form-radio flex justify-between md:flex-row py-5 max-w-md mx-auto flex-wrap'>
                            {
                                hoursList?.length > 0 ? (
                                    <>
                                        {
                                            hoursList.length && hoursList.map((data: any) => (
                                                <div className='flex w-1/3 my-2'>
                                                    <input
                                                        type='radio'
                                                        name='start_time'
                                                        value={data.minutes}
                                                        id={`hour-${data.time_value}`}
                                                        key={`hour-${data.time_value}`}
                                                        onChange={handleChange}
                                                        disabled={data.is_blocked}
                                                        className="" />
                                                    <label
                                                        htmlFor={`hour-${data.time_value}`}
                                                        key={`hour-${data.time_value}-lbl`}
                                                        className={` ${data.is_blocked ? 'opacity-50' : ''} py-2 px-8 mx-1 flex justify-center items-center w-1/2 md:w-full cursor-pointer`}>
                                                        {data.time}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </>
                                ) : (
                                    <p className='text-grayText text-center text-base w-full'>Debes seleccionar una fecha</p>
                                )
                            }

                        </div>
                        {!validRequirements && (
                            <div>
                                <label className=' text-bluedark text-base font-medium mb-3 md:mb-0'>Documento único de identidad</label>
                                <input
                                    name='document'
                                    placeholder='número de DUI'
                                    onChange={handleChange}
                                    className="bg-white block py-3 px-2 w-full text-sm text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                <FormControlInfo message='Por su seguridad necesitamos verificar tu identidad' />
                            </div>
                        )}
                    </div>
                </div>
                <FormControlError message={error} />
            </section>
            <hr />
            <section className='my-4'>
                <div className="container">
                    <div className="flex justify-between items-center py-4 px-4">
                        <button className='flex justify-center items-center' onClick={() => navigate(-1)}>
                            <img className='mr-2' src={arrow_left} alt="" />
                            Regresar</button>
                        <div className="w-56">
                            <Button
                                disabled={isLoading}
                                onClick={handleSubmit}
                                className="relative w-full bg-primary py-5 px-4 my-4 text-white text-base rounded-full flex justify-center items-center">
                                <span className="mr-2">Continuar</span>
                                <img src={arrow_button} className="vertical-absolute-center right-3" alt="" />
                            </Button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TenantVisits