import React, { memo } from "react";
import * as yup from "yup";
import logo from "../../../../assets/images/iso_logo.svg";
import arrow_button from "../../../../assets/icons/arrow_button.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormControlError from "../../../../components/Controls/FormControlError";
import { validateGuestOTP }  from "../../services/offerServices";
import useCallApiAndLoad from "../../../../hooks/useCallApiAndLoad";
import { getStorageOffer } from "../../../../helpers/guestHelper";
import { useToast } from "../../../../components/ToastAlerts";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { AppStore } from "../../../../redux/store";

interface validateForm {
  code: string
}

const StepTwoOffer = memo(({ helpers }: any) => {
  const { country } = useSelector((state: AppStore) => state.country);
  const { goToPrevStep } = helpers;
  const navigate = useNavigate()
  const toast: any = useToast();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const offerStorage = getStorageOffer();
  const offerGuestData: any =
  offerStorage && JSON.parse(offerStorage);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<validateForm>({
    resolver: yupResolver(
      yup
        .object({
          code: yup.string().required("El código de verificación es requerido"),
        })
        .required()
    ),
  });

  const sendData = async (props: validateForm) => {
    const validateData = {
      code: props?.code,
      guest_id: offerGuestData?.guest_id
    }

    const { status, data } = await callEndpoint(validateGuestOTP(validateData))
    if (status === 201) {
      localStorage.removeItem('offer')
      navigate(`/app/${country}/invitado-oferta/registrarse/exito`, { replace: true })
    } else {
      toast.open(data?.message, 'error')
    }
  }

  return (
    <div className="w-full">
      <section className=" pb-0 ">
        <div className="text-center mb-6">
          <img
            className="inline-block cursor-pointer"
            src={logo}
            alt="Logo PropiLatam"
          />
        </div>
        <h1 className=" text-blueDark font-bold text-3xl text-center mb-2">
        Confirma tu teléfono
        </h1>  
      </section>
    <form className=" max-w-md mx-auto mt-8" onSubmit={handleSubmit(sendData)}>
        <div className="w-full max-w-xs flex flex-col justify-center items-center mx-auto">
        <p className="text-base text-graySoft text-center">Ingresa el código SMS  que enviamos a <span className="font-semibold">({offerGuestData?.area_code}) {offerGuestData?.phone}</span></p>
        <span className="text-sm text-primary text-center cursor-pointer" onClick={() => goToPrevStep()}>¿Quieres cambiar el número de teléfono?</span>
        </div>
        
        <div className="w-full flex justify-center my-5 flex-col">
          <input 
            placeholder="Código de verificación" 
            className="bg-white block py-4 px-2 w-full text-sm text-gray-900 border border-grayForm rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
            {...register('code')} 
            type="number" />
          <FormControlError
              message={errors?.code?.message}
              additionalClass="mb-3"
            />
        </div>
        <p className="text-base text-graySoft text-center">¿No recibiste ningún código? <a className=" font-semibold text-primary" href="">Reenviar</a> </p>
        <button
          type="submit"
          className="relative w-full bg-primary py-5 px-4 my-4 text-white text-base rounded-full flex justify-center items-center"
        >
          <span className="mr-2">Finalizar</span>
          <img
            src={arrow_button}
            className="vertical-absolute-center right-3"
            alt=""
          />
        </button>
        </form>
    </div>
  );
});

StepTwoOffer.displayName = "StepTwoOffer";
export default StepTwoOffer;
