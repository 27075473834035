interface Props {
    label: string;
    children: JSX.Element;
}

const DetailFormControlSection = (props: Props) => {
    const { label, children } = props
    return (
        <>
            <div className="px-4 lg:px-2 w-full lg:w-2/3">
                <div className="w-full flex justify-between items-center my-6">
                    <div className="w-full">
                        <label className="text-blueForm font-regular">
                            { label }
                        </label>
                        { children }
                    </div>
                </div>
            </div>
            <hr />
        </>
    )
}

export default DetailFormControlSection
