import axios from "axios";
import { OfferForm, guestForm } from "../models/OfferModel";

const loadAbort = () => {
  return new AbortController();
};

export const createOfferEndpoint = (form: OfferForm) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/user/v1/guest/offer`,
        data: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const createOtpEndpoint = (guest: guestForm) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/user/v1/guest/send-otp`,
        data: JSON.stringify(guest),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const validateGuestOTP = (form: any) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/user/v1/guest/validate-otp`,
        data: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};
