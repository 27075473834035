export const replaceParamsUnits = (searchParams: any) => {
    return {
        bedrooms: searchParams.get('bedrooms') 
            && !isNaN(Number(searchParams.get('bedrooms'))) ? Number(searchParams.get('bedrooms')) : null,
        area: searchParams.get('area') 
            && !isNaN(Number(searchParams.get('area'))) ? Number(searchParams.get('area')) : null,
        level: searchParams.get('level') 
            && !isNaN(Number(searchParams.get('level'))) ? Number(searchParams.get('level')) : null,
        take: searchParams.get('take') 
            && !isNaN(Number(searchParams.get('take'))) ? Number(searchParams.get('take')) : null,
        model_id: searchParams.get('model_id') || null,
        status: searchParams.get('status') || null,
        landlord_id: searchParams.get('landlord_id') || null,
        // unit_name: searchParams.get('unit_name') || null,
    }

}

export const replaceFormParamsUnits = (data: any) => {    
    return {
        take: data.take ? Number(data.take) : null,
        bedrooms: data.bedrooms ? Number(data.bedrooms) : null,
        area: data.area ? Number(data.area) : null,
        level: data.level ? Number(data.level) : null,
        model_id: data.model_id !== "" ? data.model_id : null ,
        status: data.status !== "" ? data.status : null ,
        landlord_id: data.landlord_id !== "" ? data.landlord_id : null,
        unit_name: data.unit_name !== "" ? data.unit_name : null
    }
}

export const cleanFormParamsUnits = (data: any) => {
    let response: any = {}
    if(data?.page !== null) response.page = data?.page
    if(data?.take !== null) response.take = data?.take
    if(data?.bedrooms !== null) response.bedrooms = data?.bedrooms
    if(data?.area !== null) response.area = data?.area
    if(data?.level !== null ) response.level = data?.level
    if(data?.model_id !== null) response.model_id = data.model_id
    if(data?.status !== null) response.status = data.status
    if(data?.landlord_id !== null) response.landlord_id = data.landlord_id
    if(data?.unit_name !== null) response.unit_name = data.unit_name
    
    return response
}