export const getPathByActionPathCode = (actionCode: string): string | null => {
  switch (actionCode) {
    case "o": //offers
      return "/l/tn/offers";
    case "pb": // pre bookins
      return "/l/ld/pre-booking";
    default:
      return null;
  }
};
