import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth";
import propertyReducer from "./features/property";
import registerPropertyReducer from "./features/register";
import favoritesReducer from "./features/favorites";
import adminPropertyReducer from "./features/adminProperty";
import filtersReducer from "./features/filters";
import navigationReducer from "./features/navigation";
import offerReducer from "./features/offer";
import visitsReducer from "./features/guestVisits";
import filtersProjectsReducer from "./features/filtersProjects";
import countryReducer from "./features/country";
import levelsMapReducer from "./features/levelsMap";
import ipClientReducer from "./features/ip";
import polygonsUserFollowUpReducer from "./features/polygonsUserFollowUp";
import authDeveloperReducer from "./features/authDeveloper"
export interface AppStore {
  auth: any;
  authDeveloper: any;
  property: any;
  register: any;
  favorites: any;
  adminProperty: any;
  filters: any;
  navigation: any;
  offer: any;
  visits: any;
  filtersProjects: any;
  country: any;
  levelsMap: any;
  ipClient: any;
  polygonsUserFollowUp: any;
}

export default configureStore<AppStore>({
  reducer: {
    auth: authReducer,
    authDeveloper: authDeveloperReducer,
    property: propertyReducer,
    register: registerPropertyReducer,
    favorites: favoritesReducer,
    adminProperty: adminPropertyReducer,
    filters: filtersReducer,
    navigation: navigationReducer,
    offer: offerReducer,
    visits: visitsReducer,
    filtersProjects: filtersProjectsReducer,
    country: countryReducer,
    levelsMap: levelsMapReducer,
    ipClient: ipClientReducer,
    polygonsUserFollowUp: polygonsUserFollowUpReducer,
  },
});
