import { ReactNode, useEffect, useState } from "react";
import Button from "./Button";
//icon
import closeModal from "../assets/icons/close-modal-black.svg";
import arrow_button from "../assets/icons/arrow_button.svg";

type IModalProps = {
  name?: string;
  body?: ReactNode;
  onAction: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onHandleSubmit?: () => void | undefined;
};

const WhiteModal: React.FC<IModalProps> = (props) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";
  }, []);

  return (
    <div className="overflow-hidden fade modal">
      <div className="fixed inset-0  z-50 h-full w-screen overflow-hidden bg-modalBg bg-blend-multiply">
        <div className="w-full h-full flex justify-center items-center">
          <div className="w-5/6 lg:w-1/3 mx-auto rounded-t-md">
            <div className="bg-white py-3 px-2 flex justify-between items-center rounded-t-md drop-shadow">
              <div className="mx-1 text-black font-semibold text-lg">
                {props.name}
              </div>
              <button
                className="mx-1 text-white text-2xl"
                id="navMobile"
                onClick={props.onAction}
              >
                <i>
                  <img src={closeModal} alt="closemodal" />
                </i>
              </button>
            </div>
            {props.body}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhiteModal;
