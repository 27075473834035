import { createSlice } from "@reduxjs/toolkit";

interface PolygonsUserFollowUp {
  follow_up_sent: boolean;
  last_updated_at: string | null;
  current_parent_id: number | null;
  views: number[];
  show_follow_up_modal_count: number;
  show_follow_up_modal: boolean;
}

const POLYGONS_TO_SHOW_MODAL = 1000;
const initialState: PolygonsUserFollowUp = {
  follow_up_sent: false,
  last_updated_at: null, // date, probably unused
  current_parent_id: null, // id of current polygon clicked
  views: [], // track of polygons clicked
  show_follow_up_modal: false, // boolean to show follow up modal
  show_follow_up_modal_count: 0, // number of polygons clicked to trigger show up modal
};

export const polygonsFollowUpSlice = createSlice({
  name: "polygons-user-follow-up",
  initialState,
  reducers: {
    clearFollowUpAction: () => initialState,
    initFollowUpAction: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    hideFollowUpAction: (state, action) => {
      return {
        ...state,
        show_follow_up_modal: false,
        show_follow_up_modal_count: 0,
      };
    },
    setFollowUpAction: (state, action) => {
      const previousViews = state.views;
      const followUpModalSent = state.follow_up_sent;
      let updatedViews = [...previousViews];
      let showUpCount = state.show_follow_up_modal_count;
      if (action.payload.current_parent_id) {
        showUpCount++;
        updatedViews.push(action.payload.current_parent_id);
      }
      const enableModal =
        !followUpModalSent && showUpCount >= POLYGONS_TO_SHOW_MODAL;
      action.payload.views = updatedViews;
      action.payload.show_follow_up_modal = enableModal;
      action.payload.last_updated_at = new Date().getTime();
      action.payload.show_follow_up_modal_count = showUpCount;
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

// provide actions
export const {
  setFollowUpAction,
  hideFollowUpAction,
  clearFollowUpAction,
  initFollowUpAction,
} = polygonsFollowUpSlice.actions;
// provide reducer
export default polygonsFollowUpSlice.reducer;
