import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";


import {IconArrowNarrowRight} from "@tabler/icons-react"
import { useSelector } from "react-redux";
import { AppStore } from "../redux/store";
import axios from "axios";

const FooterComponent = memo(() => {
  const { country } = useSelector((store: AppStore) => store.country);
  const [contentFooter, setContentFooter] = useState<any>({})


  const GTFooter: string = '6gdUWDuA45IsiS51vOaUqp'
  const SVFooter: string = '7GbLQhQA2SC5KFo1YXp9Xs'

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://cdn.contentful.com/spaces/2s65zqhp8au5/entries/${ country === 'sv' ? SVFooter : GTFooter }`, {
          headers: {
            Authorization: 'Bearer y0IYxPnHITMQAbl3NERyBLUdvMzb8p3ON1tLtlDRna0'
          }
        });
        let data = response.data;
        setContentFooter(data?.fields)
      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    };
  
    fetchData();
  }, []);

  
  return (
    <footer className="py-16 bg-tea-400">
        <div className="container mx-auto ">
          <div className="flex justify-between flex-col md:flex-row px-4 md:px-0">
            <div className="w-full lg:w-2/6 bg-primary p-4 lg:p-6 text-white text-sm rounded-lg">
              <div className="w-full">
                <img
                  className="inline lg:block md:m-0 mb-8 lg:w-1/3"
                  src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/invest/propi.svg"
                  alt="Logo white"
                />
              </div>
              <div className="my-4 text-left">
                Somos una Proptech que busca democratizar el mercado de bienes
                raíces, creando oportunidades para todos los participantes con
                un servicio end-to-end a través de eficiencia y transparencia
                para propietarios, inquilinos, agentes y desarrolladoras.
              </div>
              <a
                href={`/${country}/quienes-somos`}
                className="w-fit text-white text-left flex items-center justify-start ease-in-out duration-300 hover:translate-x-3"
              >
                Conoce más <IconArrowNarrowRight color="white" />
              </a>
            </div>
            <div className="w-full mt-4 lg:mt-0 lg:w-1/6 md:px-4 md:pl-10 lg:text-left text-sm">
              <h3 className="w-full text-blackScale mb-4 font-semibold">
                Navega en Propi
              </h3>
              {/* <div className="my-6  w-full duration-300 ease-in-out text-[#595D56] hover:translate-x-3 cursor-pointer hover:text-primary">
              Soy inquilino
            </div> */}
              <a href={`/app/${country}/marketplace?marketplace_type=for_rent`}>
                <div className="my-6  w-full duration-300 ease-in-out text-[#595D56] hover:translate-x-3 cursor-pointer hover:text-primary">
                  Quiero alquilar
                </div>
              </a>
              <a href={`/${country}/venta/casas-y-apartamentos/proyectos`}>
                <div className="my-6  w-full duration-300 ease-in-out text-[#595D56] hover:translate-x-3 cursor-pointer hover:text-primary">
                  Quiero comprar
                </div>
              </a>

              <a
                href={`/${country}/alquiler/alquila-tu-casa-apartamento-con-propi`}
              >
                <div className="my-6  w-full duration-300 ease-in-out text-[#595D56] hover:translate-x-3 cursor-pointer hover:text-primary">
                  Quiero rentar mi propiedad
                </div>
              </a>
              <a href={`/${country}/venta/vender-casa-apartamento-con-propi`}>
                <div className="my-6  w-full duration-300 ease-in-out text-[#595D56] hover:translate-x-3 cursor-pointer hover:text-primary">
                  Quiero vender mi propiedad
                </div>
              </a>
              <a href={`/${country}/ayuda`}>
                <div className="my-6  w-full duration-300 ease-in-out text-[#595D56] hover:translate-x-3 cursor-pointer hover:text-primary">
                  Ayuda
                </div>
              </a>
              {/* <div className="my-6  w-full duration-300 ease-in-out text-[#595D56] hover:translate-x-3 cursor-pointer hover:text-primary">
              Quiero invertir
            </div> */}
            </div>
            <div className="w-full lg:w-1/6 md:px-4 lg:text-left text-sm">
              <h3 className="w-full text-blackScale mb-4 font-semibold">
                Sobre Propi
              </h3>
              <a href={`/${country}/quienes-somos`}>
                <div className="my-6  w-full duration-300 ease-in-out text-[#595D56] hover:translate-x-3 cursor-pointer hover:text-primary">
                  Quienes somos
                </div>
              </a>
              <a
                className="my-6  w-full duration-300 ease-in-out text-[#595D56] hover:translate-x-3 cursor-pointer hover:text-primary block"
                href="https://blog.propilatam.com/"
              >
                Blog de propi
              </a>
              <a href={`/app/${country}/politicas-privacidad`}>
                <div className="my-6  w-full duration-300 ease-in-out text-[#595D56] hover:translate-x-3 cursor-pointer hover:text-primary">
                  Políticas de privacidad
                </div>
              </a>

              <a href={`/app/${country}/terminos-condiciones`}>
                <div className="my-6  w-full duration-300 ease-in-out text-[#595D56] hover:translate-x-3 cursor-pointer hover:text-primary">
                  Términos y condiciones
                </div>
              </a>
            </div>
            <div className="w-full lg:w-1/6 md:px-4 lg:text-left text-sm">
              <h3 className="w-full text-blackScale mb-4 font-semibold">
                Trabaja con Propi
              </h3>

              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLScNq5CZWZxk7wULjbgXnOTUKUyoXR1LCTjusyaWlMmwv5DdRQ/viewform"
                target="_blank"
                rel="noreferrer"
              >
                <a
                  href={`/app/${country}/referidos`}
                  className="my-6  w-full duration-300 ease-in-out text-[#595D56] hover:translate-x-3 cursor-pointer hover:text-primary block"
                >
                  Refiere y gana
                </a>
              </a>
            </div>
            <div className="w-full lg:w-1/6 md:px-4 lg:text-left text-sm">
              <h3 className="w-full text-blackScale mb-4 font-semibold">
                {contentFooter?.contacto?.title}
              </h3>
              <a href={contentFooter?.contacto?.email_link}>
                <div className="my-6  w-full duration-300 ease-in-out text-[#595D56] hover:translate-x-3 cursor-pointer hover:text-primary">
                  {contentFooter?.contacto?.email_text}
                </div>
              </a>
              <div className="my-6  w-full duration-300 ease-in-out text-[#595D56] hover:translate-x-3 cursor-pointer hover:text-primary">
                <a
                  className="outline-none"
                  href={contentFooter?.contacto?.whatsapp_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {contentFooter?.contacto?.whatsapp_text}
                </a>
              </div>
              <div className="my-6  w-full duration-300 text-[#595D56]">
                {contentFooter?.contacto?.address_text}
              </div>
            </div>
          </div>

          <div className="flex mt-12 justify-center lg:justify-between flex-col md:flex-row items-center px-4">
            <div className="my-2 md:my-0">
              <p className="text-base text-[#595D56]">{contentFooter?.copyright?.copyright}</p>
              
            </div>
            <div className="my-2 md:my-0 ">
              <p className="text-base text-[#595D56]">{
                contentFooter?.copyright?.society
              }</p>
              
              
            </div>
            <div className="my-2 md:my-0 text-base text-[#595D56]"></div>
            <div className="flex justify-center">
              <a
                className="mr-4 md:mr-4"
                href=" https://www.facebook.com/propi.latam/"
                target="_blank"
              >
                <img
                  src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/invest/facebook.svg"
                  alt="facebook"
                />
              </a>
              <a
                className="mr-4 md:mr-4"
                href="https://www.instagram.com/propi.latam/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/invest/instagram.svg"
                  alt="instagram"
                />
              </a>
              <a
                className="mr-4 md:mr-4"
                href="https://www.linkedin.com/company/propilatam"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/invest/linkedin.svg"
                  alt="linkedin"
                />
              </a>
            </div>
          </div>
        </div>
      </footer>
  );
});
FooterComponent.displayName = "FooterComponent";
export default FooterComponent;
