import { MutableRefObject, useEffect, useRef, useState } from "react";
import "../../../../scss/BidderCard.scss";
import "./CardPro.scss";

//icon
import loadingLogo from "../../../../assets/loading.gif";
import level1 from "../../../../assets/icons/level-1.png";
import level2 from "../../../../assets/icons/level-2.png";
import level3 from "../../../../assets/icons/level-3.png";
import level4 from "../../../../assets/icons/level-4.png";
import level5 from "../../../../assets/icons/level-5.png";
import { LandlordInfo } from "../../models/Devs";
import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import ColorfulNameComponent from "../ColorfulName/ColorfulName";
import { useDetailProjectsUnit } from "../../pages/ProjectDetail/hooks/useDetailProject";
import { useParams } from "react-router-dom";
import { AppStore } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { getAuthToken } from "../../../../helpers/authHelper";
import {
  IconChevronRight,
  IconDownload,
  IconEyeCheck,
  IconFile,
  IconHistory,
} from "@tabler/icons-react";
import { formatDistanceToNow, isValid, parse } from "date-fns";
import { es } from "date-fns/locale";

type ICardProps = {
  landlordId: string;
  landlordInfo: LandlordInfo | null;
  idUnit: string;
  pre_booking_date?: string;
  onAction: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

type Tab = "tab1" | "tab2" | "tab3" | "tab4";

const InvestorCard: React.FC<ICardProps> = ({
  landlordInfo,
  idUnit,
  onAction,
  pre_booking_date,
}: ICardProps) => {
  const { id = "" } = useParams();
  const user = useSelector((store: AppStore) => store.auth);
  const [open, setOpen] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [currentDocumentPath, setCurrentDocumentPath] = useState("");
  const token = getAuthToken(user);

  const { usersUnit, getDetailUnitUser, loading } = useDetailProjectsUnit();

  useEffect(() => {
    document.body.style.overflowY = "hidden";
  }, []);

  useEffect(() => {
    getDetailUnitUser(token, id, idUnit);
  }, []);

  const handleOpen = (documentPath: string) => {
    setCurrentDocumentPath(documentPath);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentDocumentPath("");
  };
  const handleOpenHistory = () => {
    setOpenHistory(true);
  };

  const handleCloseHistory = () => {
    setOpenHistory(false);
  };

  const [activeTab, setActiveTab] = useState<Tab>("tab1");

  const tabRefs: Record<Tab, MutableRefObject<HTMLButtonElement | null>> = {
    tab1: useRef<HTMLButtonElement>(null),
    tab2: useRef<HTMLButtonElement>(null),
    tab3: useRef<HTMLButtonElement>(null),
    tab4: useRef<HTMLButtonElement>(null),
  };

  const openTab = (tabName: Tab) => {
    setActiveTab(tabName);
    if (tabRefs[tabName] && tabRefs[tabName].current) {
      tabRefs[tabName]?.current?.scrollIntoView({
        behavior: "smooth",
        inline: "center",
      });
    }
  };

  const downloadFile = async (documentPath: string) => {
    const response = await fetch(documentPath);
    const filenameSplit = documentPath.split("/");
    const fileName = filenameSplit[filenameSplit.length - 1];
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const ProgressBar = ({ percentage }: any) => {
    const progressStyle = {
      width: `${percentage}%`,
      backgroundImage: `linear-gradient(to right, #1C6D44 , #26955D, #2FB672)`,
      height: "20px",
      transition: "width 0.5s ease-in-out",
    };

    return (
      <div className="progress-container rounded-full bg-[#D5F0E3]">
        <div
          className="progress-bar rounded-full flex justify-center items-center"
          style={progressStyle}
        >
          <p className="text-xs font-medium text-white">{percentage}%</p>
        </div>
      </div>
    );
  };

  const updateDateTime = (updateDate: string) => {
    const parsedDate = parse(
      updateDate,
      "dd/MM/yyyy",
      new Date()
    );
    if (isValid(parsedDate)) {
      return (<p
        className="text-sm font-bold text-primary flex justify-start items-center"
      > Actualizacíon {formatDistanceToNow(parsedDate, {
          addSuffix: true,
          locale: es,
        })}
        
      </p>)
    }
    
  }

  return (
    <>
      <div className="bidder-container-devs bg-white relative">
        {loading ? (
          <div className="py-4 flex justify-center items-center">
            <img src={loadingLogo} className="w-24" alt="loading" />
          </div>
        ) : (
          <>
            <div className="container_id w-full flex flex-wrap bg-[#d5f0e3] px-6 py-6 sticky top-0 z-10">
              <div className="w-full lg:w-1/4 flex justify-center items-center">
                {usersUnit?.landlord_info &&
                usersUnit?.landlord_info?.avatar ? (
                  <img
                    src={usersUnit?.landlord_info?.avatar}
                    className="object-cover object-center rounded-full w-24 border-4 border-primary mx-auto"
                    alt=""
                  />
                ) : (
                  <ColorfulNameComponent
                    firstName={usersUnit?.landlord_info?.first_name}
                    lastName={usersUnit?.landlord_info?.last_name}
                  />
                )}
              </div>
              <div className="w-full lg:w-3/4 pl-0 lg:pl-3">
                <p className=" text-sm text-[#212121] text-center lg:text-left mt-2 lg:mt-0">
                  Inversionista
                </p>
                <h1 className=" text-lg font-semibold text-[#212121] mb-2 text-center lg:text-left">
                  {usersUnit?.landlord_info?.first_name}{" "}
                  {usersUnit?.landlord_info?.last_name}
                </h1>
                <div className="w-full flex bg-[#2C2E2B] rounded-xl py-2 px-4 items-center">
                  <div className="w-full ">
                    <p className="text-sm text-white font-semibold">
                      Nivel de riesgo
                    </p>
                  </div>
                  <div className="w-full text-white text-right">
                    <>
                      {usersUnit?.landlord_info?.credit_score ? (
                        (() => {
                          switch (true) {
                            case usersUnit?.landlord_info?.credit_score <= 100:
                              return (
                                <span className="text-sm">
                                  Muy Alto{" "}
                                  <img
                                    style={{ display: "inherit" }}
                                    src={level1}
                                    alt="score 1"
                                  />
                                </span>
                              );
                            case usersUnit?.landlord_info?.credit_score <= 300:
                              return (
                                <span className="text-sm">
                                  Alto{" "}
                                  <img
                                    style={{ display: "inherit" }}
                                    src={level2}
                                    alt="level2"
                                  />
                                </span>
                              );
                            case usersUnit?.landlord_info?.credit_score <= 600:
                              return (
                                <span className="text-sm">
                                  Medio{" "}
                                  <img
                                    style={{ display: "inherit" }}
                                    src={level3}
                                    alt="level4"
                                  />
                                </span>
                              );
                            case usersUnit?.landlord_info?.credit_score <= 800:
                              return (
                                <span className="text-sm">
                                  Bueno{" "}
                                  <img
                                    style={{ display: "inherit" }}
                                    src={level4}
                                    alt="level4"
                                  />
                                </span>
                              );
                            case usersUnit?.landlord_info?.credit_score < 1000:
                              return (
                                <span className="text-sm">
                                  Excelente{" "}
                                  <img
                                    style={{ display: "inherit" }}
                                    src={level5}
                                    alt="level5"
                                  />
                                </span>
                              );
                            default:
                              return (
                                <span className="text-sm">No disponible </span>
                              );
                          }
                        })()
                      ) : (
                        <span>No disponible.</span>
                      )}
                    </>
                  </div>
                </div>
              </div>
              <div className="w-full bg-[#d5f0e3] tabs overflow-x-auto">
                <button
                  className={
                    activeTab === "tab1" ? "tablink active" : "tablink"
                  }
                  onClick={() => openTab("tab1")}
                  ref={tabRefs.tab1}
                >
                  Historial
                </button>
                <button
                  className={
                    activeTab === "tab2" ? "tablink active" : "tablink"
                  }
                  onClick={() => openTab("tab2")}
                  ref={tabRefs.tab2}
                >
                  Contacto
                </button>
                <button
                  className={
                    activeTab === "tab3" ? "tablink active" : "tablink"
                  }
                  onClick={() => openTab("tab3")}
                  ref={tabRefs.tab3}
                >
                  Perfil
                </button>
                <button
                  className={
                    activeTab === "tab4" ? "tablink active" : "tablink"
                  }
                  onClick={() => openTab("tab4")}
                  ref={tabRefs.tab4}
                >
                  Documentación
                </button>
              </div>
            </div>

            <div className="w-full scrollbar relative px-5 py-4 h-[450px]">
              <div
                id="tab1"
                className={
                  activeTab === "tab1" ? "tabcontent active" : "tabcontent"
                }
              >
                <div className="w-full flex justify-between py-2">
                  <div className="w-1/2">
                    <p className="text-[#212121] text-sm lg:text-base font-semibold">
                      KYC
                    </p>
                    <p className="text-xs lg:text-sm text-[#757575]">
                      {usersUnit?.history_info?.kyc ? "Completo" : "Pendiente"}
                    </p>
                  </div>
                  <div className="w-1/2">
                    <p className="text-[#212121] text-sm lg:text-base font-semibold">
                      Pago de reserva
                    </p>
                    <p className="text-xs lg:text-sm text-[#757575]">
                      {usersUnit?.history_info?.reservation_payment
                        ? usersUnit?.history_info?.reservation_payment
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="w-full flex justify-between pt-2">
                  <div className="w-1/2">
                    <p className="text-[#212121] text-sm lg:text-base font-semibold">
                      Firma de contrato
                    </p>
                    <p className="text-xs lg:text-sm text-[#757575]">
                      {usersUnit?.history_info?.signing_promise
                        ? usersUnit?.history_info?.signing_promise
                        : "Pendiente"}
                    </p>
                  </div>
                  <div className="w-1/2">
                    <p className="text-[#212121] text-sm lg:text-base font-semibold">
                      Cuotas pagadas
                    </p>
                    <p className="text-xs lg:text-sm text-[#757575]">
                      {usersUnit?.history_info?.payment?.quota_summary}
                    </p>
                  </div>
                </div>
                <hr className="my-4" />
                <h2 className="text-[#212121] font-semibold text-base lg:text-lg mb-2">
                  Plan de pagos
                </h2>
                <div className="w-full flex flex-wrap">
                  <div className="w-1/2">
                    <p className="text-sm text-[#757575]">Pagado</p>
                    <p className="text-base lg:text-lg font-bold text-[#212121]">
                      {usersUnit?.history_info?.payment?.paid_amount}
                    </p>
                  </div>
                  <div className="w-1/2 flex flex-col items-end">
                    <p className="text-sm text-[#757575]">Pendiente</p>
                    <p className="text-base lg:text-lg font-bold text-[#212121]">
                      {usersUnit?.history_info?.payment?.pending_amount}
                    </p>
                  </div>
                  <div className="w-full my-3">
                    <ProgressBar
                      percentage={
                        usersUnit?.history_info?.payment?.paid_percentage
                      }
                    />
                  </div>
                  <div className="w-1/2">
                    <p className="text-sm text-[#757575]">Total financiado</p>
                    <p className="text-base lg:text-lg font-bold text-[#212121]">
                      {usersUnit?.history_info?.payment?.total_financed}
                    </p>
                  </div>
                </div>

                <hr className="my-4" />
                <h2 className="text-[#212121] font-semibold text-base lg:text-lg mb-2">
                  Historial de pagos
                </h2>

                {usersUnit?.history_info?.payment?.paid_detail?.map(
                  (payment: any, index: number) => (
                    <div className="w-full flex flex-wrap py-2">
                      <div className="w-1/2">
                        <p className="text-[#212121] font-semibold text-sm lg:text-base">
                          {payment?.date}
                        </p>
                        <p className="text-[#757575] text-xs lg:text-sm">
                          {payment?.description}
                        </p>
                      </div>
                      <div className="w-1/2 flex justify-end">
                        <p className="text-[#212121] font-semibold text-sm lg:text-base">
                          {payment?.amount}
                        </p>
                      </div>
                    </div>
                  )
                )}
                <div className="pb-4" />
              </div>

              <div
                id="tab2"
                className={
                  activeTab === "tab2" ? "tabcontent active" : "tabcontent"
                }
              >
                <div className="w-full flex py-2">
                  <p className="w-1/2 text-[#212121] font-semibold text-sm lg:text-base">
                    Correo
                  </p>
                  <p className="w-1/2 text-[#757575] text-sm lg:text-base font-normal text-right whitespace-nowrap overflow-ellipsis overflow-hidden">
                    {usersUnit?.contact_info?.email}
                  </p>
                </div>
                <hr className="my-2" />
                <div className="w-full flex py-2">
                  <p className="w-1/2 text-[#212121] font-semibold text-sm lg:text-base">
                    Teléfono
                  </p>
                  <p className="w-1/2 text-[#757575] text-sm lg:text-base font-normal text-right whitespace-nowrap overflow-ellipsis overflow-hidden">
                    {usersUnit?.contact_info?.phone}
                  </p>
                </div>
              </div>

              <div
                id="tab3"
                className={
                  activeTab === "tab3" ? "tabcontent active" : "tabcontent"
                }
              >
                {usersUnit?.profile_info?.map((item: any, index: number) => (
                  <>
                    <div key={index} className="w-full flex py-2">
                      <p className="w-1/2 text-[#212121] font-semibold text-sm lg:text-base">
                        {item?.key}
                      </p>
                      <p className="w-1/2 text-[#757575] text-sm lg:text-base font-normal text-right whitespace-nowrap overflow-ellipsis overflow-hidden">
                        {item?.value}
                      </p>
                    </div>
                    <hr className="my-2" />
                  </>
                ))}
              </div>
              <div
                id="tab4"
                className={
                  activeTab === "tab4" ? "tabcontent active" : "tabcontent"
                }
              >
                <div className="w-full">
                  {usersUnit?.landlord_info?.documents?.length > 0 &&
                    usersUnit?.landlord_info?.documents?.map(
                      (document: any, index: number) => (
                        <div
                          key={index}
                          className="w-full flex justify-between items-center border border-gray-300 rounded-lg my-2 py-2 px-2 gap-3"
                        >
                          <div className="flex justify-start items-center gap-2 w-3/5">
                            <div className="w-full">
                              <p className="text-sm font-bold text-[#212121] truncate">
                                {document?.document}
                              </p>
                              {
                                document.document_number !== "NA" && <p className="text-xs font-normal text-[#757575]">
                                #{document.document_number}
                              </p>
                              }

                              {
                                document?.re_entry_at && updateDateTime(document?.re_entry_at as string)
                              }
                              
                            </div>
                          </div>

                          <div className=" flex w-2/5 justify-end items-center gap-4">
                            {document?.document_path !== null ? (
                             
                                <button className="focus:outline-none border-none tooltipDev" onClick={() => handleOpen(document?.document_path)}>
                                    <IconEyeCheck color="#212121" />
                                    <span className="tooltiptext">Ver documento</span>
                                </button>  
                                
                              
                            ) : (
                              <p>No disponible</p>
                            )}
                            {document?.document_path !== null ? (
                              
                                <button className="focus:outline-none border-none tooltipDev"
                                  onClick={() =>
                                    downloadFile(document.document_path)
                                  }
                                  
                                >
                                  <IconDownload color="#212121" />
                                  <span className="tooltiptext">Descargar</span>
                                </button>
                             
                            ) : (
                              <></>
                            )}
                            
                              {/* <button onClick={() => handleOpenHistory()} className="focus:outline-none border-none tooltipDev">
                                <IconHistory color="#212121" />
                                <span className="tooltiptext">Historial</span>
                              </button> */}
                            
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="document-modal-title"
        aria-describedby="document-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            bgcolor: "background.paper",
            p: 4,
            borderRadius: '10px',
            height: "50vh",
            overflowY: "auto",
            '@media (max-width: 780px)': {
              width: '90%',
              height: "70vh",
            }
          }}
        >
          <iframe
            src={currentDocumentPath}
            width="100%"
            height="100%"
            title="Document Viewer"
          />
          
        </Box>
      </Modal>
      <Modal
        open={openHistory}
        onClose={handleCloseHistory}
        aria-labelledby="document-modal-title"
        aria-describedby="document-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            bgcolor: "background.paper",
            p: 4,
            borderRadius: '10px',
          }}
        >
          <div className="w-full">
            <h1 className="font-semibold text-[#212121] text-xl mb-4">Historial</h1>
            <p className="text-sm inline-block text-[#757575] my-1"><IconChevronRight className=" inline-block" /> El documento fue actualizado aproximadamente hace 4 meses</p>
            <p className="text-sm inline-block text-[#757575] my-1"><IconChevronRight className=" inline-block" /> El document se actualiara dentro de 2 meses</p>
          </div>
          
        </Box>
      </Modal>
    </>
  );
};

export default InvestorCard;
