import { useNavigate, useParams } from "react-router-dom";
import PropertyRow from "../../components/PropertyDetail";
// icons
import arrow_left from "../../assets/icons/arrow_left.svg";
// functionality
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppStore } from "../../redux/store";

//modals
import Modal from "../../components/Modal";
import FormReject from "./components/Modal/FormReject";
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import { getAuthToken, setLastPath, unsetAuth } from "../../helpers/authHelper";
import { logoutAction } from "../../redux/features/auth";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";
import FormNegociate from "./components/Modal/FormNegociate";
import FormSignature from "./components/Modal/FormSignature";
import { getLandlordPropertyOffers } from "./services/LandlordOfferService";
import { LandlordOffer, LandlordOfferProperty } from "./models/landlordModel";
import CardOffer from "./components/CardOffer";
import loading from "../../assets/loading.gif";
import FormAccept from "./components/Modal/FormAccept";
import { Dialog } from "@mui/material";

const LandlordOffers = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, callEndpoint } = useCallApiAndLoad();

  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);

  const [showReject, setShowReject] = useState<boolean>(false);
  const [showNegociate, setShowNegociate] = useState<boolean>(false);
  const [showAccept, setShowAccept] = useState<boolean>(false);
  const [showSignature, setShowSignature] = useState<boolean>(false);

  const [offers, setOffers] = useState<LandlordOffer[]>([]);
  const [singleOffert, setSingleOffer] = useState<any>({});
  const [propertyCard, setPropertyCard] = useState<LandlordOfferProperty>();

  useEffect(() => {
    const loadOffers = async () => {
      const { status, data } = await callEndpoint(
        getLandlordPropertyOffers(id, token)
      );
      if (status === 401) {
        dispatch(logoutAction({}));
        setLastPath(`/app/${country}/l/property/offers/${id}`);
        unsetAuth();
        return navigate(`/app/${country}/auth/login`);
      }
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setPropertyCard(response.data.propertyInfo);
        setOffers(response.data.offers || []);
      }
    };
    loadOffers().catch((e) => console.error(e));
  }, []);

  const callback = (status: boolean, info?: any) => {
    setSingleOffer(info);
    try {
      if (info.type === "reject") {
        setShowReject((status) => !status);
        // document.body.style.overflowY = "hidden";
      }
      if (info.type === "negociate") {
        setShowNegociate((status) => !status);
        // document.body.style.overflowY = "hidden";
      }
      if (info.type === "accept") {
        setShowAccept((status) => !status);
        // document.body.style.overflowY = "hidden";
      }
      if (info.type === "signature") {
        setShowSignature((status) => !status);
        document.body.style.overflowY = "hidden";
      }
    } catch (error) {
      console.log("error");
    }
  };

  return (
    <>
      <div className="w-full pt-16">
        <div className="flex justify-between items-center py-4 px-4">
          <a
            onClick={() => navigate(-1)}
            className="cursor-pointer flex justify-center items-center hover:-translate-x-3 duration-300 ease-in-out text-sm font-medium text-[#212121]"
          >
            <img className="mr-2 " src={arrow_left} alt="" />
            Regresar a mis propiedades
          </a>
        </div>
      </div>
      <hr className=" border-[#E0E0E0]" />

      <div className="container px-4">
        <div className="w-full pt-4">
          <div className="w-full mt-6 mb-2 text-blackGrayScale text-2xl lg:text-2xl font-semibold text-left tracking-wide">
            Ofertas recibidas
          </div>
          <p className=" text-lg font-medium text-gray600 mb-2">
            Ve el detalle de las ofertas que hicieron a tus propiedades
          </p>
        </div>
      </div>
      <div className="pb-10">
        <div className="w-full px-4 lg:px-0 lg:w-9/12 flex justify-center mx-auto mt-12">
          <div className="w-full flex flex-wrap items-center lg:border rounded-lg">
            <div className="w-full lg:w-1/4">
              <img
                className=" object-cover object-center h-[200px] w-full rounded-lg"
                src={propertyCard?.cover}
                alt=""
              />
            </div>
            <div className="w-full lg:w-3/4 md:px-4">
              <div className="lg:px-4 mt-4 lg:mt-0">
                <p className=" font-normal text-sm lg:text-base text-[#757575]">
                  #{propertyCard?.code}
                </p>
                <div className="w-full text-blackGrayScale text-2xl lg:text-3xl font-semibold text-left tracking-wide">
                  {propertyCard?.property_name}
                </div>
                <p className="text-[#757575] text-base md:text-lg">
                  {propertyCard?.address}
                </p>
              </div>
              <div className="flex flex-wrap mt-4 gap-4">
                <div className="py-1 px-4 border border-[#E0E0E0] rounded-full">
                  <p className="text-sm font-bold">Precio de alquiler</p>
                  <p className="text-xs text-left text-[#757575]">
                    {propertyCard?.rent_price}
                  </p>
                </div>
                {
                  propertyCard?.sale_price !== '$0.00' && <div className="py-1 px-4 border border-[#E0E0E0] rounded-full">
                  <p className="text-sm font-bold">Precio de venta</p>
                  <p className="text-xs text-left text-[#757575]">
                    {propertyCard?.sale_price}
                  </p>
                </div>
                }
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-8 w-full flex justify-center">
        <div className=" px-4 lg:px-20  container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 ">
          {isLoading ? (
            <div className="flex justify-center items-center pt-20">
              <img src={loading} alt="" width={60} />
            </div>
          ) : (
            offers.length > 0 &&
            offers.map((item) => (
              <CardOffer
                data={{
                  offer_info: item,
                  property_info: propertyCard,
                }}
                action={callback}
              />
            ))
          )}
        </div>
      </div>

      <Dialog
        open={showAccept}
        onClose={() => callback(false, { type: "accept" })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <FormAccept
          onAction={() => callback(false, { type: "accept" })}
          offer_info={singleOffert.offer_info}
          property_info={singleOffert.property_info}
        />
      </Dialog>

      <Dialog
        open={showReject}
        onClose={() => callback(false, { type: "reject" })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <FormReject
          onAction={() => callback(false, { type: "reject" })}
          offer_info={singleOffert?.offer_info}
          property_info={singleOffert?.property_info}
        />
      </Dialog>

      <Dialog
        open={showNegociate}
        onClose={() => callback(false, { type: "negociate" })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <FormNegociate
          onAction={() => callback(false, { type: "negociate" })}
          offer_info={singleOffert.offer_info}
          property_info={singleOffert.property_info}
        />
      </Dialog>

      {showSignature && (
        <Modal
          onAction={() => {
            callback(false, { type: "signature" });
            document.body.style.overflowY = "scroll";
          }}
          name="Contrato"
          size="max-w-2xl"
          body={
            <>
              <FormSignature
                onAction={() => callback(false, { type: "signature" })}
                offer_info={singleOffert.offer_info}
                property_info={singleOffert.property_info}
              />
            </>
          }
        />
      )}
    </>
  );
};

export default LandlordOffers;
