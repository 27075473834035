import { Link } from "react-router-dom";
import emptyBackground from "../assets/images/empty-properties.png"
import arrow_button from '../assets/icons/arrow_button.svg'
import arrowOrange from "../assets/icons/arrow-orange.svg";

interface Props {
    message: string;
    link?: string | undefined;
    linkText?: string | undefined;
    fullScreen?: boolean | undefined;
}

const EmptyTableSection = (props: Props) => {
    const { linkText, link, message, fullScreen = false } = props
    return (
        <div className={` hidden lg:flex flex-col items-center justify-center w-full ${fullScreen ? 'h-screen' : 'h-nofound' }`}>
            <div className={`bg-no-repeat bg-center ${fullScreen ? 'h-screen' : 'h-full' } w-full`}
                style={{ "backgroundImage": `url(${emptyBackground})` }}>
                <div className={`flex flex-col justify-center items-center text-center ${fullScreen ? 'h-screen' : 'h-full' } `}>
                    <span className="text-gray-700" >
                        { message }
                    </span>
                    <div
                      onClick={() => window.open(link, '_self')}
                      className="mt-3 border-b ease-in-out duration-300 cursor-pointer hover:translate-x-4 border-orange w-fit  text-orange text-left flex items-center justify-start"
                    >
                      Sigue explorando
                      <span className="ml-2">
                        <img src={arrowOrange} alt="" />
                      </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmptyTableSection
