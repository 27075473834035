import { useSelector } from "react-redux";
import FormControlError from "../../../../components/Controls/FormControlError";
import { getAuthToken } from "../../../../helpers/authHelper";
import useCallApiAndLoad from "../../../../hooks/useCallApiAndLoad";
import { AppStore } from "../../../../redux/store";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CurrencyInput from "../../../../components/Controls/CurrencyInput";
import Button from "../../../../components/Button";
import { reOfferLandord } from "../../services/tenantService";
import { useNavigate } from "react-router-dom";
import { parseNumberCurrencyValue } from "../../../../helpers/currencyHelper";
import { OfferInfo, PropertyInfo } from "../../models/tenantModel";

interface Props {
  onAction: () => void;
  id: string;
  property_info: PropertyInfo | null;
  offer_info: OfferInfo | null;
}

interface formData {
  price_to_reoffer: string;
}

const FormReOffer = (props: Props) => {
  const {
    offer_info, property_info, onAction
  } = props
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user)
  const { callEndpoint } = useCallApiAndLoad()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<formData>({
    resolver: yupResolver(
      yup.object().shape({
        price_to_reoffer: yup.string()
          .required("La oferta es requerida")
      })
    ),
  });


  const sendData = async (form: formData) => {
    const formApi: { price_to_reoffer: number } = {
      price_to_reoffer: parseNumberCurrencyValue(form.price_to_reoffer, ',')
    }
    const offer_id = props.offer_info?.id || ''
    const { status } = await callEndpoint(reOfferLandord(offer_id, token, formApi))
    if (status === 200) {
      return navigate(0)
    }
  };

  return (
    <div className="w-full text-center bg-white px-4 max-w-sm py-6">
      <div className="w-full mb-4 text-blackGrayScale text-2xl lg:text-2xl font-semibold text-left tracking-wide flex justify-start items-center">
               Estado de la oferta 
      </div>
      <div className="py-2 px-4 rounded-lg bg-[#F3D8DB]">
      <p className="text-sm font-medium text-[#7A282B]">{
        `El propietario rechazó la oferta anterior por ${offer_info?.offer_price || ''}, por estar fuera del rango esperado.`}</p>
      </div>
      
      <hr className="my-3" />
      <div className="px-4 py-2 rounded-full bg-[#F5F5F5] flex justify-between items-center">
        <p className=" text-sm text-[#757575] font-normal">Mensualidad</p>
        <p className=" font-bold text-2xl text-[#212121]">{ property_info?.rent_price }</p>
      </div>

      <form onSubmit={handleSubmit(sendData)} className="mt-4">
        <h3 className="text-lg font-medium text-[#212121] text-left mb-2">
          Realiza una nueva oferta
        </h3>
        
        <CurrencyInput
          name="price_to_reoffer"
          register={register}
          currency={"$"}
          thousand_separator={","}
          decimal_separator={"."}
        />
        <FormControlError message={errors.price_to_reoffer?.message} />
        <div className="bg-white w-full mt-6">
          <div className="w-full grid grid-cols-2 gap-6">
            <button>
              <div
                onClick={onAction}
                className="border border-[#212121] rounded-lg px-4 py-2 text-[#212121] hover:bg-[#212121] hover:text-white duration-100 text-sm"
              >
                Cancelar
              </div>
            </button>
            <Button
              type="submit"
              className=" px-4 py-2 rounded-lg bg-[#212121] hover:bg-primary duration-100 text-white text-sm"
            >
              <span className="mr-2">Ofertar</span>
              
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default FormReOffer;
