import { useNavigate } from 'react-router-dom';
import arrow_left from '../assets/icons/arrow_left.svg';

interface Props {
    text?: string;
    customPath?: string;
}

const BackSection = ({ customPath = '', text = 'Regresar' }: Props) => {
    const navigate = useNavigate()
    const handleBack = () => {
        if (customPath) {
            return navigate(customPath)
        }
        return navigate(-1)
    }
    return (
        <>
            <div className="w-full">
                <div className="flex justify-between items-center py-4 px-4">
                    <a onClick={handleBack}
                        className='cursor-pointer flex justify-center items-center' >
                        <img className='mr-2' src={arrow_left} alt="" />
                        {text}
                    </a>
                </div>
            </div>
            <hr />
        </>
    )
}

export default BackSection
