import { useSelector } from 'react-redux';
import img_success from '../../assets/images/visit_success.png'
import BackSection from '../../components/BackSection';
import { AppStore } from '../../redux/store';

const TenantVisitsSuccess = () => {
    const { country } = useSelector((store: AppStore) => store.country);
    return (
        <div className="py-20">
            <BackSection customPath={`/app/${country}/l/tn/offers`} text='Ver ofertas y visitas' />
            <img className='mx-auto w-full md:w-1/2 py-12 sm:px-2' src={img_success} alt="success" />
            <p className="max-w-xl mx-auto text-center py-2 text-grayText">Felicidades</p>
            <h1 className="text-4xl font-bold text-center py-2 text-grayText">¡Tu visita ha sido agendada con éxito!</h1>
            <p className="max-w-xl mx-auto text-center py-2 text-grayText">Nos pondremos en contacto contigo</p>
            <br />
        </div>
    )
}
export default TenantVisitsSuccess