import { useState } from 'react'
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter'
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad'
import { getDocumentsCatalog, getExistDocuments, getExistDocumentsLd } from '../../../services/documentService'
import { useDispatch, useSelector } from 'react-redux'
import { logoutAction } from '../../../redux/features/auth'
import { unsetAuth } from '../../../helpers/authHelper'
import { AppStore } from '../../../redux/store'

export const useDocuments = (): any => {
    const { country } = useSelector((store: AppStore) => store.country);
    const [cataLogDocument, setCatalogDocument] = useState([])
    const [existsDocuments, setExistsDocuments] = useState([])
    const [loading, setLoading] = useState<boolean>(false)
    const { isLoading, callEndpoint } = useCallApiAndLoad()
    const dispatch = useDispatch()

    const getDocumentsUser = async (country: string): Promise<void> => {
        setLoading(true)
        try {
            const { data} = await callEndpoint(getDocumentsCatalog(country.toUpperCase())) 
            const response = fetchResponseAdapter(data)
            setLoading(false)
            setCatalogDocument(response?.data?.documents)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    const getDocumentsExist = async (token: string): Promise<void> => {
        setLoading(true)
        try {
            const { data} = await callEndpoint(getExistDocuments(token)) 
            const response = fetchResponseAdapter(data)
            setLoading(false)
            setExistsDocuments(response?.data)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    const getDocumentsExistLandlord = async (token: string): Promise<void> => {
        setLoading(true)
        try {
            const { status, data} = await callEndpoint(getExistDocumentsLd(token)) 
            if (status === 401) {
                dispatch(logoutAction({}));
                unsetAuth();
                window.open(`/app/${country}/auth/login`);
              }
              if (status === 200) { 
                const response = fetchResponseAdapter(data)
                setLoading(false)
                setExistsDocuments(response?.data)
              }
            
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    return {
        cataLogDocument,
        existsDocuments,
        loading,
        isLoading,
        getDocumentsUser,
        getDocumentsExist,
        getDocumentsExistLandlord
    }

}