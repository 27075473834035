import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppStore } from '../redux/store';
import { isLoggedIn } from '../helpers/authHelper';

type Props = {
    children: JSX.Element
}

const PublicRoute = ({ children }: Props) => {
    const user = useSelector((store: AppStore) => store.auth)
    const isLogged = isLoggedIn(user)
    console.log('Validating public route isLogged: ' + isLogged);
    return(
        !isLogged
            ? children 
            : <Navigate to={'/'} />    
    )
}

export default PublicRoute