import React, { memo, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import FormControlError from "../../../../components/Controls/FormControlError";
import { useDispatch, useSelector } from "react-redux";
import { setDataVisits } from "../../../../redux/features/guestVisits";
import MaskInput from "../../../../components/Controls/MaskInput";
import { useNavigate, useParams } from "react-router-dom";
import { AppStore } from "../../../../redux/store";
import {
  sendGuestOTP,
  validateGuestVisit,
} from "../../../../services/visitsService";
import useCallApiAndLoad from "../../../../hooks/useCallApiAndLoad";
import {
  setStorageVisit,
  getStorageVisit,
} from "../../../../helpers/visitHelper";
import { useToast } from "../../../../components/ToastAlerts";
import { setFiltersAction } from "../../../../redux/features/filters";

import logo from "../../../../assets/images/iso_logo.svg";
import arrow_button from "../../../../assets/icons/arrow_button.svg";
import arrowOrange from "../../../../assets/icons/arrow-orange.svg";
import flag from "../../../../assets/icons/sv-flag.png";
import PhoneInput, { CountryData } from "react-phone-input-2";
import {
  DocumentData,
  useDocuments,
} from "../../../GuestOffer/hooks/useDocuments";
import { MenuItem, Select } from "@mui/material";

interface VisitForm {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  document_type: string;
  document_number: string;
}
const StepOneVisit = memo(({ helpers }: any) => {
  const [documentMask, setDocumentMask] = useState<string>("");
  const { country } = useSelector((state: AppStore) => state.country);
  const { getDataDocuments, loading, documents } = useDocuments();
  const [dialCode, setDialCode] = useState("");
  const { id = "" } = useParams();
  const toast: any = useToast();
  const { goToNextStep } = helpers;
  const { callEndpoint } = useCallApiAndLoad();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const guestVisitData = useSelector((store: AppStore) => store.visits);
  const visitStorage = getStorageVisit();
  const visitGuestData: any = visitStorage && JSON.parse(visitStorage);
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<VisitForm>({
    resolver: yupResolver(
      yup
        .object({
          first_name: yup.string().required("El nombre es requerido"),
          last_name: yup.string().required("El apellido es requerido"),
          email: yup.string().required("El correo es requerido"),
          phone: yup.string().required("El numero de teléfono es requerido"),
        })
        .required()
    ),
  });

  useEffect(() => {
    if (visitStorage) {
      setValue("first_name", visitGuestData?.first_name);
      setValue("last_name", visitGuestData?.last_name);
      setValue("email", visitGuestData?.email);
      setValue("phone", visitGuestData?.phone || "");
      setValue("document_type", visitGuestData?.document_type )
      setValue("document_number", visitGuestData?.document_number )
    }
  }, [visitStorage]);

  const sendData = async (props: VisitForm) => {
    const guestData = {
      ...guestVisitData,
      first_name: props?.first_name,
      last_name: props?.last_name,
      email: props?.email,
      phone: props?.phone,
      area_code: dialCode,
      property_id: id,
      document_number: props?.document_number,
      document_type: props?.document_type
    };
    dispatch(setDataVisits(guestData));
    setStorageVisit(guestData);

    await send(guestData);
  };

  const send = async (dataGuest: any) => {
    const { status, data } = await callEndpoint(validateGuestVisit(dataGuest));
    if (status === 201) {
      setStorageVisit({ ...dataGuest, guest_id: data?.data?.guest_id });
      const sendOtp = async () => {
        await sendGuestOTP({ guest_id: data?.data?.guest_id });
      };
      sendOtp();
      goToNextStep();
    } else {
      toast.open(data?.message, "error");
    }
  };

  const goToMarket = () => {
    dispatch(
      setFiltersAction({
        min_price: null,
        max_price: null,
        rooms: null,
        bathrooms: null,
        type: null,
        is_furnished: null,
      })
    );
    navigate(`/${country}/marketplace`);
  };

  useEffect(() => {
    getDataDocuments(country.toUpperCase());
  }, [country]);

  const handleDocumentTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedDocument = documents.find(
      (doc: any) => doc.code === event.target.value
    );
    if (selectedDocument) {
      setDocumentMask(selectedDocument.input_mask);
    }
  };

  return (
    <div className=" w-full">
      <section className=" pb-0 ">
        <div className="text-center mb-6">
          <img
            className="inline-block cursor-pointer"
            src={logo}
            alt="Logo PropiLatam"
          />
        </div>
        <h1 className=" text-blueDark font-bold text-3xl text-center mb-2">
          ¡Queremos conocerte!
        </h1>
        <p className="text-center text-graySoft text-base font-normal">
          Completa los siguientes campos:
        </p>
      </section>
      <form
        id="agenda-visita-inquilino"
        className=" max-w-2xl mx-auto mt-8"
        onSubmit={handleSubmit(sendData)}
      >
        <div className="w-full flex flex-wrap overflow-y-auto">
          <div className="lg:w-1/2 w-full px-0 md:px-2 xl:px-2">
          <div className="w-full text-sm mt-3 text-graySoft font-medium">
              Nombre
            </div>
            <input
              type="text"
              className="bg-white block py-3 px-2 w-full text-sm text-gray-900 border border-grayForm rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder="Nombre"
              {...register("first_name")}
            />
            <FormControlError
              message={errors?.first_name?.message}
              additionalClass="mb-3"
            />
          </div>
          <div className="lg:w-1/2 w-full px-0 md:px-2 xl:px-2">
          <div className="w-full text-sm mt-3 text-graySoft font-medium">
              Apellido
            </div>
            <input
              type="text"
              className="bg-white block py-3 px-2 w-full text-sm text-gray-900 border border-grayForm rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder="Apellido"
              {...register("last_name")}
            />
            <FormControlError
              message={errors?.last_name?.message}
              additionalClass="mb-3"
            />
          </div>
          <div className="lg:w-1/2 w-full px-0 md:px-2 xl:px-2">
          <div className="w-full text-sm mt-3 text-graySoft font-medium">
              Correo
            </div>
            <input
              type="email"
              className="bg-white block py-3 px-2 w-full text-sm text-gray-900 border border-grayForm rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder="Correo"
              {...register("email")}
            />
            <FormControlError
              message={errors?.email?.message}
              additionalClass="mb-3"
            />
          </div>
          <div className="lg:w-1/2 w-full px-0 md:px-2 xl:px-2">
            <div className="w-full text-sm mt-3 text-graySoft font-medium">
              Tipo de documento
            </div>
            {!loading && (
              <Controller
                name="document_type"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    onChange={(event: any) => {
                      onChange(event);
                      handleDocumentTypeChange(event);
                    }}
                    sx={{
                      width: "100%",
                      height: "50px",
                      borderColor: "#CFD4D9",
                      background: "white",
                    }}
                  >
                    {documents.length > 0 &&
                      documents?.map((item: DocumentData, index: number) => (
                        <MenuItem value={item.code}>{item.document}</MenuItem>
                      ))}
                  </Select>
                )}
              />
            )}

            <FormControlError
              message={errors?.document_type?.message}
              additionalClass="mb-3"
            />
          </div>
          <div className="lg:w-1/2 w-full px-0 md:px-2 xl:px-2">
            <div className="w-full text-sm text-graySoft font-medium mt-3">
              Número de documento
            </div>
            <Controller
              control={control}
              name="document_number"
              render={({ field: { ref } }) => (
                <MaskInput
                  ref={ref}
                  register={register}
                  name={"document_number"}
                  mask={documentMask}
                />
              )}
            />

            {errors?.document_number?.message && (
              <p className=" text-redValidation text-sm font-normal mt-1">
                {errors?.document_number?.message}
              </p>
            )}
          </div>
          {/* <div className="lg:w-1/2 w-full px-0 md:px-2 xl:px-2 flex items-center flex-wrap">
            <div className="flex justify-start items-center w-1/5">
              <img className="w-6 md:w-auto" src={flag} alt="" />
              <p className="text-sm md:text-base font-medium ml-1 md:ml-2">+503</p>
            </div>
            <div className="w-4/5">
            <Controller
              control={control}
              name="phone"
              render={({ field: { ref } }) => (
                <MaskInput 
                  ref={ref}
                  register={register}
                  name={"phone"}
                  mask={"9999-9999"}
                  placeholder="Teléfono"
                />
              )}
            />
            </div>
            
            <FormControlError
              message={errors?.phone?.message}
              additionalClass="mb-3 w-full"
            />
          </div> */}
          <div className="lg:w-1/2 w-full px-0 md:px-2 xl:px-2">
          <div className="w-full text-sm mt-3 text-graySoft font-medium">
          Número de teléfono
            </div>
          <div className="w-full">
            <PhoneInput
              onChange={(value, data: CountryData, event: any) => {
                let phone = event?.target?.value;
                if (phone) {
                  let cleanPhone = phone?.replace(/[\s()-]/g, "");
                  switch (data?.dialCode) {
                    case "503":
                      cleanPhone = cleanPhone.slice(-8);
                      break;
                    case "504":
                      cleanPhone = cleanPhone.slice(-8);
                      break;
                    case "52":
                      cleanPhone = cleanPhone.slice(-10);
                      break;
                    case "1":
                      cleanPhone = cleanPhone.slice(-10);
                      break;
                    case "507":
                      cleanPhone = cleanPhone.slice(-7);
                      break;
                    case "506":
                      cleanPhone = cleanPhone.slice(-8);
                      break;
                    case "502":
                      cleanPhone = cleanPhone.slice(-7);
                      break;
                    default:
                      return cleanPhone;
                  }
                  setValue("phone", cleanPhone);
                }

                setDialCode(`+${data?.dialCode}`);
              }}
              countryCodeEditable={false}
              enableSearch
              country={"sv"}
              onlyCountries={["sv", "gt", "cr", "pa", "us", "hn", "ca", "mx"]}
            />
            <FormControlError
              message={errors?.phone?.message}
              additionalClass="mb-3 w-full"
            />
          </div>
            </div>
          
        </div>

        <button
          type="submit"
          className="relative w-1/2 mx-auto bg-primary py-5 px-4 my-4 text-white text-base rounded-full flex justify-center items-center"
        >
          <span className="mr-2">Programar visita</span>
          <img
            src={arrow_button}
            className="vertical-absolute-center right-3"
            alt=""
          />
        </button>
        <div
          onClick={goToMarket}
          className="border-b ease-in-out duration-300 cursor-pointer hover:translate-x-4 border-orange w-fit  text-orange text-center flex items-center justify-center mt-5 mx-auto"
        >
          Sigue explorando
          <span className="ml-2">
            <img src={arrowOrange} alt="" />
          </span>
        </div>
      </form>
    </div>
  );
});

StepOneVisit.displayName = "StepOneVisit";
export default StepOneVisit;
