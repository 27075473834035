//form
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { googleLogout, useGoogleLogin, GoogleLogin } from "@react-oauth/google";
import {
  GoogleProviderInfo,
  NewsletterForm,
} from "../../Home/models/homeModel";
import FormControlError from "../../../components/Controls/FormControlError";
import useCallApiAndLoad from "../../../hooks/useCallApiAndLoad";
import {
  requestGoogleUserEndpoint,
  saveNewsletterMailEndpoint,
} from "../../Home/services/HomeServices";
import { IconMail } from "@tabler/icons-react";
import useSessionManager from "../../../hooks/useSessionManager";
import { fetchResponseAdapter } from "../../../adapters/fetchAdapter";
import { useToast } from "../../../components/ToastAlerts";
import { useDispatch } from "react-redux";
import { initFollowUpAction } from "../../../redux/features/polygonsUserFollowUp";
import { useEffect, useState } from "react";

interface FollowUpUserFormProps {
  onSuccessProp: Function;
}

const FollowUpUserForm = ({
  onSuccessProp = () => {},
}: FollowUpUserFormProps) => {
  const toast: any = useToast();
  const dispatch = useDispatch();
  const { sessionId } = useSessionManager();
  const [googleToken, setGoogleToken] = useState<string>();
  const { callEndpoint, isLoading } = useCallApiAndLoad();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewsletterForm>({
    resolver: yupResolver(
      yup
        .object({
          email: yup
            .string()
            .email("Formato inválido")
            .required("Correo es requerido"),
        })
        .required()
    ),
  });

  const sendNewsletter = async (form: NewsletterForm) => {
    form.session_id = sessionId;
    form.source = "polygons_map";
    const { status, data } = await callEndpoint(
      saveNewsletterMailEndpoint(form)
    );
    if (status === 201) {
      onSuccessProp();
      localStorage.setItem("sid_f_up", "1");
      dispatch(
        initFollowUpAction({
          follow_up_sent: true,
        })
      );
    } else {
      const response = fetchResponseAdapter(data);
      toast.open(
        `(${
          response.code || 0
        }) Ocurrió un error al momento de envíar correo de seguimiento`,
        "error"
      );
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => setGoogleToken(codeResponse.access_token),
    onError: (error) =>
      toast.open(
        `(1) Ocurrió un error al momento de autenticarse con proveedor`,
        "error"
      ),
  });

  useEffect(() => {
    const getGoogleUser = async (googleToken: string) => {
      const { status, data } = await callEndpoint(
        requestGoogleUserEndpoint(googleToken)
      );
      if (status === 200) {
        const newsletterForm: NewsletterForm = {
          email: data.email,
          provider_info: {
            email: data.email,
            last_name: data.family_name,
            first_name: data.given_name,
            avatar: data.picture,
          },
        };
        return sendNewsletter(newsletterForm);
      }
    };
    if (googleToken) {
      getGoogleUser(googleToken).catch((e) => console.error(e));
    }
  }, [googleToken]);

  return (
    <>
      <div className="w-full text-center bg-white py-8 px-8 max-w-lg relative">
        <img
          className="w-28 mx-auto"
          src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/v2/logo-propi.svg"
          alt=""
        />
        <p className="mt-5 text-[#757575] lg:text-base text-sm">
          Conoce más sobre Proptech, el sector inmobiliario y nuevos
          lanzamientos. Prometemos no mandarte muchos correos
        </p>
        <div className="w-full bg-white rounded-full mt-12 lg:mt-0 lg:py-4 lg:px-4 flex flex-wrap justify-center items-center">
          <form onSubmit={handleSubmit(sendNewsletter)}>
            <div className="relative input-icon">
              <IconMail color="#BDBDBD" />
              <input
                {...register("email")}
                className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 pl-10"
                placeholder="Correo Electrónico"
              />
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="bg-[#212121] w-full rounded-lg font-semibold mt-4 py-3 text-center text-white hover:bg-primary hover:text-white hover:transition-300 transition-300 ease-in-out"
            >
              Enviar
            </button>
          </form>
          <button
            className="bg-[#212121] w-full rounded-lg font-semibold mt-4 py-3 text-center text-white hover:bg-primary hover:text-white hover:transition-300 transition-300 ease-in-out"
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
              googleLogin()
            }
          >
            Suscribete con google{" "}
          </button>
        </div>
        <FormControlError message={errors.email?.message} />
      </div>
    </>
  );
};

export default FollowUpUserForm;
