import { useEffect, useState } from "react";

import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";

//assets
import {
  getPropertiesEndpoint,
  getTakenPropertiesEndpoint,
} from "./services/PropertiesService";
import { useDispatch, useSelector } from "react-redux";
import { AppStore } from "../../redux/store";
import { logoutAction } from "../../redux/features/auth";
import { createSearchParams, useNavigate } from "react-router-dom";
import { unsetAuth, getAuthToken } from "../../helpers/authHelper";
import filtericon from "../../assets/icons/filter.svg";
import EmptyTableSection from "../../components/EmptyTableSection";
import { Paginator } from "../../models/formModel";
import CardProperty from "./components/CardProperty/CardProperty";

const Properties = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const { callEndpoint } = useCallApiAndLoad();
  const [data, setData] = useState<Array<any>>([]);
  const [takenProperty, setTakenProperty] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [paginator, setPaginator] = useState<Paginator>({
    page: 0,
    take: 0,
    itemCount: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });

  const loadProperties = async (page: number = 0, take: number = 0) => {
    let query: string = "";
    let filters: any = {};
    let responseAPI: any;

    if (page > 0 && take > 0) {
      filters.page = page.toString();
      filters.take = take.toString();
    }
    // if (propertyStatus) {
    //   filters.status = propertyStatus
    // }
    query = `?${createSearchParams(filters)}`;

    if (!takenProperty) {
      responseAPI = await callEndpoint(getPropertiesEndpoint(token, query));
    } else {
      responseAPI = await callEndpoint(getTakenPropertiesEndpoint(token));
    }
    if (responseAPI.status === 401) {
      dispatch(logoutAction({}));
      unsetAuth();
      return navigate(`/app/${country}/auth/login`);
    }
    if (responseAPI.status === 200) {
      const response = fetchResponseAdapter(responseAPI.data);
      setData(response.data.items || []);
      setPaginator(response.data.meta);
      document.documentElement.scrollTop = 0;
    }
  };

  useEffect(() => {
    loadProperties().catch((e) => console.error(e));
  }, [takenProperty]);


  return (
    <div className="pt-32 pb-20">
      <div className="m-auto w-full px-4 lg:px-0">
        <h1 className="text-blackGrayScale text-3xl lg:text-4xl font-semibold text-left tracking-wide mb-10">Bienvenido, {user?.name} </h1>
        <div className="w-full mt-6 mb-2 text-blackGrayScale text-2xl lg:text-2xl font-semibold text-left tracking-wide">
          Gestiona y administra tus propiedades
        </div>
        <p className=" text-lg font-medium text-gray600 mb-2">
          Mis propiedades
        </p>
      </div>

      <div className="mt-12 lg:px-4 bg-graysoft px-4 md:px-0">
        <div className="flex justify-between items-center flex-wrap lg:flex-nowrap ">
          <div className="flex items-center justify-end w-full lg:w-full">
            {/* <div className="flex gap-1 w-full md:w-auto">
              <button
                className={` w-1/2 lg:w-40 h-12 ease-in-out duration-300 py-2 border-2 text-sm mr-2 rounded-full ${
                  takenProperty
                    ? "border-[#E0E0E0] text-blackGrayScale bg-white text-gray"
                    : " border-blackGrayScale text-blackGrayScale font-medium"
                }`}
                onClick={filterPublished}
              >
                Publicaciones
              </button>
              <button
                className={` w-1/2 lg:w-40 h-12 ease-in-out duration-300 py-2 border-2 text-sm  rounded-full ${
                  takenProperty
                    ? " border-blackGrayScale text-blackGrayScale font-medium"
                    : "border-[#E0E0E0] bg-white text-gray"
                }`}
                onClick={filterRented}
              >
                Alquiladas
              </button>
            </div> */}

            <div className="font-medium hidden lg:flex ml-10 border-blackGrayScale text-base">
              {data.length} propiedades
              {takenProperty ? " en alquiler" : " publicadas"}
            </div>
          </div>
          <div className="right-0 flex lg:block items-center my-4 lg:my-0 px-4 lg:px-0 justify-between">
            <div className="text-soft lg:hidden flex mr-4">
              {data.length} propiedades{" "}
              {takenProperty ? " en alquiler" : " publicadas"}
            </div>
            <button
              type="submit"
              className="hidden relative top-0 right-0 pt-2.5 pb-2 pl pr-2 h-10 text-sm font-medium text-white bg-third rounded mx-2 lg:mx-0 flex justify-center items-center"
            >
              <i>
                <img src={filtericon} className="w-4 mx-2" alt="filter" />
              </i>
              <span>Filtrar</span>
            </button>
          </div>
        </div>
      </div>
        <div className="mt-8 px-4">
          

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-8">
          { data.length > 0 && data.map((item) => (
                
                <CardProperty
                cover={item.cover}
                address={item.address}
                monthly_price={item.monthly_price}
                status={item.status}
                dues={item.dues}
                property_id={item.id}
                code={item.code}
                contract_end={item.contract_end}
                type="normal"
                offers={item.offers}
                sale_monthly_price={item?.sale_monthly_price}
                offers_sale={item?.offers_sale}
                sale_status={item?.sale_status}
              />
              ))}
          </div>

        
          {data.length === 0 ? (
            <EmptyTableSection
              message="Aún no tienes publicaciones de tus propiedades"
              link={`/app/${country}/l/publish-properties`}
              
            />
          ) : (
            <></>
          )}
        </div>
      <div className="flex justify-center lg:justify-end py-2 px-4 mt-3">
        <button
          onClick={() => loadProperties(paginator.page - 1, paginator.take)}
          disabled={!paginator.hasPreviousPage}
          className={`pr-2 cursor-pointer text-sm font-medium text-blueForm hover:underline ${
            !paginator.hasPreviousPage
              ? "opacity-40 cursor-not-allowed"
              : ""
          } `}
        >
          Anterior
        </button>
        <span className="bg-primary h-8 w-8 flex justify-center items-center text-white font-medium rounded-full mx-4">{paginator.page}</span>
        <button
          onClick={() => loadProperties(paginator.page + 1, paginator.take)}
          disabled={!paginator.hasNextPage}
          className={`pr-2 cursor-pointer text-sm font-medium text-blueForm hover:underline ${
            !paginator.hasNextPage
              ? "opacity-40 cursor-not-allowed"
              : ""
          }`}
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default Properties;
