import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    country: '',
}

export const countrySlice = createSlice({
    name: 'country',
    initialState,
    reducers: {
        setCountryAction: (state, action) => {
            return {
                ...action.payload
            }
        },
    }
})

// provide actions
export const { setCountryAction } = countrySlice.actions;
// provide reducer
export default countrySlice.reducer