import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppStore } from "../../../redux/store";
import MapV2 from "../../../components/MapV2/MapV2";

const MapSection: React.FC<any> = ({
  pathPinDetail,
  zoom,
  pinArray,
  total,
  loading,
  showMap,
  marketplace_type
}) => {
  const filters = useSelector((store: AppStore) => store.filters);

  const [pin, setPin] = useState<any>()

  const {country} = useSelector((store: AppStore) => store.country);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   console.log('pasando data', pinArray)
  //   pinArray.length > 0 ? setLoading(false) : setLoading(true);
  // }, [pinArray]);

  useEffect(() => {
    if(country === 'sv') {
      setPin({ lat: 13.692096933804919, lng:-89.23480625986329 })
    } else {
      setPin({ lat: 14.602933, lng: -90.5107544})
    }
  }, [country])
  return (
    <>
    
        <MapV2
          center={
            // { lat: 43.68, lng: -79.43 }
            filters.lat || filters.lng
              ? { lat: Number(filters.lat), lng: Number(filters.lng) }
              : pin
          }
          items={pinArray}
          total={total}
          
          zoom={zoom}
          withSearchButton
          loading={loading}
          marketplace_type={marketplace_type}
        />
    
    </>
  );
};

export default MapSection;
