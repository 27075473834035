// hooks
import { useDispatch, useSelector } from "react-redux";
import { getAuthToken, unsetAuth } from "../../../helpers/authHelper";
import useCallApiAndLoad from "../../../hooks/useCallApiAndLoad";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// helpers
import { AppStore } from "../../../redux/store";
import { logoutAction } from "../../../redux/features/auth";
import { getSalesPayments } from "../services/tenantService";
import { fetchResponseAdapter } from "../../../adapters/fetchAdapter";

export enum ContractState {
  SIGNATURE_CONTRACT = "signature_contract",
  DEPOSIT_AND_FIRST_DUE = "deposit_and_first_due",
  MONTHLY_PAYMENT = "monthly_payment",
  RECURRENT_PAYMENT = "recurrent_payment",
  FINISH_CONTRACT = "finish_contract",
}

export type ValueOf<T> = T[keyof T];

interface IcontractStateStyleitem {
  TagClass: string;
  TagLabel: string;
  TagWidth: string;
}
interface IcontractStateStyle {
  [key: string]: IcontractStateStyleitem;
}

const SalesPayments = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const [data, setData] = useState<Array<any>>([]);
  const [checkAuto, setCheckAuto] = useState<boolean>(false);

  const { callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const loadPayments = async () => {
      // TODO add new fields from backend and new table
      const { status, data } = await callEndpoint(getSalesPayments(token));
      if (status === 401) {
        dispatch(logoutAction({}));
        unsetAuth();
        return navigate(`/app/${country}/auth/login`);
      }
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        // setPayments(response.data.items || [])
        setData(response.data);
      }
    };
    loadPayments().catch((e) => console.error(e));
  }, []);

  const viewDetail = (propertyId: string) => {
    navigate(`/app/${country}/l/tn/make-payment-sales/${propertyId}`);
  };

  return (
    <>
      {/* <NavBar /> */}
      <div className="container mx-auto">
        <div className="w-full pt-32 pb-20">
          <div className="m-auto w-full px-4 lg:px-0">
            <div className="w-full mt-6 mb-2 text-blackGrayScale text-2xl lg:text-2xl font-semibold text-left tracking-wide">
              Mis propiedades
            </div>
            <p className=" text-lg font-medium text-gray600 mb-2">
              Ve el detalle de tus ofertas, y realiza tus pagos
            </p>
          </div>

          {data?.length > 0 ? (
            <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-10 px-4 xl:px-2">
              {data?.map((item: any, index: number) => (
                <div key={index} className="card-property pt-4 px-4">
                  <div className="flex justify-between flex-wrap">
                    <div className="w-4/5">
                      <h2 className="font-medium text-lg truncate text-blackGrayScale leading-tight mt-4">
                        {item?.property_address}
                      </h2>
                      <p className="text-base font-semibold text-blackGrayScale leading-tight">
                        Oferta: {item?.property_offer_price}
                      </p>
                    </div>
                    <div className="w-1/5">
                      <img
                        className="w-16 h-14 rounded-md"
                        src={item?.property_cover}
                        alt=""
                      />
                    </div>
                    <div className="w-full mt-3">
                      <p className=" text-gray600 text-sm">
                        #{item?.property_code}
                      </p>
                    </div>
                    <div className="w-full py-3 flex justify-center items-center border-t border-[#E0E0E0] mt-4">
                      <button
                        onClick={() => viewDetail(item?.id)}
                        className="text-blackGrayScale font-medium text-sm"
                      >
                        Ver detalle
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full max-w-md mx-auto py-10 px-10">
              <img
                className=" w-48 mx-auto"
                src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/empty-offer.svg"
                alt=""
              />

              <h1 className="text-2xl font-semibold text-[#212121] text-center mt-4">
                Sin resultados
              </h1>
              <p className="text-[#757575] text-center text-base">
                Lo sentimos, no encontramos ninguna propiedad por el momento
              </p>
              <a
                href={`/${country}/venta/casas-y-apartamentos/proyectos`}
                target="_blank"
                className="flex justify-center items-center w-48 px-8 py-2 rounded-lg bg-[#212121] text-white font-medium text-sm mt-4 mx-auto"
                rel="noreferrer"
              >
                Ver propiedades
              </a>
            </div>
          )}

          {/* <div className="w-full my-8 px-4 lg:mt-24 mt-16">
            <div className="w-full py-6 px-4 hidden lg:flex justify-start border-grayDark border shadow-md rounded bg-plight mb-4">
              <div className="text-blueForm text-sm border-r  border-grayDark font-medium w-60">
                Dirección
              </div>
              <div className="text-blueForm text-sm flex justify-center items-center border-r border-grayDark font-medium w-32">
                Mensualidad
              </div>
              <div className="text-blueForm text-sm flex justify-center items-center border-r border-grayDark font-medium w-36">
                Estado
              </div>
              <div className="text-blueForm text-sm flex justify-center items-center border-r border-grayDark font-medium w-36">
                Cuotas pagadas
              </div>
              <div className="text-blueForm text-sm flex justify-center items-center border-r border-grayDark font-medium w-36">
                Total pendiente
              </div>
              <div className="text-blueForm text-sm flex justify-center items-center border-r border-grayDark font-medium w-28">
                Vencimiento
              </div>
            </div> 

            {data.length >= 0 ? (
              data.map((items) => {
                const { TagLabel, TagClass, TagWidth } =
                  CONTRACT_STATUS[items.state];
                return (
                  <div
                    key={items.id}
                    className="border border-tea-500 mb-4 rounded"
                  >
                    <div className="hidden lg:flex justify-start py-6 px-4 items-center">
                      <div className="text-graySoft border-r border-x-graySoft w-60">
                        {items.propertyInfo.address}
                      </div>
                      <div className="flex justify-center items-center text-sm text-graySoft border-r border-x-graySoft w-32">
                        {items.monthly_price}
                      </div>

                      <div className="flex justify-center items-center text-sm border-r border-x-graySoft w-36">
                        <p className={`"text-${TagClass} font-medium"`}>
                          {TagLabel}
                        </p>
                        
                      </div>
                      <div className="flex justify-center items-center text-sm text-graySoft border-r border-x-graySoft w-36">
                        {items.dues}
                      </div>
                      <div className="flex justify-center items-center text-sm text-graySoft border-r border-x-graySoft w-36">
                        {items.pending_total}
                      </div>
                      <div className="flex justify-center items-center text-sm text-graySoft border-r border-x-graySoft w-28">
                        {items.end_contract}
                      </div>

                      <div className="text-graySoft text-sm font-medium flex justify-end items-center">
                        <div className="text-center flex items-center ml-4 ">
                          <Link
                            to={`/app/${country}/l/tn/make-payment/${items.id}`}
                            onClick={() => {
                              window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "smooth",
                              });
                            }}
                          >
                            <button className="duration-300 hover:translate-x-4 cursor-pointer text-sm flex justify-center items-center">
                              Ver detalle
                              <img className="ml-3" src={moreIcon} alt="" />
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="block lg:hidden px-3 py-3 shadow-md">
                      <div className="w-full px-2 py-3 bg-gray300 rounded-md mb-2">
                        <span className="text-sm font-medium text-grayText">
                          Dirección
                        </span>
                        <p className="text-sm font-normal text-graySoft">
                          {items.propertyInfo.address}
                        </p>
                      </div>

                      <div className="w-full px-2 py-3 bg-tea-100 rounded-sm flex justify-between">
                        <div className="w-1/2">
                          <span className="text-sm font-medium text-grayText">
                            Mensualidad
                          </span>
                          <p className="text-sm font-normal text-graySoft">
                            {items.monthly_price}
                          </p>
                        </div>
                        <div className="w-1/2">
                          <span className="text-sm font-medium text-grayText">
                            Estado
                          </span>
                          <p className="text-sm font-normal text-graySoft">
                            <span className="text-primary font-medium">
                              {TagLabel}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="w-full px-2 py-3 bg-white rounded-sm flex justify-between">
                        <div className="w-1/2">
                          <span className="text-sm font-medium text-grayText">
                            Cuotas pagadas
                          </span>
                          <p className="text-sm font-normal text-graySoft">
                            {items.monthly_price}
                          </p>
                        </div>
                        <div className="w-1/2">
                          <span className="text-sm font-medium text-grayText">
                            Total pendiente
                          </span>
                          <p className="text-sm font-normal text-graySoft">
                            {items.pending_total}
                          </p>
                        </div>
                      </div>

                      <div className="w-full px-2 py-3 bg-tea-100 rounded-sm flex justify-between">
                        <div className="w-1/2">
                          <span className="text-sm font-medium text-grayText">
                            Vencimiento
                          </span>
                          <p className="text-sm font-normal text-graySoft">
                            {items.end_contract}
                          </p>
                        </div>
                      </div>
                      <div className="w-full flex justify-center mt-4">
                        <Link
                          to={`/app/${country}/l/tn/make-payment/${items.id}`}
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          <p className=" text-sm font-medium text-graySoft flex items-center">
                            Ver detalle
                            <img className="ml-3" src={moreIcon} alt="" />
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                <div className="w-full flex justify-center items-center h-screen bg-empty-state">
                  Parece ser que no tienes alquileres
                </div>
              </>
            )}
          </div>*/}
        </div>
      </div>
    </>
  );
};
export default SalesPayments;
