interface Props {
    register: any;
    name: string;
}

const TextAreaInput = (props: Props) => {
    const { register, name } = props
    return (
        <div className="w-full">
            <textarea
                className="w-full border mt-2 rounded-md p-2 flex items-center"
                placeholder=""
                {...register(name, { required: true, maxLength: 250 })}
            />
        </div>
    )
}

export default TextAreaInput
