import React, { useEffect, useState } from "react";
import "./SelectMaxMinPrice.scss";
import {
  IconSquareRoundedMinus,
  IconSquareRoundedPlus,
} from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { AppStore } from "../../../../../../redux/store";
import {
  cleanFormParams,
  replaceFormParams,
} from "../../../../helpers/paramsHelpers";
import { setFiltersAction } from "../../../../../../redux/features/filters";
import { FiltersStore } from "../../../../../../models/PropertyModel";
import { createSearchParams, useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";

interface SelectMaxMinProps {
  onClose: () => void
}

const SelectMaxMinPrice = ({onClose}: SelectMaxMinProps) => {
  const [error, setError] = useState("");
  const filters = useSelector((store: AppStore) => store.filters);
  const {country} = useSelector((store: AppStore) =>  store.country)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [maxPrice, setMaxPrice] = useState<any>(null);
  const [minPrice, setMinPrice] = useState<any>(null);

  const onSubmit = (event: any) => {
    event.preventDefault();
    if (maxPrice !== null && minPrice !== null) {
      if (+minPrice > +maxPrice) {
        setError("Precio minimo no puede ser mayor al precio máximo");
        return;
      }
    }

    const nullableData = {
      ...filters,
      max_price: maxPrice || null,
      min_price: minPrice || null
    };
    const dataFilter: FiltersStore = replaceFormParams(nullableData);

    const cleanedFilters = cleanFormParams(dataFilter);
    dispatch(setFiltersAction(cleanedFilters));
    if (Object.keys(cleanedFilters).length > 0) {
      navigate(`?${createSearchParams(cleanedFilters)}`);
    }
    onClose()
  };

  useEffect(() => {
    const cleanedFilters = cleanFormParams(filters);
    setMaxPrice(cleanedFilters?.max_price);
    setMinPrice(cleanedFilters?.min_price);
  }, [filters]);

  

  
  return (
    <div className="dialog-price">
      <label className="text-base lg:text-lg font-semibold text-[#212121] text-left">
        Rango de precio
      </label>
      {country === "gt" && (
        <p className="text-sm text-normal text-[#757575]">
          Precios en dólares. Los filtros utilizan una conversión aproximada a
          Quetzales
        </p>
      )}

      <form>
        <div className="w-full grid grid-cols-2 gap-4">
          <div className="w-full mt-4">
            <div className="relative input-icon">
              <IconSquareRoundedMinus color="#BDBDBD" />

              <NumericFormat
                name="min_price"
                prefix="US $"
                defaultValue={minPrice?.toString()}
                value={minPrice}
                onValueChange={(values: any) => setMinPrice(+values.value)}
                thousandSeparator=","
                placeholder="$"
                className="guest-input bg-white block pl-10 py-3 px-2 w-full text-sm text-gray-900 border border-grayForm rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              />
            </div>
          </div>

          <div className="w-full mt-4">
            <div className="relative input-icon">
              <IconSquareRoundedPlus color="#BDBDBD" />
              <NumericFormat
                name="max_price"
                prefix="US $"
                defaultValue={maxPrice?.toString()}
                value={maxPrice}
                onValueChange={(values: any) => setMaxPrice(+values.value)}
                thousandSeparator=","
                placeholder="$"
                className="guest-input bg-white block pl-10 py-3 px-2 w-full text-sm text-gray-900 border border-grayForm rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              />
            </div>
          </div>
        </div>
        {error && (
          <p className="text-red text-sm font-normal text-center mt-2">
            {error}
          </p>
        )}
        <div className="w-full mt-4">
          <button
            onClick={onSubmit}
            className="py-3 rounded-lg bg-[#212121] text-white font-medium text-center text-sm focus:outline-none hover:bg-primary w-full"
            type="submit"
          >
            Aplicar filtro
          </button>
        </div>
      </form>
    </div>
  );
};

export default SelectMaxMinPrice;
