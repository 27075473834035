import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import delete_img from '../assets/icons/delete.svg';
import FormControlError from "../components/Controls/FormControlError";
import { getAuthToken } from "../helpers/authHelper";
import useCallApiAndLoad from "../hooks/useCallApiAndLoad";
import { AppStore } from "../redux/store";
import { uploadAvatarEndpoint } from '../services/publicService';

interface Props {
    onAction: () => void;
    realm: string;
}

const UploadAvatar = (props: Props) => {

    const { onAction, realm } = props
    const [myFiles, setMyFiles] = useState<Array<any>>([])
    const [imageAccepted, setImageAccepted] = useState<any>()
    const { isLoading, callEndpoint } = useCallApiAndLoad()
    const user = useSelector((store: AppStore) => store.auth)
    const [error, setError] = useState('')
    const navigate = useNavigate()

    const onDrop = useCallback((acceptedFiles: any) => {
        setError('')
        setImageAccepted(acceptedFiles)
        setMyFiles([...acceptedFiles.map((file: any) => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }))])
    }, [myFiles, imageAccepted])

    // Dropzone config
    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        accept: {
            'image/*': []
        },
        onDrop,
    })

    const removeFile = (file: any) => {
        const newFiles = [...myFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setMyFiles(newFiles)
    }

    const handleUpload = async() => {
        setError('')
        if(!imageAccepted || !imageAccepted.length){
            setError('Debés seleccionar una imágen para subir')
        }
        let formData = new FormData()
        formData.append('avatar', imageAccepted[0])
        const token = getAuthToken(user)
        const {status, data} = await callEndpoint(uploadAvatarEndpoint(formData, token, realm))
        if(status === 200){
            navigate(0) //make a reload
        } else {
            setError('Lo sentimos, por el momento no es posible completar la acción')
        }
    }

    const files = myFiles.map(file => (
        <div className='flex justify-between items-center my-2 border-gray500 border rounded-md p-2' key={file.path}>
            <p className='text-sm text-gray-500'>{file.path} - {file.size} bytes{" "}</p>
            <button onClick={() => removeFile(file)}>
                <img className='' src={delete_img} alt="" />
            </button>
        </div>
    ))

    const thumbs = myFiles.map(file => (
        <div key={file.name}>
            <img className='w-40 h-40 object-cover' src={file.preview} onLoad={() => { URL.revokeObjectURL(file.preview) }} alt="avatar" />
        </div>
    ));

    return (
        <div className="w-full text-center bg-white ">
            <div className="flex flex-start px-6 pt-8">
                <h4 className="text-bluedark text-base font-semibold">Sube una foto</h4>
            </div>
            <div className="text-graySoft pt-0 pb-6 px-6">
                <div {...getRootProps({ className: "dropzone flex flex-col py-8 w-full justify-center items-center" })}>
                    <input {...getInputProps()} />
                    <p className=' text-grayText font-normal text-base'>Arrastra tu foto o Selecciona</p>
                    <span className='text-gray-500 text-sm'>Formatos soportados: JPEG y PNG, máximo de 1MB por archivo.</span>
                </div>
                <div className='mt-2'>
                    <div className='w-full mt-4'>{files}</div>
                    <div className='w-full flex flex-wrap mt-4 gap-4'>{thumbs}</div>
                </div>
                <FormControlError message={error} />
            </div>
            <div className="bg-white border-t border-graySoft w-full">
                <div className="w-full flex justify-between px-4 py-4 items-center">
                    <button>
                        <div
                            onClick={onAction}
                            className="text-blueForm text-lg rounded-full border text-base border-blueForm p-2">
                            Cancelar
                        </div>
                    </button>
                    <button
                        disabled={isLoading}
                        onClick={handleUpload}
                        className="bg-green500 py-2 px-4 text-white text-base rounded-full">
                        <span className="mx-auto">Subir</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default UploadAvatar
