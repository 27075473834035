import { useState } from 'react'
import { fetchResponseAdapter } from '../../../../../adapters/fetchAdapter'
import useCallApiAndLoad from '../../../../../hooks/useCallApiAndLoad'
import { getDatesAvailableVisits, getDatesAvailableVisitsProperties } from '../../../../../services/visitsService'

export const useVisitDates = (): any => {
    const [visitsData, setVisitsData] = useState([])
    const [visitsDataProperty, setVisitsDataProperty] = useState([])
    const [loading, setLoading] = useState<boolean>(false)
    const { isLoading, callEndpoint } = useCallApiAndLoad()

    const getDataVisits = async (idProperty: string): Promise<void> => {
        console.log(idProperty)
        setLoading(true)
        try {
            const { data} = await callEndpoint(getDatesAvailableVisits(idProperty)) 
            const response = fetchResponseAdapter(data)
            setLoading(false)
            setVisitsData(response.data)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }
    const getDataVisitsProperties = async (idProperty: string): Promise<void> => {
        setLoading(true)
        try {
            const { data} = await callEndpoint(getDatesAvailableVisitsProperties(idProperty)) 
            const response = fetchResponseAdapter(data)
            setLoading(false)
            setVisitsDataProperty(response.data)
        } catch (error) {
            setLoading(false)            
        }
    }

    return {
        visitsData,
        loading,
        isLoading,
        getDataVisits,
        getDataVisitsProperties,
        visitsDataProperty
    }

}