interface Props {
    message: string | undefined
    additionalClass?: string | undefined
}

const FormControlInfo = ({ message, additionalClass = '' }: Props) => {
    if(!message) return null;
    return (
        <div className={`mt-3 rounded-lg gap-2 px-3 py-2 text-primary bg-greenLight ${additionalClass}`} >
            <p className="text-sm font-medium">{ message }</p>
        </div>
    )
}

export default FormControlInfo
