import React, { memo } from "react";
import { ContractResume } from "../../models/tenantModel";

type IPropertyProps = {
  address?: string;
  price?: string;
};

interface IPay {
  is_subscribed: boolean;
  dataProperty?: IPropertyProps;
  dataContractResume?: ContractResume;
  onAction: () => void
  onActionDesactive: () => void
}

const paymentMethodMapper = {
  'card': "Tarjeta",
  'card_subscription': "Subscripción",
  'wire_transfer': "Transferencia bancaria",
}

const CardInfoPay = memo(({
  dataProperty, dataContractResume, onAction, onActionDesactive, is_subscribed = false
}: IPay) => {

  return (
    <div className="cardpay-container w-full p-3 md:p-6 border border-[#E0E0E0] rounded-lg">
      <div className="w-full flex justify-between mb-5 px-2 py-2 rounded-lg">
        <div className="w-1/2">
          <div className="w-full md:w-1/2">
            <p className="font-bold text-blackGrayScale lg:text-base text-sm">
              Dirección
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <p className="text-sm md:text-base font-normal text-[#757575]">
              {dataProperty?.address}
            </p>
          </div>
        </div>
        <div className="w-1/2">
          <div className="w-full md:w-1/2">
            <p className="font-bold text-blackGrayScale lg:text-base text-sm">
              Cuota
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <p className="text-sm md:text-base font-normal text-[#757575]">
              {dataContractResume?.due}
            </p>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between mb-5 px-2 py-2 bg-tea-100 rounded-lg">
        <div className="w-1/2">
          <div className="w-full md:w-1/2">
            <p className="font-bold text-blackGrayScale lg:text-base text-sm">
              Fecha de pago
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <p className="text-sm md:text-base font-normal text-[#757575]">
              {dataContractResume?.payment_date}
            </p>
          </div>
        </div>
        <div className="w-1/2">
          <div className="w-full md:w-1/2">
            <p className="font-bold text-blackGrayScale lg:text-base text-sm">
              Método
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <p className="text-sm md:text-base font-normal text-[#757575]">
              {paymentMethodMapper[dataContractResume?.payment_method as keyof typeof paymentMethodMapper]}
            </p>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between mb-5 px-2 py-2 bg-tea-100 rounded-lg">
        <div className="w-1/2">
          <div className="w-full md:w-1/2">
            <p className="font-bold text-blackGrayScale lg:text-base text-sm">
              Cuotas pagadas
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <p className="text-sm md:text-base font-normal text-[#757575]">
              {dataContractResume?.due_paid}
            </p>
          </div>
        </div>
        <div className="w-1/2">
          <div className="w-full md:w-1/2">
            <p className="font-bold text-blackGrayScale lg:text-base text-sm">
              Vencimiento
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <p className="text-sm md:text-base font-normal text-[#757575]">
              {dataContractResume?.contract_end}
            </p>
          </div>
        </div>
      </div>

    </div>
  );
});

CardInfoPay.displayName = "CardInfoPay";
export default CardInfoPay;
