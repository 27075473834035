import axios from "axios";
import { NewsletterForm } from "../models/homeModel";

const loadAbort = () => {
  return new AbortController();
};

export const getRecentProperties = () => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/rs/v1/marketplace/properties/recents`,
      }),
    controller,
  };
};
export const getInvestProjectsRecents = () => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/pre-sales/v1/marketplace/projects/recents`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const saveNewsletterMailEndpoint = (form: NewsletterForm) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/user/v1/config/newsletter`,
        data: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const requestGoogleUserEndpoint = (googleAccessToken: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleAccessToken}`,
        headers: {
          Authorization: `Bearer ${googleAccessToken}`,
          Accept: "application/json",
        },
      }),
    controller,
  };
};
