import React, { memo, useEffect, useState } from "react";
import CardPro from "../../components/Cards/Cards";
import { Controller, useForm } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ReactSelect from "react-select";
import MenuItem from "@mui/material/MenuItem";
import { useDetailProjectsUnit } from "./hooks/useDetailProject";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppStore } from "../../../../redux/store";
import { getAuthToken, unsetAuth } from "../../../../helpers/authHelper";
import { setFiltersProjectsAction } from "../../../../redux/features/filtersProjects";
import {
  cleanFormParamsUnits,
  replaceFormParamsUnits,
} from "../../helpers/paramsFilters";
import { ProjectProperty } from "../../../../models/PropertyModel";
import MainTemplate from "../../layout/MainLayout";
import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  Menu,
  TextField,
} from "@mui/material";
import { IconChevronDown, IconMapPin } from "@tabler/icons-react";
import { InvestsUser } from "../../../../models/presales";
import useCallApiAndLoad from "../../../../hooks/useCallApiAndLoad";
import { getDataProjects } from "../../services/devsServices";
import { logoutAction } from "../../../../redux/features/auth";
import { fetchResponseAdapter } from "../../../../adapters/fetchAdapter";
import { IProject } from "../../models/Devs";
import { logoutActionDev } from "../../../../redux/features/authDeveloper";
interface GuestForm {
  type_id: string;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  city_id: string;
}

interface FilterData {
  model_id: string;
  status: string;
  area: string | null;
  level: string | null;
  bedrooms: string;
  landlord_id: string;
  unit_name: string;
}

const ProjectDetail = memo(() => {
  const { country } = useSelector((store: AppStore) => store.country);
  const { id = "" } = useParams();
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const {
    getDataDetailModel,
    detailProject,
    loading,
    getDataDetailUnit,
    isLoadingUnit,
    dataDetailUnit,
    getDataInvestor,
    investors,
    paginator,
  } = useDetailProjectsUnit();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    getValues,
    watch,
  } = useForm<FilterData>();

  const filters = useSelector((store: AppStore) => store.filtersProjects);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getDataDetailModel(token, id);
    getDataInvestor(token, id);
  }, []);

  useEffect(() => {
    const queryParamsString = window.location.search.substr(1);
    const queryParams: any = queryParamsString
      .split("&")
      .reduce((accumulator: any, singleQueryParam: any) => {
        const [key, value] = singleQueryParam.split("=");
        accumulator[key] = value;
        return accumulator;
      }, {});

    if (queryParams) {
      setValue("area", queryParams?.area);
      setValue("level", queryParams?.level);
      setValue("model_id", queryParams?.model_id);
      setValue("bedrooms", queryParams?.bedrooms);
      setValue("status", queryParams?.status);
      setValue("landlord_id", queryParams?.landlord_id);
      setValue("unit_name", queryParams?.unit_name);

      dispatch(setFiltersProjectsAction(queryParams));
    }
  }, [useParams]);

  useEffect(() => {
    const handleBlur = () => {
      const unitNameValue = watch("unit_name");
      setValue("unit_name", unitNameValue); // Actualiza el valor en el useForm

      const nullableData = {
        ...filters,
        unit_name: unitNameValue || null,
      };
      const dataFilter: ProjectProperty = replaceFormParamsUnits(nullableData);
      dispatch(setFiltersProjectsAction(dataFilter));
    };

    // Asigna el evento onBlur al input cuando se monta el componente
    register("unit_name", { onBlur: handleBlur });
  }, [register, setValue, watch]);

  const watchArea = watch("area");
  const watchLevel = watch("level");
  const watchBeds = watch("bedrooms");
  const watchModel = watch("model_id");
  const watchStatus = watch("status");
  const watchLandlord = watch("landlord_id");
  const watchUnit = watch("unit_name");

  useEffect(() => {
    if (watchArea) {
      const nullableData = {
        ...filters,
        area: watchArea || null,
      };

      const dataFilter: ProjectProperty = replaceFormParamsUnits(nullableData);
      dispatch(setFiltersProjectsAction(dataFilter));
    }

    if (watchLevel) {
      const nullableData = {
        ...filters,
        level: watchLevel || null,
      };

      const dataFilter: ProjectProperty = replaceFormParamsUnits(nullableData);
      dispatch(setFiltersProjectsAction(dataFilter));
    }
    if (watchBeds) {
      const nullableData = {
        ...filters,
        bedrooms: watchBeds || null,
      };

      const dataFilter: ProjectProperty = replaceFormParamsUnits(nullableData);
      dispatch(setFiltersProjectsAction(dataFilter));
    }
    if (watchModel) {
      const nullableData = {
        ...filters,
        model_id: watchModel || null,
      };

      const dataFilter: ProjectProperty = replaceFormParamsUnits(nullableData);
      dispatch(setFiltersProjectsAction(dataFilter));
    }
    if (watchStatus) {
      const nullableData = {
        ...filters,
        status: watchStatus || null,
      };

      const dataFilter: ProjectProperty = replaceFormParamsUnits(nullableData);
      dispatch(setFiltersProjectsAction(dataFilter));
    }
    if (watchLandlord) {
      const nullableData = {
        ...filters,
        landlord_id: watchLandlord || null,
      };

      const dataFilter: ProjectProperty = replaceFormParamsUnits(nullableData);
      dispatch(setFiltersProjectsAction(dataFilter));
    }
  }, [
    watchArea,
    watchLevel,
    watchBeds,
    watchModel,
    watchStatus,
    watchLandlord,
  ]);

  useEffect(() => {
    const cleanedFilters = cleanFormParamsUnits(filters);
    const strSearchParams = createSearchParams(cleanedFilters);
    getDataDetailUnit(token, id, strSearchParams);
    if (Object.keys(cleanedFilters).length > 0) {
      navigate(`?${createSearchParams(cleanedFilters)}`);
    }
  }, [filters]);

  const removeParams = () => {
    navigate("");
    reset({
      status: "",
      level: "",
      model_id: "",
      bedrooms: "",
      area: "",
      landlord_id: "",
      unit_name: "",
    });
  };
  const clearFilter = () => {
    dispatch(
      setFiltersProjectsAction({
        page: 1,
        take: 15,
        level: null,
        area: null,
        status: null,
        model_id: null,
        bedrooms: null,
        landlord_id: null,
        unit_name: null,
      })
    );
    removeParams();
    window.location.reload();
  };

  function ProgressBar({ percentage }: any) {
    const progressStyle = {
      width: `${percentage}%`,
      backgroundImage: `linear-gradient(to right, #1C6D44 , #26955D, #2FB672)`,
      height: "20px",
      transition: "width 0.5s ease-in-out",
    };

    return (
      <div className="progress-container rounded-full bg-[#D5F0E3]">
        <div
          className="progress-bar rounded-full flex justify-center items-center"
          style={progressStyle}
        >
          <p className="text-xs font-medium text-white">
            {percentage?.toString()}%
          </p>
        </div>
      </div>
    );
  }

  const [data, setData] = useState<Array<IProject>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { callEndpoint } = useCallApiAndLoad();

  useEffect(() => {
    const loadPayments = async () => {
      setIsLoading(true);
      // TODO add new fields from backend and new table
      const { status, data } = await callEndpoint(getDataProjects(token));
      if (status === 401) {
        dispatch(logoutAction({}));
        unsetAuth();
        navigate(`/app/${country}/auth/desarrolladoras/login`);
      }
      if (status === 200) {
        setIsLoading(false);
        const response = fetchResponseAdapter(data);
        // setPayments(response.data.items || [])
        setData(response.data.items);
      }
    };
    loadPayments().catch((e) => console.error(e));
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MainTemplate>
      <div className="w-full">
        <div className="container mx-auto">
          <div className="py-28">
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{
                textTransform: "capitalize",
                padding: "0",
              }}
            >
              <h1 className="font-semibold text-[#212121] text-2xl lg:text-3xl flex items-center justify-start">
                {detailProject?.name} <IconChevronDown color="#212121" />
              </h1>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {data?.length > 0 &&
                data?.map((item: IProject) => (
                  <MenuItem
                    onClick={() => {
                      window.open(
                        `/app/${country}/desarrolladoras/proyectos/${item.id}`,
                        "_self"
                      );
                      handleClose();
                    }}
                  >
                    {item?.name}
                  </MenuItem>
                ))}
            </Menu>
            <p className="text-base lg:text-lg text-normal text-[#757575]">
              Detalle del proyecto
            </p>
            <div className=" px-4 py-4 rounded-lg mb-4">
              {!loading && (
                <div className="w-full flex flex-wrap items-center">
                  <div className="w-full lg:w-1/4">
                    <img
                      className=" object-cover object-center h-[200px] w-full rounded-lg"
                      src={detailProject?.cover}
                      alt=""
                    />
                  </div>
                  <div className="w-full lg:w-3/4 md:px-4">
                    <div className="lg:px-4 mt-4 lg:mt-0">
                      <p className=" font-normal text-sm lg:text-base text-[#757575]">
                        #{detailProject?.code}
                      </p>
                      <div className="w-full text-blackGrayScale text-2xl lg:text-3xl font-semibold text-left tracking-wide">
                        {!loading && detailProject?.name}
                      </div>
                      <p className="text-[#757575] text-base md:text-lg">
                        {detailProject?.address}
                      </p>
                    </div>
                    <div className="flex flex-wrap mt-4 gap-4">
                      <div className="py-1 px-4 border border-[#E0E0E0] rounded-full">
                        <p className="text-sm font-bold">Valor de proyecto</p>
                        <p className="text-xs text-left text-[#757575]">
                          {detailProject?.total_project_price}
                        </p>
                      </div>
                      <div className="py-1 px-4 border border-[#E0E0E0] rounded-full">
                        <p className="text-sm font-bold">Unidades totales</p>
                        <p className="text-xs text-left text-[#757575]">
                          {detailProject?.total_units}
                        </p>
                      </div>
                      <div className="py-1 px-4 border border-[#E0E0E0] rounded-full">
                        <p className="text-sm font-bold">
                          Fecha estimada de entrega
                        </p>
                        <p className="text-xs text-left text-[#757575]">
                          {detailProject?.estimate_built_at}
                        </p>
                      </div>
                      <div className="py-1 px-4 border border-[#E0E0E0] rounded-full">
                        <p className="text-sm font-bold">Disponible</p>
                        <p className="text-xs text-left text-[#757575]">
                          {detailProject?.total_available_units}
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                      <div className="w-full flex flex-wrap mt-4 px-4">
                        <div className="w-1/2">
                          <p className="text-sm text-[#757575]">
                            Unidades vendidas
                          </p>
                          <p className="text-base lg:text-lg font-bold text-[#212121]">
                            {detailProject?.total_sold_units}
                          </p>
                        </div>
                        <div className="w-1/2 flex flex-col items-end">
                          <p className="text-sm text-[#757575]">
                            Unidades disponibles
                          </p>
                          <p className="text-base lg:text-lg font-bold text-[#212121]">
                            {detailProject?.total_available_units}
                          </p>
                        </div>
                        <div className="w-full my-3">
                          <ProgressBar
                            percentage={detailProject?.units_percentage?.toFixed()}
                          />
                        </div>
                      </div>
                      <div className="w-full flex flex-wrap mt-4 px-4">
                        <div className="w-1/2">
                          <p className="text-sm text-[#757575]">
                            Valor vendidas
                          </p>
                          <p className="text-base lg:text-lg font-bold text-[#212121]">
                            {detailProject?.total_project_sold_price}
                          </p>
                        </div>
                        <div className="w-1/2 flex flex-col items-end">
                          <p className="text-sm text-[#757575]">
                            Valor de proyecto
                          </p>
                          <p className="text-base lg:text-lg font-bold text-[#212121]">
                            {detailProject?.total_project_price}
                          </p>
                        </div>
                        <div className="w-full my-3">
                          <ProgressBar
                            percentage={detailProject?.price_percentage?.toFixed()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="grid grid-cols-1 xl:grid-cols-7 pt-0 md:pt-10">
              <aside className="self-start sticky top-11 md:top-16 xl:top-20 col-span-1 bg-transparent px-4 xl:px-0 pb-2">
                <h2 className="text-xl font-semibold text-[#212121] md:mb-4 mt-2">
                  Filtros de busqueda
                </h2>
                <div className=" w-full grid grid-cols-3 xl:grid-cols-1 gap-2">
                  <div className=" flex flex-col w-full mt-3 lg:mt-0">
                    <label className="text-sm font-medium text-[#212121] mb-2">
                      Unidad
                    </label>
                    <input
                      className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2"
                      type="text"
                      {...register("unit_name")}
                    />
                  </div>
                  <div className=" flex flex-col w-full mt-3 lg:mt-0">
                    {investors?.length > 0 && (
                      <>
                        <label className="text-sm font-medium text-[#212121] mb-2">
                          Propietario
                        </label>
                        <Controller
                          name="landlord_id"
                          defaultValue={getValues("landlord_id")}
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <ReactSelect
                              options={investors.map((investor: any) => ({
                                label: investor.name,
                                value: investor.id,
                              }))}
                              defaultValue={investors.find(
                                (investor: any) => investor.id === value
                              )}
                              onChange={(selectedOption) => {
                                onChange(selectedOption?.value);
                              }}
                              isSearchable={true}
                              isClearable={true}
                            />
                          )}
                        />
                      </>
                    )}
                  </div>
                  <div className=" flex flex-col w-full mt-3 lg:mt-0">
                    <label className="text-sm font-medium text-[#212121] mb-2">
                      Modelo
                    </label>
                    {detailProject?.filtersSettings?.by_model?.length > 0 && (
                      <FormControl className="w-full" size="small">
                        <Controller
                          name="model_id"
                          control={control}
                          defaultValue={getValues("model_id")}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={value}
                              onChange={onChange}
                              defaultValue={getValues("model_id")}
                            >
                              {detailProject?.filtersSettings?.by_model?.map(
                                (option: any, index: number) => (
                                  <MenuItem
                                    defaultChecked
                                    key={index}
                                    value={option?.key}
                                  >
                                    {option?.value}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          )}
                        />
                      </FormControl>
                    )}
                  </div>
                  <div className=" flex flex-col w-full mt-3">
                    <label className="text-sm font-medium text-[#212121] mb-2">
                      Estado
                    </label>
                    {detailProject?.filtersSettings?.by_status?.length > 0 && (
                      <FormControl className="w-full" size="small">
                        <Controller
                          name="status"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={value}
                              onChange={onChange}
                              defaultValue={getValues("status")}
                            >
                              {detailProject?.filtersSettings?.by_status?.map(
                                (option: any, index: number) => (
                                  <MenuItem key={index} value={option?.key}>
                                    {option?.value}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          )}
                        />
                      </FormControl>
                    )}
                  </div>
                  <div className=" flex flex-col w-full mt-3">
                    <label className="text-sm font-medium text-[#212121] mb-2">
                      Área
                    </label>
                    {detailProject?.filtersSettings?.by_area?.length > 0 && (
                      <FormControl className="w-full" size="small">
                        <Controller
                          name="area"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={value}
                              onChange={onChange}
                              defaultValue={getValues("area")}
                            >
                              {detailProject?.filtersSettings?.by_area?.map(
                                (option: any, index: number) => (
                                  <MenuItem key={index} value={option}>
                                    {option}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          )}
                        />
                      </FormControl>
                    )}
                  </div>
                  <div className=" flex flex-col w-full mt-3">
                    <label className="text-sm font-medium text-[#212121] mb-2">
                      Nivel
                    </label>
                    {detailProject?.filtersSettings?.by_level?.length > 0 && (
                      <FormControl className="w-full" size="small">
                        <Controller
                          name="level"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={value}
                              onChange={onChange}
                              defaultValue={getValues("level")}
                            >
                              {detailProject?.filtersSettings?.by_level?.map(
                                (option: any, index: number) => (
                                  <MenuItem key={index} value={option}>
                                    {option}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          )}
                        />
                      </FormControl>
                    )}
                  </div>
                  <div className=" flex flex-col w-full mt-3">
                    <label className="text-sm font-medium text-[#212121] mb-2">
                      Habitaciones
                    </label>
                    {detailProject?.filtersSettings?.by_bedrooms?.length >
                      0 && (
                      <FormControl className="w-full" size="small">
                        <Controller
                          name="bedrooms"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={value}
                              onChange={onChange}
                              defaultValue={getValues("bedrooms")}
                            >
                              {detailProject?.filtersSettings?.by_bedrooms?.map(
                                (option: any, index: number) => (
                                  <MenuItem key={index} value={option}>
                                    {option}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          )}
                        />
                      </FormControl>
                    )}
                  </div>
                  <div className="flex flex-col w-full justify-center items-center">
                    <button
                      onClick={clearFilter}
                      className=" text-sm lg:text-base font-medium text-primary"
                    >
                      Limpiar filtros
                    </button>
                  </div>
                </div>
              </aside>

              <main className="col-span-6 px-4 md:px-0">
                <div className="w-full h-full">
                  {isLoadingUnit ? (
                    <>Cargando...</>
                  ) : (
                    <>
                      {dataDetailUnit?.length > 0 ? (
                        <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 ">
                          {dataDetailUnit?.map((unit: any, index: number) => (
                            <div key={index} className="w-full lg:px-2 py-3">
                              <CardPro
                                area={unit?.area}
                                base_price={unit?.base_price}
                                bathrooms={unit?.bathrooms}
                                bedrooms={unit?.bedrooms}
                                cover={unit?.cover}
                                id={unit?.id}
                                name={unit?.name}
                                status={unit?.status}
                                landlordInfo={unit?.landlordInfo || null}
                                pre_booking_date={unit?.pre_booking_date}
                                pre_booking_disable_at={
                                  unit?.pre_booking_disable_at
                                }
                                price_by_area={unit?.price_by_area}
                                project_supports_multiple_bookings={
                                  unit?.project_supports_multiple_bookings
                                }
                                landlords_booked={unit?.landlords_booked}
                                idProject={detailProject?.id}
                              />
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="w-full flex justify-center items-center lg:py-0 py-20 h-full">
                          <div className="max-w-md mx-auto">
                            <img
                              className="mx-auto w-64"
                              src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/not-result.svg"
                              alt="not-found"
                            />
                            <p className="font-semibold text-lg text-[#212121] text-center mt-4">
                              Lo sentimos, parece ser que no contamos con
                              unidades con las características seleccionadas.
                            </p>
                            <p className="text-center font-medium text-base text-[#757575]">
                              Prueba ajustando algunos filtros
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                {dataDetailUnit?.length > 0 && (
                  <div className="flex justify-end py-2 px-4">
                    <button
                      onClick={() =>
                        dispatch(
                          setFiltersProjectsAction({ page: paginator.page - 1 })
                        )
                      }
                      disabled={!paginator.hasPreviousPage}
                      className={`pr-2 cursor-pointer text-sm font-medium text-blueForm hover:underline ${
                        !paginator.hasPreviousPage
                          ? "opacity-40 cursor-not-allowed"
                          : ""
                      } `}
                    >
                      Anterior
                    </button>
                    <span className=" bg-primary h-8 w-8 flex justify-center items-center text-white font-medium rounded-full mx-4">
                      {paginator.page}
                    </span>
                    <button
                      onClick={() =>
                        dispatch(
                          setFiltersProjectsAction({ page: paginator.page + 1 })
                        )
                      }
                      disabled={!paginator.hasNextPage}
                      className={`pr-2 cursor-pointer text-sm font-medium text-blueForm hover:underline ${
                        !paginator.hasNextPage
                          ? "opacity-40 cursor-not-allowed"
                          : ""
                      }`}
                    >
                      Siguiente
                    </button>
                  </div>
                )}
              </main>
            </div>
          </div>
        </div>
      </div>
    </MainTemplate>
  );
});

ProjectDetail.displayName = "ProjectDetail";
export default ProjectDetail;
