import React, { useState, useEffect } from 'react';

interface ColorfulNameProps {
  firstName: string;
  lastName: string;
}

const ColorfulNameComponent: React.FC<ColorfulNameProps> = ({ firstName, lastName }) => {
  // Array de colores disponibles
  const colors: string[] = ['#DB4437', '#F4B400', '#0F9D58', '#4285F4', '#1F70C1', '#AA66CC'];

  // Obtener el color asignado del almacenamiento local
  const savedColor = localStorage.getItem('nameColor');
  const [color, setColor] = useState(savedColor || getRandomColor());

  // Función para seleccionar un color aleatorio del array
  function getRandomColor(): string {
    return colors[Math.floor(Math.random() * colors.length)];
  }

  // Almacenar el color asignado en el almacenamiento local
  useEffect(() => {
    localStorage.setItem('nameColor', color);
  }, [color]);

  // Estilo dinámico para el componente
  const style: React.CSSProperties = {
    backgroundColor: color,
    color: 'white',
    borderRadius: '50%',
    fontWeight: 'bold',
  };

  // Extraer la primera letra del nombre y del apellido
  const initials: string = `${firstName?.charAt(0).toUpperCase()}${lastName?.charAt(0).toUpperCase()}`;

  return (
    <div style={style} className='h-7 w-7 md:h-6 md:w-6 flex justify-center items-center'>
      <p className='text-xs'>{initials}</p>
    </div>
  );
};

export default ColorfulNameComponent;
