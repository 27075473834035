const stringCharacters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export const generateRandomString = (length: number = 10) => {
  let result = "";
  let validCharacters = stringCharacters;
  const charactersLength = validCharacters.length;
  for (let i = 0; i < length; i++) {
    result += validCharacters.charAt(
      Math.floor(Math.random() * charactersLength)
    );
  }
  return result;
};
