import React, { useState } from "react";
import Card from "../Card/Card";
import { PinContainer, Bubble, PriceContainer } from "./styles";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { Box, Modal } from "@mui/material";
import CardProperty from "../../CardProperty/Card";
import { useSelector } from "react-redux";
import { AppStore } from "../../../redux/store";
import './Pin.scss'

const Pin: React.FC<any> = ({
  property,
  setShowModal,
  invertColor = false,
}) => {
  const { country } = useSelector((state: AppStore) => state.country);
  const filters = useSelector((store: AppStore) => store.filters);
  const { width } = useWindowDimensions();
  const [open, setOpen] = useState(false);
  const [visibleCard, setVisibleCard] = useState(false);
  const isDesktop = width > 992;
  const handleClose = () => setOpen(false);
  const showProperty = () => (isDesktop ? setVisibleCard(true) : setOpen(true));

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    p: 3,
    height: "auto",
    bgcolor: "white",
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
    border: "none",
    borderColor: "transparent",
    outline: "none",
    ".card": {
      width: "100%",
    },
  };

  return (
    <>
      <PinContainer>
        {visibleCard && isDesktop ? (
          <Card // Only view in desktop
            property={property}
            clickOutside={(visible: any) => setVisibleCard(visible)}
            path={
              property.url_detail && property.property_code
                ? filters?.marketplace_type === "for_sale"
                  ? `/${country}/venta/${property.url_detail}/${property.property_code}`
                  : `/${country}/alquiler/${property.url_detail}/${property.property_code}`
                : `/${country}/marketplace/detail/${property.property_id}`
            }
            openInOtherTab
          />
        ) : (
          <Bubble
            onClick={showProperty}
            onTouchStart={showProperty}
            className={`${
              filters?.marketplace_type === "for_sale"
                ? "bg-orange pinSale"
                : "bg-primary pinRent"
            }`}
          >
            <PriceContainer>{property.property_price}</PriceContainer>
          </Bubble>
        )}
      </PinContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
      >
        <Box sx={style}>
          <CardProperty
            property_status={property?.property_status as string}
            property_name={property?.property_name as string}
            gallery={property?.gallery}
            property_city={property?.property_city as string}
            property_code={property?.property_code as number}
            property_id={property?.property_id as string}
            property_price={property?.property_price as string}
            property_type={property?.property_type as string}
            state_name={property?.state_name as string}
            characteristics={property?.characteristics}
            url_detail={property?.url_detail}
            property_old_price={property?.property_old_price}
            exchange_property_price={
              property?.exchange_property_price
            }
            property_price_currency_symbol={
              property?.property_price_currency_symbol
            }
            exchange_property_old_price={
              property?.exchange_property_old_price
            }
            marketplace_type={filters?.marketplace_type}
            property_type_value={property?.property_type_value}
            
          />
        </Box>
      </Modal>
    </>
  );
};

export default Pin;
