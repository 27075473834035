import InputMask from "react-input-mask";

interface Props {
  register: any;
  name: string;
  mask: string;
  ref: any;
  placeholder?: string;
  customClass?: string
}

const MaskInput = (props: Props) => {
  const { 
    register, 
    name, 
    mask, 
    ref, 
    placeholder, 
    customClass = 'w-full border py-3 px-2 flex border-grayForm rounded-md items-center bg-white' 
  } = props;
  return (
    <div className="">
      <InputMask
        {...register(name, { required: true })}
        className={customClass}
        mask={mask}
        inputRef={ref}
        placeholder={placeholder}
        alwaysShowMask={false}
        maskChar=''
      />
    </div>
  );
};

export default MaskInput;
