import React, { useCallback, useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { MAP_STYLE_POLYGON } from "../../constants/MapConstants";
import { renderToStaticMarkup } from "react-dom/server";
import RainbowVis from "../../pages/PolygonMap/rainbowVis";
import { useDispatch, useSelector } from "react-redux";
import { setLevelsAction } from "../../redux/features/levelsMap";
import { CSSTransition } from "react-transition-group";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

import logoPropi from "../../assets/icons/logo_propi_white.svg";

import "./PolygonMap.scss";
import { IconMapDollar, IconMapUp, IconX } from "@tabler/icons-react";
import { Box, SwipeableDrawer } from "@mui/material";
import { AppStore } from "../../redux/store";
import { PolygonApiResponse } from "../../pages/PolygonMap/models/polygonModel";
import {
  setFollowUpAction,
} from "../../redux/features/polygonsUserFollowUp";

export interface GeoPoint {
  lat: number;
  lng: number;
}

type Anchor = "top" | "left" | "bottom" | "right";

export interface Props {
  zoom: number;
  center: GeoPoint;
  polygons: PolygonApiResponse[];
  loading: boolean;
  prices: {
    max_price: string;
    min_price: string;
  };
}

const PolygonMap: React.FC<Props> = ({
  zoom,
  center,
  polygons,
  loading,
  prices,
}) => {
  const { parent } = useSelector((store: AppStore) => store.levelsMap);
  const { country } = useSelector((store: AppStore) => store.country)
  const [polygonClicked, setPolygonClicked] = useState(false);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const mapContainerStyles = {
    width: "100%",
    height: "100%",
  };

  const mapOptions = {
    zoom,
    center,
    streetViewControl: false,
    mapTypeControl: true,
    mapTypeControlOptions: {
      position: window?.google?.maps?.ControlPosition?.RIGHT_BOTTOM, // Mueve el control a la esquina inferior derecha
    },
    gestureHandling: "greedy",
    styles: MAP_STYLE_POLYGON,
    maxZoom: 18,
    minZoom: 9,
    fullscreenControl: false,
    clickableIcons: false,
  };

  const [centerState, setCenterState] = useState<GeoPoint>(center);
  const [zoomState, setZoomState] = useState<number>(zoom);
  const mapRef = useRef();
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
  const [oldMapPolygon, setOldMapPolygon] = useState<Array<any>>([]);
  const [selectedPolygon, setSelectedPolygon] = useState<any>({});
  const [lastPolygon, setLastPolygon] = useState<any>({});

  const [polygonHistory, setPolygonHistory] = useState<any[]>([]);

  //Asignar colores segun precio
  const getColorForMidPrice = (midPriceValue: any) => {
    // Define tu rango y colores aquí
    const colorRange = [
      { value: 300, color: "#D5F0E3" },
      { value: 600, color: "#ACE2C7" },
      { value: 900, color: "#82D3AA" },
      { value: 1200, color: "#59C58E" },
      { value: 1500, color: "#2FB672" },
      { value: 1800, color: "#26925B" },
      { value: 2200, color: "#1C6D44" },
      //{ value: 2700, color: "#092417" },
    ];

    const colorObject = colorRange.find(
      (range: any) => midPriceValue <= range.value
    );

    return colorObject
      ? colorObject.color
      : colorRange[colorRange.length - 1].color;
  };
  // Constructor de poligonos
  const buildPolygons = useCallback(
    (map: any) => {
      if (!isGoogleMapsLoaded || polygons.length <= 0) {
        return;
      }
      oldMapPolygon.forEach((p) => {
        p.setMap(null);
      });
      let polygonsSorted: Array<any>;
      let rainbow: any;
      if (polygons.length > 1) {
        rainbow = new RainbowVis();
        rainbow.setNumberRange(1, polygons.length);
        polygonsSorted = polygons.sort((poly1, poly2) => {
          if (
            poly1.display_info.mid_price_value ===
            poly2.display_info.mid_price_value
          )
            return 0;

          if (
            poly1.display_info.mid_price_value <
            poly2.display_info.mid_price_value
          )
            return -1;

          return 1;
        });
      } else {
        polygonsSorted = polygons;
      }

      // getPolygons()
      const polygonsOnMap = polygonsSorted.map((polygon, index) => {
        let coordinates = polygon?.geo_points?.coordinates[0];
        let coorNew: GeoPoint[] = [];

        coordinates?.map((coordinate: any) =>
          coorNew.push({
            lat: coordinate[1],
            lng: coordinate[0],
          })
        );

        const mapPolygon = new google.maps.Polygon({
          paths: coorNew,
          strokeColor: "#212121",
          strokeOpacity: 1.0,
          strokeWeight: 1.0,
          fillColor:
            polygon?.display_info?.property_type === "non_habitational"
              ? "#BBDEFB"
              : polygon?.display_info?.property_type === "commercial"
              ? "#B0BEC5"
              : polygon?.excludePaint
              ? "#E0E0E0"
              : getColorForMidPrice(polygon.display_info.mid_price_value),
          fillOpacity: 0.6,
          map: mapRef.current,
        });

        const infoWindow = new google.maps.InfoWindow();

        if (width > 1000) {
          mapPolygon.addListener("click", function (event: any) {
            infoWindow.close();
            setPolygonHistory((prevHsitory) => [...prevHsitory, polygon]);
            setLastPolygon(polygon);
            setZoomState(polygon?.display_info?.zoom);

            setCenterState(polygon?.display_info?.center);
            setPolygonClicked(true);
            setShowMessage(false);
            const nextLevel = polygon?.level + 1;
            if (nextLevel >= 4) {
              dispatch(
                setLevelsAction({
                  level: polygon?.level,
                  parent: polygon?.id,
                  excludePolygon: polygon?.id,
                  showCombination: 1,
                  back: 0,
                })
              );
            } else {
              dispatch(setLevelsAction({ parent: polygon?.id }));
            }
            // capture levels on clicking process
            dispatch(setFollowUpAction({ current_parent_id: polygon?.id }));
          });
        } else {
          mapPolygon.addListener("click", function (event: any) {
            const dataPol: any = {
              name: polygon?.name,
              mid_price: polygon.display_info.mid_price,
              zoom: polygon?.display_info?.zoom,
              center: polygon?.display_info?.center,
              level: polygon?.level,
              parent: polygon?.id,
              excludePolygon: polygon?.id,
              type: polygon?.display_info?.property_type,
              mid_price_value: polygon?.display_info?.mid_price_value,
              area: polygon?.display_info?.area,
            };
            setPolygonHistory((prevHsitory) => [...prevHsitory, polygon]);
            setSelectedPolygon(dataPol);
            toggleDrawerDetail("bottom", true);
            setStateDetail({ ...stateDetail, bottom: true });
            setLastPolygon(polygon);
            // capture levels on clicking process
            dispatch(setFollowUpAction({ current_parent_id: polygon?.id }));
          });
        }

        mapPolygon.addListener("mousemove", function (event: any) {
          const cursorPosition = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };

          const infoWindowPosition = {
            lat: cursorPosition.lat, // Convertir OFFSET_Y a grados aproximados
            lng: cursorPosition.lng, // Convertir OFFSET_X a grados aproximados
          };

          const propertyValue =
            polygon?.display_info?.mid_price_value *
            polygon?.display_info?.area;

          let content = (
            <div className="bg-transparent">
              <h2 className="text-lg font-semibold text-[#212121] mb-1 leading-10">
                {polygon.name}
              </h2>
              {polygon?.display_info?.property_type !== "commercial" &&
                polygon?.display_info?.property_type !== "non_habitational" && (
                  <>
                    <hr className="border-t border-[#E0E0E0]" />
                    <p className="text-base font-normal text-[#757575] mt-1 flex justify-between items-center">
                      Valor promedio m²:{" "}
                      <span className="font-bold ml-8">
                        {polygon.display_info.mid_price}
                      </span>
                    </p>
                    <p className="text-base font-normal text-[#757575] mt-1 flex justify-between items-center">
                      Valor promedio propiedad:{" "}
                      <span className="font-bold ml-8">
                      ${propertyValue.toLocaleString()}
                      </span>
                    </p>
                  </>
                )}
            </div>
          );

          infoWindow.setContent(renderToStaticMarkup(content));
          infoWindow.setPosition(infoWindowPosition);
          infoWindow.open(mapRef.current);
          mapPolygon.setOptions({ fillOpacity: 0.8 });
        });
        mapPolygon.addListener("mouseout", function (event: any) {
          infoWindow.close();
          mapPolygon.setOptions({ fillOpacity: 0.6 });
        });

        return mapPolygon;
      });

      setOldMapPolygon(polygonsOnMap);
    },
    [isGoogleMapsLoaded, polygons]
  );

  useEffect(() => {
    buildPolygons(mapRef.current);
  }, [buildPolygons]);

  // change zoom
  const handleZoomChange = (newZoom: any) => {
    setZoomState(newZoom); // Actualiza el estado si es necesario
    handleZoom(newZoom); // Pasa el zoom a la función handleZoom
    // Otros procesamientos que desees realizar con el zoom actual
  };

  const handleZoom = (zoomito: any) => {
    if (zoomito === 11) {
      dispatch(setLevelsAction({ level: 2, back: 0 }));
    } else if (zoomito === 12) {
      dispatch(setLevelsAction({ level: 2, back: 0 }));
    } else if (zoomito === 13) {
      dispatch(setLevelsAction({ level: 3, back: 0 }));
    } else if (zoomito === 14) {
      dispatch(setLevelsAction({ level: 4, back: 0 }));
    } else if (zoomito === 15) {
      dispatch(setLevelsAction({ level: 4, back: 0 }));
    } else if (zoomito === 16) {
      dispatch(setLevelsAction({ level: 4, back: 0 }));
    } else if (zoomito === 17) {
      dispatch(setLevelsAction({ level: 5, back: 0 }));
    } else if (zoomito === 18) {
      dispatch(setLevelsAction({ level: 5, back: 0 }));
    } else if (zoomito === 9) {
      dispatch(setLevelsAction({ level: 1, back: 0 }));
    } else if (zoomito === 10) {
      dispatch(setLevelsAction({ level: 1, back: 0 }));
    }
  };

  const favsProvinces = [
    {
      name: "San Salvador",
      id: 44,
      level: 1,
      parent_id: null,
      zoom: 11,
      parent: 44,
      center: {
        lat: 13.735626,
        lng: -89.158837,
      },
      cover:
        "https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/estimate/San_Salvador.jpg",
    },
    {
      name: "La Libertad",
      id: 33,
      level: 1,
      parent_id: null,
      zoom: 11,
      parent: 33,
      center: {
        lat: 13.744003,
        lng: -89.375134,
      },
      cover:
        "https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/estimate/La_Libertad.webp",
    },
    {
      name: "Santa Ana",
      id: 43,
      level: 1,
      parent_id: null,
      zoom: 11,
      parent: 43,
      center: {
        lat: 14.124126,
        lng: -89.470539,
      },
      cover:
        "https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/estimate/Santa_Ana.jpg",
    },
    {
      name: "San Miguel",
      id: 38,
      level: 1,
      parent_id: null,
      zoom: 11,
      parent: 38,
      center: {
        lat: 13.508436,
        lng: -88.217053,
      },
      cover:
        "https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/estimate/San_Miguel.jpg",
    },
  ];
  const favsProvincesGT = [
    {
      name: "Ciudad de Guatemala",
      id: 44,
      level: 1,
      parent_id: null,
      zoom: 11,
      parent: 44,
      center: {
        lat: 14.6262164,
        lng: -90.5337598,
      },
      cover:
        "https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/estimate/ciudadgt.jpg",
    },
    {
      name: "Antigua Guatemala",
      id: 33,
      level: 1,
      parent_id: null,
      zoom: 11,
      parent: 33,
      center: {
        lat: 14.559144,
        lng: -90.7441199,
      },
      cover:
        "https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/estimate/Antigua-Guatemala.jpg",
    },
    {
      name: "Quetzaltenango",
      id: 43,
      level: 1,
      parent_id: null,
      zoom: 11,
      parent: 43,
      center: {
        lat: 14.8390693,
        lng: -91.5621969,
      },
      cover:
        "https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/estimate/quetzaltenango.webp",
    },
    {
      name: "Escuintla",
      id: 38,
      level: 1,
      parent_id: null,
      zoom: 11,
      parent: 38,
      center: {
        lat: 14.2997404,
        lng: -90.7955672,
      },
      cover:
        "https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/estimate/escuintla.jpeg",
    },
  ];

  const goToZoomCenterMap = (
    zoomDestiny: any,
    centerDestiny: any,
    polygonData: any
  ) => {
    setZoomState(zoomDestiny);
    setCenterState(centerDestiny);

    setPolygonClicked(true);
    const nextLevel = polygonData?.level + 1;
    if (nextLevel >= 4) {
      dispatch(
        setLevelsAction({
          level: polygonData?.level,
          parent: polygonData?.parent,
          excludePolygon: polygonData?.excludePolygon,
          showCombination: 1,
        })
      );
    } else {
      dispatch(setLevelsAction({ parent: polygonData?.parent }));
    }
  };

  const handleZoomAnimationEnd = (map: any) => {
    if (!polygonClicked) {
      const newZoom = map?.getZoom();
      handleZoomChange(newZoom);
      dispatch(
        setLevelsAction({
          showCombination: 0,
        })
      );
    }

    // Restablecer el estado
    setPolygonClicked(false);
  };

  const goToLastPolygon = (polygon: any) => {
    if (polygonHistory.length > 0) {
      const lastIndex = polygonHistory.length - 1;
      // Obtén el último polígono del historial
      const lastPolygon = polygonHistory[lastIndex];

      setZoomState(polygon?.display_info?.zoom);
      setCenterState(polygon?.display_info?.center);

      setPolygonHistory((prevHistory) => prevHistory.slice(0, -1));

      const nextLevel = lastPolygon?.level + 1;

      if (lastPolygon?.level === 1) {
        handleZoomChange(9);
        dispatch(
          setLevelsAction({
            back: 1,
            showCombination: 0,
            level: 1,
            parent: null,
            excludePolygon: 0,
          })
        );
        setPolygonClicked(false);
      } else {
        setPolygonClicked(true);
        setZoomState(lastPolygon?.display_info?.zoom - 2);
        if (nextLevel <= 4) {
          dispatch(
            setLevelsAction({
              parent: lastPolygon?.parent_id,
              showCombination: 0,
            })
          );
        } else {
          dispatch(
            setLevelsAction({
              level: lastPolygon?.level,
              parent: lastPolygon?.parent_id,
              excludePolygon: lastPolygon?.parent_id,
              showCombination: 1,
            })
          );
        }
      }
      return lastPolygon;
    }
    return null;
  };

  // Menu

  const [showButton, setShowButton] = useState(width > 1200 ? false : true);
  const [showMessage, setShowMessage] = useState(width > 1200 ? true : false);
  const nodeRef = useRef(null);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [stateDetail, setStateDetail] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const toggleDrawerDetail =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent | React.TouchEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setStateDetail({ ...stateDetail, [anchor]: open });
    };
  // List component to show info
  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: "100%",
        paddingLeft: 3,
        paddingRight: 3,
        paddingTop: 4,
        paddingBottom: 4,
        height: 600,
        overflowY: "auto",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className=" title-midprice w-full">
        <h1
          className="text-xl font-semibold text-left"
          style={{ letterSpacing: "0px" }}
        >
          Mapa de valores por zona
        </h1>
      </div>
      <hr className="my-4" />
      <div className="mb-2">
        <h1
          className="text-lg font-semibold text-left"
          style={{ letterSpacing: "0px" }}
        >
          Ver precios en
        </h1>
        <div className="grid grid-cols-1 gap-4 mt-4">
          {country === 'sv' && favsProvinces?.map((provice: any, index: number) => (
            <div
              onClick={() => {
                goToZoomCenterMap(provice?.zoom, provice?.center, provice);
              }}
              className="h-[150px] w-full cursor-pointer group flex justify-center items-center rounded-lg relative overflow-hidden aspect-video"
            >
              <img
                loading="lazy"
                height="150"
                className=" h-full w-full object-cover object-center transition-transform group-hover:scale-105"
                src={provice?.cover}
                alt={provice?.name}
              />
              <div className="absolute inset-0 flex h-full w-full items-center justify-center bg-[rgba(0,0,0,0.5)] text-xl font-medium text-white">
                <h2 className="text-base font-medium">{provice?.name}</h2>
              </div>
            </div>
          ))}
          {country === 'gt' && favsProvincesGT?.map((provice: any, index: number) => (
            <div
              onClick={() => {
                goToZoomCenterMap(provice?.zoom, provice?.center, provice);
              }}
              className="h-[150px] w-full cursor-pointer group flex justify-center items-center rounded-lg relative overflow-hidden aspect-video"
            >
              <img
                loading="lazy"
                height="150"
                className=" h-full w-full object-cover object-center transition-transform group-hover:scale-105"
                src={provice?.cover}
                alt={provice?.name}
              />
              <div className="absolute inset-0 flex h-full w-full items-center justify-center bg-[rgba(0,0,0,0.5)] text-xl font-medium text-white">
                <h2 className="text-base font-medium">{provice?.name}</h2>
              </div>
            </div>
          ))}
        </div>
      </div>
      <hr className="my-4" />
      <div className="w-full ">
        <h1
          className="text-lg font-semibold text-left"
          style={{ letterSpacing: "0px" }}
        >
          Avalua tu propiedad
        </h1>
        <p className="text-sm font-normal text-[#757575] mt-2">
          Propi te asegura el valor más preciso de mercado. Completa el
          formulario con tus datos y nuestro equipo se pondrá en contacto
          contigo.
        </p>
        <a
          href="https://propilatam.typeform.com/to/j2pWvd5Y?typeform-source=www.propilatam.com"
          target="_blank"
          className=" text-[#212121] bg-white border border-[#212121] hover:bg-[#212121] transition hover:text-white w-full py-2 rounded-lg mt-4 text-sm font-medium flex justify-center items-center"
          rel="noreferrer"
        >
          Solicitar avalúo
        </a>
      </div>
      <div className="mt-10 flex flex-wrap items-center justify-center">
        <img
          className="w-32"
          src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/v2/logo-propi.svg"
          alt="logo"
        />
        {
          country === "sv" && <img
          className="w-32"
          src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/estimate/geoterra.jpeg"
          alt="logo"
        />
        }
        
        <div className="w-full mt-4">
          <p className="text-[#212121] text-sm mt-2">
            Desarrollado por Propi Tech
          </p>
        </div>
      </div>
    </Box>
  );

  return (
    <div className="container-map relative" style={mapContainerStyles}>
      {!loading && (
        <>
          {parent && (
            <div className="absolute right-16 lg:right-5 bottom-6 lg:top-5 z-10">
              <button
                onClick={() => goToLastPolygon(lastPolygon)}
                className="block bg-[#212121] rounded-lg py-4 px-2 w-24"
              >
                <IconMapUp className="mx-auto" color="#fff" />
                <p className="text-sm text-white text-center leading-none mt-1">
                  Sector anterior
                </p>
              </button>
            </div>
          )}
        </>
      )}
      {showButton && (
        <div className="relative">
          <div className="propi-sphere-normal top-5 left-5">
            <div className="logo">
              <div className="circle-wrap">
                <div className="circle c1" />
                <div className="circle c2" />
                <div className="circle c3" />
                <div className="circle c4" />
                <div className="circle c5" />
                <div className="circle c6" />
                <div className="circle c7" />
              </div>
              <img src={logoPropi} alt="logo" className="icon" />
            </div>
          </div>
          <button
            onClick={() => setShowMessage(true)}
            className=" bg-[#212121] px-4 py-2 rounded-full hidden lg:flex justify-center items-center text-white text-sm font-normal hover:bg-primary absolute z-20 top-5 left-[70px]"
          >
            <IconMapDollar color="#fff" className="mr-2" />
            Mapa de valores
          </button>
          <button
            onClick={toggleDrawer("bottom", true)}
            className=" bg-[#212121] px-4 py-2 rounded-full flex lg:hidden justify-center items-center text-white text-sm font-normal hover:bg-primary absolute z-20 top-5 left-[70px]"
          >
            <IconMapDollar color="#fff" className="mr-2" />
            Mapa de valores
          </button>
          <div className=" range-midprice w-[235px] lg:w-[300px] mt-4 top-14 absolute left-5 z-10 bg-white px-2 py-3 rounded-lg shadow-sm">
            <p className=" text-sm font-semibold text-[#212121] mb-2">
              Valor promedio m²
            </p>
            <div className="rounded-lg py-2 w-full bg-gradient-prices" />
            <div className="w-full flex justify-between items-center mt-2">
              <p className="text-xs lg:text-sm font-medium text-[#757575]">
                {prices?.min_price}
              </p>
              <p className="text-xs lg:text-sm font-medium text-[#757575]">
                {prices?.max_price}
              </p>
            </div>
          </div>
        </div>
      )}
      <CSSTransition
        in={showMessage}
        nodeRef={nodeRef}
        timeout={300}
        classNames="alert"
        unmountOnExit
        onEnter={() => setShowButton(false)}
        onExited={() => setShowButton(true)}
      >
        <div className="card_polygons relative " ref={nodeRef}>
          <button
            className="focus:outline-none border-none right-5 absolute top-5"
            style={{ zIndex: "1000" }}
            onClick={() => setShowMessage(false)}
          >
            <IconX color="#212121" />
          </button>
          <div className="card_polygons_inner">
            <div className="propi-sphere">
              <div className="logo">
                <div className="circle-wrap">
                  <div className="circle c1" />
                  <div className="circle c2" />
                  <div className="circle c3" />
                  <div className="circle c4" />
                  <div className="circle c5" />
                  <div className="circle c6" />
                  <div className="circle c7" />
                </div>
                <img src={logoPropi} alt="logo" className="icon" />
              </div>
            </div>
            <div className="card_polygons_background">
              <div className=" title-midprice w-full">
                <h1
                  className="text-xl font-semibold text-left"
                  style={{ letterSpacing: "0px" }}
                >
                  Mapa de valores por zona
                </h1>
              </div>
              <hr className="my-2" />
              <div className="mb-2">
                <h1
                  className="text-lg font-semibold text-left"
                  style={{ letterSpacing: "0px" }}
                >
                  Ver precios en
                </h1>
                <div className="grid grid-cols-1 gap-4 mt-4">
                {country === 'sv' && favsProvinces?.map((provice: any, index: number) => (
            <div
              onClick={() => {
                goToZoomCenterMap(provice?.zoom, provice?.center, provice);
              }}
              className="h-[150px] w-full cursor-pointer group flex justify-center items-center rounded-lg relative overflow-hidden aspect-video"
            >
              <img
                loading="lazy"
                height="150"
                className=" h-full w-full object-cover object-center transition-transform group-hover:scale-105"
                src={provice?.cover}
                alt={provice?.name}
              />
              <div className="absolute inset-0 flex h-full w-full items-center justify-center bg-[rgba(0,0,0,0.5)] text-xl font-medium text-white">
                <h2 className="text-base font-medium">{provice?.name}</h2>
              </div>
            </div>
          ))}
          {country === 'gt' && favsProvincesGT?.map((provice: any, index: number) => (
            <div
              onClick={() => {
                goToZoomCenterMap(provice?.zoom, provice?.center, provice);
              }}
              className="h-[150px] w-full cursor-pointer group flex justify-center items-center rounded-lg relative overflow-hidden aspect-video"
            >
              <img
                loading="lazy"
                height="150"
                className=" h-full w-full object-cover object-center transition-transform group-hover:scale-105"
                src={provice?.cover}
                alt={provice?.name}
              />
              <div className="absolute inset-0 flex h-full w-full items-center justify-center bg-[rgba(0,0,0,0.3)] text-xl font-medium text-white">
                <h2 className="text-base font-medium">{provice?.name}</h2>
              </div>
            </div>
          ))}
                </div>
              </div>
              <hr className="my-4" />
              <div className="w-full ">
                <h1
                  className="text-lg font-semibold text-left"
                  style={{ letterSpacing: "0px" }}
                >
                  Avalua tu propiedad
                </h1>
                <p className="text-sm font-normal text-[#757575] mt-2">
                  Propi te asegura el valor más preciso de mercado. Completa el
                  formulario con tus datos y nuestro equipo se pondrá en
                  contacto contigo.
                </p>
                <a
                  href="https://propilatam.typeform.com/to/j2pWvd5Y?typeform-source=www.propilatam.com"
                  target="_blank"
                  className=" text-[#212121] bg-white border border-[#212121] hover:bg-[#212121] transition hover:text-white w-full py-2 rounded-lg mt-4 text-sm font-medium flex justify-center items-center"
                  rel="noreferrer"
                >
                  Solicitar avalúo
                </a>
              </div>
              <div className="mt-10 flex flex-wrap items-center justify-center ">
                <img
                  className="w-32"
                  src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/v2/logo-propi.svg"
                  alt="logo"
                />
                {
          country === "sv" && <img
                  className="w-20 ml-6"
                  src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/estimate/geoterra.jpeg"
                  alt="logo"
                />
        }
                
                <div className="w-full mt-2">
                  <p className="text-[#212121] text-sm mt-2 text-center">
                    Desarrollado por Propi Tech { country === 'sv' && <>y Geoterra valuos</> } 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>

      {/*
       */}
      <GoogleMapReact
        bootstrapURLKeys={{
          key:
            process.env.REACT_APP_MAPS_KEY ??
            "AIzaSyB0c1nq-isvWJWWam5CICmPt0oYgY1A_eg",
        }}
        defaultCenter={center}
        center={centerState}
        defaultZoom={zoom}
        zoom={zoomState}
        options={mapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
          setIsGoogleMapsLoaded(true);
          map.addListener("tilesloaded", () => {
            // Mapa y azulejos cargados completamente
            buildPolygons(map);
            const bounds = map.getBounds();
            if (bounds) {
              const ne = bounds.getNorthEast();
              const sw = bounds.getSouthWest();
              dispatch(
                setLevelsAction({
                  ne_coordinate: `${ne.lng()},${ne.lat()}`,
                  sw_coordinate: `${sw.lng()},${sw.lat()}`,
                })
              );
            }
          });
        }}
        onZoomAnimationEnd={() => {
          handleZoomAnimationEnd(mapRef.current);
        }}
      />

      <SwipeableDrawer
        anchor={"bottom"}
        open={state["bottom"]}
        onClose={toggleDrawer("bottom", false)}
        onOpen={toggleDrawer("bottom", true)}
      >
        {list("bottom")}
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor={"bottom"}
        open={stateDetail["bottom"]}
        onClose={toggleDrawerDetail("bottom", false)}
        onOpen={toggleDrawerDetail("bottom", true)}
      >
        <Box
          sx={{
            width: "100%",
            paddingLeft: 3,
            paddingRight: 3,
            paddingTop: 4,
            paddingBottom: 4,
            height: "auto",
            overflowY: "auto",
          }}
          role="presentation"
          onClick={toggleDrawerDetail("bottom", false)}
          onKeyDown={toggleDrawerDetail("bottom", false)}
        >
          <div className="bg-transparent">
            <h2 className="text-lg font-semibold text-[#212121] ">
              {selectedPolygon?.name}
            </h2>
            {selectedPolygon?.type !== "commercial" &&
              selectedPolygon?.type !== "non_habitational" && (
                <>
                  <hr className="border-t border-[#E0E0E0] my-4" />
                  <p className="text-base font-normal text-[#757575] mt-1">
                    Valor promedio m²:{" "}
                    <span className="font-bold ml-8">
                      {selectedPolygon?.mid_price}
                    </span>
                  </p>
                  <p className="text-base font-normal text-[#757575] mt-1">
                    Valor promedio propiedad:{" "}
                    <span className="font-bold ml-8">${(
                        selectedPolygon?.mid_price_value * selectedPolygon?.area
                      ).toLocaleString()}
                    </span>
                  </p>
                  <hr className="border-t border-[#E0E0E0] my-4" />
                  <button
                    onClick={() =>
                      goToZoomCenterMap(
                        selectedPolygon?.zoom,
                        selectedPolygon?.center,
                        selectedPolygon
                      )
                    }
                    className="w-full bg-[#212121] px-4 py-3 rounded-lg flex justify-center items-center text-white text-sm font-normal hover:bg-primary"
                  >
                    Explorar ubicación
                  </button>
                </>
              )}
          </div>
        </Box>
      </SwipeableDrawer>
    </div>
  );
};

export default PolygonMap;
