import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import "./FormVisit.scss";
import Button from "../../../../components/Button";
import { getAuthToken } from "../../../../helpers/authHelper";
import useCallApiAndLoad from "../../../../hooks/useCallApiAndLoad";
import { AppStore } from "../../../../redux/store";
import {
  fetchResponseAdapter,
} from "../../../../adapters/fetchAdapter";
import FormControlError from "../../../../components/Controls/FormControlError";
import {
  getAvailableTimeVisitsEndpoint,
  rescheduleVisitEndpoint,
  scheduleVisitEndpoint,
} from "../../../../services/visitsService";
import {
  SaveScheduleVisitForm,
  UpdateScheduleVisitForm,
} from "../../../../models/visitModel";
import { useNavigate } from "react-router-dom";
import { useVisitDates } from "../../../Marketplace/components/VisitForm/hooks/useVisitsDates";

interface Props {
  onAction: () => void;
  id: string;
  isUpdate?: boolean | undefined;
  visit_id?: string | null;
}

interface formData {
  property_id: string;
  date: string;
  start_time: string;
}

const FormVisit = (props: Props) => {
  const {
    visitsDataProperty,
    getDataVisitsProperties,
  } = useVisitDates();
  const user = useSelector((store: AppStore) => store.auth);
  const { id, onAction, isUpdate = false, visit_id = "" } = props;
  const token = getAuthToken(user);
  const { callEndpoint } = useCallApiAndLoad();
  const [hoursList, setHoursList] = useState<Array<any>>([]);
  const [dateSelect, setDateSelect] = useState(new Date());
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<formData>({
    resolver: yupResolver(
      yup.object().shape({
        date: yup.string().required("Fecha de visita es requerida"),
        start_time: yup
          .string()
          .required("Hora de visita es requerida")
          .nullable(),
      })
    ),
  });

  useEffect(() => {
    setValue("property_id", props.id);
  }, []);

  useEffect(() => {
    getDataVisitsProperties(id);
  }, [id]);

  useEffect(() => {
    if (visitsDataProperty?.length > 0) {
      setValue("date", visitsDataProperty[0]?.date_value);

      const currentDate = getValues("date");

      const fetchDates = async () => {
        const { status, data } = await callEndpoint(
          getAvailableTimeVisitsEndpoint(id, currentDate, token)
        );
        if (status === 200) {
          const hoursResponse = fetchResponseAdapter(data);
          setHoursList(hoursResponse.data);
        }
      };
      fetchDates();
    }
  }, [visitsDataProperty]);

  const onChangeSelectDate = async (date: any) => {
    setDateSelect(date);
    const formatDate = dayjs(date).format("YYYY-MM-DD");
    const { status, data } = await callEndpoint(
      getAvailableTimeVisitsEndpoint(id, formatDate, token)
    );
    if (status === 200) {
      const hoursResponse = fetchResponseAdapter(data);
      setHoursList(hoursResponse.data);
    }
  };

  const doCreateVisit = async (form: formData) => {
    const saveScheduleVisit: SaveScheduleVisitForm = {
      property_id: form.property_id,
      date: dayjs(dateSelect).format("YYYY-MM-DD"),
      start_time: parseInt(form.start_time),
    };
    const { status } = await callEndpoint(
      scheduleVisitEndpoint(saveScheduleVisit, token)
    );
    if (status === 201) {
      onAction(); // close modal
      navigate(0);
    } else {
      setError("Lo sentimos, no es posible agendar una visita en ese momento");
    }
  };

  const doUpdateVisit = async (form: formData) => {
    const updateScheduleVisit: UpdateScheduleVisitForm = {
      date: dayjs(dateSelect).format("YYYY-MM-DD"),
      start_time: parseInt(form.start_time),
    };
    const { status } = await callEndpoint(
      rescheduleVisitEndpoint(updateScheduleVisit, visit_id || "", token)
    );
    if (status === 200) {
      onAction(); // close modal
      navigate("");
    } else {
      setError(
        "Lo sentimos, no es posible reagendar una visita en ese momento"
      );
    }
  };

  const sendData = async (form: formData) => {
    if (isUpdate) {
      await doUpdateVisit(form);
    } else {
      await doCreateVisit(form);
    }
  };

  return (
    <div className="form-visit w-full text-center bg-white py-6 px-6 max-w-lg relative">
      <h2 className="w-full mb-4 text-[#212121] text-2xl lg:text-2xl font-semibold text-left tracking-wide flex justify-start items-center">
        Reagendar visita
      </h2>
      <div className="bg-[#f5f5f5] px-4 py-4 mt-3 rounded-lg">
        <h2 className="text-lg text-[#212121] font-semibold text-left ">
          Selecciona una fecha
        </h2>
        <p className=" text-[#757575] text-sm text-left">
          Uno de nuestros propers se encargará de visitar y acompañarte en la
          fecha y hora que elijas
        </p>
      </div>

      <form onSubmit={handleSubmit(sendData)} className="relative mt-2">
        <div className="form-radio-vertical-visit grid grid-cols-7 py-4 gap-3 px-4">
          {visitsDataProperty?.length > 0 &&
            visitsDataProperty?.map((item: any, index: number) => (
              <div key={index} className="flex justify-center relative hour">
                <input
                  type="radio"
                  {...register("date")}
                  value={item.date_value as string}
                  id={`date-${item.date}`}
                  className=""
                  onChange={(e: any) => onChangeSelectDate(e.target.value)}
                />
                <label
                  className="py-3 text-base cursor-pointer flex flex-col w-full"
                  htmlFor={`date-${item.date}`}
                >
                  <p className="text-base font-normal ">{item.date}</p>
                  <p className="text-xl font-medium">{item.day}</p>
                </label>
              </div>
            ))}
        </div>

        <div className="form-radio grid grid-cols-4 lg:grid-cols-5 gap-3 pb-5 mx-auto flex-wrap">
          {hoursList?.length > 0 ? (
            <>
              {hoursList.length &&
                hoursList.map((data: any) => (
                  <div className="flex relative">
                    <input
                      {...register("start_time")}
                      type="radio"
                      name="start_time"
                      value={data.minutes}
                      id={`hour-${data.time_value}`}
                      disabled={data.is_blocked}
                    />
                    <label
                      htmlFor={`hour-${data.time_value}`}
                      className="py-1 px-6 w-full cursor-pointer flex justify-center text-sm"
                    >
                      {data.time}
                    </label>
                  </div>
                ))}
            </>
          ) : (
            <p className="text-grayText text-center text-base w-full">
              Debes seleccionar una fecha
            </p>
          )}
        </div>
        <FormControlError message={errors.date?.message} />
        <FormControlError message={errors.start_time?.message} />
        <FormControlError message={error} />
        <div className="bg-white w-full">
          <div className="w-full grid grid-cols-2 gap-6 mt-1">
            <button>
              <div
                onClick={onAction}
                className="border border-[#212121] rounded-lg px-4 py-2 text-[#212121] hover:bg-[#212121] hover:text-white duration-100"
              >
                Cancelar
              </div>
            </button>
            <Button
              type="submit"
              className="px-4 py-2 rounded-lg bg-[#212121] hover:bg-primary duration-100 text-white"
            >
              {isUpdate ? "Reagendar" : "Agendar"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default FormVisit;
