import { currencyInputFormat } from "../../helpers/currencyHelper";

interface Props {
    register: any;
    name: string;
    thousand_separator: string;
    decimal_separator: string;
    currency: string;
    placeholder?: string;
}

const CurrencyInput = (props: Props) => {
    const {
        register,
        name,
        thousand_separator,
        decimal_separator,
        currency,
        placeholder
    } = props
    return (
        <div className="w-full focus:outline-none border border-grayForm rounded-md text-[#757575] py-2 px-2 flex items-center">
            
            <input
                type="text"
                autofocus
                placeholder={placeholder}
                className="border-none focus:ring-0 w-full focus:outline-none focus:border-none"
                {...register(name, {
                    onChange: (e: any) => 
                        currencyInputFormat(e, thousand_separator, decimal_separator)
                })}
            />
        </div>
    )
}

export default CurrencyInput
