export const fetchRequestAdapter = (request: any) => ({
    status: request.status,
    statusText: request.statusText,
    data: request.data
})

export const fetchResponseAdapter = (response: any) => ({
    code: response.code,
    message: response.message,
    data: response.data,
    metadata: response?.metadata
})