import styled from "styled-components";

export const CardContainer = styled.a`
  height: auto;
  width: 100%;
  position: relative;
  background-color: white;
    border-radius: 10px;
    box-shadow: 0 6px 20px rgba(0,0,0,0.2);
    display: block;
    overflow: unset;
    position: relative;
    left: -70px;
    height: auto;
    width: 327px;
    z-index:1;
  @media (max-width: 992px) {
    width: 200px;
  }
`;

export const ContainerSliderDefault = styled.div`
  align-items: center;
  background-color: var(--color-grey);
  border-radius: 10px 10px 0 0;
  display: flex;
  height: 212px;
  width: 327px;
  justify-content: center;
  overflow: hidden;

  @media (min-width: 768px) and (max-width: 992px) {
    height: 200px;
  }

`;

export const CharacteristicsContainer = styled.div`
  cursor: pointer;
  overflow: hidden;

  h1 {
    font-family: "Mena Grotesk" !important;
  }

  p {
    font-family: 'Nunito', sans-serif!important;
  }
`;

export const PriceContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
`;

export const Price = styled.h2`
  position: relative;
  margin: 0;
  color: var(--color-dark-contrast);
`;

export const Title = styled.h2`
  color: var(--color-dark);
  font-size: 16px;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 207px;
`;

export const Details = styled.p`
  color: var(--color-grey-contrast);
  font-size: 16px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const Characteristics = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 16px;
`;

export const Characteristic = styled.div`
  align-items: center;
  color: var(--color-grey-contrast);
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin: 0 0 0 6px;
`;

export const CharacteristicAmount = styled.p`
  margin: 0;
`;

export const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px;
  position: absolute;
  top: 90px;
`;

export const Tag = styled.div`
  background-color: var(--color-brand);
  color: var(--color-white);
  font-family: var(--font-circular-book);
  font-size: 12px;
  padding: 2px 4px;
  border-radius: 4px;
`;

export const CloseCard = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  height: 24px;
  width: 24px;
  background-color: #ffffffbf;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: var(--color-dark-contrast);
`;
