import { Option } from '../../models/formModel'

interface Props {
    register: any;
    name: string;
    list: Array<Option>;
    empty_value?: string;
}

const SelectInput = (props: Props) => {
    const {
        register,
        name,
        list,
        empty_value = '',
    } = props
    return (
        <div className="w-full border mt-2 rounded-md p-2 flex items-center bg-white">
            <select
                className="border-none focus:ring-0 w-full focus:outline-none  focus:border-none text-sm text-[#757575]"
                {...register(name)}
            >
                { empty_value !== '' && (
                    <option value="" >{empty_value}</option>
                )}
                {list && list.length > 0 && list.map((el, index: number) => (
                    <option key={index} value={el.value} >{el.label}</option>
                ))}
            </select>
        </div>
    )
}

export default SelectInput
