import { createSlice } from "@reduxjs/toolkit"
import { OfferStore } from "../../models/offerModel"

const initialState: OfferStore = {
    property_id: "",
    price: 0,
    request_date: "",
    contract_duration: "",
    signature: "",
    score_id: "",
    has_consent: false,
    request_consent: false,
    show_modal: false
}

export const offerSlice = createSlice({
    name: 'offer',
    initialState,
    reducers: {
        clearOfferAction: () => (initialState),
        setOfferAction: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        setShowOfferProcessAction: (state, action) => {
            return {
                ...state,
                show_modal: action.payload
            }
        }
    }
})

export const { 
    clearOfferAction, 
    setOfferAction, 
    setShowOfferProcessAction 
} = offerSlice.actions
export default offerSlice.reducer
