import React, { memo } from 'react';
import { InputText } from '../../../models/Devs';
import { useFormContext } from 'react-hook-form';
import { IconLock, IconMail } from '@tabler/icons-react';



const TextInput = memo(({ name, type, label, icon } : InputText) => {
    const methods = useFormContext()

      const getIcon = (icon: string) => {
        switch (icon) {
          case "email":
            return <IconMail color="#BDBDBD" />;
          case "password":
            return <IconLock color="#BDBDBD" />;
        }
      };
    
    
    return (
      <div className="relative input-icon">
        {getIcon(icon as string)}
        <input
            className='w-full focus:outline-none mt-1 border border-grayForm rounded-md text-[#757575] py-3 px-2 pl-10'
            type={type} 
            placeholder={label}
            { ...methods.register(name, { required: true }) } />
      </div>
    )
})

TextInput.displayName = "TextInput"
export default TextInput;