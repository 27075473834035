import { IconHomeCheck, IconHomeDollar } from "@tabler/icons-react";
import deal from "../../../../assets/icons/deal.svg";
import "./styles.scss";
interface OfferInfo {
  id: string;
  offer_at: string;
  offer_expires_in?: string;
  offer_price: string;
  status: string;
  marketplace_type: string;
  listing_price: string;
}

interface PropertyInfo {
  address: string;
  cover: string;
  has_old_price: boolean;
  id: string;
  old_rent_price: string;
  rent_price: string;
  url_detail: string;
  code: string;
}

interface VisitInfo {
  id: string;
  status: string;
  visit_date: string;
  visited_at: string;
}

interface DataPropertyOffer {
  data: {
    has_offer: boolean;
    has_visit: boolean;
    offer_info?: OfferInfo;
    property_info?: PropertyInfo;
    visit_info?: VisitInfo;
  };
  action: (data: boolean, info?: any) => void;
}
const CardOffer = ({ data, action }: DataPropertyOffer) => {
  const showModal = (type: string) => {
    action(true, {
      id: data.property_info?.id,
      type: type,
      property_info: data.property_info,
      offer_info: data?.offer_info || {},
      visit_info: data?.visit_info || {},
    });
  };
  return (
    <div
      className="card_offer h-full flex flex-col justify-start"
      data-variation={data.offer_info?.status === "accepted" ? "accepted" : ""}
    >
      <div className="card_offer__image">
        <div className="overlay">
        {data?.offer_info?.marketplace_type === "on_sale" ? (
            <span className="text-[#FA712D] bg-[#FEE3D5] px-4 rounded-lg font-semibold inline-block text-xs">
              Venta
            </span>
          ) : (
            <span className="text-primary bg-[#D5F0E3] px-4 rounded-lg font-semibold inline-block text-xs">
              Renta
            </span>
          )}
          <h1 className="text-xl font-semibold text-white mt-1">
            {data?.property_info?.address}
          </h1>
        </div>
        <img
          className=" object-cover object-center h-44 w-full"
          src={data.property_info?.cover}
          alt="img-propi"
        />
      </div>
      <div className="card_offer__info px-6">
        <div className="">

          <>
            {(() => {
              switch (data.offer_info?.status) {
                case "rejected":
                  return (<span className="text-[#FA712D] bg-[#FEE3D5] px-3 rounded-lg font-semibold mt-4 inline-block">
                      Oferta rechazada por propietario
                    </span>
                    
                  );
                case "accepted":
                  return (
                    <span className="text-primary bg-[#D5F0E3] px-3 rounded-lg font-semibold mt-4 inline-block">
                      Oferta aceptada
                    </span>
                  );
                case "negotiating":
                  return (
                    <span className="text-[#757575] bg-[#F5F5F5] px-3 rounded-lg font-semibold mt-4 inline-block">
                      Negociando oferta con propietario
                    </span>
                  );
                case "on_wait":
                  return (
                    <span className="text-[#757575] bg-[#F5F5F5] px-3 rounded-lg font-semibold mt-4 inline-block">
                      Oferta en espera
                    </span>
                  );
                case "signature":
                  return (
                    <span className="text-[#757575] bg-[#F5F5F5] px-3 rounded-lg font-semibold mt-4 inline-block">
                      En espera de firma del propietario
                    </span>
                  );
                default:
                  return null;
              }
            })()}
          </>
        </div>
        <div className="flex justify-between items-center mt-4">
          <div>
            <p className="text-[#757575] text-sm font-medium">
              Oferta realizada
            </p>
            <p className="text-base font-bold text-[#212121]">
              {data.offer_info?.offer_price}
            </p>
          </div>
          <div>
            <p className="text-[#757575] text-sm font-medium">
              Precio de lista
            </p>

            <p className="text-base font-bold text-[#212121]">
              {" "}
              {data.offer_info?.listing_price}{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="card_offer__info">
        {/* <div className="normal current_gray flex justify-between items-center">
          <p className="w-3/5 text-ellipsis">{data.property_info?.address}</p>
          <a className="font-bold text-blueDark" target="blank" href={`/alquiler/${data.property_info?.url_detail}/${data.property_info?.code}`}>
            Ver propiedad{" "}
          </a>
        </div> 
        <div className="normal flex justify-between items-center">
          <div className="flex">
            <p className="mr-3">
              <b>Visita</b>
            </p>
            {
              data?.has_visit ? (
                <p>{data.visit_info?.visit_date}</p>
              ) : (
                <p>Sin agendar</p>
              )
            }
          </div>
          {
            data?.has_visit
              ? <button className="font-bold text-blueDark" onClick={() => showModal('reagendar')}>
                Reagendar
              </button>
              : <button className="font-bold text-blueDark" onClick={() => showModal('agendar')}>
                Agendar
              </button>
          }
        </div>*/}
        {/* <div className="normal flex justify-between items-center">
          <div className="flex">
            <p className="mr-3">
              <b>Estado</b>
            </p>
            {!data.has_offer ? (
              <p>Aún sin ofertar</p>
            ) : (
              <>
                {(() => {
                  switch (data.offer_info?.status) {
                    case "rejected":
                      return <p className="text-secondary">Rechazada por propietario</p>;
                    case "accepted":
                      return <p className="text-primary">Aceptada</p>;
                    case "negotiating":
                      return <p>Negociando con propietario</p>;
                    case "on_wait":
                      return <p>En espera</p>;
                    case "signature":
                        return <p>En espera de firma del propietario</p>;
                    default:
                      return null;
                  }
                })()}
              </>
            )}
          </div>
        </div> */}
        {/* <div className="normal flex justify-between items-center">
          <div className="flex">
            <p className="mr-3">
              <b>Alquiler</b>
            </p>
            <p> {data.property_info?.rent_price} </p>
          </div>
        </div> */}
        {/* <div className="normal flex justify-between items-center">
          <div className="flex">
            <p className="mr-3">
              <b>Tu oferta</b>
            </p>
            {data.has_offer ? (
              <p> {data.offer_info?.offer_price} </p>
            ) : (
              <p>Sin realizar</p>
            )}
          </div>
        </div> */}
        <div className="pt-6 pb-6 px-6 flex justify-center items-center">
          <div className="flex">
            <>
              {(() => {
                switch (data.offer_info?.status) {
                  case "rejected":
                    return (
                      <button
                        className="flex items-center gap-2 px-4 py-2 font-medium shadow-md bg-primary text-white outline-none rounded-lg mb-"
                        onClick={() => showModal("cambiar-ofertar")}
                      >
                        Hacer nueva oferta{" "}
                        <IconHomeCheck width={22} color="#fff" />
                      </button>
                    );
                  case "signature":
                  case "finished":
                    return (
                      <button className="flex items-center gap-2 px-3 py-2 font-bold shadow-green500 shadow-md bg-green500 text-white outline-none rounded-full">
                        Contrato firmado <img src={deal} alt="" />
                      </button>
                    );
                  case "accepted":
                    return (
                      <button
                        onClick={() => showModal("aceptar")}
                        className="flex items-center gap-2 px-3 py-2 font-medium shadow-md bg-[#212121] text-white outline-none rounded-lg mb-"
                      >
                        Aceptar contrato <img src={deal} alt="" />
                      </button>
                    );

                  case "negotiating":
                    return (
                      <button
                        className="flex items-center gap-2 px-4 py-2 font-medium shadow-md bg-orange text-white outline-none rounded-lg mb-"
                        onClick={() => showModal("negociar")}
                      >
                        Negociar <IconHomeDollar width={22} color="#fff" />
                      </button>
                    );
                  case "on_wait":
                    return (
                      <p className="text-[#757575] text-sm">
                        Tu oferta fue enviada al propietario, espera una pronta
                        respuesta de aceptacion o rechazo para contraofertar.
                      </p>
                    );

                  default:
                    return null;
                }
              })()}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardOffer;
