// router
import AppRouter from './routers/AppRouter';
import store from './redux/store';
import { Provider } from 'react-redux';
import ModalProvider from './pages/Marketplace/context/ModalContext';
import { ToastProvider } from './components/ToastAlerts';
import MainModalProvider from './context/MainModalContext';

const App = () => { 
  return (
    <Provider store={store} >
      <MainModalProvider>
        <ModalProvider>
          <ToastProvider>
            <AppRouter />
          </ToastProvider>
        </ModalProvider>
      </MainModalProvider>
    </Provider>
  );
}

export default App;
