import { createSlice } from "@reduxjs/toolkit";
import { Navigation } from "../../models/navigationModel";

const initialState: Navigation = {
    visible: false
}

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        toogleAction: (state, action) => {
            return {
                ...state,
                visible: action.payload
            }
        },
    }
})

// provide actions
export const { toogleAction } = navigationSlice.actions;
// provide reducer
export default navigationSlice.reducer