import axios from "axios";
const loadAbort = () => {
  return new AbortController();
};
export const getScheduleList = (id: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/properties/${id}/config-visits/config`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const updateScheduleList = (idProperty: string, data: any) => {
  console.log(JSON.stringify(data));
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "put",
        url: `${urlService}/ts/v1/properties/${idProperty}/config-visits`,
        data: JSON.stringify({
          available_visits: data,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const getScheduleSelectedList = (idProperty: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/properties/${idProperty}/config-visits`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};
