import React, {useState, createContext, PropsWithChildren, useMemo} from "react";
// import ModalFilter from "../../Marketplace/components/ModalFilter";
import useMainModal from "../hooks/useMainModal";
export const MainModalContext = createContext<any | null>({})

interface Props {
    
}

const MainModalProvider: React.FC<PropsWithChildren<Props>> = ({ children }) => {
    
    let { modal, handleMainModal, modalContent } = useMainModal();
    let context = useMemo(() => ({
        modal,
        handleMainModal,
        modalContent 
    }), [modal, handleMainModal, modalContent])
    return (
        <MainModalContext.Provider
            value={context}
        >
            {children}  
        </MainModalContext.Provider>
    )
}
export default MainModalProvider