import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import useCallApiAndLoad from "../../../hooks/useCallApiAndLoad"
import { verifyTenant } from "../services/VerifciationServices"

import background from '../../../assets/images/confirmation-background.png';
import arrow_button from '../../../assets/icons/arrow_button.svg';
import logo from '../../../assets/images/logo_propi_white.svg';
import Button from "../../../components/Button";
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { getAuthUserAdapter } from '../../../adapters/userAdapter';
import { useDispatch, useSelector } from 'react-redux';
import { loginAction } from '../../../redux/features/auth';
import { setAuth } from '../../../helpers/authHelper';
import { AppStore } from '../../../redux/store';

const TenantVerification = ({ id }: { id: string }) => {
    const { country } = useSelector((store: AppStore) => store.country);
    const navigate = useNavigate()
    const { isLoading, callEndpoint } = useCallApiAndLoad()
    const dispatch = useDispatch()
    
    const handleVerify = async () => {
        const { status, data } = await callEndpoint(verifyTenant(id))
        if (status === 200) {
            const response = fetchResponseAdapter(data)
            const responseData = response.data
            if(responseData && responseData.auth && responseData.auth.access_token){
                const { auth } = responseData
                const authData = getAuthUserAdapter({
                    first_name: auth.name,
                    access_token: auth.access_token,
                    refresh_token: auth.refresh_token,
                    realm: auth.realm,
                    avatar: auth.avatar,
                    name: auth.name
                })
                dispatch(loginAction(authData))
                setAuth(authData)
                return window.open(`/${country}/`, '_self')
            }
            return window.open(`/app/${country}/auth/login`, '_self')
        }
        const errorCode = data.code;
    }

    return (
        <section
            className="overflow-hidden bg-cover bg-center h-screen"
            style={{ "backgroundImage": `url(${background})` }}>

            <div className="flex flex-col items-center justify-center h-screen">
                <div className="text-center mb-6">
                    <img className="inline-block mb-5" src={logo} alt="Logo PropiLatam" />
                </div>
                <h2 className="text-white font-semibold text-4xl text-center mb-6">
                    ¡Encuentra tu hogar ideal!
                </h2>
                <h3 className="text-white font-normal text-l text-center mb-12">
                    Activa tu cuenta por medio de la siguiente acción
                </h3>
                <Button
                    onClick={handleVerify}
                    className="relative w-56 bg-orange py-5 px-4 my-4 text-white text-base rounded-full"
                    disabled={isLoading}>
                    <span className="mr-2">Explorar</span>
                    <img src={arrow_button} className="vertical-absolute-center right-3" alt="" />
                </Button>
            </div>

        </section>
    )
}

export default TenantVerification
