import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup'

import useCallApiAndLoad from "../../../hooks/useCallApiAndLoad"
import { verifyLandlord } from "../services/VerifciationServices"
import Button from "../../../components/Button"
import background from '../../../assets/images/confirmation-background.png';
import arrow_button from '../../../assets/icons/arrow_button.svg';
import logo from '../../../assets/images/logo_propi_white.svg';
import { LandlordVerificationForm } from '../models/VerificationModel';
import FormControlError from '../../../components/Controls/FormControlError';
import { useState } from 'react';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { getAuthUserAdapter } from '../../../adapters/userAdapter';
import { useDispatch, useSelector } from 'react-redux';
import { loginAction } from '../../../redux/features/auth';
import { setAuth } from '../../../helpers/authHelper';
import { AppStore } from '../../../redux/store';

const LandlordVerification = ({ id }: { id: string }) => {
    const { country } = useSelector((store: AppStore) => store.country);
    const dispatch = useDispatch()
    const [ error, setError ] = useState('')
    const { isLoading, callEndpoint } = useCallApiAndLoad()
    const { register, handleSubmit, formState: { errors } } = useForm<LandlordVerificationForm>({
        resolver: yupResolver(yup.object({
            code: yup.string().required('Código es requerido')
        }).required())
    })

    const handleVerify = async ({ code }: LandlordVerificationForm) => {
        const { status, data } = await callEndpoint(verifyLandlord(id, code))
        if (status === 200) {
            const response = fetchResponseAdapter(data)
            const responseData = response.data
            if(responseData && responseData.auth && responseData.auth.access_token){
                const { auth } = responseData
                const authData = getAuthUserAdapter({
                    first_name: auth.name,
                    access_token: auth.access_token,
                    refresh_token: auth.refresh_token,
                    realm: auth.realm,
                    avatar: auth.avatar,
                    name: auth.name
                })
                dispatch(loginAction(authData))
                setAuth(authData)
                return window.open(`/${country}/`, '_self')
            }
            return window.open(`/app/${country}/auth/login`, '_self')
        }
        const errorCode = data.code;
        setError(`Error(${errorCode}): ${data.message}`)
    }

    return (
        <section
            className="overflow-hidden bg-cover bg-center h-screen"
            style={{ "backgroundImage": `url(${background})` }}>

            <div className="flex flex-col items-center justify-center h-screen">
                <div className="text-center mb-6">
                    <img className="inline-block mb-5" src={logo} alt="Logo PropiLatam" />
                </div>
                <h2 className="text-white font-semibold text-4xl text-center mb-6">
                    Valida tu cuenta
                </h2>
                <h3 className="text-white font-normal text-l text-center mb-12">
                    Ingresa el código recibido para activar tu cuenta
                </h3>
                <form onSubmit={handleSubmit(handleVerify)} className="flex flex-col justify-center items-center" >
                    <input
                        {...register("code")}
                        className="w-80 bg-white block py-3 px-2 w-full text-sm text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer text-center justify-content-center"
                        type="text"
                        placeholder="código de verificación" />
                    <FormControlError message={errors.code?.message} additionalClass="w-full" />
                    <FormControlError message={ error } additionalClass='w-full' />
                    <Button
                        type='submit'
                        className="relative w-56 bg-orange py-5 px-4 my-4 text-white text-base rounded-full"
                        disabled={isLoading}>
                        <span className="mr-2">Publicar</span>
                        <img src={arrow_button} className="vertical-absolute-center right-3" alt="" />
                    </Button>
                </form>
            </div>

        </section>
    )
}

export default LandlordVerification
