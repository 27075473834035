export const havePetsOptions: any = [
    { name: "Sí", value: true },
    { name: "No", value: false }
]

export const petSizesOptions: any = [
    { name: "Pequeño", value: "P" },
    { name: "Mediano", value: "M" },
    { name: "Grande", value: "G" }
]

export const genderOptions: any = [
    { name: "Femenino", value: "F" },
    { name: "Masculino", value: "M" }
]

export const mapPetSize = (code: string) => {
    let value = "";
    for(const element of petSizesOptions){
        const petSize = element;
        if(petSize.value === code){
            value = petSize.name
            break;
        }
    }
    return value
}

export const mapGender = (code: string) => {
    let value = "";
    for(const element of genderOptions){
        const gender = element;
        if(gender.value === code){
            value = gender.name
            break;
        }
    }
    return value
}