
interface Props {
    label: string;
    value: string;
}

const DetailSectionLabel = (props: Props) => {
    const { label, value } = props
    return (
        <>
            <div className="px-4 lg:px-2 w-full lg:w-2/3">
                <div className="w-full flex justify-between items-center my-6">
                    <div className="text-blueForm font-semibold w-1/2 lg:w-auto">
                        { label }
                    </div>
                    <div className="text-graySoft ">{ value }</div>
                </div>
            </div>
            <hr />
        </>
    )
}

export default DetailSectionLabel;
