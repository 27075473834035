import React, { useEffect } from "react";

import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";

//assets
import { getAdminPropertyEndpoint } from "./services/PropertiesService";
import { useDispatch, useSelector } from "react-redux";
import { AppStore } from "../../redux/store";
import { logoutAction } from "../../redux/features/auth";
import { useNavigate, useParams } from "react-router-dom";
import { unsetAuth, getAuthToken } from "../../helpers/authHelper";
import DetailSectionMenu from "../../components/General/DetailSectionMenu";
import { loadAction } from "../../redux/features/adminProperty";
import PropertyGallery from "../Marketplace/components/Imagebanner";

const AdminProperty = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  const { id = "" } = useParams();
  const user = useSelector((store: AppStore) => store.auth);
  const adminProperty = useSelector((store: AppStore) => store.adminProperty);
  const token = getAuthToken(user);
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const loadAdminProperty = async () => {
      const { status, data } = await callEndpoint(
        getAdminPropertyEndpoint(id, token)
      );
      if (status === 401) {
        dispatch(logoutAction({}));
        unsetAuth();
        return navigate(`/app/${country}/auth/login`);
      }
      const response = fetchResponseAdapter(data);
      dispatch(loadAction(response.data));
    };
    loadAdminProperty().catch((e) => console.error(e));
  }, []);

  const propertyStatusMapper = (status: string) => {
    const statusMapper: Record<string, string> = {
      created: "Creada",
      published: "Publicada",
      disabled: "Desactivada",
      taken: "Alquilada",
      rented_external: "Rentado por fuera"

    };

    return statusMapper[status?.toLowerCase()] || "";
  };

  const propertyStatusSalesMapper = (status: string) => {
    const statusMapper: Record<string, string> = {
      created: "Creada",
      published: "Publicada",
      disabled: "Desactivada",
      taken: "Alquilada",
      sold_external: "Vendida por fuera",
      sold: "Vendida",
      transfer_ownership: "Escrituración",
      reserved: "Reservada",
      purchase_agreement: "Firma de promesa",
      take_photos: "Toma de fotos"

    };

    return statusMapper[status?.toLowerCase()] || "";
  };

  return (
    <>
      <section className="pt-20 xl:pt-32 pb-20">
        <div className="m-auto w-full px-4 xl:px-0">
          <div className="w-full mt-6 mb-2 text-blackGrayScale  text-2xl lg:text-4xl font-semibold text-left tracking-wide">
            Administra tu propiedad
          </div>
          <p className=" text-xl font-medium text-gray600 mb-2">
            {adminProperty?.address}
          </p>
          <div className="w-full text-left mb-4 text-gray600 text-base">
          Propiedad ID {adminProperty?.code}
        </div>
        </div>
        
        
        <div className="flex flex-wrap mt-10">
          <div className="w-full xl:w-2/5 px-2">
            <PropertyGallery
              cover={adminProperty?.cover}
              gallery={
                adminProperty?.full_gallery
                  ? adminProperty?.full_gallery.slice(1)
                  : []
              }
              video={adminProperty?.link_video || ""}
              video360={adminProperty?.link_tour360 || ""}
            />
          </div>
          <div className="w-full xl:w-3/5 px-4 xl:px-0">
            <section className="flex justify-center">
              <div className="lg:px-2 w-full lg:w-2/3">
                <div className="w-full">
                  <DetailSectionMenu
                    label="Estado de alquiler"
                    value={propertyStatusMapper(adminProperty?.status)}
                    typelink='normal'
                  />
                  {
                    adminProperty?.sale_status !== null && <DetailSectionMenu
                    label="Estado de venta"
                    value={propertyStatusSalesMapper(adminProperty?.sale_status)}
                    typelink='normal'
                  />
                  }
                  
                  <DetailSectionMenu
                    label="Ver en la marketplace"
                    value={""}
                    link={`/${country}/alquiler/${adminProperty?.url_detail}/${adminProperty?.code}`}
                    typelink='external'
                  />
                  <DetailSectionMenu
                    label="Ofertas recibidas"
                    value={adminProperty?.offers}
                    hasBubble
                    link={`/app/${country}/l/property/offers/${id}`}
                    typelink='normal'
                  />
                  <DetailSectionMenu
                    label="Visitas"
                    value={adminProperty?.visits}
                    hasBubble
                    link={`/app/${country}/l/admin-property/visits/${id}`}
                    typelink='normal'
                  />
                  <DetailSectionMenu
                    label="Detalle de propiedad"
                    value={""}
                    link={`/app/${country}/l/property/${id}`}
                    typelink='normal'
                  />

                  {adminProperty?.active_contract_link && (
                    <DetailSectionMenu
                      label="Contrato de arrendamiento"
                      value={""}
                      link={adminProperty?.active_contract_link}
                      typelink='external'
                    />
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminProperty;
