import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormControlError from "../../../components/Controls/FormControlError";
import { getAuthToken } from "../../../helpers/authHelper";
import useCallApiAndLoad from "../../../hooks/useCallApiAndLoad";
import { pluckAction } from "../../../redux/features/favorites";
import { AppStore } from "../../../redux/store";
import { deleteTenantFavorite } from '../services/tenantService';

interface Props {
  onAction: () => void;
  id: string;
}

const ModalTrash = (props: Props) => {
  const user = useSelector((store: AppStore) => store.auth);
  const { id, onAction } = props
  const token = getAuthToken(user);
  const dispatch = useDispatch()
  const { isLoading, callEndpoint } = useCallApiAndLoad()
  const [formError, setFormError] = useState('')

  const handleDeleteFavorite = async (e: any) => {
    e.preventDefault()
    const { status } = await callEndpoint(deleteTenantFavorite(id, token))
    if(status === 200){
      dispatch(pluckAction({ id }))
      onAction()
    }
    setFormError('Lo sentimos, no es posible remover favoritos')
  }

  return (
    <div className="w-full text-center bg-white ">
      <div className="text-graySoft py-8 px-12">
      ¿Estás seguro de eliminar el inmueble de favoritos?
        <FormControlError message={formError} />
      </div>
      
      <div className="bg-white border-t border-graySoft w-full">
        <div className="w-full flex justify-between px-4 py-4 items-center">
          <button>
            <div
              onClick={onAction} 
              className="text-blueForm rounded-full border text-base border-blueForm p-2 px-3">
              Cancelar
            </div>
          </button>
          <button
            disabled={isLoading}
            onClick={(e: any) => handleDeleteFavorite(e)}
            className="bg-green500 py-2 px-4 text-white text-base rounded-full">
            <span className="mx-auto">Eliminar</span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default ModalTrash;
