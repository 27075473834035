import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom"
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad"
import { resendVerificationEndpoint } from "./services/RegistrationService"
import Button from "../../components/Button";

import background from '../../assets/images/confirmation-background.png';
import arrow_button from '../../assets/icons/arrow_button.svg';
import logo from '../../assets/images/logo_propi_white.svg';
import { getRealmPath } from '../../helpers/realmHelpers';
import { useSelector } from 'react-redux';
import { AppStore } from '../../redux/store';

interface Props {
    realm: string;
}

const Confirmation = (props: Props) => {
    const {country} = useSelector((state: AppStore) => state.country);

    const { realm } = props
    const { pid = '' } = useParams()
    const [error, setError] = useState('')
    const { isLoading, callEndpoint } = useCallApiAndLoad()
    const location = useLocation()
    const navigate = useNavigate()
    const [ confirmationEmail, setConfirmationEmail ] = useState('')

    useEffect(() => {
        const state: any = location.state;
        state && state.email && setConfirmationEmail(state.email || '')
    }, [])

    const handleChangeEmail = () => {
        const pathRealm = getRealmPath(realm)
        return navigate(`/app/${country}/auth/change-mail/${pathRealm}/${pid}`)
    }

    const handleResend = async () => {
        setError('')
        const {
            status, data
        } = await callEndpoint(resendVerificationEndpoint(realm, pid))
        if (status === 200) {
        } else {
            const { code, message } = data;
            setError(`Error(${code}): ${message}`)
        }
    }

    return (
        <>
            <section
                className="overflow-hidden bg-cover bg-center h-screen"
                style={{ "backgroundImage": `url(${background})` }}>

                <div className="flex flex-col items-center justify-center h-screen">
                    <div className="text-center mb-6">
                        <img className="inline-block mb-5" src={logo} alt="Logo PropiLatam"/>
                    </div>
                    <h2 className="text-white font-semibold text-4xl text-center mb-6">
                        ¡Estás por iniciar!
                    </h2>
                    <h3 className="text-white font-normal text-l text-center mb-12">
                        Activa tu cuenta a traves de un correo que enviamos a tu cuenta
                    </h3>
                    <h3 className="text-white font-semibold text-g text-center mb-6">
                        { confirmationEmail  }
                    </h3>
                    <h3 className="text-white font-normal text-g text-center mb-8">
                        ¿No recibiste ningún correo?
                    </h3>
                    <div className="flex space-x-2">
                        <Button
                            className="bg-transparent border-2 bg-white-500 py-5 px-4 w-64 text-white text-base rounded-full flex justify-center items-center"
                            onClick={handleChangeEmail} >
                            Cambiar correo
                        </Button>
                        <Button
                            onClick={handleResend}
                            disabled={isLoading}
                            className="bg-orange py-2 px-4 text-white text-base rounded-full flex justify-start items-center">
                            <span className="mr-2 ml-8 ">Reenviar correo</span>
                            <img src={arrow_button} alt="" />
                        </Button>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Confirmation
