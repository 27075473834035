import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    visit_type: '',
    date: '',
    start_time: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    area_code: '',
    property_id: ''
}

export const registerDataVisitsSlice = createSlice({
    name: 'visit-property',
    initialState,
    reducers: {
        setDataVisits: (state, action) => {
            console.log(action);
            return {
                ...state,
                ...action.payload
            }
        },
    }
})

// provide actions
export const { setDataVisits } = registerDataVisitsSlice.actions;
// provide reducer
export default registerDataVisitsSlice.reducer