import React from 'react'
import FooterComponent from '../../components/Footer'
import NavBar from '../../components/NavBar/NavBar'

const Privacy = () => {
  return (
    <>
      <NavBar />
      <div className="container mx-auto pt-24">
        <div className="w-full flex justify-center flex-wrap flex-row pb-8">
          <div className="w-full text-center text-4xl my-4 text-grayText font-bold">
            Aviso de privacidad
          </div>
        </div>
        <div className="text-graySoft my-4">
          <p className='pt-1' >Por medio de este Aviso de Privacidad, informamos sobre los aspectos que usted debe saber y autorizar en relación con la privacidad de sus datos en la Plataforma de PROPI.</p>
          <p className='pt-1'><b>Partes y definiciones</b></p>
          <p className='pt-1'>
            Serán consideradas partes del presente Aviso de Privacidad, las siguientes:
          </p>
          <p className='pt-2'>
            <b>PROPI:</b> se entenderá PROPI TECH INC, una sociedad debidamente constituida y existente bajo
            las leyes de la República de Panamá y PROPI TECH S.A. de C.V., una sociedad debidamente
            constituida y existente bajo las leyes de la República de El Salvador y cualquier otra
            subsidiaria de PROPI de origen mercantil que operan u operarán en la región Centroamericana,
            y con dirección de correo electrónico hola@propilatam.com1 dedicada a facilitar la experiencia
            del proceso de arrendamiento y compraventa de inmuebles por internet por medio de la Plataforma
            de PROPI (en adelante la “Plataforma”).
          </p>
          <p className='pt-2'>
            <b>USUARIO:</b> Cualquier persona que acceda o use la Plataforma y/o los Servicios de PROPI independientemente
            de si se ha registrado o no, incluyendo, sin limitación alguna a, Inquilinos, Compradores, Propietarios, PROPERS y PROPERCURADORES.
          </p>
          <p className='pt-2'>
            <b>PROPIETARIO:</b> Usuario dueño del inmueble que decide ofertarlo a través de la Plataforma de PROPI, único y exclusivo responsable de la información: (i) de su inmueble; y (ii) que sea publicada en la Plataforma.
          </p>
          <p className='pt-2'>
            <b>INQUILINO:</b> Usuario que a través de la Plataforma de PROPI elige el inmueble que mejor se acopla a sus necesidades y decide arrendarlo, previa aprobación y estudio de crédito e ingresos por parte de PROPI y/o los terceros que este último designe.
          </p>
          <p className='pt-2'>
            <b>COMPRADOR:</b> Usuario que a través de la Plataforma de PROPI elige el inmueble que mejor se acopla a sus necesidades y decide comprarlo, previa aprobación y estudio de crédito e ingresos por parte de PROPI y/o los terceros que este último designe.
          </p>
          <p className='pt-2'>
            <b>PROPER CURADOR:</b> Personas físicas independientes y sin relación laboral alguna con PROPI, encargadas de: tomar fotografías y videos de las Propiedades, así como verificar el estado del inmueble por medio de un checklist elaborado por PROPI y firmado por el Propietario y por el Inquilino en los casos que sea requerido por PROPI.
          </p>
          <p className='pt-2'>
            <b>PROPERS:</b> Personas físicas que pueden ser empleadas por PROPI o ser terceros independientes y sin relación laboral alguna con PROPI, que actúan como asesores y corredores inmobiliarios en el arrendamiento y/o compraventa de los inmuebles de la Plataforma, y que tienen conocimientos del sector inmobiliario. Son los encargados de agendar citas de visita, acompañar a los posibles Inquilinos y Compradores y llevar a cabo actividades de seguimiento inherentes al arrendamiento de los inmuebles para uso habitacional y/o su compraventa que se encuentran dentro de la Plataforma de PROPI.
          </p>
          <p className='pt-2'>
            <b>PLATAFORMA:</b> Se refiere a la aplicación móvil de Propi, en la cual se listarán los inmuebles para su compraventa o arrendamiento.
          </p>
          <p className='pt-2'>
            <b>T&C:</b> Significan los términos y condiciones.
          </p>
          <p className='pt-4'>
            <b>Recopilación de Información Personal</b>
          </p>
          <p className='pt-2'>
            Los siguientes tipos de información personal de los Usuarios pueden ser recopilados, almacenados y usados por PROPI de acuerdo con lo establecido en el presente Aviso de Privacidad.
          </p>
          <ul className='list-disc pl-4'>
            <li>Información sobre sus visitas y uso de este sitio web, incluidas las fuentes de referencia, duración de su visita, visitas a la Plataforma y rutas de navegación de la Plataforma.</li>
            <li>Información que introduzca al crear un perfil de usuario en la Plataforma, como la dirección de correo electrónico, nombre, foto de perfil, género, fecha de nacimiento, estado civil, documentos de identidad tales como Documento Único de Identidad o pasaporte, entre otros.</li>
            <li>Información que introduzca mientras usa los servicios que ofrece PROPI en la Plataforma, tales como ubicación de los inmuebles,</li>
            <li>Datos de las cuentas bancarias y/o de la tarjeta de crédito o débito.</li>
            <li>Cualquier otra información personal que nos proporcione y nos autorice a recopilar.</li>
          </ul>
          <p className='pt-2'>
            Antes de compartir información personal de un tercero, el Usuario deberá de conseguir el consentimiento de dicha persona. Cualquier información que el Usuario ingrese a la Plataforma se considerará que se encuentra debidamente autorizada y consentida por su legítimo interesado.
          </p>
          <p className='pt-4'>
            <b>Uso de la Información Personal</b>
          </p>
          <p className='pt-2'>
            La información personal que nos envíe a través de nuestra Plataforma será usada con los fines especificados en el presente Aviso de Privacidad y podrá ser usada para los siguientes fines:
          </p>
          <ul className='list-disc pl-4'>
            <li>Formalizar la relación contractual con el Usuario y brindar acceso a la Plataforma.</li>
            <li>Poder registrarlo o darlo de baja, según sea el caso, en nuestro registro de Inquilinos, Compradores, Propietarios, PROPERS y PROPERCURADORES.</li>
            <li>Realizar el pago y/o cobro de las cantidades adeudadas por la prestación de bienes o servicios;</li>
            <li>Gestionar y dar cumplimiento a la relación contractual establecida con el Usuario en los términos establecidos en los T&C y en el presente Aviso de Privacidad.</li>
            <li>Organizar el registro de información de los Propietarios.</li>
            <li>Investigar, verificar y validar la información de los inmuebles registrados por los Usuarios en la Plataforma.</li>
            <li>Investigar, verificar y validar la información de los Usuarios.</li>
            <li>Elaborar reportes, estadísticas y otros relacionados a las compraventas, los arrendamientos, los pagos, situación de los usuarios, situación financiera, entre otros.</li>
            <li>Dar información estadística a terceros sobre nuestros Usuarios, en estos casos los terceros no podrán identificar a ningún usuario individual con esa información.</li>
            <li>Enviar a los Usuarios información sobre promociones, descuentos, ofertas y/o boletines informativos.</li>
            <li>Actividades de cobranza, aclaración, investigación y facturación, perfiles de consumo, promedios de gasto e inscripción de programas de lealtad.</li>
            <li>Hacer consultas, investigaciones y revisiones en relación con quejas o reclamaciones de los Usuarios.</li>
            <li>Dar respuesta a las preguntas y quejas de nuestros Usuarios, relacionadas con nuestros Servicios, sitio web o Plataforma.</li>
            <li>Mantener protegido el sitio web o Plataforma y evitar el fraude.</li>
            <li>Verificar el cumplimiento de los términos y condiciones que rigen sobre el uso de nuestro sitio web o Plataforma y otros usos que puedan estar vigentes según las modificaciones a las presentes Políticas de Privacidad.</li>
          </ul>
          <p className='pt-4'>
            <b>Uso y divulgación de información personal</b>
          </p>
          <p className='pt-2'>
            El Usuario, autoriza que la información personal sea compartida con terceros, según se indicará a continuación, de conformidad con lo establecido en los T&C de PROPI. A dichos terceros se les exigirá los mismos estándares de seguridad que sigue PROPI y que son requeridos por las leyes aplicables en la República de El Salvador. Podemos compartir la información que recopilamos sobre el Usuario con:
          </p>
          <ul className='list-disc pl-4'>
            <li>Nuestro personal de atención al cliente, para dar respuesta a las preguntas y quejas del Usuario de PROPI en relación con el servicio;</li>
            <li>El público en general si envía el contenido a un foro público, por ejemplo, comentarios en blogs, mensajes de redes sociales u otras opciones de nuestros servicios a disposición del público en general, en cuyo caso el Usuario asumirá el riesgo y liberará de toda responsabilidad a PROPI, por cualquier uso indebido, no autorizado o ilegal de la información por parte de terceros;</li>
            <li>Con terceros con quienes PROPI tenga relaciones comerciales, siempre que estos terceros no puedan identificar a ningún Usuario individual con dicha información;</li>
            <li>Con cualquiera de las subsidiarias, afiliadas o miembros del mismo grupo de interés económico, en los países en que opera PROPI, que razonablemente necesiten para los fines descritos en este Aviso de Privacidad;=</li>
            <li>Para cualquier otro uso no descrito expresamente en el presente Aviso de Privacidad, que sea previamente notificado y aceptado por el Usuario.</li>
          </ul>
          <p className='pt-2'>Asimismo, podrá compartirse con terceros en caso de que la información personal deba ser divulgada por orden judicial o de alguna autoridad competente con facultades suficientes para requerirla a PROPI o para establecer, ejercer o defender los derechos de PROPI; o en caso de que PROPI deba denunciar a las autoridades competentes algún tipo de actividad ilegal por parte del Usuario en relación con los Servicios.</p>
          <p className='pt-2'>Los datos de los Usuarios no serán cedidos, comunicados ni transferidos a terceros salvo por lo indicado en el presente Aviso de Privacidad.</p>
          <p className='pt-2'>El Usuario se da por enterado con su aceptación del presente Aviso de Privacidad, y autoriza en forma expresa a PROPI a realizar cualquier operación o conjunto de operaciones mediante procedimientos automatizados o manuales en relación con sus datos personales. PROPI podrá recolectar, llevar a cabo registros, organizar, conservar, modificar, extraer, consultar, utilizar, procesar, transmitir, transferir, cotejar, bloquear, suprimir o destruir la información personal del Usuario.</p>
          <p className='pt-2'>El Usuario entiende y acepta que los procedimientos automatizados de tratamiento de datos incluyen pero no se limitan a cualquier operación, conjunto de operaciones o procedimientos que PROPI aplique o pueda llegar a aplicar a la información personal, mediante la utilización de software, bots, inteligencia artificial, redes, aplicaciones, en sitios físicos o virtuales, o cualquier otra tecnología que permita la recolección, el registro, el acceso, la organización, la conservación, la modificación, la extracción, la consulta, la utilización, la comunicación por medio de transmisión, la transferencia, el cotejo, la interconexión, el bloqueo, la supresión, la destrucción, el intercambio o digitalización de datos personales.</p>
          <p className='pt-2'>La transferencia de datos que se realice de conformidad con el presente Aviso de Privacidad se realizará bajo estándares de seguridad que garanticen al Usuario y a PROPI que la información será tratada, procesada y almacenada en concordancia con los fines específicos del presente Aviso de Privacidad. La información y los datos recolectados por PROPI serán tratados con absoluta privacidad, confidencialidad y seguridad, según estipule la legislación vigente, los T&C y el presente Aviso de Privacidad.</p>
          <p className='pt-2'>El Usuario, con la lectura y aceptación expresa de este Aviso de Privacidad, de forma informada, individualizada, clara, precisa, inequívoca, voluntaria, libre, y consiente de los alcances de lo aquí aceptado, otorga su consentimiento para el tratamiento de sus datos según lo que aquí se ha indicado.</p>

          <p className='pt-2'><b>Transferencia Internacional de Datos Personales</b></p>
          <p className='pt-2'>La información que recopilamos puede ser almacenada, procesada y transferida entre cualquiera de los países en que opera PROPI o en servicios de almacenamiento de datos en la nube o en línea, con el fin de permitir a PROPI usar la información de conformidad con este Aviso de Privacidad.</p>
          <p className='pt-2'><b>Utilización de cookies y otros sistemas y tecnologías</b></p>
          <p className='pt-2'>Cuando el Usuario visita nuestra Plataforma y/o nuestro sitio web algunos de los datos de navegación se envían desde su computador o dispositivo móvil a nuestros servidores. Esta información permite optimizar nuestros servicios, personalizar y mejorar su experiencia en nuestro sitio web o la Plataforma. Esta información puede incluir aspectos tales como pero no limitados a:</p>
          <ul className='list-disc pl-4'>
            <li>Su dirección IP</li>
            <li>La fecha y hora de su visita</li>
            <li>La URL de referencia</li>
            <li>Las páginas que visitó en nuestro sitio web.</li>
            <li>Las funciones que utilizó dentro de la Plataforma.</li>
            <li>Información sobre el navegador que utilizó.</li>
            <li>Tiempo que estuvo en el sitio web y/o en la Plataforma.</li>
            <li>Cantidad de veces que ingresó al sitio web y/o la Plataforma y las veces que visito alguna página del sitio web o utilizó alguna de las funciones disponibles en la Plataforma.</li>
          </ul>
          <p className='pt-2'>PROPI utiliza varias tecnologías para recopilar información cuando el Usuario visita nuestro sitio web y/o nuestra Plataforma y esto puede incluir el envío de una o más cookies u otros identificadores para su dispositivo.</p>
          <p className='pt-2'><b>Conservación de Información Personal</b></p>
          <p className='pt-2'>La información que recopilamos será conservada por PROPI durante el plazo legal establecido en la legislación aplicable de la República de El Salvador. El Usuario puede solicitar la información de su cuenta en cualquier momento, así como solicitar la actualización y modificación de la misma. El Usuario también podrá solicitar la eliminación de su información personal siempre y cuando no sea información personal que PROPI tenga la obligación de conservar para cumplir con sus obligaciones legales o contractuales, o ejercer sus derechos contractuales.</p>
          <p className='pt-2'>Si el Usuario desea cancelar su cuenta, deben notificar a PROPI al correo electrónico [____]. Aun en caso de cancelación de su cuenta, PROPI podrá retener cierta información sobre el Usuario para cumplir con las obligaciones legales o contractuales que tenga y/o ejercer sus derechos con motivo de los servicios prestados2.</p>
          <p className='pt-2'><b>Liberación de responsabilidad</b></p>
          <p className='pt-2'>El Usuario reconoce y acepta que el uso del sitio web y la Plataforma, se realiza en todo momento bajo su entero riesgo y responsabilidad. PROPI no será responsable por el uso indebido que pueda realizarse de estos medios de comunicación y/o tecnologías.</p>
          <p className='pt-2'>PROPI no será responsable por daños y perjuicios de cualquier tipo causados en los equipos electrónicos (cómputo, teléfono, tableta, u otros) del Usuario por virus, gusanos o cualquier otro elemento dañino. El Usuario reconoce que el uso de la red Internet supone la asunción de un riesgo de que sus equipos informáticos puedan ser afectados por los elementos antes enunciados. A tal efecto, corresponde al Usuario, en todo caso, la disponibilidad de herramientas adecuadas para la detección y eliminación de programas electrónicos dañinos que puedan suponer un riesgo para su equipo de cómputo.</p>


          <p className='pt-2'>PROPI no tendrá responsabilidad por la falta de ejecución de sus obligaciones cuando medie fuerza mayor, caso fortuito o la entrada en vigencia de legislación gubernamental, regulaciones o restricciones de cualquier otra índole que le impidan la ejecución de sus obligaciones.</p>
          <p className='pt-2'><b>Modificación de Políticas de Privacidad</b></p>
          <p className='pt-2 pb-4'>PROPI tendrá la facultad unilateral de modificar a su entera discreción de forma ocasional y sin incurrir en responsabilidad alguna, este Aviso de Privacidad en cualquier tiempo, caso en el cual notificará y Publicará en el sitio web https://www.propilatam.com3 y/o en los correos electrónicos de los Usuarios los ajustes realizados para facilidad de acceso al público. El uso continuo del Usuario de la Plataforma o el acceso al sitio web de PROPI después de la publicación de cualquier cambio en los términos y condiciones de PROPI implicará una aceptación del Usuario a dichos cambios. En caso de que el Usuario no esté de acuerdo con dichos cambios deberá enviar una solicitud para ser atendido al correo electrónico: hola@propilatam.com, explicando aquello con lo que no está de acuerdo</p>

        </div>
      </div>
      <FooterComponent />
    </>
  )
}

export default Privacy
