import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const PinContainer = styled.div`
  height: fit-content;
  position: relative;
  width: fit-content;

`;

export const Bubble = styled.div`
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 260px;
  color: white;
  cursor: pointer;
  font-size: 11px;
  padding: 1px 7px;
  height: 18px;
  font-weight: 500;
  margin: 0;
  display: flex;
  align-items: center;

  &:after {
    position: absolute;
    height: 0;
    width: 0;
    left: calc(50% - 4px);
    top: 99%;
    border: 4px solid transparent;
    content: "";
  }

  &:hover {
    background: #0F1125;
    transition-duration: 0.3s;

    &:after {
      border-top-color: #0F1125;
    }
  }
  &:hover {
    transform: scale(0.7)
    transition-animation: 1s;
  }
`;

export const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  height: 12px;
  justify-content: center;
  margin-right: 2px;
  width: 12px;

  .icon-verified {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
`;

export const PriceContainer = styled.p`
  margin: 0;
  display: flex;
`;
