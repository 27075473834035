// lib
import {
    Routes,
    Route,
} from 'react-router-dom'
import ProjectList from '../pages/Developers/pages/ProjectsList/ProjectList';
import ProjectDetail from '../pages/Developers/pages/ProjectDetail/ProjectDetail';
import ProjectInvestors from '../pages/Developers/pages/ProjectInvestors/ProjectInvestors';
// components

const PrivatesDevsRoutes = () => {
    return (
        <>
            <Routes>
                <Route path='/proyectos' element={<ProjectList />} />
                <Route path='/proyectos/:id' element={<ProjectDetail />} />
                <Route path='/propietarios/proyectos/:id' element={<ProjectInvestors />} />
            </Routes>
        </>
    )
}
export default PrivatesDevsRoutes



