export const getRealmPath = (realm: string) => {
    switch (realm) {
        case 'landlord':
            return 'ld'
        case 'tenant':
            return 'tn'
        case 'proper':
            return 'pr'
        case 'customer':
            return 'c'
        default:
            return ''
    }
}

/**
 * This function returns balancing extra path that gcp needs to 
 * switch between SPA (Single page application) and SSR project
 * @returns 
 */
export const getAppPropiSpaPath = () => {
    return "/app";
  };