import "../../App.css";
import Button from "../../components/Button";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { registrationEndpoint } from "./services/RegistrationService";
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import { RegisterUser, RegisterUserForm } from "./models/RegistrationModel";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";
import { Fragment, useEffect, useState } from "react";
// assets
import logo from "../../assets/images/iso_logo.svg";
import banner from "../../assets/images/registerpropietario.jpg";
import banner2 from "../../assets/images/registerinquilino.jpg";
import { getRealmPath } from "../../helpers/realmHelpers";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// import { listToSelectOptionAdapter } from "../../adapters/listsAdapter";
// import {
//   getCountriesEndpoint,
//   getStatesEndpoint,
// } from "../../services/publicService";
import FormControlError from "../../components/Controls/FormControlError";
import MaskInput from "../../components/Controls/MaskInput";
import { useSelector } from "react-redux";
import { AppStore } from "../../redux/store";
import { IconLock, IconMail, IconPhone, IconUser, IconUsers } from "@tabler/icons-react";
import MainButton from "../../components/MainButton/mainButton";

interface Props {
  realm: string;
}

const Registration = (props: Props) => {
  const { country } = useSelector((state: AppStore) => state.country);

  const { realm } = props;
  // const [countries, setCountries] = useState<Array<any>>([]);
  // const [states, setStates] = useState<Array<any>>([]);
  let bannerRegistration;

  if (realm === "tenant") {
    bannerRegistration = banner2;
  } else {
    bannerRegistration = banner;
  }
  useEffect(() => {
    const callCatalogs = async () => {
      if (realm === "tenant") {
        bannerRegistration = banner2;
      } else {
        bannerRegistration = banner;
      }
      // if (realm !== "landlord") {
      //   return;
      // }
      // const { status, data } = await callEndpoint(getCountriesEndpoint());
      // if (status !== 200) {
      //   return;
      // }
      // const countriesData = fetchResponseAdapter(data);
      // setCountries(
      //   listToSelectOptionAdapter(
      //     countriesData.data,
      //     "country_id",
      //     "country_name"
      //   )
      // );
    };
    callCatalogs().catch((e) => console.error(e));
  }, []);

  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<RegisterUserForm>({
    resolver: yupResolver(
      yup
        .object({
          name: yup.string().required("Nombre es requerido"),
          last_name: yup.string().required("Apellido es requerido"),
          email: yup
            .string()
            .email("Ingrese un formato correcto para correo")
            .required("Email es requerido"),
          password: yup.string().required("Contraseña es requerida"),
          confirm_password: yup
            .string()
            .required("Confirmación de Contraseña es requerida")
            .oneOf(
              [yup.ref("password"), null],
              "Contraseñas deben ser iguales"
            ),
          phone: yup
            .string()
            .required("Teléfono es requerido")
            .min(9, "Teléfono debe estar en formato válido") // 9 includes 8 digits plus -
            .max(9, "Teléfono debe estar en formato válido"),
          // ...(realm === "landlord"
          //   ? {
          //       country: yup.string().required("País es requerido"),
          //       state: yup.string().required("Departamento es requerido"),
          //     }
          //   : {}),
        })
        .required()
    ),
  });

  const doRegister = async (form: RegisterUserForm) => {
    const registration: RegisterUser = {
      username: form.email,
      password: form.password,
      first_name: form.name,
      last_name: form.last_name,
      email: form.email,
      phone: form.phone,
      area_code: "-",
      country: "",
      state: "",
    };
    const { status, data } = await callEndpoint(
      registrationEndpoint(realm, registration)
    );
    if (status === 200) {
      const response = fetchResponseAdapter(data);
      const responseData = response.data;
      const pathRealm = getRealmPath(realm);
      return navigate(
        `/app/${country}/auth/confirmation/${pathRealm}/${responseData.pid}`,
        {
          replace: true,
          state: { email: form.email },
        }
      );
    }
    const { code, message } = data;
    setLoginError(`Error(${code}): ${message}`);
  };

  useEffect(() => {
    let url = window.location.pathname;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          // setUbicacion(position?.coords);
          console.log({ position });
        },
        (error) => {
          console.error("Error al obtener la ubicación:", error);
        }
      );
    } else {
      console.error("La geolocalización no está disponible en este navegador.");
    }
  }, []);

  // const handleChangeCountry = async ({ target }: any) => {
  //   const { value = "" } = target;
  //   setStates([]);
  //   if (value) {
  //     const { status, data } = await callEndpoint(getStatesEndpoint(value));
  //     if (status === 200) {
  //       const statesResponse = fetchResponseAdapter(data);
  //       setStates(
  //         listToSelectOptionAdapter(
  //           statesResponse.data,
  //           "state_id",
  //           "state_name"
  //         )
  //       );
  //       return;
  //     }
  //   }
  // };

  return (
    <Fragment>
      <div className="flex flex-col h-auto lg:h-screen">
        <div className=" flex-1">
          <div className="flex md:flex-row flex-col-reverse h-full">
            <div className="flex flex-col md:w-1/2 justify-center items-center text-flat-dark-blue-500 bg-white">
              <div className="md:w-10/12 md:p-0 py-14 px-8">
                <h1 className="text-3xl lg:text-4xl font-bold">
                  ¡Hola, bienvenido!👋🏼
                </h1>
                <h2 className="font-semibold text-xl mt-4">
                  Con Propi puedes:
                </h2>
                <ul className=" space-y-12 mt-14 md:w-10/12">
                  <li className="flex space-x-10">
                    <img
                      className="w-20 h-20"
                      src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/v2/quiero_comprar.svg"
                      alt=""
                    />
                    <div>
                      <p className="font-bold">Encontrar tu nuevo hogar</p>
                      <p className="mt-1 text-[#757575] text-sm leading-tight">
                        Descubre los mejores inmuebles para vivir o invertir.
                        ¡Te apoyamos a construir tu patrimonio!
                      </p>
                    </div>
                  </li>
                  <li className="flex space-x-10">
                    <img
                      className="w-20 h-20"
                      src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/v2/quiero_alquilar.svg"
                      alt=""
                    />
                    <div>
                      <p className="font-bold">Vender mi propiedad</p>
                      <p className="mt-1 text-[#757575] text-sm leading-tight">
                        ¡Haz el proceso 100% digital! Selecciona tus propiedades
                        favoritas, programa tus visitas, oferta y negocia con el
                        propietario y firma el contrato digitalmente.
                      </p>
                    </div>
                  </li>
                  <li className="flex space-x-10">
                    <img
                      className="w-20 h-20"
                      src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/v2/quiero_vender.svg"
                      alt=""
                    />
                    <div>
                      <p className="font-bold">
                        Alquilar mi propiedad y rental management
                      </p>
                      <p className="mt-1 text-[#757575] text-sm leading-tight">
                        ¡Despreocúpate de tu casa o apartamento! Nos encargamos
                        de rentabilizar tus inversiones alquilando al mejor
                        inquilino o vendiendo al mejor precio.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="bg-[#EBF1E8] drop-shadow-none md:w-1/2 flex flex-wrap px-4 py-8 md:py-0 md:px-0">
              <div className="w-full md:w-[400px] m-auto bg-white px-8 py-10 rounded-lg container-form">
                <div className="mb-4">
                  <a href={`/${country}`}>
                    <img
                      className=" mx-auto w-44"
                      src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/v2/logo-propi.svg"
                      alt=""
                    />
                  </a>
                </div>
                <p className="text-sm text-[#757575] font-medium text-left leading-tight">
                Para ofrecerte las mejores opciones para comprar o vender tu propiedad, necesitamos que completes los siguientes datos.
                </p>

                <form
                  id={
                    realm === "landlord"
                      ? "cuenta-creada-propietario"
                      : "cuenta-creada-inquilino"
                  }
                  onSubmit={handleSubmit(doRegister)}
                  className="login-form mt-3"
                >
                  <FormControlError message={loginError} />

                  <div className="relative input-icon">
                    <IconUser color="#BDBDBD" />
                    <input
                      type="text"
                      placeholder="Nombres"
                      className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-[#757575] py-2 px-2 pl-10 text-sm"
                      {...register("name")}
                    />
                  </div>

                  <FormControlError message={errors.name?.message} />

                  <div className="relative input-icon">
                    <IconUsers color="#BDBDBD" />
                    <input
                      type="text"
                      placeholder="Apellidos"
                      className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 pl-10 text-sm"
                      {...register("last_name")}
                    />
                  </div>

                  <FormControlError message={errors.last_name?.message} />

                  <div className="relative input-icon">
                    <IconMail color="#BDBDBD" />
                    <input
                      type="text"
                      placeholder="Email"
                      className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 pl-10"
                      {...register("email")}
                    />
                  </div>

                  <FormControlError message={errors.email?.message} />
                  {/* {realm === "landlord" ? (
                <>
                  <select
                    {...register("country", {
                      onChange: (e: Event) => handleChangeCountry(e),
                    })}
                    className="bg-white block py-3 px-2 w-full text-sm text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-5"
                  >
                    <option value="">Seleccione un país...</option>
                    {countries.map((c) => (
                      <option value={c.value}>{c.label}</option>
                    ))}
                  </select>
                  <FormControlError message={errors.country?.message} />
                  <select
                    {...register("state")}
                    className="bg-white block py-3 px-2 w-full text-sm text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-5"
                  >
                    <option value="">Seleccione un departamento...</option>
                    {states.map((c) => (
                      <option value={c.value}>{c.label}</option>
                    ))}
                  </select>
                  <FormControlError message={errors.state?.message} />
                </>
              ) : null} */}

                  <div className="relative input-icon">
                    <IconLock color="#BDBDBD" />
                    <input
                      type="password"
                      placeholder="Contraseña"
                      className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-[#757575] py-2 px-2 pl-10 text-sm"
                      {...register("password")}
                    />
                  </div>

                  <FormControlError message={errors.password?.message} />

                  <div className="relative input-icon">
                    <IconLock color="#BDBDBD" />
                    <input
                      type="password"
                      placeholder="Confirmar contraseña"
                      className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-[#757575] py-2 px-2 pl-10 text-sm"
                      {...register("confirm_password")}
                    />
                  </div>
                  <FormControlError
                    message={errors.confirm_password?.message}
                  />

                  {/* <input
                type="text"
                placeholder="Número teléfonico"
                className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-[#757575] py-2 px-2 pl-10 text-sm"
                {...register("phone")}
              /> */}
              <div className="relative input-icon">
              <IconPhone color="#BDBDBD" />
                  <Controller
                    control={control}
                    name="phone"
                    render={({ field: { ref } }) => (
                      <MaskInput
                        ref={ref}
                        register={register}
                        name={"phone"}
                        mask={"9999-9999"}
                        placeholder="Número teléfonico"
                        customClass="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-[#757575] py-2 px-2 pl-10 text-sm"
                      />
                    )}
                  />
                  </div>
                  <FormControlError message={errors.phone?.message} />
                  {/* <Button
                    type="submit"
                    disabled={isLoading}
                    className="relative w-full bg-primary text-white py-5 px-4 my-4 text-base rounded-full flex justify-center items-center"
                  >
                    Registrarme
                  </Button> */}
                 
                <div className="my-6">
                  <MainButton
                    topic="Crear cuenta"
                    type="submit"
                    colorText="text-white"
                    background="bg-[#212121]"
                  />
                </div>
                <div className="mt-2">
                  <p className="text-base text-[#757575] font-medium text-center">
                    ¿Ya tienes una cuenta?{" "}
                    <Link
                      to={`/app/${country}/auth/registration`}
                      className="text-primary"
                    >
                      <span className="mr-2 font-semibold">Iniciar sesión</span>
                    </Link>
                  </p>
                </div>
                </form>

                {/* <Link
                to={`/app/${country}/auth/registration`}
                className="relative w-full ease-in-out duration-300 h-[68px] hover:bg-primary hover:text-tea text-primary bg-transparent border border-primary color-primary py-5 px-4 my-4 text-white text-base rounded-full flex justify-center items-center"
              >
                <span className="mr-2">Solicitar c</span>
              </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
};

export default Registration;
