import React, { memo, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moreIcon from "../../../../assets/icons/r-arrow.svg";
import useCallApiAndLoad from "../../../../hooks/useCallApiAndLoad";
import { getDataProjects } from "../../services/devsServices";
import { useDispatch, useSelector } from "react-redux";
import { AppStore } from "../../../../redux/store";
import { getAuthToken, unsetAuth } from "../../../../helpers/authHelper";
import { logoutAction } from "../../../../redux/features/auth";
import { fetchResponseAdapter } from "../../../../adapters/fetchAdapter";
import { IProject } from "../../models/Devs";

import "./ProjectDetail.scss";
import MainTemplate from "../../layout/MainLayout";
import { IconLogout } from "@tabler/icons-react";
import { logoutActionDev } from "../../../../redux/features/authDeveloper";
import useAuthServices from "../../../../hooks/useAuthService";
const ProjectList = memo(() => {
  const { handleLogout } = useAuthServices()
  const { country } = useSelector((store: AppStore) => store.country);
  const [data, setData] = useState<Array<IProject>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { callEndpoint } = useCallApiAndLoad();
  const user = useSelector((store: AppStore) => store.auth);
  const dev = useSelector((store: AppStore) => store.authDeveloper);
  const token = getAuthToken(user);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const loadPayments = async () => {
      setIsLoading(true);
      // TODO add new fields from backend and new table
      const { status, data } = await callEndpoint(getDataProjects(token));
      if (status === 401) {
        dispatch(logoutAction({}));
        unsetAuth();
        navigate(`/app/${country}/auth/desarrolladoras/login`);
      }
      if (status === 200) {
        setIsLoading(false);
        const response = fetchResponseAdapter(data);
        // setPayments(response.data.items || [])
        setData(response.data.items);
      }
    };
    loadPayments().catch((e) => console.error(e));
  }, []);

  const goToDetail = (id: string) => {
    if (user?.realm_roles[0] === "developer_bank_dashboard") {
      navigate(`/app/${country}/desarrolladoras/propietarios/proyectos/${id}`);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else {
      navigate(`/app/${country}/desarrolladoras/proyectos/${id}`);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <MainTemplate>
      <div className="container">
        <div className="w-full pt-28 pb-24">
          <div className="flex flex-wrap lg:flex-nowrap flex-row gap-6 px-4 lg:px-0">
            <div className="w-full lg:w-1/4">
              <div className="info-card sticky top-0 flex flex-col overflow-hidden rounded-lg shadow-at-8 w-full">
                <div className="relative h-full">
                  <div className="rounded-xl px-5 py-6 bg-white">
                    {user?.realm_roles[0] === "developer_bank_dashboard" ? (
                      <div className="m-auto w-full lg:px-0">
                        <div className="w-auto">
                         
                          <h1 className="text-left w-full text-blackGrayScale text-2xl lg:text-2xl font-semibold tracking-wide">
                            Bienvenido, {user?.name}
                          </h1>
                          <p className="text-base text-left text-[#616161] -mt-1">
                            {dev?.company_role}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="m-auto w-full px-4 lg:px-0">
                        <h1 className="w-full mt-6 text-blackGrayScale text-2xl lg:text-3xl font-semibold text-left tracking-wide">
                          Bienvenido
                        </h1>
                        <p className=" text-base font-medium text-gray600 mb-2">
                          Administrador de proyectos en preventa
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-6 hidden lg:block">
                <button onClick={handleLogout} className=" flex justify-start items-center gap-2 text-base text-[#757575] font-normal">
                  <IconLogout /> Cerrar sesión
                </button>
              </div>
            </div>
            <div className="w-full lg:w-3/4">
            <h1 className="w-full text-blackGrayScale text-3xl lg:text-4xl font-semibold text-left tracking-wide">
                            Proyectos 
                          </h1>
              <p className=" text-lg font-normal text-gray600 mb-2">
                Consulta toda la información de tus proyectos
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-12 xl:gap-6 mt-14">
                {data?.length >= 0 ? (
                  data?.map((item: IProject) => {
                    return (
                      <div className="mt-4 relative flex flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-lg">
                        <div
                          style={{ backgroundImage: `url(${item.cover})` }}
                          className="relative mx-4 -mt-6 h-40 overflow-hidden rounded-xl bg-clip-border text-white shadow-lg shadow-blue-gray-500/40 bg-gradient-to-r from-blue-500 to-blue-600 bg-cover bg-no-repeat bg-center"
                        ></div>
                        <div className="p-6">
                          <h5 className=" block font-sans text-xl font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased">
                            {item.name}
                          </h5>
                          <span className="text-sm font-semibold text-[#757575] mb-3 block">
                            #{item?.code}
                          </span>
                          <div className="w-full">
                            <p className="text-[#212121] font-semibold text-sm">
                              Fecha de entrega:{" "}
                              <span className="text-[#757575] font-normal">
                                {item.estimate_built_at}
                              </span>{" "}
                            </p>
                            <p className="text-[#212121] font-semibold text-sm">
                              Unidades totales:{" "}
                              <span className="text-[#757575] font-normal">
                                {item.total_units}
                              </span>{" "}
                            </p>
                            <p className="text-[#212121] font-semibold text-sm">
                              Unidades disponibles:{" "}
                              <span className="text-[#757575] font-normal">
                                {item.total_available_units}
                              </span>{" "}
                            </p>
                          </div>
                        </div>
                        <div className="p-6 pt-0">
                          <button
                            data-ripple-light="true"
                            onClick={() => goToDetail(item?.id)}
                            type="button"
                            className="select-none rounded-lg bg-[#212121] hover:bg-primary py-3 px-6 text-center align-middle font-sans text-xs font-semibold text-white shadow-md shadow-blue-500/20 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none w-full"
                          >
                            Ver detalle
                          </button>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <> No hay disponibles</>
                )}
              </div>
            </div>
          </div>

          
        </div>
      </div>
    </MainTemplate>
  );
});

ProjectList.displayName = "ProjectList";
export default ProjectList;
