import { getCharacteristic } from "../helpers/propertyHelpers";
import { PropertyDetailAdapted, PropertyDetailModel } from "../models/MarketplaceModel";

export const propertyDetailResponseAdapter =
    (detail: PropertyDetailModel): PropertyDetailAdapted => {
        return {
            id: detail.id,
            code: detail.code,
            gallery: detail.propertyInfo.gallery,
            is_favorite: detail.propertyInfo.is_favorite,
            name: detail.propertyInfo.name,
            state_city: detail.propertyInfo.state_city,
            description: detail.propertyInfo.description,
            amenities: detail.propertyInfo.amenities,
            available_at: detail.propertyInfo.available_at,
            rooms: getCharacteristic(detail.propertyInfo.characteristics || [], 'RMS'),
            bathrooms: getCharacteristic(detail.propertyInfo.characteristics || [], 'FBTH'),
            parkings: getCharacteristic(detail.propertyInfo.characteristics || [], 'PRKN'),
            area: getCharacteristic(detail.propertyInfo.characteristics || [], 'MT2'),
            rentInfo: detail.rentInfo,
            properInfo: detail.properInfo,
            coordinates: detail.propertyInfo.coordinates,
            cover: detail.propertyInfo.cover,
            link_video: detail.propertyInfo.link_video || '',
            link_tour360: detail.propertyInfo.link_tour360 || '',
            has_white_goods: detail.propertyInfo.has_white_goods,
            is_furnished: detail.propertyInfo.is_furnished,
            pets_allowed: detail.propertyInfo.pets_allowed,
            
        }
    }