import { IconArrowLeft, IconArrowRight, IconCalendar, IconHelp, IconHome, IconHomeDollar, IconHomeHand, IconHomeMove, IconHomeStats, IconLogout, IconPhone, IconUser } from "@tabler/icons-react";
import { useState, useEffect, useRef, Fragment } from "react";
import { CSSTransition } from 'react-transition-group';
import './DropdownMenu.scss'
import { useSelector } from "react-redux";
import { AppStore } from "../../../../redux/store";
import useAuth from "../../../../hooks/useAuth";


  function DropdownMenu() {
    const { country } = useSelector((store: AppStore) => store.country);
    const user = useSelector((store: AppStore) => store.auth);
    const { handleLogout } = useAuth()
    const [activeMenu, setActiveMenu] = useState('main');
    const [menuHeight, setMenuHeight] = useState<any>(null);
    const dropdownRef = useRef<any>(null);
  
    useEffect(() => {
      setMenuHeight(dropdownRef.current?.firstChild?.offsetHeight)
    }, [])
  
    function calcHeight(el: any) {
      const height = el.offsetHeight;
      setMenuHeight(height);
    }
  
    function DropdownItem(props: any) {
      return (
        <a href={props?.url} className="menu-item " onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}>
          <span className="icon-button">{props.leftIcon}</span>
           <p className="text-[#212121] text-base font-normal">{props.children}</p>
          <span className="icon-right">{props.rightIcon}</span>
        </a>
      );
    }

    const renderMenuItems = () => {
      if (user.realm === 'landlord') {
        return (
          <>
            <DropdownItem leftIcon={<IconUser />}>Mi cuenta</DropdownItem>
            <DropdownItem leftIcon={<IconHomeStats />} rightIcon={<IconArrowRight />} goToMenu="settings">
              Soy propietario
            </DropdownItem>
            <DropdownItem url={`/${country}/ayuda`} leftIcon={<IconHelp />}>Ayuda</DropdownItem>
           <a className="menu-item cursor-pointer" onClick={() => handleLogout()}>
          <span className="icon-button"><IconLogout /></span>
           <p className="text-[#212121] text-base font-normal">Cerrar sesión</p>
          
        </a>
          </>
        );
      }
  
      if (user.realm === 'tenant') {
        return (
          <>
            <DropdownItem leftIcon={<IconUser />}>Mi cuenta</DropdownItem>
            <DropdownItem leftIcon={<IconHomeHand />} rightIcon={<IconArrowRight />} goToMenu="animals">
              Soy inquilino
            </DropdownItem>
            <DropdownItem url={`/${country}/ayuda`} leftIcon={<IconHelp />}>Ayuda</DropdownItem>
            <a className="menu-item cursor-pointer" onClick={() => handleLogout()}>
          <span className="icon-button"><IconLogout /></span>
           <p className="text-[#212121] text-base font-normal">Cerrar sesión</p>
          
        </a>
          </>
        );
      }
  
      // Para 'customer', mostrar ambas opciones
      if (user.realm === 'customer') {
        return (
          <>
            <DropdownItem leftIcon={<IconUser />}>Mi cuenta</DropdownItem>
            <DropdownItem leftIcon={<IconHomeStats />} rightIcon={<IconArrowRight />} goToMenu="settings">
              Soy propietario
            </DropdownItem>
            <DropdownItem leftIcon={<IconHomeHand />} rightIcon={<IconArrowRight />} goToMenu="animals">
              Soy inquilino
            </DropdownItem>
            <DropdownItem url={`/${country}/ayuda`} leftIcon={<IconHelp />}>Ayuda</DropdownItem>
           <a className="menu-item cursor-pointer" onClick={() => handleLogout()}>
          <span className="icon-button"><IconLogout /></span>
           <p className="text-[#212121] text-base font-normal">Cerrar sesión</p>
          
        </a>
          </>
        );
      }
  
      return null; // En caso de que user.realm no coincida con ninguna opción.
    };
  
    return (
      <div className="dropdown-user-menu" style={{ height: menuHeight }} ref={dropdownRef}>
  
        <CSSTransition
          in={activeMenu === 'main'}
          timeout={500}
          classNames="menu-primary"
          unmountOnExit
          onEnter={calcHeight}>
          <div className="menu">
            {renderMenuItems()}
          </div>
        </CSSTransition>
  
        <CSSTransition
          in={activeMenu === 'settings'}
          timeout={500}
          classNames="menu-secondary"
          unmountOnExit
          onEnter={calcHeight}>
          <div className="menu">
            <DropdownItem goToMenu="main" leftIcon={<IconArrowLeft />}>
              Soy propietario
            </DropdownItem>
            <DropdownItem url={`/app/${country}/l/ld/payments`} leftIcon={<IconHome color="#616161" />}>Mis Inversiones</DropdownItem>
            <DropdownItem url={`/app/${country}/l/ld/pre-booking`} leftIcon={<IconHomeDollar color="#616161" />}>Mis pre-reservas</DropdownItem>
            <DropdownItem url={`/app/${country}/l/properties`} leftIcon={<IconHomeMove color="#616161" />}>Ofertas y propiedades</DropdownItem>
          </div>
        </CSSTransition>
  
        <CSSTransition
          in={activeMenu === 'animals'}
          timeout={500}
          classNames="menu-secondary"
          unmountOnExit
          onEnter={calcHeight}>
          <div className="menu">
            <DropdownItem goToMenu="main" leftIcon={<IconArrowLeft />}>
              Soy inquilino
            </DropdownItem>
            <DropdownItem url={`/app/${country}/l/tn/offers`} leftIcon={<IconHomeDollar color="#616161" />}>Ofertas realizadas</DropdownItem>
            <DropdownItem url={`/app/${country}/l/tn/visits`} leftIcon={<IconCalendar color="#616161" />}>Mis visitas</DropdownItem>
            <DropdownItem url={`/app/${country}/l/tn/payments`} leftIcon={<IconHomeMove color="#616161" />}>Mis alquileres</DropdownItem>
            <DropdownItem url={`/app/${country}/l/tn/payments-sales`} leftIcon={<IconHomeDollar color="#616161" />}>Mis compras</DropdownItem>
          </div>
        </CSSTransition>
      </div>
    );
  }

  export default function DropdownMenuUser() {
    const { visible: showNav } = useSelector(
      (store: AppStore) => store.navigation
    );
    return (
      <Fragment>
{
        showNav && <DropdownMenu />
      }

      </Fragment>
      
        
          
        

    );
  }

  