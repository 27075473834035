import { createSlice } from "@reduxjs/toolkit";
import { FiltersStore } from '../../models/PropertyModel';

const initialState: FiltersStore = {
    is_new: null,
    is_furnished: null,
    has_white_goods: null,
    min_price: null,
    max_price: null,
    min_area: null,
    max_area: null,
    type: null,
    rooms: null,
    bathrooms: null,
    sort: null,
    lat: null,
    lng: null,
    zoom_search: null,
    location_id: null,
    location_type: null,
    page: null,
    take:null,
    marketplace_type: null,
    parking_spots: null
}

export const filtersPropertiesSlice = createSlice({
    name: 'filters-properties',
    initialState,
    reducers: {
        clearFiltersAction: () => (initialState),
        setFiltersAction: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
    }
})

// provide actions
export const { setFiltersAction, clearFiltersAction } = filtersPropertiesSlice.actions;
// provide reducer
export default filtersPropertiesSlice.reducer