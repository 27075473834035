import { ReactNode, useEffect } from "react";
//icon
import closeModal from "../assets/icons/close-modal.svg";

type IModalProps = {
  name?: string;
  body?: ReactNode;
  onAction: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onHandleSubmit?: () => void | undefined;
  size?: string;
  page?: string;
};

const Modal: React.FC<IModalProps> = ({
  name = "",
  body,
  onAction = () => {},
  onHandleSubmit = () => {},
  size = "w-1/2",
  page,
}) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";
  }, []);

  return (
    <div className="overflow-hidden fade modal">
      <div
        className={`fixed inset-0 z-50 h-full w-screen overflow-hidden ${
          page === "market" ? "" : "bg-modalBg"
        }  bg-blend-multiply`}
      >
        <div className="w-full h-full flex justify-center items-center px-3 md:px-0">
          <div className={`w-full ${size} mx-auto rounded-t-md`}>
            <div
              className={`${
                name !== "" ? "bg-blueForm" : ""
              } py-3 px-2 flex justify-between items-center rounded-t-md`}
            >
              <div className="text-white font-semibold text-lg">{name}</div>
              <button
                className="text-white text-2xl"
                id="navMobile"
                onClick={onAction}
              >
                <i>
                  <img src={closeModal} alt="closemodal" />
                </i>
              </button>
            </div>
            {body}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
