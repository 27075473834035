import React, { memo, useEffect, useState } from "react";
import NavBarDev from "../../../components/NavBar/NavBarDev";
import LoadingComponent from "../../../components/Loading/Loading";
import { useLocation } from "react-router-dom";

interface MainTemplateProps {
  children: React.ReactNode | JSX.Element | JSX.Element[] | React.ReactNode[];
}
const MainTemplate = memo(({ children }: MainTemplateProps) => {
  const [preLoad, setPreLoad] = useState(true);
  const location = useLocation(); // Obtiene la ruta actual

  useEffect(() => {
    setTimeout(() => {
      setPreLoad(!preLoad);
    }, 2200);
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;

    if (currentPath.includes('/desarrolladoras')) {
      // Asignar un background color fijo cuando la ruta sea /desarrolladoras
      document.documentElement.style.backgroundColor = '#f6f6f6'; // Cambia el color a tu preferencia
      document.documentElement.style.backgroundAttachment = 'fixed';
    } else {
      // Restablecer el background color cuando no sea /desarrolladoras
      document.documentElement.style.backgroundColor = '';
      document.documentElement.style.backgroundAttachment = '';
    }

    // Limpiar estilos al desmontar el componente o cambiar de ruta
    return () => {
      document.documentElement.style.backgroundColor = '';
      document.documentElement.style.backgroundAttachment = '';
    };
  }, [location.pathname]);


  return (
    <>
      {!preLoad ? (
        <div className="layout bg-[#f6f6f6]">
          <NavBarDev />
          <div className="body">{children}</div>
        </div>
      ) : (
        <LoadingComponent />
      )}
    </>
  );
});

MainTemplate.displayName = "MainTemplate";
export default MainTemplate;
