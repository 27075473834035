import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import moreIcon from "../../assets/icons/r-arrow.svg";
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import { getAuthToken, unsetAuth } from '../../helpers/authHelper';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { logoutAction } from '../../redux/features/auth';
import { AppStore } from '../../redux/store';
import { BookingList } from './models/investorModel';
import { getInvestorBookingEndpoint } from './services/investorService';

const InvestorPrebooking = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const [data, setData] = useState<Array<BookingList>>([]);
  const [checkAuto, setCheckAuto] = useState<boolean>(false)

  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const loadPayments = async () => {
      // TODO add new fields from backend and new table
      const { status, data } = await callEndpoint(
        getInvestorBookingEndpoint(token)
      );
      if (status === 401) {
        dispatch(logoutAction({}));
        unsetAuth();
        return navigate(`/app/${country}/auth/login`);
      }
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setData(response.data.items);
      }
    };
    loadPayments()
      .catch((e) => console.error(e));
  }, []);

  const gotToMarket = () => {
    window.open(`/${country}/venta/casas-y-apartamentos/proyectos`, '_self');
  }

  return (
    <div className="container">
      <div className="w-full pt-24">
        <div className="w-full text-center my-6 text-third text-lg">
          Gestiona tus proyectos de inversión
        </div>
        <div className="w-full my-6 text-third text-4xl font-bold text-center">
          Pre reservas
        </div>
        <hr />
        <div className="w-full my-8 px-4 lg:mt-24 mt-16">
          <div className="w-full py-6 px-4 hidden lg:flex justify-start border-grayDark border shadow-md rounded bg-plight mb-4">
            <div className="text-blueForm text-sm flex justify-center items-center border-r border-grayDark font-medium w-60">
              Imagen
            </div>
            <div className="text-blueForm text-sm flex justify-center items-center border-r border-grayDark font-medium w-32">
              ID
            </div>
            <div className="text-blueForm text-sm flex justify-center items-center border-r border-grayDark font-medium w-36">
              Desarrolladora
            </div>
            <div className="text-blueForm text-sm flex justify-center items-center border-r border-grayDark font-medium w-36">
              Precio
            </div>
            <div className="text-blueForm text-sm flex justify-center items-center border-r border-grayDark font-medium w-36">
              Unidad
            </div>
          </div>
          {data && data.length > 0 ? (
            data.map((items) => {
              return (
                <div key={items.id} className="border border-tea-500 mb-10 rounded">
                  <div className="hidden lg:flex justify-start py-6 px-4 items-center">
                    <div className="text-graySoft border-r border-tea-500 w-60">
                      <img
                        src={items.cover}
                        className="w-[220px] h-[120px] "
                        alt=""
                      />
                    </div>
                    <div className="flex justify-center items-center text-sm text-graySoft border-r border-tea-500 w-32">
                      {items.project_code}
                    </div>
                    <div className="flex text-center justify-center items-center text-sm text-graySoft border-r border-tea-500 w-36">
                      {items.developer_name}
                    </div>
                    <div className="flex justify-center items-center text-sm text-graySoft border-r border-tea-500 w-36">
                      {items.price}
                    </div>
                    <div className="flex justify-center items-center text-sm text-graySoft border-r border-tea-500 w-36">
                      {items.model_unit_name}
                    </div>

                    <div className="text-graySoft text-sm font-medium flex justify-end items-center">
                      <div className="text-center flex items-center ml-4 ">
                        <Link
                          to={`/app/${country}/l/ld/pre-booking/${items.id}`}
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          <button className="duration-300 hover:translate-x-4 cursor-pointer text-sm flex justify-center items-center">
                            Ver detalle
                            <img className="ml-3" src={moreIcon} alt="" />
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* responsive */}
                  <div className="block lg:hidden px-3 py-3 shadow-md">
                    <div className="w-full px-2 py-3 bg-gray300 rounded-md mb-2">
                      <span className="text-sm font-medium text-grayText">
                        Información de modelo
                      </span>
                      <p className="text-sm font-normal text-graySoft">
                      </p>
                    </div>

                    <div className="w-full px-2 py-3 bg-tea-100 rounded-sm flex justify-between">
                      <div className="w-1/2">
                        <span className="text-sm font-medium text-grayText">
                          ID
                        </span>
                        <p className="text-sm font-normal text-graySoft">
                          {items.project_code}
                        </p>
                      </div>
                      <div className="w-1/2">
                        <span className="text-sm font-medium text-grayText">
                          Desarrolladora
                        </span>
                        <p className="text-sm font-normal text-graySoft">
                          {items.developer_name}
                        </p>
                      </div>
                    </div>

                    <div className="w-full px-2 py-3 bg-white rounded-sm flex justify-between">
                      <div className="w-1/2">
                        <span className="text-sm font-medium text-grayText">
                          Precio
                        </span>
                        <p className="text-sm font-normal text-graySoft">
                          {items.price}
                        </p>
                      </div>
                      <div className="w-1/2">
                        <span className="text-sm font-medium text-grayText">
                          Unidad
                        </span>
                        <p className="text-sm font-normal text-graySoft">
                          {items.model_unit_name}
                        </p>
                      </div>
                    </div>
                    <div className="w-full flex justify-center mt-4">
                      <Link
                        to={`/app/${country}/l/ld/pre-booking/${items.id}`}
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <p className=" text-sm font-medium text-graySoft flex items-center">
                          Ver detalle
                          <img className="ml-3" src={moreIcon} alt="" />
                        </p>
                      </Link>
                    </div>
                  </div>
                  {/* end responsive */}
                </div>
              );
            })
          ) : (
            <>
              <div className="w-full flex flex-col justify-center items-center bg-empty-state py-10 h-[250px]">
               <p className='lg:text-base text-sm text-graySoft'>Parece ser que no tienes pre-reservas.</p> 
                <div onClick={gotToMarket} className="border-b-[1.35px] ease-in-out duration-300 cursor-pointer hover:translate-x-4 border-orange w-fit  text-orange text-left flex items-center justify-start">
                Ver proyectos <span className="ml-2"><img src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/arrow-orange.svg" alt="" /></span>
              </div>
              </div>
            </>
          )}

        </div>
      </div>
    </div>
  )
}

export default InvestorPrebooking