import React, { memo, useEffect } from 'react'
import { useStep } from 'usehooks-ts'
import { useDispatch } from 'react-redux'
import { getStorageOffer } from '../../helpers/guestHelper';
import LayoutGuestOffer from './components/LayoutGuest/Layout'
import StepOneOffer from './components/StepOneOffer/StepOneOffer'
import StepTwoOffer from './components/StepTwoOffer/StepTwoOffer'
const GuestOffer = memo(() => {
  const [currentStep, helpers] = useStep(2)
  const dispatch = useDispatch()

  useEffect(() => {
    const prevDataOffer = getStorageOffer()
  })

  return (
    <LayoutGuestOffer image='https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/guest.png'>
      <>
        {(() => {
          switch (currentStep) {
            case 1:
              return <StepOneOffer helpers={helpers} />
            case 2:
              return <StepTwoOffer helpers={helpers} />
            default:
              return null
          }
        })()}
      </>
    </LayoutGuestOffer>
  )
})

GuestOffer.displayName = 'GuestOffer'
export default GuestOffer
