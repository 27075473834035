import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { AppStore } from "../../redux/store";
import { logoutAction } from "../../redux/features/auth";
import Button from "../Button";
import DateInput from "../Controls/DateInput";
import FormControlError from "../Controls/FormControlError";
import FormControlInfo from "../Controls/FormControlInfo";

import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import {
  checkSignedConsentEndpoint,
  checkVisitRequirementsEndpoint,
  consentRequestEndpoint,
  createOfferEndpoint,
  prequalificateEndpoint,
  saveVisitRequirementsEndpoint,
} from "../../services/offerService";

import { fetchResponseAdapter } from "../../adapters/fetchAdapter";
import { OfferForm, OfferRequirementsForm } from "../../models/offerModel";
import { getAuthToken, unsetAuth } from "../../helpers/authHelper";
import arrow_button from "../../assets/icons/arrow_button.svg";
import SelectInput from "../Controls/SelectInput";
import { setOfferAction, setShowOfferProcessAction } from "../../redux/features/offer";
import FormLabel from "../Controls/FormLabel";
import MaskInput from "../Controls/MaskInput";
import CurrencyInput from "../Controls/CurrencyInput";
import { parseNumberCurrencyValue } from "../../helpers/currencyHelper";
import { getOfferPeriods } from "../../helpers/OfferHelper";

export interface RentItem {
  item: string;
  value: string;
  is_total: boolean;
}

interface Props {
  name?: string;
  btnText?: string;
  property_id: string;
  rentInfo: Array<RentItem> | undefined;
  parent?: string
}

const ModalOffer = (props: Props) => {
  const { country } = useSelector((store: AppStore) => store.country);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((store: AppStore) => store.auth);
  const offer = useSelector((store: AppStore) => store.offer);
  const token = getAuthToken(user);
  const [formError, setFormError] = useState("");
  const { rentInfo, property_id } = props;
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [validRequirements, setValidRequirements] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<OfferRequirementsForm>({
    resolver: yupResolver(
      yup
        .object({
          price: yup
            .string()
            .required("Precio de oferta es requerido"),
          request_date: yup.string().required("Fecha es requerida"),
          contract_duration: yup.string().required("Periodo es requerido"),
          ...(!validRequirements
            ? {
              document: yup.string().required("Documento es requerido"),
            }
            : {}),
        })
        .required()
    ),
  });

  useEffect(() => {
    const checkVisitRequirements = async () => {
      const { status, data } = await callEndpoint(checkVisitRequirementsEndpoint(token));
      if (status === 401) {
        dispatch(logoutAction({}));
        unsetAuth();
        return navigate(`/app/${country}/auth/login`);
      }
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        const { is_valid } = response.data;
        setValidRequirements(is_valid);
      }
    };

    // const hasConsent = async () => {
    //   const { status, data } = await callEndpoint(checkSignedConsentEndpoint(token));
    //   if (status === 401) {
    //     dispatch(logoutAction({}));
    //     unsetAuth();
    //     return navigate("/auth/login");
    //   }
    //   if (status === 200) {
    //     const response = fetchResponseAdapter(data);
    //     const { required = false } = response.data;
    //     console.log(required);
    //     dispatch(
    //       setOfferAction({
    //         has_consent: !required,
    //       })
    //     );
    //   }
    // };
    checkVisitRequirements()
      .catch((e) => console.error(e));
    // hasConsent()
    //   .catch((e) => console.error(e));
  }, []);

  /*
  1 - si tiene consent ofertar
  2 - si no tiene consent
    - precalificar
      si no tiene perfil en equifax -> ofertar
      si tiene perfil en equifax
    - generamos consentimiento
        si nos da error -> metemos oferta
    - firmamos consentimiento
        si nos da error -> metemos oferta
    debe ser un proceso que se puedan llamar los endpoints cuando queramos y no afectar el resultado en caso sean satisfactorios
    
  */

  const createOffer = async (form: OfferRequirementsForm) => {
    const offerForm: OfferForm = {
      price: parseNumberCurrencyValue(form.price, ','),
      property_id: form.property_id,
      estimate_moving_at: form.request_date,
      period: parseInt(form.contract_duration)
    }
    const { status, data } = await callEndpoint(createOfferEndpoint(offerForm, token))
    if (status === 401) {
      dispatch(logoutAction({}))
      unsetAuth()
      return navigate(`/app/${country}/auth/login`)
    }
    if (status === 201) {
      if (props.parent === 'tenant-offers') {
        return navigate(0)
      }
      dispatch(setShowOfferProcessAction(false))
      return
    }
    if (status === 409) {
      setFormError('Ya posees una oferta para esta propiedad, revisala en tu menú "Ofertas realizadas"')
      return
    }
    if (status === 406) {
      const responseAdapted = fetchResponseAdapter(data)
      setFormError(responseAdapted.message || 'Oops! La oferta es menor al monto negociable')
      return
    }
    setFormError('Error al momento de crear la oferta, Intenta nuevamente')
  }

  const doOffer = async (form: OfferRequirementsForm) => {
    setFormError("");
    form.property_id = property_id;
    form.document = form.document?.replace("-", "");
    if (!validRequirements) {
      const {
        status: docStatus
      } = await callEndpoint(saveVisitRequirementsEndpoint(form.document, token))
      if (docStatus !== 201) {
        setFormError('Ocurrió un error al almacenar documento, intente mas tarde')
        return
      }
      setValidRequirements(true)
      callEndpoint(prequalificateEndpoint(token))
        .then()
        .catch()
    }

    await createOffer(form)

    // if (offer.has_consent) { // do offer
    //   await createOffer(form)
    // } else { // do request consent process
    //   const {
    //     status: statusPrequalification, data: dataPrequalification
    //   } = await callEndpoint(prequalificateEndpoint(token))
    //   if (statusPrequalification !== 201) {
    //     // set offer
    //     await createOffer(form)
    //     return
    //   }
    //   const {
    //     status, data
    //   } = await callEndpoint(consentRequestEndpoint(token))
    //   if (status === 200) {
    //     const responseData = fetchResponseAdapter(data)
    //     dispatch(
    //       setOfferAction({
    //         property_id: property_id,
    //         score_id: responseData.data.id || '',
    //         price: parseNumberCurrencyValue(form.price, ','),
    //         request_date: form.request_date,
    //         contract_duration: form.contract_duration,
    //         request_consent: true,
    //       })
    //     );
    //   } else {
    //     // set offer
    //     await createOffer(form)
    //   }
    // }
  };

  return (
    <form onSubmit={handleSubmit(doOffer)}>
      <div className="bg-white p-4">
      <div className="w-full mb-4 text-blackGrayScale text-2xl lg:text-2xl font-semibold text-left tracking-wide flex justify-start items-center">
               Ofertar propiedad
      </div>
        <div className="overflow-y-auto">
          <div className="px-2">
            {rentInfo &&
              rentInfo.length > 0 &&
              rentInfo.map((rentItem) => {
                const { is_total = false, item, value } = rentItem;
                return (
                  !is_total && (
                    <div className="w-full flex justify-between items-center my-2 px-4">
                      <div className="text-[#757575] text-sm">{item}</div>
                      <div className="text-sm text-[#212121] font-semibold">{value}</div>
                    </div>
                  )
                );
              })}
          </div>
          {rentInfo &&
            rentInfo.length > 0 &&
            rentInfo.map((rentItem) => {
              const { is_total = false, item, value } = rentItem;
              return (
                is_total && (
                  <div className="px-3 py-2 rounded-full bg-[#f5f5f5] flex justify-between items-center ">
                    <div className="text-sm text-[#757575]">{item}</div>
                    <div className="text-xl text-[#212121] font-semibold">
                      {value}
                    </div>
                  </div>
                )
              );
            })}
          <div className="w-full my-4">
          <h3 className="text-lg font-medium text-[#212121] text-left mb-2">
          Haz una oferta
        </h3>
            <CurrencyInput
              name="price"
              register={register}
              currency={"$"}
              thousand_separator={","}
              decimal_separator={"."}
            />
            <FormControlError message={errors.price?.message} />

            {!validRequirements && (
              <>
                <FormLabel label="Número de DUI" />
                <Controller
                  control={control}
                  name="document"
                  render={({ field: { ref } }) => (
                    <MaskInput
                      ref={ref}
                      register={register}
                      name={"document"}
                      mask={"99999999-9"}
                    />
                  )}
                />
                <FormControlError message={errors.document?.message} />
                <FormControlInfo message="Por seguridad necesitamos verificar tu indentidad" />
              </>
            )}

            <FormLabel label="¿Cuándo deseas mudarte a la propiedad?" />
            <DateInput
              register={register}
              name={"request_date"}
              disablePastDates
            />
            <FormControlError message={errors.request_date?.message} />

            <FormLabel label="¿Cuánto tiempo deseas alquilar este inmueble?" />
            <SelectInput
              list={getOfferPeriods()}
              register={register}
              name={"contract_duration"}
            />
            <FormControlError message={errors.contract_duration?.message} />

            <FormControlError message={formError} />
          </div>
        </div>
      </div>
      <div className="bg-white w-full px-4 pb-4">
        <div className="w-full grid grid-cols-2 gap-6">
          <button onClick={() => {
            document.body.style.overflowY = "scroll";
            dispatch(setShowOfferProcessAction(false))
          }}>
            <div className="border border-[#212121] rounded-lg px-4 py-2 text-[#212121] hover:bg-[#212121] hover:text-white duration-100">Cancelar</div>
          </button>
          <Button
            disabled={isLoading}
            className="px-4 py-2 rounded-lg bg-[#212121] hover:bg-primary duration-100 text-white">
            {props.btnText}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ModalOffer;
