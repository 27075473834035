import { createSlice } from "@reduxjs/toolkit";
import { AuthUser } from '../../models/authUserModel';

const initialState: AuthUser = {
    token: '',
    rft: '',
    name: '',
    avatar: '',
    realm: '',
    last_name: '',
    realm_roles: [],
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginAction: (state, action) => {
            return {
                ...action.payload
            }
        },
        logoutAction: (state, action) => initialState,
        loginActionDeveloper: (state, action) => {
            return {
                ...action.payload
            }
        }
    }
})

// provide actions
export const { loginAction, logoutAction, loginActionDeveloper } = authSlice.actions;
// provide reducer
export default authSlice.reducer