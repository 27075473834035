import { useEffect, useState } from "react";
import { generateRandomString } from "../helpers/stringHelper";
import { useDispatch } from "react-redux";
import { initFollowUpAction } from "../redux/features/polygonsUserFollowUp";
const SESSION_LENGTH = 35;

const useSessionManager = () => {
  const [sessionId, setSessionId] = useState<string>();

  const generateSessionId = () => {
    return generateRandomString(SESSION_LENGTH);
  };

  useEffect(() => {
    // Check if session ID already exists
    const existingSessionId: string | null = localStorage.getItem("sid");
    if (existingSessionId) {
      setSessionId(existingSessionId);
    } else {
      // create new sessionId and store it
      const newSessionId = generateSessionId();
      setSessionId(newSessionId);
      localStorage.setItem("sid", newSessionId);
    }
  }, []);

  return {
    sessionId,
  };
};

export default useSessionManager;
