import React, { memo } from "react";
import { PopularCities } from "../../../../models/PropertyModel";
import CardPopular from "../../../../components/CardPopular/CardPopular";
import "./NotFoundProperties.scss";
import { AppStore } from "../../../../redux/store";
import { useSelector } from "react-redux";
import ScrollContainer from "react-indiana-drag-scroll";
import { IconArrowNarrowRight } from "@tabler/icons-react";


interface NotPropertiesProps {
  title: string;
  subtitle: string;
  citiesPopular: PopularCities[];
}

const NotFoundProperties = memo(
  ({ title, subtitle, citiesPopular }: NotPropertiesProps) => {
    const { country } = useSelector((store: AppStore) => store.country);
    return (
      <div className="pt-10 md:py-20 bg-white mx-auto">
        <div className="container mx-auto">
          <div className="w-full flex flex-wrap">
            <div className="w-full">
              <p className="text-[#757575] text-xs md:text-base text-left mb-2">
                {subtitle}
              </p>
              <h2 className="text-[#212121] text-2xl md:text-4xl mb-6 text-left font-semibold">
                {title}
              </h2>
            </div>
            <ScrollContainer className="w-full overflow-x-auto py-4">
              <div className="flex flex-nowrap space-x-4 pb-4">
                {citiesPopular.length > 0 &&
                  citiesPopular.map((item, index) => (
                    <div
                      className="card-p flex-shrink-0 w-64 lg:w-1/4 xl:w-1/5 px-2 py-2"
                      key={index}
                    >
                      <CardPopular
                        city_name={item.city_name}
                        location_id={item.location_id}
                        location_type={item.location_type}
                        location_zoom={item.location_zoom}
                        qty={item.qty}
                        url_cover={item.url_cover}
                        lat={item.lat}
                        lng={item.lng}
                      />
                    </div>
                  ))}
              </div>
            </ScrollContainer>
          </div>
        </div>
      </div>
    );
  }
);
NotFoundProperties.displayName = "NotFoundProperties";
export default NotFoundProperties;
