import React, { ReactNode, useEffect, useState } from "react";

type IPropertyProps = {
  id?: string | number;
  address?: string;
  price?: string;
  image?: string;
};

const PropertyDetail: React.FC<IPropertyProps> = (props) => {
  return (
    <>
      <div className="hidden  lg:flex justify-start w-full items-center border rounded-lg">
        <div className="text-graysoft w-56">
          <div>
            <div className="bg-cover bg-center w-40 h-24 rounded-l-lg">
              <img className="rounded-l-lg w-full h-full" src={props.image} />
            </div>
          </div>
        </div>
        <div className="text-graySoft font-300 pl-4 w-32  border-r-2 ">
          # {props.id}
        </div>
        <div className="text-graySoft font-300 border-r-2 pl-4 w-72">
          {props.address}
        </div>
        <div className="text-graySoft font-300 px-4 w-40">
          {props.price}
        </div>
      </div>

      <div className="flex w-full lg:hidden p-4 items-center justify-between border rounded-lg shadow-md">
        <div className="w-2/5">
          <div
            className="bg-cover bg-center w-24 h-24 rounded-lg">
            <img className="rounded w-full h-full" src={props.image} />
          </div>
        </div>
        <div className="w-3/5 px-2">
          <div className="w-full my-4">
            <span className="text-blueForm font-medium mr-6">ID</span>
            <span className="text-graysoft text-sm">{props.id}</span>
          </div>
          <div className="w-full">
            <span className="text-graySoft font-medium mr-6">Cuota </span>
            <span className="text-graySoft text-sm">$ {props.price}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyDetail;
