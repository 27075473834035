import { useContext, useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchResponseAdapter } from "../../../adapters/fetchAdapter";
import useCallApiAndLoad from "../../../hooks/useCallApiAndLoad";
import { getTypePropertiesEndpoint } from "../../Properties/services/PropertiesService";
import {
  setFiltersAction,
} from "../../../redux/features/filters";
import { AppStore } from "../../../redux/store";
import { ModalContext } from "../context/ModalContext";
import { FiltersStore } from "../../../models/PropertyModel";
import { cleanFormParams, replaceFormParams } from "../helpers/paramsHelpers";
import FormControlError from "../../../components/Controls/FormControlError";
import {
  Box,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  Slider,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import { createSearchParams, useNavigate } from "react-router-dom";

import "./scss/FilterForm.scss";
import {
  IconBed,
  IconBath,
  IconCar,
  IconSquareRoundedMinus,
  IconSquareRoundedPlus,
} from "@tabler/icons-react";

interface FilterData {
  min_price: string;
  max_price: string;
  min_area: string;
  max_area: string;
  type: string;
  is_new: string;
  is_furnished: string;
  has_white_goods: string;
  rooms: string;
  bathrooms: string;
  parking_spots: string;
  marketplace_type: string;
  min_square_rods: string;
  max_square_rods: string;
}
export interface PublishCatalogs {
  propertyTypes: Array<any>;
}
const furnishedOptions: any = [
  { name: "Sí", value: true },
  { name: "No", value: false },
];

const FilterForm = () => {
  const { handleModal } = useContext(ModalContext);
  const filters = useSelector((store: AppStore) => store.filters);
  const { country } = useSelector((store: AppStore) => store.country);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    control,
    setValue,
  } = useForm<FilterData>();
  const { callEndpoint } = useCallApiAndLoad();
  const [error, setError] = useState("");
  const [catalogs, setCatalogs] = useState<PublishCatalogs>({
    propertyTypes: [],
  });

  const [values, setValues] = useState({
    min_area: 0,
    max_area: 0,
    min_square_rods: 50,
    max_square_rods: 1000,
  });

  const navigate = useNavigate();

  const [sliderArea, setSliderArea] = useState([50, 300]);
  const [sliderSquare, setSliderSquare] = useState([
    values.min_square_rods,
    values.max_square_rods,
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    const callCatalogs = async () => {
      const propertyTypes = await callEndpoint(getTypePropertiesEndpoint());
      if (propertyTypes.status !== 200) {
        setError(
          "Ocurrio un error al momento de cargar los catalogos de tipos"
        );
        return;
      }
      const propertyTypesResponse = fetchResponseAdapter(propertyTypes.data);
      setCatalogs({
        propertyTypes: propertyTypesResponse.data,
      });
    };
    callCatalogs().catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    if (filters) {
      reset({
        parking_spots: filters?.parking_spots
          ? filters?.parking_spots.toString()
          : null,
        marketplace_type: filters?.marketplace_type,
        min_price: filters?.min_price,
        max_price: filters?.max_price,
        min_area: filters?.min_area,
        max_area: filters?.max_area,
        rooms: filters?.rooms ? filters?.rooms.toString() : null,
        bathrooms: filters?.bathrooms ? filters?.bathrooms.toString() : null,
        type: filters?.type,
        is_furnished:
          filters?.is_furnished !== null
            ? filters?.is_furnished
              ? "true"
              : "false"
            : undefined,
        has_white_goods:
          filters?.has_white_goods !== null
            ? filters?.has_white_goods
              ? "true"
              : "false"
            : undefined,
      });
    }
  }, []);

  const clearForm = () => {
    const paramsToPreserve: string[] = ["marketplace_type"]; // Lista de parámetros a conservar
    const urlParams = new URLSearchParams(window.location.search);
  
    const updatedParams: string[] = Array.from(urlParams.entries()).reduce(
      (result: string[], [nameFilter, value]: [string, string]) => {
        if (paramsToPreserve.includes(nameFilter)) {
          // Conservar el parámetro
          result.push(`${nameFilter}=${value}`);
        } else {
          // Eliminar el parámetro de la URL y establecer en null en el estado
          urlParams.delete(nameFilter);
          dispatch(setFiltersAction({ [nameFilter]: null }));
        }
        return result;
      },
      []
    );
  
    const newUrl = `${window.location.pathname}?${updatedParams.join("&")}`;
  
    // Restaurar la capacidad de desplazamiento vertical en el cuerpo del documento
    document.body.style.overflowY = "scroll";
  
    // Abrir la nueva URL en la misma ventana/tab
    window.open(newUrl, "_self");
    console.log({ newUrl });
  };
  
  

  const onChangeSliderArea = (event: any, newValue: any) => {
    setSliderArea(newValue);
    setValues((state) => ({
      ...state,
      min_area: newValue[0],
      max_area: newValue[1],
    }));
    setValue("min_area", newValue[0]);
    setValue("max_area", newValue[1]);
  };

  const sendData: SubmitHandler<FilterData> = (data) => {
    // merge missing filters in the form (e.g. lat lng)
    if (data.min_price !== null && data.max_price !== null) {
      if (+data?.min_price > +data?.max_price) {
        setError("Precio minimo no puede ser mayor al precio máximo");
        return;
      }
    }

    if (data.min_area !== null && data.max_area !== null) {
      if (+data?.min_area > +data?.max_area) {
        setError("Area minima no puede ser mayor al área máxima");
        return;
      }
    }

    const filtersNull: any = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => value !== null)
    );
    const dataNull: any = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== null)
    );

    const replaceParamsObject: any = {
      ...filtersNull,
      ...dataNull,
      lat: filters.lat || null,
      lng: filters.lng || null,
    };
    if (JSON.stringify(data) !== "{}") {
      const filterDataMapped: FiltersStore =
        replaceFormParams(replaceParamsObject);
      dispatch(setFiltersAction(filterDataMapped));
      const cleanedFilters = cleanFormParams(filterDataMapped);
      if (Object.keys(cleanedFilters).length > 0) {
        navigate(`?${createSearchParams(cleanedFilters)}`);
      }

      handleModal();
      document.body.style.overflowY = "scroll";
    }
  };

  const sendDataSales: SubmitHandler<FilterData> = (data) => {
    // merge missing filters in the form (e.g. lat lng)
    if (data.min_price !== null && data.max_price !== null) {
      if (+data?.min_price > +data?.max_price) {
        setError("Precio minimo no puede ser mayor al precio máximo");
        return;
      }
    }

    if (data.min_area !== null && data.max_area !== null) {
      if (+data?.min_area > +data?.max_area) {
        setError("Area minima no puede ser mayor al área máxima");
        return;
      }
    }

    const filtersNull: any = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => value !== null)
    );
    const dataNull: any = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== null)
    );

    const replaceParamsObject: any = {
      ...filtersNull,
      ...dataNull,
      lat: filters.lat || null,
      lng: filters.lng || null,
    };

    const filtersSales: any = {
      ...filtersNull,
      ...dataNull,
      min_square_rods: data.min_square_rods || null,
      max_square_rods: data.max_square_rods || null,
      type: null,
      marketplace_type: null,
    };

    if (buildType === "used") {
      if (JSON.stringify(data) !== "{}") {
        const filterDataMapped: FiltersStore =
          replaceFormParams(replaceParamsObject);
        dispatch(setFiltersAction(filterDataMapped));
        const cleanedFilters = cleanFormParams(filterDataMapped);
        if (Object.keys(cleanedFilters).length > 0) {
          navigate(`?${createSearchParams(cleanedFilters)}`);
        }

        handleModal();
        document.body.style.overflowY = "scroll";
      }
    } else {
      if (JSON.stringify(data) !== "{}") {
        const filterDataMapped: FiltersStore = replaceFormParams(filtersSales);
        // dispatch(setFiltersAction(filterDataMapped));
        const cleanedFilters = cleanFormParams(filterDataMapped);
        if (Object.keys(cleanedFilters).length > 0) {
          window.open(
            `https://www.propilatam.com/${country}/venta/casas-y-apartamentos/proyectos?${createSearchParams(
              cleanedFilters
            )}`,
            "self"
          );
        }

        handleModal();
      }
    }
  };


  const [buildType, setBuildType] = useState("used");

  const handleBuildtype = (build: string) => {
    setBuildType(build);
    reset()
    
  };

  const onChangeSliderSquare = (event: any, newValue: any) => {
    setSliderSquare(newValue);
    setValues((state) => ({
      ...state,
      min_square_rods: newValue[0],
      max_square_rods: newValue[1],
    }));
    setValue("min_square_rods", newValue[0]);
    setValue("max_square_rods", newValue[1]);
  };

  return (
    <>
      <form
        onSubmit={
          filters?.marketplace_type === "for_sale"
            ? handleSubmit(sendDataSales)
            : handleSubmit(sendData)
        }
      >
        <div className="mt-4 block md:hidden relative">
          <label className="text-sm lg:text-base text-[#757575]">
            ¿Qué quieres hacer?
          </label>
          <div className="form-radio-filter flex justify-between flex-col md:flex-row mt-4">
            <div className="flex w-full relative">
              <>
                <input
                  {...register("marketplace_type")}
                  type="radio"
                  name="marketplace_type"
                  value="for_rent"
                  id="for_rent"
                />
                <label
                  className={`py-3 lg:px-8 w-full cursor-pointer flex justify-center text-xs lg:text-sm left`}
                  htmlFor="for_rent"
                >
                  Alquilar
                </label>
              </>

              <>
                <input
                  {...register("marketplace_type")}
                  type="radio"
                  name="marketplace_type"
                  value="for_sale"
                  id="for_sale"
                />
                <label
                  className={`py-3 lg:px-8 w-full cursor-pointer flex justify-center text-xs lg:text-sm right`}
                  htmlFor="for_sale"
                >
                  Comprar
                </label>
              </>
            </div>
            <br />
          </div>
        </div>
        {filters?.marketplace_type === "for_sale" && (
          <div className="mt-4 relative">
            <label className="text-sm lg:text-base text-[#757575]">
              Tipo de construcción
            </label>
            <div className="form-radio-filter flex justify-between flex-col md:flex-row mt-4">
              <div className="flex justify-between w-full relative">
                <>
                  <input
                    type="radio"
                    name="market_construction"
                    value="new"
                    id="new"
                    defaultChecked={buildType === "new"}
                    onChange={(e: any) => handleBuildtype(e?.target?.value)}
                  />
                  <label
                    className={`py-3 lg:px-8 w-full cursor-pointer flex justify-center text-xs lg:text-sm left`}
                    htmlFor="new"
                  >
                    Nueva
                  </label>
                </>

                <>
                  <input
                    type="radio"
                    name="market_construction"
                    value="used"
                    id="used"
                    defaultChecked={buildType === "used"}
                    onChange={(e: any) => handleBuildtype(e?.target?.value)}
                  />
                  <label
                    className={`py-3 lg:px-8 w-full cursor-pointer flex justify-center text-xs lg:text-sm right`}
                    htmlFor="used"
                  >
                    Usada
                  </label>
                </>
              </div>
              <br />
            </div>
          </div>
        )}

        <div className="mt-4 block xl:hidden relative">
          <label className="text-sm lg:text-base text-[#757575]">
            Tipo de inmueble
          </label>
          <div className="form-radio-filter flex justify-between flex-col md:flex-row mt-4">
            <div className="flex w-full">

                  <>
                    <input
                      {...register("type")}
                      type="radio"
                      name="type"
                      value="house"
                      id="house"
                    />
                    <label
                      className={`py-3 lg:px-8 w-full cursor-pointer flex justify-center text-xs lg:text-sm left`}
                      htmlFor="house"
                    >
                      Casa
                    </label>
                  </>

                  <>
                    <input
                      {...register("type")}
                      type="radio"
                      name="type"
                      value="apto"
                      id="apto"
                    />
                    <label
                      className={`py-3 lg:px-8 w-full cursor-pointer flex justify-center text-xs lg:text-sm rigth`}
                      htmlFor="apto"
                    >
                      Apartamento
                    </label>
                  </>
              
            </div>
            <br />
          </div>
        </div>
        <div className="mt-4 block xl:hidden relative">
          <label className="text-sm lg:text-base text-[#757575] font-medium">
            Rango de precios
          </label>
          {country === "gt" && (
        <p className="text-xs text-normal text-[#212121]">
          Precios en dólares. Los filtros utilizan una conversión aproximada a
          Quetzales
        </p>
      )}
          <div className="flex flex-wrap ">
            <div className="w-full mt-2 grid grid-cols-2 gap-4">
              <div className="relative input-icon">
                <IconSquareRoundedMinus color="#BDBDBD" />
                <Controller
                  render={({ field: { onChange, value, onBlur } }) => (
                    <NumericFormat
                      className="bg-white block pl-10 py-3 px-2 w-full text-sm text-gray-900 border border-grayForm rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      onValueChange={(values: any) => onChange(values?.value)}
                      allowNegative
                      prefix="US $"
                      thousandSeparator=","
                      defaultValue={getValues('min_price')?.toString()}
                      value={getValues('min_price')}
                    />
                  )}
                  name="min_price"
                  control={control}
                />
              

               
              </div>

              <div className="relative input-icon">
                <IconSquareRoundedPlus color="#BDBDBD" />
                <Controller
                  render={({ field: { onChange, value, onBlur } }) => (
                    <NumericFormat
                      className="bg-white block pl-10 py-3 px-2 w-full text-sm text-gray-900 border border-grayForm rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      onValueChange={(values: any) => onChange(values?.value)}
                      allowNegative
                      prefix="US $"
                      thousandSeparator=","
                      defaultValue={getValues('max_price')?.toString()}
                      value={getValues('max_price')}
                    />
                  )}
                  name="max_price"
                  control={control}
                />
                
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 relative">
          <label className="text-sm lg:text-base text-[#757575]">
            Características
          </label>
          <div className="w-full grid grid-cols-2 gap-4 mt-2">
            <FormControl>
              <Controller
                name="rooms"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={Number(value)}
                    onChange={onChange}
                    placeholder="test"
                    displayEmpty={true}
                    defaultValue={Number(getValues("rooms"))}
                    inputProps={{ "aria-label": "Without label" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconBed color="#BDBDBD" />
                      </InputAdornment>
                    }
                    renderValue={(value) => {
                      const displayValue =
                        value === undefined || value === null || value === 0
                          ? "Habitaciones"
                          : Array.isArray(value)
                          ? value.join(", ")
                          : value.toString();

                      return displayValue;
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>Habitaciones</em>
                    </MenuItem>

                    <MenuItem value={1}>1 habitación</MenuItem>
                    <MenuItem value={2}>2 habitaciones</MenuItem>
                    <MenuItem value={3}>3 habitaciones</MenuItem>
                    <MenuItem value={4}>4 habitaciones</MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            <FormControl>
              <Controller
                name="bathrooms"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={Number(value)}
                    onChange={onChange}
                    placeholder="Baños"
                    defaultValue={Number(getValues("bathrooms"))}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconBath color="#BDBDBD" />
                      </InputAdornment>
                    }
                    renderValue={(value) => {
                      const displayValue =
                        value === undefined || value === null || value === 0
                          ? "Baños"
                          : Array.isArray(value)
                          ? value.join(", ")
                          : value.toString();

                      return displayValue;
                    }}
                  >
                    <MenuItem value={1}>1 baño</MenuItem>
                    <MenuItem value={2}>2 baños</MenuItem>
                    <MenuItem value={3}>3 baños</MenuItem>
                    <MenuItem value={4}>4 baños</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </div>
          <div className="w-full mt-4">
            <FormControl>
              <Controller
                name="parking_spots"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={Number(value)}
                    placeholder="Parqueos"
                    onChange={onChange}
                    defaultValue={Number(getValues("parking_spots"))}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconCar color="#BDBDBD" />
                      </InputAdornment>
                    }
                    renderValue={(value) => {
                      const displayValue =
                        value === undefined || value === null || value === 0
                          ? "Parqueos"
                          : Array.isArray(value)
                          ? value.join(", ")
                          : value.toString();

                      return displayValue;
                    }}
                  >
                    <MenuItem value={1}>1 parqueo</MenuItem>
                    <MenuItem value={2}>2 parqueos</MenuItem>
                    <MenuItem value={3}>3 parqueos</MenuItem>
                    <MenuItem value={4}>4 parqueos</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </div>
        </div>
        {filters?.marketplace_type !== "for_sale" && (
          <>
            <div className="mt-6 relative">
              <div className="form-radio-filter flex justify-between flex-col md:flex-row ">
                <p className="text-sm lg:text-base text-[#757575] mb-3 md:mb-0">
                  Amueblado
                </p>
                <div className="flex items-center">
                  {furnishedOptions &&
                    furnishedOptions.map((item: any) => (
                      <>
                        <input
                          {...register("is_furnished")}
                          type="radio"
                          name="is_furnished"
                          value={item.value}
                          id={`${item.name}-f`}
                        />
                        <label
                          className={`py-3 lg:px-8 w-full cursor-pointer flex justify-center text-xs lg:text-sm ${
                            item?.name === "Sí" ? "left" : "right"
                          }`}
                          htmlFor={`${item.name}-f`}
                        >
                          {" "}
                          {item.name}{" "}
                        </label>
                      </>
                    ))}
                </div>
              </div>
            </div>
            <div className="mt-6 relative">
              <div className="form-radio-filter flex justify-between flex-col md:flex-row ">
                <p className=" text-sm lg:text-base text-[#757575] mb-3 md:mb-0">
                  Linea blanca{" "}
                </p>
                <div className="flex items-center">
                  {furnishedOptions &&
                    furnishedOptions.map((item: any) => (
                      <>
                        <input
                          {...register("has_white_goods")}
                          type="radio"
                          name="has_white_goods"
                          value={item.value}
                          id={`hwg-${item.name}`}
                        />
                        <label
                          className={`py-3 lg:px-8 w-full cursor-pointer flex justify-center text-xs lg:text-sm ${
                            item?.name === "Sí" ? "left" : "right"
                          }`}
                          htmlFor={`hwg-${item.name}`}
                        >
                          {" "}
                          {item.name}{" "}
                        </label>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}

        {/* <label className=" text-graySoft text-xs font-medium mb-2">
          Rango de cuotas
        </label>
        <div className="py-5 flex flex-wrap border-b-gray-200 md:border-b">
          <div className="w-full md:w-1/2 pb-5 md:pb-0 md:pr-2 mb-3 md:mb-0 border-b-gray-200 border-b md:border-b-0">
            <label className=" text-left pb-2 text-xs text-graySoft">
              Mínimo
            </label>
            <input
              {...register("min_price")}
              name="min_price"
              type="number"
              min={0}
              pattern="[0-9]"
              placeholder="$"
              className="bg-white block py-3 px-2 w-full text-sm text-gray-900 border border-grayForm rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            />
          </div>
          <div className="w-full md:w-1/2 pb-5 md:pb-0 md:pl-2 border-b-gray-200 border-b md:border-b-0">
            <label className=" text-left pb-2 text-xs text-graySoft">
              Máximo
            </label>
            <input
              {...register("max_price")}
              name="max_price"
              type="number"
              min={0}
              pattern="[0-9]"
              placeholder="$"
              className="bg-white block py-3 px-2 w-full text-sm text-gray-900 border border-grayForm rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            />
          </div>
        </div> */}
        <div className="mt-6 flex flex-wrap relative">
          <label className="text-sm lg:text-base text-[#757575]" htmlFor="">
            Área de construcción
          </label>
          <Box className="flex justify-between items-between w-full py-2">
            <FormControl>
              <div className="relative input-icon">
                <IconSquareRoundedMinus color="#BDBDBD" />
                <Controller
                  render={({ field: { onChange, value, onBlur } }) => (
                    <NumericFormat
                      className="bg-white block pl-10 py-3 px-2 w-full text-sm text-gray-900 border border-grayForm rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      onValueChange={(values: any) => onChange(values?.value)}
                      allowNegative
                      thousandSeparator=","
                      defaultValue={values?.min_area.toString()}
                      value={values?.min_area}
                    />
                  )}
                  name="min_area"
                  control={control}
                />
              </div>
            </FormControl>
            <Box width="12px" />
            <FormControl className="pl-1">
              <div className="relative input-icon">
                <IconSquareRoundedMinus color="#BDBDBD" />
                <Controller
                  render={({ field: { onChange, value, onBlur } }) => (
                    <NumericFormat
                      className="bg-white block pl-10 py-3 px-2 w-full text-sm text-gray-900 border border-grayForm rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer guest-input"
                      onValueChange={(values: any) => onChange(values?.value)}
                      allowNegative
                      thousandSeparator=","
                      value={values?.max_area}
                    />
                  )}
                  defaultValue={values?.max_area.toString()}
                  name="max_area"
                  control={control}
                />
              </div>
            </FormControl>
          </Box>
          <Box className="py-2 w-full">
            <Slider
              getAriaLabel={() => "Temperature range"}
              value={sliderArea}
              onChange={onChangeSliderArea}
              min={50}
              max={300}
              color="primary"
            />
          </Box>
        </div>

        {filters?.marketplace_type === "for_sale" && (
          <>
            {buildType === "new" && (
              <div className="border-b-gray-200 border-b py-5">
                <label className="text-sm lg:text-base text-[#757575]">
                  Área de terreno
                </label>
                <Box className="flex justify-between py-2">
                  <FormControl>
                    <div className="relative input-icon">
                      <IconSquareRoundedMinus color="#BDBDBD" />
                      <Controller
                        render={({ field: { onChange, value, onBlur } }) => (
                          <NumericFormat
                            placeholder="$"
                            className="bg-white block pl-10 py-3 px-2 w-full text-sm text-gray-900 border border-grayForm rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            onValueChange={(values: any) =>
                              onChange(values?.value)
                            }
                            allowNegative
                            thousandSeparator=","
                            defaultValue={values?.min_square_rods}
                            value={values?.min_square_rods}
                          />
                        )}
                        defaultValue={values?.min_square_rods?.toString()}
                        name="min_square_rods"
                        control={control}
                      />
                    </div>
                  </FormControl>
                  <Box width="12px" />
                  <FormControl className="pl-1">
                    <div className="relative input-icon">
                      <IconSquareRoundedMinus color="#BDBDBD" />
                      <Controller
                        render={({ field: { onChange, value, onBlur } }) => (
                          <NumericFormat
                            placeholder="$"
                            className="bg-white block pl-10 py-3 px-2 w-full text-sm text-gray-900 border border-grayForm rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            onValueChange={(values: any) =>
                              onChange(values?.value)
                            }
                            allowNegative
                            thousandSeparator=","
                            defaultValue={values?.max_square_rods}
                            value={values?.max_square_rods}
                          />
                        )}
                        defaultValue={values?.max_square_rods?.toString()}
                        name="max_square_rods"
                        control={control}
                      />
                    </div>
                  </FormControl>
                </Box>
                <Box className="py-2">
                  <Slider
                    getAriaLabel={() => "Temperature range"}
                    value={sliderSquare}
                    onChange={onChangeSliderSquare}
                    min={50}
                    max={1000}
                    color="primary"
                  />
                </Box>
              </div>
            )}
          </>
        )}

        {/* <div className="form-radio flex justify-between flex-col md:flex-row py-5 border-b-gray-200 border-b">
          <p className=" text-graySoft text-xs font-medium mb-3 md:mb-0">
            Tipo de inmueble&nbsp;
          </p>
          <div className="flex">
            {catalogs.propertyTypes &&
              catalogs.propertyTypes.map((item) => (
                <>
                  <input
                    {...register("type")}
                    type="radio"
                    name="type"
                    value={item.id}
                    id={item.id}
                  />
                  <label
                    className="py-2 px-8 mx-1 block w-1/2 md:w-auto cursor-pointer text-center"
                    htmlFor={item.id}
                  >
                    {item.type}
                  </label>
                </>
              ))}
          </div>
          <br />
        </div> 
        <div className="py-5 flex justify-between flex-col md:flex-row border-b-gray-200 border-b">
          <label className="text-graySoft text-xs font-medium mb-3 md:mb-0">
            Habitaciones
          </label>
          <div className="form-radio circle flex">
            {numberList.length &&
              numberList.map((option: any) => (
                <>
                  <input
                    {...register("rooms")}
                    type="radio"
                    value={option.value}
                    id={`room-${option.value}`}
                  />
                  <label
                    className="h-8 w-8 mx-1 flex justify-center items-center cursor-pointer"
                    htmlFor={`room-${option.value}`}
                  >
                    {" "}
                    {option.value}{" "}
                  </label>
                </>
              ))}
          </div>
        </div>
        <div className="py-5 flex justify-between flex-col md:flex-row border-b-gray-200 border-b">
          <label className=" text-graySoft text-xs font-medium mb-3 md:mb-0">
            Baños
          </label>
          <div className="form-radio circle flex">
            {numberList.length &&
              numberList.map((option: any) => (
                <>
                  <input
                    {...register("bathrooms")}
                    type="radio"
                    value={option.value}
                    id={`bath-${option.value}`}
                  />
                  <label
                    className="h-8 w-8 mx-1 flex justify-center items-center cursor-pointer"
                    htmlFor={`bath-${option.value}`}
                  >
                    {" "}
                    {option.value}{" "}
                  </label>
                </>
              ))}
          </div>
        </div>
        */}
        <FormControlError message={error} />
        <div className="pt-3 grid grid-cols-2 gap-4 mt-4">
          <button
            onClick={clearForm}
            className=" border-[#212121] border text-[#212121] py-3 px-3 rounded-lg text-sm font-normal w-full"
          >
            Limpiar
          </button>
          <button
            className="bg-[#212121] hover:bg-primary  text-white py-3 px-3 rounded-lg text-sm font-normal w-full"
            type="submit"
          >
            Aplicar filtros
          </button>
        </div>
      </form>
    </>
  );
};

export default FilterForm;
