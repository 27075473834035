import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { unsetAuth } from "../../helpers/authHelper";
import './scss/NavBar.scss';
import useAuthServices from "../../hooks/useAuthService";

const OptionDev = () => {
  
  const { handleLogout } = useAuthServices()

  return (
    <nav className="dropdown-menu bg-white lg:absolute lg:mt-[45px] lg:shadow-lg lg:rounded-[5px] w-full lg:w-fit lg:right-[15px] lg:z-[100] w-max">
      <ul className="w-max">
        <li className="w-fit">
          <ul className="w-fit">
            <li onClick={handleLogout}
              className="hover:bg-green200 hover:text-green700 text-grayText text-sm lg:text-base py-2 lg:pl-2 lg:pr-6 rounded-b-lg">
              <button>Cerrar sesión</button>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default OptionDev;
