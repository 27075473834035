import { useState } from "react";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import { changeMailEndpoint } from "./services/RegistrationService";
import { getRealmPath } from "../../helpers/realmHelpers";
import { ChangeMailForm } from "./models/RegistrationModel";
import Button from "../../components/Button";
import logo from "../../assets/images/iso_logo.svg";
import arrow_button from "../../assets/icons/arrow_button.svg";
import FormControlError from "../../components/Controls/FormControlError";
import changemail from "../../assets/images/change-mail.jpg";
import { useSelector } from "react-redux";
import { AppStore } from "../../redux/store";
interface Props {
  realm: string;
}

const ChangeMail = (props: Props) => {
  const {country} = useSelector((state: AppStore) => state.country);

  const navigate = useNavigate();
  const { realm } = props;
  const { pid = "" } = useParams();
  const [error, setError] = useState("");
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangeMailForm>({
    resolver: yupResolver(
      yup
        .object({
          email: yup
            .string()
            .email("Ingrese un formato correcto para correo")
            .required("Email es requerido"),
        })
        .required()
    ),
  });

  const doChangeMail = async ({ email }: ChangeMailForm) => {
    setError("");
    const { status, data } = await callEndpoint(changeMailEndpoint(pid, email));
    if (status === 200) {
      const pathRealm = getRealmPath(realm);
      return navigate(`/app/${country}/auth/confirmation/${pathRealm}/${pid}`, {
        replace: true,
        state: { email },
      });
    }
    const errorCode = data.code;
    setError(`Error(${errorCode}): ${data.message}`);
  };

  return (
    <section className="overflow-hidden bg-cover bg-center h-screen">
      <div className="w-full flex justify-between items-start">
        <div className="w-full lg:w-1/2 lg:h-screen lg:overflow-y-scroll px-4">
          <div className="flex flex-col items-center justify-center h-screen">
            <div className="text-center mb-6">
              <img className="inline-block" src={logo} alt="Logo PropiLatam" />
            </div>
            <h1 className="text-bluedark font-medium text-2xl text-center mb-8">
              Cambio de correo
            </h1>
            <form onSubmit={handleSubmit(doChangeMail)} className="w-80">
              {error ? <span style={{ color: "red" }}>{error}</span> : null}
              <input
                type="text"
                placeholder="Correo electrónico"
                className="bg-white block py-3 px-2 w-full text-sm text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                {...register("email")}
              />
              <FormControlError message={errors.email?.message} />
              <Button
                type="submit"
                className="relative w-full bg-primary py-5 px-4 my-4 text-white text-base rounded-full flex justify-center items-center"
                disabled={isLoading}
              >
                <span className="mr-2">Registrarse</span>
                <img
                  src={arrow_button}
                  className="vertical-absolute-center right-3"
                  alt=""
                />
              </Button>
            </form>
          </div>
        </div>
        <div className="w-full hidden lg:block h-full lg:w-1/2">
          <img src={changemail} className="h-screen object-cover" alt="" />
        </div>
      </div>
    </section>
  );
};

export default ChangeMail;
