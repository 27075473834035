// lib
import { Routes, Route } from "react-router-dom";
import Verification from "../pages/AccountVerification/Verification";
import ChangePassword from "../pages/Login/ChangePassword";
import ChangePasswordRequested from "../pages/Login/ChangePasswordRequested";
import Login from "../pages/Login/Login";
import RequestChangePassword from "../pages/Login/RequestChangePassword";
import ChangeMail from "../pages/Registration/ChangeMail";
import ChooseRegistration from "../pages/Registration/ChooseRegistration";
import Confirmation from "../pages/Registration/Confirmation";
import Registration from "../pages/Registration/Registration";
import LoginByCode from "../pages/Login/LoginByCode";
import VerifyLoginByCode from "../pages/Login/VerifyLoginByCode";

const AuthRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/request-login-by-code" element={<LoginByCode />} />
        <Route
          path="/verify-login-by-code/:id"
          element={<VerifyLoginByCode />}
        />
        {/* Change password */}
        <Route
          path="/request-change-password"
          element={<RequestChangePassword />}
        />
        <Route
          path="/change-password-requested"
          element={<ChangePasswordRequested />}
        />
        <Route path="/change-password/:id" element={<ChangePassword />} />
        <Route
          path="/change-password/:id/:action_code"
          element={<ChangePassword />}
        />
        {/* Registration */}
        {/* <Route path="/registration" element={<ChooseRegistration />} />
        <Route
          path="/registration/ld"
          element={<Registration realm="landlord" />}
        />
        <Route
          path="/registration/tn"
          element={<Registration realm="tenant" />}
        /> */}
        <Route
          path="/registrarse"
          element={<Registration realm="customer" />}
        />
        <Route
          path="/confirmation/ld/:pid"
          element={<Confirmation realm="landlord" />}
        />
        <Route
          path="/confirmation/tn/:pid"
          element={<Confirmation realm="tenant" />}
        />
        <Route path="/verify/:id" element={<Verification />} />
        {/* Change registration mail */}
        <Route
          path="/change-mail/ld/:pid"
          element={<ChangeMail realm="landlord" />}
        />
        <Route
          path="/change-mail/tn/:pid"
          element={<ChangeMail realm="tenant" />}
        />
      </Routes>
    </>
  );
};
export default AuthRoutes;
