import { createSlice } from "@reduxjs/toolkit";
import { ProjectProperty } from '../../models/PropertyModel';

const initialState: ProjectProperty = {
    page: 1,
    take: 15,
    area: null,
    bedrooms: null,
    level: null,
    model_id: null,
    status: null,
    landlord_id: null,
    unit_name: null
}

export const filtersProjectsSlice = createSlice({
    name: 'filters-projects',
    initialState,
    reducers: {
        clearFiltersProjectsAction: () => (initialState),
        setFiltersProjectsAction: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
    }
})

// provide actions
export const { setFiltersProjectsAction, clearFiltersProjectsAction } = filtersProjectsSlice.actions;
// provide reducer
export default filtersProjectsSlice.reducer