import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import { useState } from "react";
import { VerifyLoginByCodeForm } from "./models/loginModel";
import FormControlError from "../../components/Controls/FormControlError";
import { verifyLoginByCodeEndpoint } from "./services/loginService";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";
import { loginAction } from "../../redux/features/auth";
import {
  customDelay,
  getLastPath,
  removeLastPath,
  setAuth,
} from "../../helpers/authHelper";
import { getAuthUserAdapter } from "../../adapters/userAdapter";
import { useDispatch, useSelector } from "react-redux";
import { AppStore } from "../../redux/store";
import MainButton from "../../components/MainButton/mainButton";
import { Box, FormHelperText } from "@mui/material";

import { MuiOtpInput } from "mui-one-time-password-input";

const VerifyLoginByCode = () => {
  const { country } = useSelector((state: AppStore) => state.country);
  const { id = "" } = useParams();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const { callEndpoint } = useCallApiAndLoad();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<VerifyLoginByCodeForm>({
    resolver: yupResolver(
      yup
        .object({
          code: yup.string().required("Código de acceso es requerido"),
        })
        .required()
    ),
  });

  const handleVerifyLoginByCode = async (form: VerifyLoginByCodeForm) => {
    const { code } = form;
    const { status, data } = await callEndpoint(
      verifyLoginByCodeEndpoint(id, code)
    );
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      const responseData = response.data;
      const authData = getAuthUserAdapter({
        name: responseData.name,
        access_token: responseData.access_token,
        refresh_token: responseData.refresh_token,
        realm: responseData.realm,
        avatar: responseData.avatar,
        last_name: responseData.last_name,
      });
      dispatch(loginAction(authData));
      setAuth(authData);
      const lpath = getLastPath();
      if (lpath) {
        removeLastPath();
        await customDelay(300);
        return window.open(lpath, "_self");
      } else {
        return window.open(`/${country}/`, "_self");
      }
    } else {
      setError(response.message || "Ocurrio un error");
    }
  };

  return (
    <div className="overflow-hidden bg-cover bg-center h-screen bg-[#EBF1E8]">
      <div className="flex flex-col items-center justify-center h-screen px-4 md:px-0">
        <div className="max-w-md m-auto bg-white px-6 py-8 rounded-lg">
          <section className="pt-0 pb-0 ">
            <div className="text-center mb-6">
              <img
                className="inline-block w-52"
                src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/v2/logo-propi.svg"
                alt="Logo PropiLatam"
              />
            </div>
            <h1 className="text-[#212121] font-semibold text-2xl text-center mb-4 leading-tight">
              Ingresa tu código de acceso
            </h1>
            <p className="text-[#757575] font-light text-sm text-center mb-6">
              Revisa tu bandeja de entrada, ahi tendras tu código de acceso.
            </p>
          </section>
          <form onSubmit={handleSubmit(handleVerifyLoginByCode)}>
            <Controller
              name="code"
              control={control}
              rules={{ validate: (value) => value.length === 6 }}
              render={({ field, fieldState }) => (
                <Box>
                  <MuiOtpInput sx={{ gap: 1 }} {...field} length={6} inputMode="numeric" TextFieldsProps={{ type: 'number', }}  />
                  {fieldState.invalid ? (
                    <FormHelperText error>Codigo invalido</FormHelperText>
                  ) : null}
                </Box>
              )}
            />
            <FormControlError message={errors.code?.message} />
            <FormControlError message={error} />
            <div className="mt-8">
              <MainButton
                topic="Verificar"
                background="bg-[#212121]"
                colorText="text-white"
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerifyLoginByCode;
