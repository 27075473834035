import React, { memo, useState } from "react";
import "./SwitchPrice.scss";

interface SwitchPriceProps {
  onChange: (isChecked: boolean) => void;
  id: string;
  defaultChecked?: boolean;
}

const SwitchPrice = memo(
  ({ onChange, id, defaultChecked = false }: SwitchPriceProps) => {
    const [isChecked, setIsChecked] = useState(defaultChecked);

    const handleToggle = () => {
      const newCheckedValue = !isChecked;
      setIsChecked(newCheckedValue);
      onChange(newCheckedValue); // Llama a la función onChange del padre
    };

    return (
      <>
        <div className="can-toggle demo-rebrand-2" onTouchStart={handleToggle}>
          <input
            id={`switch-${id}`}
            type="checkbox"
            checked={isChecked}
            onChange={handleToggle}
          />
          <label htmlFor={`switch-${id}`}>
            <div
              className="can-toggle__switch"
              data-checked="$"
              data-unchecked="Q"
            ></div>
          </label>
        </div>
      </>
    );
  }
);
SwitchPrice.displayName = "SwitchPrice";
export default SwitchPrice;
