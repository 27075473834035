import { useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import "./scss/NavBar.scss";
import { AppStore } from "../../redux/store";
import { Divider } from "@mui/material";

const NavBarLandlord = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  const { handleLogout } = useAuth();

  return (
    <nav className="dropdown-menu bg-white lg:absolute lg:mt-[45px] lg:shadow-lg lg:rounded-[5px] w-full lg:w-fit lg:right-[15px] lg:z-[100] w-max">
      <ul className="w-max">
        <li className="w-fit">
          <ul className="w-fit">
          <li className="hover:bg-green200 hover:text-green700 text-[#212121] font-medium text-base py-2 lg:pl-2 lg:pr-6 rounded-t-lg">
              <a href={`/app/${country}/l/ld/mi-cuenta`}>Mi cuenta</a>
            </li>
            <li className="hover:bg-green200 hover:text-green700 text-sm lg:text-base py-2 lg:pl-2 lg:pr-6">
              <a href={`/app/${country}/l/ld/payments`}>Mis Inversiones</a>
            </li>
            <li className="hover:bg-green200 hover:text-green700 text-sm lg:text-base py-2 lg:pl-2 lg:pr-6">
              <a href={`/app/${country}/l/ld/pre-booking`}>Mis pre-reservas</a>
            </li>
            {/* <li className="hover:bg-green200 hover:text-green700 text-grayText text-sm lg:text-base py-2 lg:pl-2 lg:pr-6">
              <a href={`/app/${country}/l/tn/payments-sales`}>Mis compras</a>
            </li> */}
            <li className="hover:bg-green200 hover:text-green700 text-sm lg:text-base py-2 lg:pl-2 lg:pr-6">
              <a href={`/app/${country}/l/properties`}>Ofertas y propiedades</a>
            </li>
           
            <li className="hover:bg-green200 hover:text-green700 text-sm lg:text-base py-2 lg:pl-2 lg:pr-6">
              <a href={`/${country}/ayuda`}>Obtener ayuda</a>
            </li>
            <Divider />
            <li className="hover:bg-green200 hover:text-green700 text-sm lg:text-base py-2 lg:pl-2 lg:pr-6 rounded-b-lg">
              <button onClick={handleLogout}>Cerrar sesión</button>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default NavBarLandlord;
