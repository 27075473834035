import React, { memo } from "react";
import "./LoginDevs.scss";
import TextInput from "../../components/Inputs/Text/Text";
import { FormProvider, useForm } from "react-hook-form";
import { LoginDev } from "../../models/Devs";
import Button from "../../../../components/Button";
import arrow_button from "../../../../assets/icons/arrow_button.svg";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormControlError from "../../../../components/Controls/FormControlError";
import useAuthServices from "../../../../hooks/useAuthService";
import { AppStore } from "../../../../redux/store";
import { useSelector } from "react-redux";
import MainButton from "../../../../components/MainButton/mainButton";
const LoginDevs = memo(() => {
  const { handleLogin } = useAuthServices();
  const { country } = useSelector((state: AppStore) => state.country);
  const methods = useForm<LoginDev>({
    resolver: yupResolver(
      yup
        .object({
          username: yup.string().required("Correo es requerido"),
          password: yup.string().required("Contraseña es requerida"),
        })
        .required()
    ),
  });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = handleSubmit((data) => {
    handleLogin(data);
  });
  return (
    <div className="loginDevs-container flex flex-col h-auto lg:h-screen">
      <div className=" flex-1">
        <div className="flex md:flex-row flex-col-reverse h-full">
          <div className="flex flex-col md:w-1/2 justify-center items-center text-flat-dark-blue-500 headerLogin"></div>
          <div className=" drop-shadow-none md:w-1/2 flex flex-wrap px-4 py-8 md:py-0 md:px-0">
            <FormProvider {...methods}>
              
              <form
                className="py-5 w-full md:w-[450px] m-auto px-4 md:px-0"
                onSubmit={onSubmit}
              >
                <div className="mb-4">
                <a href={`/${country}`}>
                <img className=" mx-auto w-44" src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/v2/logo-propi.svg" alt="" />
              </a>
              <h2 className="text-xl font-semibold text-center mt-4">Bienvenido!</h2>
              <p className="text-[#757575] text-sm font-medium text-center">Ingresa tus datos</p>
              </div>
                <div className="mb-6">
                  <TextInput label="Correo electrónico" icon="email" type="email" name="username" />
                </div>

                <div className="mb-6">
                  <TextInput
                    label="Contraseña"
                    icon="password"
                    type="password"
                    name="password"
                  />
                </div>
                <div className="w-full">
                  {errors?.password && (
                    <FormControlError
                      message={errors?.password?.message}
                      additionalClass="mb-3"
                    />
                  )}

                  {errors?.username && (
                    <FormControlError
                      message={errors?.username?.message}
                      additionalClass="mb-3"
                    />
                  )}
                </div>
                <div className="my-6">
                  <MainButton
                    topic="Iniciar sesión"
                    type="submit"
                    colorText="text-white"
                    background="bg-[#212121]"
                  />
                </div>

              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
});

LoginDevs.displayName = "LoginDevs";
export default LoginDevs;
