import { createSlice } from "@reduxjs/toolkit";
import { PublishPropertyStore } from '../../models/PropertyModel';


const initialState: PublishPropertyStore = {
        name: "",
        street:"",
        is_new: false,
        is_furnished: false,
        characteristics_info: [],
        type_id: "",
        price: 0,
        available_at: "",
        address: "",
        country_name: "", 
        country_id: "",
        city_id: "",
        state_id: "",
        state_name: "",
        property_number: "",
        extra_info: "",
        description: "",
        amenities_info: [],
        lat: 13.701402266923608,
        lng: -89.22444462777489,
}

export const registerPropertySlice = createSlice({
    name: 'register-property',
    initialState,
    reducers: {
        setRegisterAction: (state, action) => {
            console.log(action);
            return {
                ...action.payload
            }
        },
    }
})

// provide actions
export const { setRegisterAction } = registerPropertySlice.actions;
// provide reducer
export default registerPropertySlice.reducer