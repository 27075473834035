import React, {memo} from 'react'
import './CardPopular.scss'
import { PopularCities } from '../../models/PropertyModel';
import { AppStore } from '../../redux/store';
import { useSelector } from 'react-redux';

const CardPopular = memo(({ city_name, location_id, location_type, location_zoom, qty, url_cover, lat, lng }:PopularCities) => {
    const { country } = useSelector((store: AppStore) => store.country);
    const filters = useSelector((store: AppStore) => store.filters)
    const handleDetail = () => {
        if (filters?.marketplace_type === null) {
            if (typeof window !== 'undefined') {
                window.open(`/app/${country}/marketplace?location_id=${location_id}&location_type=${location_type}&zoom_search=${location_zoom}&lat=${lat}&lng=${lng}`, '_self')
              }
        } else {
            if (typeof window !== 'undefined') {
                window.open(`/app/${country}/marketplace?marketplace_type=${filters?.marketplace_type}&location_id=${location_id}&location_type=${location_type}&zoom_search=${location_zoom}&lat=${lat}&lng=${lng}`, '_self')
              }
        }     
      };
    return(<div className='card-popular cursor-pointer' onClick={handleDetail}>
        <img src={url_cover} alt="" />
        <div className='content'>
            <div className='h-auto'>
                <h2 className='font-semibold text-sm lg:text-base text-white truncate'>{city_name}</h2>
            </div>

        </div>
    </div>)
})

CardPopular.displayName = 'CardPopular'
export default CardPopular