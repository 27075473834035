import axios from "axios";
import {
  PublishProperty,
  PublishPropertyStep2,
  FiltersProperties,
} from "../models/PropertyModel";

const loadAbort = () => {
  return new AbortController();
};

export const publishEndpoint = (
  publishProperty: PublishProperty,
  token: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/rs/v1/properties`,
        data: JSON.stringify(publishProperty),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const publishEndpointStepTwo = (
  dataStep2: any,
  idProperty: string,
  token: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "put",
        url: `${urlService}/rs/v1/properties/${idProperty}/property-step-2`,
        data: dataStep2,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getTypePropertiesEndpoint = () => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/rs/v1/type-properties`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const getCharacteristicsEndpoint = () => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/rs/v1/characteristics?is_required=true`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const getAmenitiesEndpoint = () => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/rs/v1/amenities`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const getPlansEndpoint = () => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/rs/v1/plans`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const getHoursAvailable = (date: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/rs/v1/request-photography-properties/${date}`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const getPropertiesEndpoint = (token: string, query: string = "") => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/rs/v1/landlord/properties${query}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
export const getTakenPropertiesEndpoint = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/rs/v1/landlord/properties/taken`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getAdminPropertyEndpoint = (id: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/rs/v1/landlord/properties/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getAdminPropertyDetailEndpoint = (id: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/rs/v1/landlord/properties/${id}/detail`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getPropertiesClusterMarketplace = (filters?: any) => {
  const controller = new AbortController(); // Crear un nuevo controlador aquí
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/rs/v1/marketplace/properties/cluster?${decodeURIComponent(filters)}`,
        headers: {
          "Content-Type": "application/json",
        },
        signal: controller.signal, // Agregar el signal al axios
      }),
    controller,
  };
};


export const getPropertiesMarketplace = (filters?: any) => {
  // filters: FiltersProperties
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/rs/v1/marketplace/properties?${decodeURIComponent(
          filters
        )}`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const getBatchesCluster = () => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/rs/v1/marketplace/properties/cluster/retrieval-config`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

export const getVisitsProperty = (idProperty: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/landlord/${idProperty}/visits?page=1&take=15`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getPopularCities = () => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/address/v1/polygons/per-cities`,
      }),
    controller,
  };
};
