import React, { memo } from "react";
import { TransactionInfo } from "../models/investorModel";

interface CardInfo {
  transactionInfo?: TransactionInfo;
}

const CardInfoPay = memo(({ transactionInfo }: CardInfo) => {

  return (
    <div className="cardpay-container w-full p-3 md:p-6 border border-tea-500 rounded-lg">
      <div className="w-full flex justify-between mb-5 px-2 py-2 rounded-lg">
        <div className="w-1/2">
          <div className="w-full md:w-1/2">
            <p className="font-bold text-[#212121] lg:text-base text-sm">
              Nombre
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <p className="text-sm md:text-base font-normal text-[#616161]">
              {transactionInfo?.project_name}
            </p>
          </div>
        </div>
        <div className="w-1/2">
          <div className="w-full md:w-1/2">
            <p className="font-bold text-[#212121] lg:text-base text-sm">
              Dirección
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <p className="text-sm md:text-base font-normal text-[#616161]">
              {transactionInfo?.project_address}
            </p>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between mb-5 px-2 py-2 bg-tea-100 rounded-lg">
        <div className="w-1/2">
          <div className="w-full md:w-1/2">
            <p className="font-bold text-[#212121] lg:text-base text-sm">
              Precio
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <p className="text-sm md:text-base font-normal text-[#616161]">
              {transactionInfo?.unit_price}
            </p>
          </div>
        </div>
        <div className="w-1/2">
          <div className="w-full md:w-1/2">
            <p className="font-bold text-[#212121] lg:text-base text-sm">
              Modelo
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <p className="text-sm md:text-base font-normal text-[#616161]">
              {transactionInfo?.model_name}
            </p>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between mb-5 px-2 py-2 bg-tea-100 rounded-lg">
        <div className="w-1/2">
          <div className="w-full md:w-1/2">
            <p className="font-bold text-[#212121] lg:text-base text-sm">
              Fecha estimada de entrega
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <p className="text-sm md:text-base font-normal text-[#616161]">
              {transactionInfo?.estimate_delivered_at}
            </p>
          </div>
        </div>
        <div className="w-1/2">
          <div className="w-full md:w-1/2">
            <p className="font-bold text-[#212121] lg:text-base text-sm">
              Unidad
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <p className="text-sm md:text-base font-normal text-[#616161]">
              {transactionInfo?.unit_name}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});

CardInfoPay.displayName = "CardInfoPay";
export default CardInfoPay;
