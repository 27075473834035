import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";
import FooterComponent from "../../components/Footer";
import { getAuthToken, unsetAuth } from "../../helpers/authHelper";
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import { logoutAction } from "../../redux/features/auth";
import { AppStore } from "../../redux/store";
import { getBankInfoEndpoint } from "./services/landlordService";

const BankInfo = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  const navigate = useNavigate();
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const dispatch = useDispatch();
  const [data, setData] = useState<any>();

  useEffect(() => {
    const loadBankInfo = async () => {
      const { status, data } = await callEndpoint(getBankInfoEndpoint(token));
      if (status === 401) {
        dispatch(logoutAction({}));
        unsetAuth();
        return navigate(`/app/${country}auth/login`);
      }
      const response = fetchResponseAdapter(data);
      setData(response.data);
    };
    loadBankInfo().catch((e) => console.error(e));
  }, []);

  const handleEdit = () => {
    navigate(`/app/${country}/l/ld/update-bank-info`, {
      state: data,
    });
  };

  return (
    <>
      <section className='pt-24' >
        <div className="w-full text-center my-6 text-third text-lg">
          Edita o consulta tus datos
        </div>
        <div className="m-auto w-full">
          <div className="w-full my-6 text-third text-4xl font-bold text-center">
            Datos bancarios
          </div>
        </div>
      </section>
      <hr />
      <section>
        <div className="h-screen">
          <div className="m-auto w-full">
            <div className="w-full my-10">
              <div className="w-full flex justify-end lg:px-0 px-4">
                <button className="text-green500" onClick={handleEdit}>
                  Editar datos
                </button>
              </div>
              <div className="px-4 lg:px-2 w-full lg:w-2/3">
                <div className="w-full flex justify-between items-center my-6">
                  <div className="text-blueForm font-semibold w-1/2 lg:w-auto">
                    Nombre de la entidad bancaria
                  </div>
                  <div className="text-graySoft ">{data?.bank_name}</div>
                </div>
              </div>
              <hr />
              <div className="px-4 lg:px-2 w-full lg:w-2/3">
                <div className="w-full flex justify-between items-center my-6">
                  <div className="text-blueForm font-semibold w-1/2 lg:w-auto">
                    Titular de cuenta
                  </div>
                  <div className="text-graySoft ">{data?.bank_headline}</div>
                </div>
              </div>
              <hr />
              <div className="px-4 lg:px-2 w-full lg:w-2/3">
                <div className="w-full flex justify-between items-center my-6">
                  <div className="text-blueForm font-semibold w-1/2 lg:w-auto">
                    Número de cuenta
                  </div>
                  <div className="text-graySoft ">{data?.bank_account}</div>
                </div>
              </div>
              <hr />
              <div className="px-4 lg:px-2 w-full lg:w-2/3">
                <div className="w-full flex justify-between items-center my-6">
                  <div className="text-blueForm font-semibold w-1/2 lg:w-auto">
                    Tipo de cuenta
                  </div>
                  <div className="text-graySoft ">
                    {data?.bank_account_type}
                  </div>
                </div>
              </div>
              <hr />
              <div className="px-4 lg:px-2 w-full lg:w-2/3">
                <div className="w-full flex justify-between items-center my-6">
                  <div className="text-blueForm font-semibold w-1/2 lg:w-auto">
                    Empresa
                  </div>
                  <div className="text-graySoft ">{data?.company_name}</div>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BankInfo;
