import React, { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useStep } from 'usehooks-ts'
import { getStorageVisit } from '../../helpers/visitHelper'
import { setDataVisits } from '../../redux/features/guestVisits'
import LayoutGuestVisit from './components/LayoutGuest/Layout'
import StepOneVisit from './components/StepOneVisit/StepOneVisit'
import StepTwoVisit from './components/StepTwoVisit/StepTwoVisit'
import StepSuccesVisit from './components/StepSuccesVisit/StepSuccesVisit'

const GuestVisit = memo(() => {
  const [currentStep, helpers] = useStep(2)
  const dispatch = useDispatch()

  useEffect(() => {
    const prevDataVisit = getStorageVisit()
    if (prevDataVisit) {
      const dataVisit = JSON.parse(prevDataVisit)
      dispatch(setDataVisits({
        visit_type: dataVisit?.visit_type,
        date: dataVisit?.date,
        start_time: dataVisit?.start_time
      }))
    } else {
      console.log('No hay informacion')
    }
  }, [dispatch])

  return (
    <LayoutGuestVisit image='https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/guest.png'>
      <>
        {(() => {
          switch (currentStep) {
            case 1:
              return <StepOneVisit helpers={helpers} />
            case 2:
              return <StepTwoVisit helpers={helpers} />
            default:
              return null
          }
        })()}
      </>
    </LayoutGuestVisit>
  )
})

GuestVisit.displayName = 'GuestVisit'
export default GuestVisit
