import NavBar from "../../components/NavBar/NavBar";
import FooterComponent from "../../components/Footer";
import { landlordFaq, tenantFaqs } from "./helpers/data";
interface FaqData {
  question?: string;
  answer?: string;
}

const needHelp = () => {
  return (
    <>
      <NavBar></NavBar>
      <div className="container mx-auto px-4">
        <div className="w-full ">
          <div className="w-full pt-24 pb-6 text-blueForm text-4xl font-bold text-center">
            Obtener ayuda
          </div>
        </div>
      </div>
      <div className="w-full border-b border-gray-100 my-4"></div>
      <div className="container mx-auto py-16 px-4">
        <div className="w-full rounded-lg bg-[#F8F8F8] py-4 px-4">
          <div className="w-full text-center mt-2 text-blueForm text-lg font-bold">
            Contáctanos
          </div>
          <div className="w-full my-6">
            <div className="w-full text-center text-graySoft  duration-300 ease-in-out hover:underline">
              <a
                href="https://wa.me/message/DMRZE4RUWJP5E1"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="mx-auto"
                  src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/WhatsAppButtonGreenMedium.svg"
                  alt="wha"
                />
              </a>
              <br />
            </div>

            <div className="w-full text-center text-graySoft duration-300 ease-in-out hover:underline">
              <a
                href="mailto:hola@propilatam.com"
                target="_blank"
                rel="noreferrer"
              >
                <span className="font-bold">Correo:</span> hola@propilatam.com
              </a>
            </div>
          </div>
          <div className="mt-2 w-full flex justify-center items-center">
            <div className="text-graySoft my-2 px-4  hover:underline duration-300 ease-in-out">
              <a href=" https://www.facebook.com/propi.latam/" target="_blank">
                Facebook
              </a>
            </div>
            <div className="text-graySoft my-2 px-4  hover:underline duration-300 ease-in-out border-x border-text-gray">
              <a href="https://www.instagram.com/propi.latam/" target="_blank">
                Instagram
              </a>
            </div>
            <div className="text-graySoft my-2 px-4  hover:underline duration-300 ease-in-out ">
              <a
                href="https://www.linkedin.com/company/propilatam"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full py-4">
        <div className="w-full pb-4 text-blueForm text-4xl font-bold text-center">
          Preguntas Frecuentes
        </div>
        <div className="container mx-auto my-4 px-4">
          <div className="w-full flex justify-between items-start flex-wrap">
            <div className="w-full md:w-1/2 md:pr-4">
              <div className="w-full  mt-2 text-blueForm text-lg font-bold">
                Propietarios
              </div>
              <div className="w-full my-4">
                {landlordFaq.length > 0 &&
                  landlordFaq.map((item: FaqData, index: number) => (
                    <div className="w-full text-graySoft">
                      <div className="font-bold my-1">{item.question}</div>
                      <div className="my-1">{item.answer}</div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="w-full md:w-1/2 md:pl-4">
              <div className="w-full  mt-2 text-blueForm text-lg font-bold">
                Inquilino
              </div>
              <div className="w-full my-4">
                {tenantFaqs.length > 0 &&
                  tenantFaqs.map((item: FaqData, index: number) => (
                    <div className="w-full text-graySoft">
                      <div className="font-bold my-1">{item.question}</div>
                      <div className="my-1">{item.answer}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterComponent></FooterComponent>
    </>
  );
};

export default needHelp;
