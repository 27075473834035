import { createSlice } from "@reduxjs/toolkit";
import { AuthDeveloper } from '../../models/authUserModel';

const initialState: AuthDeveloper = {
    company_name: "",
    company_role: "",
    company_logo: "",
}

export const authDevSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logoutActionDev: (state, action) => initialState,
        loginActionDeveloper: (state, action) => {
            return {
                ...action.payload
            }
        }
    }
})

// provide actions
export const { logoutActionDev, loginActionDeveloper } = authDevSlice.actions;
// provide reducer
export default authDevSlice.reducer