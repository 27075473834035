interface Props {
    message: string | undefined
    additionalClass?: string | undefined
}

const FormControlError = ({ message, additionalClass = '' }: Props) => {
    if(!message) return null;
    return (
        <div className={`mt-3 rounded-xl gap-2.5 px-3 py-1  bg-redFormValidation ${additionalClass}`} >
            <p className="text-red text-sm font-medium">{ message }</p>
        </div>
    )
}

export default FormControlError
