import React, { useState } from "react";
import "./CardProperty.scss";
import { useSelector } from "react-redux";
import { AppStore } from "../../../../redux/store";
import { useNavigate } from "react-router-dom";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { IconChevronRight } from "@tabler/icons-react";
interface PropertyProps {
  cover?: string;
  address?: string;
  monthly_price?: string;
  status: string;
  dues?: string;
  property_id: string;
  code?: string;
  contract_end?: string;
  type?: string;
  offers?: number;
  sale_monthly_price?: string;
  offers_sale?: string;
  sale_status: string;
}
const CardProperty = ({
  cover,
  address,
  monthly_price,
  status,
  dues,
  property_id,
  code,
  contract_end,
  type,
  offers,
  sale_monthly_price,
  offers_sale,
  sale_status
}: PropertyProps) => {
  const { country } = useSelector((store: AppStore) => store.country);
  const navigate = useNavigate();
  const renderSwitch = (param: string) => {
    switch (param) {
      case "paid":
        return (
          <span className=" rounded-full font-medium text-sm text-primary">
            Pagado
          </span>
        );
      case "created":
        return (
          <span className=" rounded-full font-medium text-sm text-primary">
            Creado
          </span>
        );
      case "current":
        return (
          <span className=" rounded-full font-medium text-sm text-graySoft">
            Actual
          </span>
        );
      case "pending":
        return (
          <span className=" rounded-full font-medium text-sm text-orange">
            Pendiente
          </span>
        );
      case "disabled":
        return (
          <span className=" rounded-full font-medium text-sm text-graySoft">
            Deshabilitado
          </span>
        );

      default:
        return (
          <span className=" rounded-full font-medium text-sm text-primary">
            Creado
          </span>
        );
    }
  };

  const viewDetail = (propertyId: string) => {
    document.documentElement.scrollTop = 0;
    navigate(`/app/${country}/l/admin-property/${propertyId}`);
  };

  const propertyStatusMapper = (status: string) => {
    switch (status) {
      case "published":
        return (
          <span className=" top-2 left-2 bg-white px-4 rounded-full font-semibold absolute text-base text-primary">
            Publicada
          </span>
        );
      case "created":
        return (
          <span className=" top-2 left-2 bg-white px-4 rounded-full font-semibold absolute text-base text-primary">
            Creada
          </span>
        );
      case "taken":
        return (
          <span className=" top-2 left-2 bg-white px-4 rounded-full font-semibold absolute text-base text-orange">
            Alquilada
          </span>
        );
      case "rented_external":
        return (
          <span className=" top-2 left-2 bg-white px-4 rounded-full font-semibold absolute text-base text-[#484E7B]">
            Renta por fuera
          </span>
        );
      case "disabled":
        return (
          <span className=" top-2 left-2 bg-white px-4 rounded-full font-semibold absolute text-base text-[#757575]">
            Desactivada
          </span>
        );

      default:
        return "foo";
    }
  };
  const propertySalesStatusMapper = (status: string) => {
    switch (status) {
      case "created":
        return (
          <span className=" top-2 left-2 bg-white px-4 rounded-full font-semibold absolute text-base text-primary">
            Creada
          </span>
        );
      case "take_photos":
        return (
          <span className=" top-2 left-2 bg-white px-4 rounded-full font-semibold absolute text-base text-primary">
            Toma de fotos
          </span>
        );
      case "published":
        return (
          <span className=" top-2 left-2 bg-white px-4 rounded-full font-semibold absolute text-base text-orange">
            Publicada
          </span>
        );
      case "disabled":
        return (
          <span className=" top-2 left-2 bg-white px-4 rounded-full font-semibold absolute text-base text-[#757575]">
            Desactivada
          </span>
        );
      case "purchase_agreement":
        return (
          <span className=" top-2 left-2 bg-white px-4 rounded-full font-semibold absolute text-base text-[#primary]">
            Firma de promesa
          </span>
        );
      case "reserved":
        return (
          <span className=" top-2 left-2 bg-white px-4 rounded-full font-semibold absolute text-base text-[#primary]">
            Reservada
          </span>
        );
      case "transfer_ownership":
        return (
          <span className=" top-2 left-2 bg-white px-4 rounded-full font-semibold absolute text-base text-[#757575]">
            Escrituración
          </span>
        );
      case "sold":
        return (
          <span className=" top-2 left-2 bg-white px-4 rounded-full font-semibold absolute text-base text-orange">
            Vendida
          </span>
        );
      case "sold_external":
        return (
          <span className=" top-2 left-2 bg-white px-4 rounded-full font-semibold absolute text-base text-orange">
            Vendida por fuera
          </span>
        );

      default:
        return null;
    }
  };
  const [valueTab, setValueTab] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValueTab(newValue);
  };

  return (
    <div className="card-property">
      {(() => {
        switch (type) {
          case "alquilada":
            return (
              <>
                <div className="flex justify-between flex-wrap">
                  <div className="w-4/5">
                    {renderSwitch(status)}
                    <h2 className="font-medium text-lg truncate text-blackGrayScale leading-tight mt-4">
                      {address}
                    </h2>
                    <p className="text-base font-medium text-blackGrayScale leading-tight">
                      {monthly_price}
                    </p>
                  </div>
                  <div className="w-1/5">
                    <img
                      className="w-full h-full xl:w-12 xl:h-12 rounded-md object-cover object-center"
                      src={cover}
                      alt=""
                    />
                  </div>
                  <div className="w-full mt-4">
                    <p className=" text-[#757575] text-sm">
                      #{code} {dues !== "" && <>- Cuotas {dues}</>}
                    </p>
                  </div>
                  <div className="w-full py-3 flex justify-center items-center border-t border-[#E0E0E0] mt-4">
                    <button
                      onClick={() => viewDetail(property_id)}
                      className="text-blackGrayScale font-medium text-sm"
                    >
                      Ver propiedad
                    </button>
                  </div>
                </div>
              </>
            );
          case "normal":
            return (
              <>
                <div
                  className="relative bg-center bg-cover bg-no-repeat h-[130px] w-full rounded-t-lg"
                  style={{ backgroundImage: `url(${cover})` }}
                >
                  {valueTab === '1' ? propertyStatusMapper(status) : propertySalesStatusMapper(sale_status)}
                </div>
                <div className="flex justify-between flex-wrap px-4">
                  <div className="w-full">
                    <h2 className="font-medium text-lg truncate text-blackGrayScale leading-tight mt-4">
                      {address}
                    </h2>
                    <p className="text-[#757575] font-normal text-base">
                      #{code}
                    </p>
                  </div>
                  <div className="w-full">
                    <TabContext value={valueTab}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                          TabIndicatorProps={{
                            style: {
                              backgroundColor: "#2FB672",
                            },
                          }}
                        >
                          <Tab label="Alquiler" value="1" />
                          <Tab label="Venta" value="2" />
                        </TabList>
                      </Box>
                      <TabPanel sx={{ padding: "10px 0px" }} value="1">
                        <div className="w-full">
                          <p className="text-base text-[#757575] font-medium">
                            {" "}
                            <span className="font-bold text-[#212121]">
                              Precio
                            </span>{" "}
                            {monthly_price}
                          </p>
                          <p className=" text-base text-[#757575] font-medium">
                            <span className="font-bold text-[#212121]">
                              Ofertas realizadas:
                            </span>{" "}
                            {offers}
                          </p>
                        </div>
                      </TabPanel>
                      <TabPanel sx={{ padding: "10px 0px" }} value="2">
                        {
                          sale_status !== null ? (<div className="w-full">
                          <p className="text-base text-[#757575] font-medium">
                            {" "}
                            <span className="font-bold text-[#212121]">
                              Precio
                            </span>{" "}
                            {sale_monthly_price}
                          </p>
                          <p className=" text-base text-[#757575] font-medium">
                            <span className="font-bold text-[#212121]">
                              Ofertas realizadas:
                            </span>{" "}
                            {offers_sale}
                          </p>
                        </div>) : (<div className="w-full py-3">
                          <p className="text-base text-[#757575] font-medium text-center">
                            
                          Tu propiedad no esta asignada para venta
                          </p>
                          
                        </div>)
                        }
                        
                      </TabPanel>
                    </TabContext>
                  </div>
                  <div className="w-full py-3 flex justify-center items-center border-t border-[#E0E0E0]">
                    <button
                      onClick={() => viewDetail(property_id)}
                      className="text-blackGrayScale font-medium text-sm flex justify-between items-center"
                    >
                      Ver detalle{" "}
                      <IconChevronRight color="#212121" width={20} />
                    </button>
                  </div>
                </div>
              </>
            );
        }
      })()}
    </div>
  );
};

export default CardProperty;
