import "../scss/styles.scss";
interface Props {
  video360: string;
  onAction: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const ModalVideo = (props: Props) => {
  return (
    <>
      <div className="w-full fixed top-0 left-0 bg-[#000000cc] h-screen z-50">
        <div className=" border-t border-graySoft w-full h-full flex-col flex items-center justify-center">
          <div className="w-full flex justify-end px-4 py-4 items-center">
            <button onClick={props.onAction} className="text-white text-xl">
              X
            </button>
          </div>
          <div className="container mx-auto items-center flex justify-center">
            <iframe
              width="1280"
              height="768"
              src={props.video360}
              title="YouTube video player"
              allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="w-full h-16"></div>
        </div>
      </div>
    </>
  );
};

export default ModalVideo;
