import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { isLoggedIn } from "../../helpers/authHelper";
import { AppStore } from "../../redux/store";
import NavBarLandlord from "./NavBarLandlord";
import NavBarTenant from "./NavBarTenant";
import NavBarMobile from "./NavBarMobile";
import useIsVisibleComponent from "../../hooks/useIsVisibleComponent";
import ItemNav from "./components/ItemNav/ItemNav";

import { IconUser } from "@tabler/icons-react";

import { toogleAction } from "../../redux/features/navigation";

import "./scss/NavBar.scss";
import { DEFAULT_AVATAR } from "../../constants/ProfileConstants";
import NavBarInvestor from "./NavBarInvestor";
import { setCountryAction } from "../../redux/features/country";
import { setCountry } from "../../helpers/countryHelper";
import ColorfulNameComponent from "./components/ColorfulName/ColorfulName";
import { Box, Drawer, Menu, MenuItem } from "@mui/material";
import DropdownMenuUser from "./components/NavDropdownUsers/NavDropDownUsers";

type Props = {
  children?: JSX.Element;
};

type Anchor = "top" | "left" | "bottom" | "right";

const logo =
  "https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/v2/logo-propi.svg";

const logoMin =
  "https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/v2/logo-propi.svg";
const logoMinWhite =
  "https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/logo-min-white.svg";
const logoWhite =
  "https://www.propilatam.dev/static/media/logo_propi_white.7c77c962396177555dd3b4b10e00f1e2.svg";
const hamburger_white =
  "https://www.propilatam.dev/static/media/hamburger.8a82c77d30f313c82b8f332839287179.svg";
const hamburger =
  "https://www.propilatam.dev/static/media/hamburger.8a82c77d30f313c82b8f332839287179.svg";

const NavBar = ({ children }: Props) => {
  const { id = "", code } = useParams();
  const { country } = useSelector((store: AppStore) => store.country);
  const [selectedCountry, setSelectedCountry] = useState<string>(country);
  const [isOpenOptions, setIsOpenOptions] = useState<boolean>(false);
  const user = useSelector((store: AppStore) => store.auth);
  const { visible: showNav } = useSelector(
    (store: AppStore) => store.navigation
  );
  const [showModal, setShowModal] = useState<boolean>(true);
  const dispatch = useDispatch();
  const [showMobile, setShowMobile] = useState(false);
  const isLogged = isLoggedIn(user);
  const navigate = useNavigate();
  const location = useLocation();
  const [navbar, setNavbar] = useState(false);
  const [navbarLogo, setNavbarLogo] = useState(logo);
  const { ref } = useIsVisibleComponent();
  const [navbarLogoMobile, setNavbarLogoMobile] = useState(logoMin);

  const LogoWhite = () => {
    if (location.pathname === "/" && window.scrollY <= 66) {
      setNavbarLogo(logoWhite);
    } else {
      setNavbarLogo(logo);
    }
  };
  const changeBackground = () => {
    if (location.pathname === "/" && window.scrollY <= 66) {
      setNavbar(true);
    } else if (window.location.pathname === `/app/${country}/polygons`) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });

  useEffect(() => {
    LogoWhite();
    // adding the event when scroll change Logo
    window.addEventListener("scroll", LogoWhite);
  });

  const doShowNav = () => {
    if (isLogged) {
      dispatch(toogleAction(!showNav));
    } else {
      return window.open(`/app/${country}/auth/login`, "_self");
    }
  };

  const getNavBar = () => {
    if (!showNav || !isLogged) {
      return null;
    }
    switch (user.realm) {
      case "landlord":
        return <NavBarLandlord />;
      case "tenant":
        return <NavBarTenant />;
    }
  };

  const getNavBarMobile = () => {
    if (!isLogged) {
      return null;
    }
    switch (user.realm) {
      case "landlord":
        return <NavBarLandlord />;
      case "tenant":
        return <NavBarTenant />;
    }
  };

  const toggleMobile = () => {
    try {
      setShowMobile((old) => !old);
      document.body.style.overflow = "scroll";
    } catch (error) {}
  };

  const handleCountrySelect = (option: string) => {
    const parts = location.pathname.split("/");
    parts[2] = option;
    const newPath = parts.join("/");
    const searchParams = new URLSearchParams(location.search);

    const newUrl = newPath + '?' + searchParams.toString();

    setSelectedCountry(option);
    dispatch(setCountryAction({ country: option }));
    setCountry(option);
    setIsOpenOptions(false)

    return window.open(newUrl, "_self");
    
  };

  const handleDropdownClick = () => {
    setIsOpenOptions(!isOpenOptions);
  };

  const OptionComprar: any = [
    {
      name: "En Planos",
      link: `/${country}/venta/casas-y-apartamentos/proyectos`,
    },
    {
      name: "Casas",
      link: `/app/${country}/marketplace?type=house&marketplace_type=for_sale`,
    },
    {
      name: "Apartamentos",
      link: `/app/${country}/marketplace?type=apto&marketplace_type=for_sale`,
    },
    {
      name: "Terrenos",
      link: `/app/${country}/marketplace?type=land&marketplace_type=for_sale`
    }
  ];
  const OptionComprarGt: any = [
    {
      name: "En Planos",
      link: `/${country}/venta/casas-y-apartamentos/proyectos`,
    },
    {
      name: "Casas",
      link: `/app/${country}/marketplace?type=house&marketplace_type=for_sale`,
    },
    {
      name: "Apartamentos",
      link: `/app/${country}/marketplace?type=apto&marketplace_type=for_sale`,
    },
  ];

  const OptionPropietario: any = [
    {
      name: "Vender mi propiedad",
      link: `/${country}/venta/vender-casa-apartamento-con-propi`,
    },
    {
      name: "Rentar mi propiedad",
      link: `/${country}/alquiler/alquila-tu-casa-apartamento-con-propi`,
    },
  ];

  const OptionCalcula: any = [
    {
      name: "Calculadora de crédito hipotecario",
      link: `/${country}/venta/calculadora-credito-hipotecario`,
    },
    {
      name: "Calculadora de renta",
      link: `/${country}/alquila-casa-apartamento-en-propi#calculadora`,
    },
  ];

  const OptionPropi: any = [
    {
      name: "Ayuda",
      link: `/${country}/ayuda`,
    },
    {
      name: "¿Quienes somos?",
      link: `/${country}/quienes-somos`,
    },
    {
      name: "Refiere y gana",
      link: `/app/${country}/referidos`,
    },
    {
      name: "Blog",
      link: `https://blog.propilatam.com/`,
    },
  ];

  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isLogged) {
      setAnchorEl(event.currentTarget);
    } else {
      return window.open(`/app/${country}/auth/login`, "_self");
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <nav
        className={`active bg-white ${
          navbar ? "" : "shadow-lg"
        } pt-2.5 fixed top-0 w-full z-50`}
      >
        <div className="container mx-auto flex flex-wrap justify-between items-center mb-3 px-4 md:px-4 lg:px-4 xl:px-4">
          <a href={`/${country}/`} className="flex items-center">
            <img
              src={navbarLogo}
              className="mr-3 h-6 sm:h-9 hidden lg:block"
              alt="Logo"
            />
            <img
              src={navbarLogoMobile}
              className="mr-3 h-6 block lg:hidden"
              alt="Logo"
            />
          </a>

          <div
            className="hidden justify-between items-center w-full xl:flex md:w-auto md:order-1 relative"
            id="mobile-menu-4"
          >
            {window.location.pathname !== `/${country}/` && (
              <ul className="flex flex-col mt-4 mr-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium ">
                <li className="flex items-center">
                  <ItemNav
                    name="Comprar"
                    options={country === "gt" ? OptionComprarGt : OptionComprar}
                  />
                </li>
                <li className="flex items-center">
                  <a
                    href={`/app/${country}/marketplace?marketplace_type=for_rent`}
                    className="block pr-4 pl-3 border-b"
                  >
                    <h1 className="text-sm">Alquilar</h1>
                  </a>
                </li>
                <li className="flex items-center">
                  <ItemNav name="Propietarios" options={OptionPropietario} />
                </li>
                <li className="flex items-center">
                  <ItemNav name="Simula y Calcula" options={OptionCalcula} />
                </li>
                <li className="flex items-center">
                  <ItemNav name="Más de Propi" options={OptionPropi} />
                </li>
              </ul>
            )}
            {!id && !code && (
              <div className="dropdown-country mr-2">
                <div
                  className={`selected-country ${
                    navbar ? "border-white" : "border-[#e0e0e0]"
                  } border`}
                  onClick={handleDropdownClick}
                >
                  {selectedCountry === "sv" && (
                    <img
                      className="w-8"
                      src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/el-salvador.svg"
                      alt="sv"
                    />
                  )}
                  {selectedCountry === "gt" && (
                    <img
                      className="w-8"
                      src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/guatemala.svg"
                      alt="sv"
                    />
                  )}
                  <i
                    className={`gg-chevron-down ${
                      navbar ? "text-white" : "text-[#212121]"
                    }`}
                  ></i>
                </div>
                {isOpenOptions && (
                  <ul className="options-country">
                    <li
                      className={`country-option ${
                        selectedCountry === "sv" ? "selected" : ""
                      }`}
                      onClick={() => {
                        handleCountrySelect("sv");
                      }}
                    >
                      <img
                        className="w-8"
                        src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/el-salvador.svg"
                        alt="sv"
                      />
                      <p>SV</p>
                    </li>
                    <li
                      className={`country-option ${
                        selectedCountry === "gt" ? "selected" : ""
                      }`}
                      onClick={() => {
                        handleCountrySelect("gt");
                      }}
                    >
                      <img
                        className="w-8"
                        src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/guatemala.svg"
                        alt=""
                      />
                      <p>GT</p>
                    </li>
                  </ul>
                )}
              </div>
            )}

            <div className="flex">
              <button onClick={doShowNav} type="button" className="button-auth">
                <img
                  className="w-5 mr-2"
                  src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/menu.svg"
                  alt=""
                />
                {isLogged ? (
                  <>
                    {user?.avatar !== null ? (
                      <img
                        className="rounded-full w-7 h-7"
                        src={user?.avatar}
                        alt=""
                      />
                    ) : (
                      <ColorfulNameComponent
                        firstName={user?.name}
                        lastName={user?.last_name}
                      />
                    )}
                  </>
                ) : (
                  <div className="flex justify-center items-center w-6 h-6 rounded-full border border-[#212121]">
                    <IconUser width={18} height={18} />
                  </div>
                )}
              </button>
              <DropdownMenuUser />
            </div>
          </div>
          <div
            className="flex
           xl:hidden justify-end items-center"
          >
            <button
              onClick={doShowNav}
              className="mr-4"
            >
              {isLogged ? (
                <>
                  {user?.avatar !== null ? (
                    <img
                      className="rounded-full w-7 h-7"
                      src={user?.avatar}
                      alt=""
                    />
                  ) : (
                    <ColorfulNameComponent
                      firstName={user?.name}
                      lastName={user?.last_name}
                    />
                  )}
                </>
              ) : (
                <div className="flex justify-center items-center w-6 h-6 rounded-full border border-[#212121]">
                  <IconUser width={18} height={18} />
                </div>
              )}
            </button>

            

            

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.08))",
                  mt: 1.5,
                  paddingRight: 2,
                  paddingLeft: 2,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {getNavBarMobile()}
            </Menu>
            <DropdownMenuUser />

            <button className="focus:outline-none" onClick={toggleDrawer("right", true)}>
              <img
                className="w-6"
                src={navbar ? hamburger_white : hamburger}
                alt=""
              />
            </button>
          </div>
        </div>

        {window.location.pathname === `/${country}/` && (
          <>
            <hr className="border-[##E0E0E0] container mx-auto p-0 hidden lg:block" />
            <div className="container mx-auto hidden lg:flex justify-end py-4">
              <ul className="flex flex-col mt-4 mr-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
                <li className="flex items-center">
                  <a
                    href={`/app/${country}/marketplace`}
                    className="block pr-4 pl-3 border-b"
                  >
                    <h1 className="text-sm">Alquilar</h1>
                  </a>
                </li>
                <li className="flex items-center">
                  <a
                    href={`/${country}/venta/casas-y-apartamentos/proyectos`}
                    className="block pr-4 pl-3 border-b"
                  >
                    Comprar
                  </a>
                </li>
                <li className="flex items-center">
                  <a
                    href={`/${country}/alquiler/soy-propietario`}
                    className="block pr-4 pl-3 border-b"
                  >
                    Publicar
                   
                  </a>
                </li>
                <li className="flex items-center">
                  <a
                    href={`/app/${country}/referidos`}
                    className="block pr-4 pl-3 border-b"
                  >
                    Referir
                  </a>
                </li>
                <li className="flex items-center">
                  <a
                    href={`/${country}/ayuda`}
                    className="block pr-4 pl-3 border-b"
                  >
                    Ayuda
                  </a>
                </li>
              </ul>
            </div>
          </>
        )}

        {children}
      </nav>
      <Drawer
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        className="w-full mobile-menu"
      >
        <Box
          sx={{ width: "100%" }}
          role="presentation"
        >
          <NavBarMobile onAction={toggleDrawer("right", false)} />
        </Box>
      </Drawer>
    </div>
  );
};

export default NavBar;
