export const parseStringBooleanToBool = (value: string) => {
    if (value.toLowerCase() === "true") return true;
    if (value.toLowerCase() === "false") return false;
}

export const reverseDate = (date: string) => {
    if(!date)return ''
    const strings = date.split('/');
    return `${strings[2]}-${strings[1]}-${strings[0]}`;
}
