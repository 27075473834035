import { createSlice } from "@reduxjs/toolkit";
import { AdminProperty } from '../../models/PropertyModel';

const initialState: AdminProperty = {
    id: '',
    code: '',
    address: '',
    cover: '',
    monthly_price: '',
    plan: '',
    status: '',
    offers: 0,
    visits:0
}

export const AdminPropertySlice = createSlice({
    name: 'adminProperty',
    initialState,
    reducers: {
        loadAction: (state, action) => action.payload
    }
})

export const { loadAction } = AdminPropertySlice.actions
export default AdminPropertySlice.reducer