interface FaqData {
  question?: string;
  answer?: string;
}


export const landlordFaq: FaqData[] = [
  {
    question: "¿Cuál es el costo del servicio de Propi?",
    answer:"Publicar, la sesión de fotos, mostrar tu inmueble con nuestros Propers y conseguir al mejor inquilino es GRATIS. Al iniciar el contrato se cobra la comisión por corretaje (Equivalente a 1 mes de alquiler + IVA). Si eliges el Plan Plus por que quieres despreocuparte de tu inmueble e inquilino, tiene un costo de 5.5% del alquiler mensual, el cual se debitará de tu pago mensual.",
  },
   {
    question: "¿Qué necesito para publicar mi inmueble?",
    answer: "Haz una cuenta de Propietario, haz click en el botón “Publicar” y llena el formulario. Agenda tu sesión de fotos y estará listo para ser alquilado en Propi. Antes de que tu inmueble sea alquilado, debes haber subido los documentos personales o institucionales que avalen que eres el dueño del inmueble."
  },
   {
    question: "¿Puedo utilizar fotos que ya tengo de mi inmueble?",
    answer: "Puedes subirlas, sin embargo se analizarán si cumplen con los estándares de calidad de Propi. De igual manera, se agendará una visita a tu propiedad para poder realizar el recorrido 360, lo cual es totalmente gratis."
  },
   {
    question: "¿En qué territorios está disponible Propi?",
    answer: "Por el momento, operamos en la zona metropolitana de San Salvador y La Libertad. Específicamente en las siguientes zonas: Santa Tecla, Merliot, Santa Elena, Utila, Nuevo Cuscatlán, Zaragoza, San Benito, Colonia Escalón, El Espino, Colonia San Francisco, Lomas de San Francsico y alrededores."
  },
    {
    question: "¿Con qué tipo de inmuebles trabajan?",
    answer: "El inmueble debe de ser residencial \n  Puede ser casa o apartamento \n No alquilamos habitaciones \n El inmueble debe de estar en óptimas condiciones para una vivienda digna  \n Debe de estar dentro de nuestra zona de cobertura"
  },
    {
    question: "¿Puedo contratar a Propi si mi inmueble ya está siendo alquilado?",
    answer: "¡Claro que si! Escríbenos por redes sociales, por Whatsapp +503 6937 9302 o por correo hola@propilatam.com Te corresponderemos lo antes posible."
  },
   {
    question: "¿Qué es un Proper?",
    answer: "Propers son todos los agentes inhouse y freelance que forman parte del área comercial de Propi. Se dividen en dos tipos: \n Proper Fotógrafo: Está encargado de verificar el estado del inmueble, tomar fotos, videos y recorridos 360. \n Proper Agente: Personal experto en el rubro y capacitado para mostrar tu propiedad, asegurar el alquiler de tu inmueble y apoyar al inquilino y propietario en el proceso de alquiler."

  },
  {
    question: "¿Puedo utilizar a Propi solo para encontrar al mejor inquilino y que me pague mensualmente a mí?",
    answer: "¡Claro que sí! Cuando publiques tu propiedad elige el paquete Plataforma. Publicar, la sesión de fotos y mostrar tu propiedad a posibles inquilinos con nuestros Propers es totalmente gratis. Al aceptar la oferta de alquiler, se te cobrará solamente la comisión por corretaje (equivalente a 1 mes de alquiler + IVA) que se debitará del primer pago del inquilino."

  },
    {
    question: "¿Con quién me pongo en contacto si tengo un problema con el inmueble?",
    answer: "¡Estamos siempre disponibles para ti! Escríbenos por redes sociales, por Whatsapp +503 6937 9302 o por correo hola@propilatam.com Te corresponderemos lo antes posible."

  },
      {
    question: "¿Qué pasa si mi inquilino se atrasa en los pagos del alquiler?",
    answer: "Si el inquilino no paga el alquiler en los primeros 5 días del mes, se le cobrará una multa por día de atraso estipulado en el contrato de arrendamiento. Si al día 15 no se ha cancelado la cuota correspondiente, se le notificará por correo e iniciará un proceso de cobranza judicial. Al cumplirse 30 días de atraso se inicia la demanda para el desalojo del inmueble."

  },
        {
    question: "¿Cómo funciona el proceso de alquiler y visitas a mi propiedad?",
    answer: "Agenda la sesión de fotos gratuita desde la página web y compártenos la información de tu inmueble. Un Proper Agente se te será asignado para un mejor seguimiento.   \n 1. Se realiza la sesión de fotos con nuestros Propers Fotógrafos capacitados para tomar las mejores fotos, videos y recorridos 360 de tu inmueble.  \n 2. La propiedad quedará publicada en Propi y otros portales inmobiliarios aproximadamente 48 horas después de la toma de fotografías. ¡Te notificaremos cuando ya esté disponible!  \n 3. Adicional a estar publicada en Propi y otros portales, invertiremos en pauta publicitaria para que los inmuebles tengan mayor visibilidad y así asegurar que tu inmueble sea alquilado lo antes posible. \n 4. Nuestros Propers acompañan a los interesados en el inmueble en una recorrido en tu inmueble y solventan todas las dudas. \n 5. El inquilino interesado pasará un proceso de precalificación y luego podrá hacer una oferta en Propi la cual podrás aceptar, negociar o denegar. \n 6. Una vez aceptada la oferta, se procede a la firma del contrato y el inquilino tendrá 3 días para pagar el depósito y el primer mes de alquiler.7. Luego se coordina la entrega del inmueble. \n ¡Y listo! Tu inmueble está en manos del mejor inquilino."

  },
        {
    question: "¿Qué requisitos se le piden al inquilino para hacer ofertas a mi inmueble?",
    answer: "Tu tiempo e inmueble son muy importante para nosotros. Es por eso que solo recibirás ofertas de inquilinos precalificados, las cuales incluyen:   \n Aceptar términos y condiciones para evaluación gratis de Buró de Crédito   \n Tener ingresos familiares comprobables 2.5 veces mayor que el valor del alquiler   \n Constancia de salario   \n Documento de Identidad (DUI o Pasaporte)  \n En caso de ser extranjero tener permiso temporal de residencia no mayor a 4 años."

  },
      {
    question: "¿Cuándo recibo el dinero del pago de mi inquilino?",
    answer: "El inquilino tiene los primeros 5 días del mes para el pago del alquiler. Una vez pagado, se te depositará en tu cuenta 48 horas después."

  },
 {
    question: "¿Qué incluye el seguro de contenido?",
    answer: "El Seguro de Contenido en alianza con SURA posee cobertura contra incendios, rayos, riesgos catastróficos, daños por aguas lluvias, potables y/o servidas, daños causados por corrientes eléctricas, robo y hurto con violencia."

  },
   {
    question: "La cobertura de riesgos catastróficos incluye:",
    answer: "Daños por deslizamiento, derrumbes o desplome de terreno \n Daños por desprendimiento y arrastre de tierra y lodo \n Huracán, ciclón, vientos tempestuosos y granizo \n Inundación de aguas fluviales, lacustres o marítimas \n Terremoto, temblor o erupción volcánica"

  },
   {
    question: "¿Qué pasa si mi inmueble sufre un daño catastrófico?",
    answer: "¡Comunícate inmediatamente con Propi! Contacta a tu Proper asignado, nuestro Whatsapp +503 6937 9302 o por correo hola@propilatam.com Te corresponderemos lo antes posible."

  },

]



export const tenantFaqs: FaqData[] = [
    {
        question:"¿Puedo visitar el inmueble antes de alquilarlo?",
        answer:"¡Claro que si! Y es totalmente gratis. Selecciona tu inmueble favorito, da click en “Agendar visita” y selecciona cuando lo quieres visitar. Uno de nuestros Propers te contactará para organizar la visita."
    },
    {
        question:"¿Cómo cancelo una visita?",
        answer:"¡Notifica a tu Proper asignado! Cancela o reagenda la visita en tu horario disponible."
    },
        {
        question:"¿Cuánto cobran por alquilar una casa?",
        answer:"Cobramos el depósito (equivalente a 1 mes de alquiler + IVA) + alquiler que negocies con el propietario."
    },
        {
        question:"¿Cuáles son los requisitos para rentar con Propi?",
        answer:"Aceptar términos y condiciones para evaluación gratis de Buró de Crédito. Tener ingresos familiares comprobables 2.5 veces mayor que el valor del alquiler. Constancia de salario. Documento de Identidad (DUI o Pasaporte).En caso de ser extranjero tener permiso temporal de residencia no mayor a 4 años."
    },
    {
        question:"¿Qué tengo que pagar para rentar esta una propiedad?",
        answer:"Pago del primer mes de alquiler. \n Pago del depósito: Equivalente al valor de 1 mes de alquiler, se paga solo el primer mes."
    },
        {
        question:"¿Qué métodos de pago puedo utilizar para pagar la mensualidad?",
        answer:"\nTarjeta de débito y crédito.\nTransferencias bancarias.\nPróximamente efectivo, puntos y millas."
    },
    {
        question:"¿Cuándo tengo que pagar el depósito y el primer mes del aquiler?",
        answer:"A partir de la aprobación de la oferta, el inmueble está reservado para ti y tienes 72 horas para pagar el depósito y el primer mes de alquiler. De no ser pagado, el inmueble estará disponible al público nuevamente en la plataforma."
    },
     {
        question:"¿Qué día debo pagar mensualmente el alquiler?",
        answer:"Tienes disponible para pagar entre el día 1 y 5 de cada mes."
    },
    {
        question:"¿Cuándo me cobrarán si alquilo un inmueble a mitad de mes?",
        answer:"Se te cobrará el depósito (equivalente a 1 mes de alquiler) + la cantidad equivalente a los días que sobren del mes. Ej. Si alquilas un inmueble con una cuota de alquiler de $1,000 mensuales y el primer día del alquiler es el día 22 del mes, en un mes de 30 días: Pagas $1,000 de depósito + $264 (8 días de alquiler)."
    },
    {
        question:"¿Puedo rentar más de un inmueble?",
        answer:"¡Claro que sí! Si eres empresa privada, puedes alquilar múltiples inmueble según el análisis crediticio. Si eres persona natural, contáctanos para apoyarte."
    },
        {
        question:"¿Qué pasa si me atraso con el pago del alquiler?",
        answer:"Si no pagas el alquiler en los primeros 5 días del mes, se cobrará una multa por día de atraso estipulado en el contrato de arrendamiento. Si al día 15 no has cancelado la cuota correspondiente, se te notificará por correo e iniciará un proceso de cobranza judicial. Al cumplirse 30 días de atraso se inicia la demanda para el desalojo del inmueble."
    },
     {
        question:"¿Con quién me pongo en contacto si tengo un problema con el inmueble?",
        answer:"¡Estamos siempre disponibles para ti! Escríbenos por redes sociales, por Whatsapp +503 6937 9302 o por correo hola@propilatam.com Te corresponderemos lo antes posible."
    },
    {
        question:"¿Cómo y cuándo se firma el contrato digital?",
        answer:"¡Es 100% digital y GRATIS! No te preocupes por conseguir abogado o incurrir en más costos. Recibes el contrato en formato digital en Propi y en tu correo, después de ser aprobada la oferta por parte del propietario. Tienes 3 días disponibles para enviar el contrato firmado y pagar el depósito y el primer mes de alquiler."
    },
]



