/**
 * Function to adapt any list to a select option structure { label, value }
 * @param list - any array of object list
 * @param keyIndex - name of property to extract index of new list 
 * @param keyValue - name of property to extract value of new list
 * @returns 
 */
export const listToSelectOptionAdapter = (list: Array<any>, 
    keyIndex: string, keyValue: string) => {
    let response = []
    for(const element of list){
        const value = element[keyIndex];
        const label = element[keyValue];
        response.push({
            label,
            value
        })
    }
    return response
}