import { useDispatch, useSelector } from "react-redux";
import { unsetAuth } from "../helpers/authHelper";
import useCallApiAndLoad from "../hooks/useCallApiAndLoad";
import { logoutAction } from "../redux/features/auth";
import { AppStore } from "../redux/store";
import { logoutEndpoint } from "../services/publicService";

const useAuth = () => {
  const user = useSelector((store: AppStore) => store.auth);
  const { country } = useSelector((store: AppStore) => store.country);
  const { callEndpoint } = useCallApiAndLoad();

  const dispatch = useDispatch();

  const handleLogout = async () => {
    const { token, rft, realm } = user;
    await callEndpoint(logoutEndpoint(token, rft, realm));
    dispatch(logoutAction({}));
    unsetAuth();
    localStorage.removeItem('nameColor');
    return window.open(`/app/${country}/auth/login`, "_self");
  };

  return {
    handleLogout,
  };
};

export default useAuth;
