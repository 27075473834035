import React, { memo } from 'react'

interface PropsLayout {
    children: JSX.Element,
    image: string
}

const LayoutGuestOffer = memo((props: PropsLayout) => {
    return (
        <div className='w-full h-screen flex flex-wrap'>
            <div className='w-full lg:w-1/2 bg-tea px-4 md:px-0'>
                <div className='container mx-auto h-full flex justify-center items-center'>
                    {props.children}
                </div>
            </div>
            <div className='w-full lg:w-1/2 hidden md:block'>
                <img src={props.image} className='w-full h-full object-cover object-center' alt="" />
            </div>
        </div>
    )
})

LayoutGuestOffer.displayName = 'LayoutGuestOffer'
export default LayoutGuestOffer
