
import { IconHomeCheck } from '@tabler/icons-react';
import { formatTextDate } from '../../../../helpers/dateHelper';
import './CardVisit.scss'


interface PropertyInfo {
  address: string;
  cover: string;
  has_old_price: boolean;
  id: string;
  old_rent_price: string;
  rent_price: string;
  url_detail: string;
  code: string;
  name: string;
}

interface VisitInfo {
  id: string;
  status: string;
  visit_date: string;
  visited_at: string;
  marketplace_type: string;
  visit_time: string;
}
interface DataUserVisit {
    data: {
      has_offer: boolean;
      property_info?: PropertyInfo;
      visit_info?: VisitInfo;
    };
    action: (data: boolean, info?: any) => void;
  }

  

const CardVisit = ({ data, action }: DataUserVisit) => {

  const showModal = (type: string) => {
    action(true, {
      id: data.property_info?.id,
      type: type,
      property_info: data.property_info,
      visit_info: data?.visit_info || {},
    });
  };
    return (<div className='card_visit'>
        <div className="card_visit__image">
        <div className="overlay">
        {data?.visit_info?.marketplace_type === "on_sale" ? (
            <span className="text-[#FA712D] bg-[#FEE3D5] px-4 rounded-lg font-semibold inline-block text-xs">
              Venta
            </span>
          ) : (
            <span className="text-primary bg-[#D5F0E3] px-4 rounded-lg font-semibold inline-block text-xs">
              Renta
            </span>
          )}
            
    
          <h1 className="text-xl font-semibold text-white mt-1">
            {
              data?.property_info?.name
            }
          </h1>
        </div>
        <img
          className=" object-cover object-center h-44 w-full"
          src={data?.property_info?.cover}
          alt="img-propi"
        />
      </div>
      <div className="card_visit__info px-6 py-4">
            <p className=' text-sm font-normal text-[#757575]'>
              Visita agendada para:
            </p>
            <p className='text-[#212121] text-sm font-bold'>{formatTextDate(data?.visit_info?.visit_date as string) } a las {data?.visit_info?.visit_time}</p>
            <p className=' text-sm font-normal text-[#757575] mt-2'>En caso de cualquier inconveniente, puedes <span className='text-[#26925B] font-semibold hover:border-b-primary hover:border-b hover:cursor-pointer' onClick={() => showModal("reagendar")}>reagendar tu visita</span>.</p>
      </div>
      <div className="card_visit__info px-6">
        <div className=" pb-6 px-6 flex justify-center items-center">
        <button
                        onClick={() => showModal("ofertar")}
                        className="flex items-center gap-2 px-3 py-2 font-medium shadow-md bg-[#212121] text-white outline-none rounded-lg mb-"
                      >
                        Ofertar propiedad <IconHomeCheck width={22} color="#fff" />
                      </button>
        </div>
      </div>

    </div>)
 }

 export default CardVisit;


