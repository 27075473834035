import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter'
import { unsetAuth } from '../../../helpers/authHelper'
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad'
import { logoutAction } from '../../../redux/features/auth'
import { getVisitsProperty } from '../services/PropertiesService'
import { AppStore } from '../../../redux/store'

export const useVisitProperty = (): any => {
    const { country } = useSelector((store: AppStore) => store.country);
    const [visitsData, setVisitsData] = useState([])
    const [loading, setLoading] = useState<boolean>(false)
    const { isLoading, callEndpoint } = useCallApiAndLoad()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const getDataVisits = async (idProperty: string, token: string): Promise<void> => {
        setLoading(true)
        try {
            const {status, data} = await callEndpoint(getVisitsProperty(idProperty, token)) 
            if (status === 401) {
                dispatch(logoutAction({}))
                unsetAuth()
                return navigate(`/app/${country}/auth/login`)
            }
            const response = fetchResponseAdapter(data)
            setLoading(false)
            console.log(response.data)
            setVisitsData(response.data.items)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    return {
        visitsData,
        loading,
        isLoading,
        getDataVisits
    }

}