import { useSelector } from "react-redux";
import arrow_left from "../../assets/icons/arrow-left.png";
import banner from "../../assets/images/signature-banner.jpg";
import { Link } from "react-router-dom";
import { AppStore } from "../../redux/store";

const SignatureComplete = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  return (
    <>
      <div className="w-full pt-16 ">
        <div className="flex justify-between items-center py-4 px-4 border-b border-tea-400">
          <Link
            to={`/app/${country}/l/tn/payments`}
            className="cursor-pointer flex justify-center items-center duration-300 hover:translate-x-4 ease-in-out"
          >
            <img className="mr-2 w-6" src={arrow_left} alt="" />
            Regresar a mis propiedades
          </Link>
        </div>
        <div className="w-full py-16">
          <div className="w-full text-center my-6">
            <img src={banner} className="mx-auto" alt="" />
          </div>
          <div className="w-full text-center">
            <span className="text-third">Felicidades</span>
            <div className="w-full my-4 text-third text-4xl font-bold text-center">
              ¡Firma de contrato realizada!
            </div>
            <span className="text-third text-center">
              Nos pondremos en contacto cuando se haya aprobado y puedas
              realizar tu primer pago.
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignatureComplete;
