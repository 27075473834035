import axios from "axios";

const loadAbort = () => {
  return new AbortController();
};

export const getTranferInfo = (service: string, projectId: any) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: projectId
          ? `${urlService}/ts/v1/payment-info/${service}/transfer-info?${projectId}`
          : `${urlService}/ts/v1/payment-info/${service}/transfer-info`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};




