import arrow_left from "../../../assets/icons/arrow-left.png";
import banner from "../../../assets/images/signature-banner.jpg";
import { Link } from 'react-router-dom';
import { AppStore } from "../../../redux/store";
import { useSelector } from "react-redux";

const SalesTransferCompleted = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  return (
    <>
      <div className="w-full pt-16 ">
        <div className="flex justify-between items-center py-4 px-4 border-b border-tea-400">
          <Link
            to={`/app/${country}/l/tn/payments-sales`}
            className="cursor-pointer flex justify-center items-center duration-300 hover:translate-x-4 ease-in-out"
          >
            <img className="mr-2 w-6" src={arrow_left} alt="" />
            Regresar a Mis compras
          </Link>
        </div>
        <div className="w-full py-16">
          <div className="w-full text-center my-6">
            <img src={banner} className="mx-auto" alt="" />
          </div>

          <div className="w-full text-center">
            <div className="w-full mb-3 text-center">
              <h1 className="text-3xl xl:text-4xl font-bold text-[#212121]">¡Información recibida!</h1>
              
            </div>
            <p className="text-[#757575] text-center text-sm xl:text-base">
            Estamos verificando tu transacción, te contactaremos pronto.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default SalesTransferCompleted

