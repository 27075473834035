import { Option } from "../models/formModel";

export const getOfferPeriods = (): Option[] => {
    return [
        {
            value: 6,
            label: "6 meses",
        },
        {
            value: 12,
            label: "12 meses",
        },
        {
            value: 24,
            label: "24 meses",
        },
        {
            value: 36,
            label: "+36 meses",
        }
    ];
}