import FooterComponent from "../../components/Footer";
import NavBar from "../../components/NavBar/NavBar";
import arrow_button from "../../assets/icons/arrow_button.svg";
import room from "../../assets/images/referrals/room.svg";
import girl from "../../assets/images/referrals/girl.svg";
import lamp from "../../assets/images/referrals/lamp.svg";
import lamp1 from "../../assets/images/referrals/lamp-1.svg";
import lamp2 from "../../assets/images/referrals/lamp-2.svg";

import "./scss/referrals.scss";

const ReferralsPage = () => {
  return (
    <div className="ownerPage">
      <NavBar />
      <section className="w-full">
        <div className="container mx-auto">
          <div className="referrals-header mt-10 lg:mt-20 bg-cover bg-center bg-no-repeat">
            <div className=" relative">
              <div className="container mx-auto flex lg:py-32 lg:px-20 px-5 h-96 lg:h-auto">
                <div className="w-full max-w-lg z-10 flex md:items-end justify-end items-center md:justify-center flex-col lg:block md:m-0 mb-5">
                  <h1 className=" text-white font-bold">
                    ¡Refiere
                    <br /> y gana!
                  </h1>
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLScNq5CZWZxk7wULjbgXnOTUKUyoXR1LCTjusyaWlMmwv5DdRQ/viewform"
                    target="_blank"
                    className="bg-orange py-2 pl-8 pr-2 md:w-40 w-auto text-white text-base rounded-full flex justify-end items-center group mt-5"
                    rel="noreferrer"
                  >
                    <span className="mr-4">Refiere</span>
                    <img
                      className="hidden md:inline group-hover:-translate-x-3 duration-300 ease-in-out"
                      src={arrow_button}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="lg:py-24 py-12 px-4 md:px-0">
        <div className="container mx-auto">
          <div className="flex flex-col justify-center items-center">
            <h1 className=" text-green800 font-medium text-2xl md:text-4xl mb-4">
              Gana dinero con solo referir
            </h1>
            <p className=" text-base text-graySoft font-normal">
              Refiere a personas que quieran alquilar su inmueble y ganas una
              comisión por hacerlo.
            </p>
          </div>
          <div className={`block p-5 max-w-4xl mx-auto`}>
            <div className="md:mx-4 p-4">
              <div className="flex items-center">
                <div className="flex items-center text-teal-600 relative">
                  <div className="border-tea-500 text-green700 font-medium rounded-full flex justify-center items-center transition duration-500 ease-in-out h-8 w-8 py-3 border-4">
                    1
                  </div>
                </div>
                <div
                  className={`border-tea-500 flex-auto border-t-2 transition duration-500 ease-in-out`}
                ></div>
                <div className="flex items-center relative">
                  <div
                    className={`border-tea-500 text-green700 rounded-full flex justify-center items-center transition duration-500 ease-in-out h-8 w-8 py-3 border-4`}
                  >
                    2
                  </div>
                </div>
                <div
                  className={`border-tea-500 flex-auto border-t-2 transition duration-500 ease-in-out`}
                ></div>
                <div className="flex items-center relative">
                  <div
                    className={`border-tea-500 text-green700 rounded-full flex justify-center items-center transition duration-500 ease-in-out h-8 w-8 py-3 border-4`}
                  >
                    3
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full mt-0 md:mt-12 flex-wrap">
            <div className="w-full md:w-1/3 md:px-12 px-0 mt-5 md:mt-0">
              <h1 className="text-2xl md:text-3xl font-bold text-grayText text-center mb-5">
                Completa el formulario en 30 segundos.
              </h1>
              <p className=" text-base font-normal text-graySoft text-center">
                Completa el formulario de registro en segundos, con tu
                información y/o la del inmueble que refieres.
              </p>
            </div>
            <div className="w-full md:w-1/3 md:px-12 px-0 mt-5 md:mt-0">
              <h1 className="text-2xl md:text-3xl font-bold text-grayText text-center mb-5">
              Al ser publicada la casa en Propi, te pagamos $25
              </h1>
              <p className=" text-base font-normal text-graySoft text-center">
                ¡No tienes que hacer nada más! Cuando se dá de alta a la
                propiedad, te pagamos.
              </p>
            </div>
            <div className="w-full md:w-1/3 md:px-12 px-0 mt-5 md:mt-0">
              <h1 className="text-2xl md:text-3xl font-bold text-grayText text-center mb-5">
              Si es alquilada, te pagamos el 10% del alquiler del primer mes.
              </h1>
              <p className=" text-base font-normal text-graySoft text-center">
                ¡Ganas aun cuando la casa sea alquilada en Propi!
              </p>
            </div>
          </div>
          <div className="flex w-full justify-center">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLScNq5CZWZxk7wULjbgXnOTUKUyoXR1LCTjusyaWlMmwv5DdRQ/viewform"
              target="_blank"
              className="bg-orange py-2 pl-8 pr-2 md:w-48 w-auto text-white text-base rounded-full flex justify-end items-center group mt-5"
              rel="noreferrer"
            >
              <span className="mr-4">Refiere ya</span>
              <img
                className="hidden md:inline group-hover:-translate-x-3 duration-300 ease-in-out"
                src={arrow_button}
                alt=""
              />
            </a>
          </div>
        </div>
      </section>
      <section className="info-section bg-greenTea px-4 md:px-0 relative">
        <img className="hidden md:block one" src={lamp} alt="" />
        <img className="hidden md:block two" src={lamp1} alt="" />
        <img className="hidden md:block three" src={lamp2} alt="" />
        <div className="container mx-auto lg:py-20 py-12">
          <div className=" mx-auto max-w-2xl">
            <h1 className=" text-green800 font-normal text-2xl md:text-4xl mb-4 text-center">
              Si refieres una casa, con un valor de alquiler de $1,500
            </h1>
            <div className="flex flex-wrap justify-around">
              <div className="w-full md:w-1/2 flex flex-col items-center py-5">
                <h1 className="font-bold text-2xl md:text-4xl text-green800 bg-white py-2 px-3 rounded-full shadow-sm text-center">
                  Recibes $150
                </h1>
                <p className=" mt-3 text-graySoft text-base">
                  Si la casa se alquila en Propi
                </p>
              </div>
              <div className="w-full md:w-1/2 flex flex-col items-center py-5">
                <h1 className="font-bold text-2xl md:text-4xl text-green800 bg-white py-2 px-3 rounded-full shadow-sm text-center">
                  Recibes $25
                </h1>
                <p className=" mt-3 text-graySoft text-base">
                  Si la casa se publica
                </p>
              </div>
              <div className="w-full md:w-1/2 flex flex-col items-center py-5">
                <h1 className="font-bold text-2xl md:text-4xl text-white bg-green500 py-2 px-3 rounded-full shadow-sm text-center">
                  Total: $175
                </h1>
                <p className=" mt-3 text-graySoft text-base">
                  Por compartir un contacto
                </p>
              </div>
            </div>
          </div>
          <div className="w-full">
            <img className="mx-auto w-full max-w-4xl" src={room} alt="" />
          </div>
        </div>
      </section>
      <div className=" bg-white py-10 px-4">
        <div className="container mx-auto flex flex-wrap">
          <div className="w-full md:w-1/2">
            <h2 className="text-green800 text-2xl md:text-4xl mt-5  mb-2 lg:mb-16">
              ¿Cómo y cuándo <br />
              se te paga?
            </h2>
            <div className="w-full my-4 max-w-lg">
              <p className=" text-graySoft text-base">
                Un inmueble referido se paga cuando ya está disponible en Propi.
              </p>
              <div className="w-full flex items-start justify-start my-2 lg:flex-row flex-col">
                <div className="mb-4 lg:mb-0">
                  <h1 className="text-3xl font-bold text-graySoft">1.</h1>
                </div>
                <div className=" text-graySoft lg:px-5">
                  <div className="text-base">
                    Inmuebles publicados los últimos 15 días del mes. Pago el
                    día 15 del siguiente mes.
                  </div>
                </div>
              </div>
              <div className="w-full flex items-start justify-start my-4 lg:flex-row flex-col">
                <div className="mb-4 lg:mb-0">
                  <h1 className="text-3xl font-bold text-graySoft">2.</h1>
                </div>
                <div className=" text-graySoft lg:px-5">
                  <div className="text-base">
                    Inmuebles publicados los primeros 15 días del mes.Pago el
                    día 15 del siguiente mes.
                  </div>
                </div>
              </div>
              <p className=" text-graySoft text-base">
                Un inmueble alquilado se paga cuando ya se firme contrato y se
                pague primera mensualidad.
              </p>
            </div>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLScNq5CZWZxk7wULjbgXnOTUKUyoXR1LCTjusyaWlMmwv5DdRQ/viewform"
              target="_blank"
              className="bg-orange py-2 pl-8 pr-2 md:w-48 w-40 text-white text-base rounded-full flex justify-center md:justify-end items-center group mt-5"
              rel="noreferrer"
            >
              <span className="mr-4">Refiere ya</span>
              <img
                className="hidden md:inline group-hover:-translate-x-3 duration-300 ease-in-out"
                src={arrow_button}
                alt=""
              />
            </a>
          </div>
          <div className="w-full md:w-1/2 flex justify-end">
            <img src={girl} alt="" />
          </div>
        </div>
      </div>

      <FooterComponent />
    </div>
  );
};

export default ReferralsPage;
