import { useEffect, useState } from "react";

import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";

//assets
import { getAdminPropertyEndpoint } from "./services/PropertiesService";
import { useDispatch, useSelector } from "react-redux";
import { AppStore } from "../../redux/store";
import { logoutAction } from "../../redux/features/auth";
import { Link, useNavigate, useParams } from "react-router-dom";
import { unsetAuth, getAuthToken, setLastPath } from "../../helpers/authHelper";
// import DetailSectionLabel from '../../components/General/DetailSectionLabel';
// import DetailSectionMenu from '../../components/General/DetailSectionMenu';
import PropertyDetail from "../../components/PropertyDetail";
import { loadAction } from "../../redux/features/adminProperty";

import Modal from "../../components/Modal";
import FormVisitLandlord from "./components/VisitForm";

import { useVisitProperty } from "./hooks/useVisitsProperty";
import { DEFAULT_AVATAR } from "../../constants/ProfileConstants";

const VisitProperty = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  const { loading, visitsData, getDataVisits } = useVisitProperty();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { id = "" } = useParams();
  const user = useSelector((store: AppStore) => store.auth);
  const adminProperty = useSelector((store: AppStore) => store.adminProperty);
  const token = getAuthToken(user);
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleModal = () => {
    try {
      setShowModal((old) => !old);
      document.body.style.overflowY = "scroll";
    } catch (error) {
      console.log("something went wrong with bidder info");
    }
  };

  useEffect(() => {
    const loadAdminProperty = async () => {
      const { status, data } = await callEndpoint(
        getAdminPropertyEndpoint(id, token)
      );
      if (status === 401) {
        dispatch(logoutAction({}));
        setLastPath(`/app/${country}/l/admin-property/visits/${id}`);
        unsetAuth();
        return navigate(`/app/${country}/auth/login`);
      }
      const response = fetchResponseAdapter(data);
      dispatch(loadAction(response.data));
    };
    loadAdminProperty().catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    if (adminProperty?.id !== "") {
      getDataVisits(adminProperty?.id, token);
    }
  }, [adminProperty]);

  return (
    <>
      <section className="pt-24 pb-12 px-4 lg:px-0">
        <div className="m-auto w-full">
          <div className="w-full mt-6 mb-2 text-blackGrayScale  text-3xl lg:text-4xl font-semibold text-left tracking-wide">
            Visitas
          </div>
          <p className=" text-xl font-medium text-gray600 mb-2">
            {adminProperty?.address}
          </p>
        </div>
        <div className="w-full text-left mb-4 text-gray600 text-base">
          Propiedad ID {adminProperty?.code}
        </div>

        {/* <div className="flex justify-center">
          <div className="lg:px-2 w-full lg:w-2/3">
            <PropertyDetail
              id={adminProperty?.code}
              address={adminProperty?.address}
              price={adminProperty?.monthly_price}
              image={adminProperty?.cover}
            />
          </div>
        </div> */}
      </section>

      <section className="flex justify-center derepb-12 px-4 lg:px-0 pb-10">
        <div className="container mx-auto">
          {/* <div className="w-full flex justify-end py-5">
            <button
              className=" bg-primary px-3 py-2 font-normal text-white text-base rounded-md hover:opacity-80"
              onClick={() => setShowModal(true)} 
            >
              Cambiar horarios de visita
            </button>
          </div> */}
          <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {!loading && (
              <>
                {visitsData.map((item: any) => (
                  <div className=" px-4 py-2 border border-gray-300 rounded-xl mb-4 flex gap-2 items-center justify-start">
                    <div className="w-1/5">
                      <img
                        className="w-12 h-12 rounded-full object-cover object-center mr-8"
                        src={item.tenantInfo?.avatar || DEFAULT_AVATAR}
                        alt=""
                      />
                    </div>
                    <div className="w-4/5">
                      <p className=" font-medium text-lg truncate text-blackGrayScale leading-tight">
                        {item.tenantInfo?.name}
                      </p>
                      <p className=" text-gray600 text-sm mt-1">
                        Fecha {item.visit_date} - {item.visit_time}
                      </p>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </section>
      {showModal && (
        <Modal
          onAction={toggleModal}
          name="Horarios de visita"
          size="max-w-3xl"
          body={
            <FormVisitLandlord
              onAction={() => {
                document.body.style.overflowY = "scroll";
                setShowModal(false);
              }}
              id={id}
            />
          }
        />
      )}
    </>
  );
};

export default VisitProperty;
