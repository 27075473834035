import axios from "axios";
import {
  NegociateOfferFormApi,
  rejectOfferForm,
} from "../models/landlordModel";

const loadAbort = () => {
  return new AbortController();
};

export const negociateOfferEndpoint = (
  propertyId: string,
  offerId: string,
  form: NegociateOfferFormApi,
  token: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "put",
        url: `${urlService}/ts/v1/landlord/properties/${propertyId}/offers/${offerId}/negociate`,
        data: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const rejectOfferEndpoint = (
  propertyId: string,
  offerId: string,
  form: rejectOfferForm,
  token: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "put",
        url: `${urlService}/ts/v1/landlord/properties/${propertyId}/offers/${offerId}/reject`,
        data: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getLandlordPropertyOffers = (
  propertyId: string,
  token: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/ts/v1/landlord/properties/${propertyId}/offers`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const acceptOfferEndpoint = (
  propertyId: string,
  offerId: string,
  token: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "put",
        url: `${urlService}/ts/v1/landlord/properties/${propertyId}/offers/${offerId}/accept`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getPostulantInfoEndpoint = (tenantId: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/user/v1/internal-tenant/postulant/${tenantId}`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    controller,
  };
};

// New contracts

export const acceptContractEndpoint = (contractId: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "put",
        url: `${urlService}/ts/v1/landlord-contract/${contractId}/accept`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const sendCommentLandlord = (
  contractId: string,
  token: string,
  dataComment: any
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "post",
        url: `${urlService}/ts/v1/landlord-contract/${contractId}/comment`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify(dataComment),
      }),
    controller,
  };
};
