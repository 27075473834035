import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useCallApiAndLoad from "../../hooks/useCallApiAndLoad"
import { logoutAction } from "../../redux/features/auth";
import { AppStore } from "../../redux/store"
import DetailSectionLabel from "../../components/General/DetailSectionLabel";
import WhiteModal from '../../components/WhiteModel';
import UploadAvatar from '../../components/UploadAvatar';
import { getTenantProfileEndpoint } from "./services/tenantService";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";
import { getAuthToken, unsetAuth } from "../../helpers/authHelper"
import { reverseDate } from '../../helpers/formHelpers';
import { mapGender, mapPetSize } from './helpers/listHelpers';

import TenantProfileLoading from './components/Loading/TenantProfileLoading';
import { DEFAULT_AVATAR } from '../../constants/ProfileConstants';

const TenantProfile = () => {
    const { country } = useSelector((store: AppStore) => store.country);
    const user = useSelector((store: AppStore) => store.auth)
    const token = getAuthToken(user)
    const { isLoading, callEndpoint } = useCallApiAndLoad()
    const [apiData, setApiData] = useState<any>()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showAvatarModal, setShowAvatarModal] = useState(false)

    useEffect(() => {
        const loadTenantProfile = async () => {
            const { status, data } = await callEndpoint(getTenantProfileEndpoint(token));
            if (status === 401) {
                dispatch(logoutAction({}))
                unsetAuth()
                return navigate(`/app/${country}/auth/login`)
            }
            const response = fetchResponseAdapter(data)

            setApiData({
                birth_date_default:
                    response.data.birth_date
                        ? reverseDate(response.data.birth_date)
                        : '',
                ...response.data
            })
        }
        loadTenantProfile()
            .catch(e => console.error(e))
    }, [])

    const toggleAvatarModal = () => {
        try {
            setShowAvatarModal((old) => !old);
            document.body.style.overflowY = "scroll";
        } catch (error) {
            console.log("something went wrong with bidder info");
        }
    };

    const handleEdit = () => {
        navigate(`/app/${country}/l/tn/update-profile`, {
            state: apiData
        })
    }

    if (isLoading) {
        return <TenantProfileLoading />
    }

    return (
        <>
            {showAvatarModal && (
                <WhiteModal
                    onAction={toggleAvatarModal}
                    name="Foto de perfil"
                    body={
                        <UploadAvatar
                            realm='tenant'
                            onAction={toggleAvatarModal}
                        />
                    }
                />)
            }
            <section className='pt-24' >
                <div className="flex w-full items-center justify-center">
                    <div onClick={toggleAvatarModal}
                        className="w-32 h-32 rounded-full border-green501 border-8  flex justify-center">
                        <div className="rounded-full">
                            <img
                                src={apiData && apiData.avatar ? apiData.avatar : DEFAULT_AVATAR}
                                key={apiData && apiData.avatar ? apiData.avatar : DEFAULT_AVATAR}
                                className="rounded-full w-28 h-28 border-green500 border-6"
                                alt="avatar"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // this prevents a checking looping
                                    currentTarget.src = DEFAULT_AVATAR
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="w-full text-center my-6 text-third text-lg">
                        Arrendatario
                    </div>
                    <div className="m-auto w-full">
                        <div className="w-full my-6 text-third text-4xl font-bold text-center">
                            {apiData?.name + ' ' + apiData?.last_name}
                        </div>
                    </div>
                </div>
            </section>
            <hr />
            <section>
                <div className="container">
                    <div className="m-auto w-full">
                        <div className="w-full my-10">
                            <div className="w-full flex justify-end lg:px-0 px-4">
                                <button className="text-green500" onClick={handleEdit}>
                                    Editar datos
                                </button>
                            </div>
                            <DetailSectionLabel
                                label="Nombres" value={apiData?.name} />
                            <DetailSectionLabel
                                label="Apellidos" value={apiData?.last_name} />
                            <DetailSectionLabel
                                label="Correo" value={apiData?.email} />
                            <DetailSectionLabel
                                label="Resumen de mi" value={apiData?.summary} />
                            <DetailSectionLabel
                                label="Estado o departamento" value={apiData?.state} />
                            <DetailSectionLabel
                                label="Teléfono" value={apiData?.phone} />
                            <DetailSectionLabel
                                label="Fecha de nacimiento" value={apiData?.birth_date} />
                            <DetailSectionLabel
                                label="Profesión" value={apiData?.profession} />
                            <DetailSectionLabel
                                label="Tamaño de mascota" value={mapPetSize(apiData?.pet_size)} />
                            <DetailSectionLabel
                                label="Sexo" value={mapGender(apiData?.gender)} />
                            <DetailSectionLabel
                                label="Cantidad de personas" value={apiData?.roommates_qty} />
                            <DetailSectionLabel
                                label="Pasatiempos" value={apiData?.hobbies} />
                            <DetailSectionLabel
                                label="Red social" value={apiData?.social_media} />
                            <DetailSectionLabel
                                label="Compañía donde labora" value={apiData?.company_name} />
                            <DetailSectionLabel
                                label="Salario neto" value={apiData?.net_income} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TenantProfile
