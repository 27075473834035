import { useEffect, useState } from "react";
import "../../../scss/BidderCard.scss";

//icon
import closeModal from "../../../assets/icons/close-modal.svg";
import useCallApiAndLoad from "../../../hooks/useCallApiAndLoad";
import { getPostulantInfoEndpoint } from "../services/LandlordOfferService";
import { fetchResponseAdapter } from "../../../adapters/fetchAdapter";
import { LandlordPostulantInfo } from "../models/landlordModel";
import { mapGender, mapPetSize } from "../../Tenant/helpers/listHelpers";
import { DEFAULT_AVATAR } from "../../../constants/ProfileConstants";

import level1 from "../../../assets/icons/level-1.png";
import level2 from "../../../assets/icons/level-2.png";
import level3 from "../../../assets/icons/level-3.png";
import level4 from "../../../assets/icons/level-4.png";
import level5 from "../../../assets/icons/level-5.png";

type ICardProps = {
  tenantId: string;
  onAction: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

const   BidderCard: React.FC<ICardProps> = (props) => {

  const { isLoading, callEndpoint } = useCallApiAndLoad()
  const [postulant, setPostulant] = useState<LandlordPostulantInfo | undefined>()

  useEffect(() => {
    const loadPostulant = async () => {
      const {
        status,
        data
      } = await callEndpoint(getPostulantInfoEndpoint(props.tenantId))
      const response = fetchResponseAdapter(data)
      setPostulant(response.data)
    }
    loadPostulant()
      .catch(e => console.error(e))
    document.body.style.overflowY = "hidden";
  }, []);

  const setTenantScore = (score: number | null) => {

  }

  return (
    <div className="overflow-hidden modal">
      <div className="absolute inset-0 z-50 h-full w-screen overflow-hidden bg-blend-multiply lg:bg-transparent ">
        <div className="w-full h-full relative flex justify-center items-center">
          <div className="bidder-container bg-userInfo lg:absolute lg:left-[37%]">

            <div className="w-full rounded-t-lg bg-userInfoProfile p-4">
              <div className="w-full flex justify-end items-center">
                <button
                  className="text-white text-2xl"
                  onClick={props.onAction}
                >
                  <i>
                    <img src={closeModal} alt="closemodal" />
                  </i>
                </button>
              </div>
              <div className="flex w-full items-center justify-center">
                <div
                  className=" h-36 w-36 rounded-full border-green501 border-8  flex justify-center
                "
                >
                  <div className="rounded-full">
                    <img
                      src={
                        postulant && postulant.avatar ? postulant.avatar : DEFAULT_AVATAR
                      }
                      className="rounded-full w-32 h-32 border-green500 border-6"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="w-full text-center text-tea-500 my-1">
                Postulante
              </div>
              <div className="w-full text-center text-xl font-medium text-tea-500 ">
                {postulant?.first_name}
              </div>

            </div>

            <div className="w-full bg-userInfoProfile py-4 px-8">
              <div className="w-full flex border-userInfoProfile bg-userInfo rounded-lg py-4 px-4">
                <div className="w-full pt-1 text-grayDark text-sm">
                  Nivel de riesgo
                </div>
                <div className="w-full text-white text-right">
                  <>
                    {
                       postulant?.score ? (() => {
                        switch (true) {
                          case postulant.score <= 100:
                            return <span>
                              Muy Alto <img style={{ display: 'inherit' }} src={level1} />
                            </span>
                          case postulant.score <= 300:
                            return <span>
                              Alto <img style={{ display: 'inherit' }} src={level2} />
                            </span>
                          case postulant.score <= 600:
                            return <span>
                              Medio <img style={{ display: 'inherit' }} src={level3} />
                            </span>
                          case postulant.score <= 800:
                            return <span>
                              Bueno <img style={{ display: 'inherit' }} src={level4} />
                            </span>
                          case postulant.score < 1000:
                            return <span>
                              Excelente <img style={{ display: 'inherit' }} src={level5} />
                            </span>
                          default: return <span>No disponible </span>
                        }
                      })() : <span>No disponible.</span>
                    }
                  </>
                </div>
              </div>
            </div>

            <div className="p-4 w-full h-64 relative overflow-y-auto">
              {/* <div className="absolute top-[10%] left-[-15px]">
                <img src={leftTail} className="fixed" alt="" />
              </div> */}
              <div className="w-full border-b flex border-userInfoProfile py-4">
                <div className="w-1/2 text-grayDark text-sm">Nombre</div>
                <div className="w-1/2 text-white">{postulant?.first_name}</div>
              </div>
              <div className="w-full border-b flex border-userInfoProfile py-4">
                <div className="w-1/2 text-grayDark text-sm">Resumen</div>
                <div className="w-1/2 text-white">{postulant?.summary}</div>
              </div>
              <div className="w-full border-b flex border-userInfoProfile py-4">
                <div className="w-1/2 text-grayDark text-sm">Compañía</div>
                <div className="w-1/2 text-white">{postulant?.company_name}</div>
              </div>
              <div className="w-full border-b flex border-userInfoProfile py-4">
                <div className="w-1/2 text-grayDark text-sm">
                  Estado o departamento
                </div>
                <div className="w-1/2 text-white">{postulant?.state}</div>
              </div>
              <div className="w-full border-b flex border-userInfoProfile py-4">
                <div className="w-1/2 text-grayDark text-sm">Edad</div>
                <div className="w-1/2 text-white">{postulant?.age}</div>
              </div>
              <div className="w-full border-b flex border-userInfoProfile py-4">
                <div className="w-1/2 text-grayDark text-sm">Salario</div>
                <div className="w-1/2 text-white">{postulant?.net_income}</div>
              </div>
              <div className="w-full border-b flex border-userInfoProfile py-4">
                <div className="w-1/2 text-grayDark text-sm">Profesión</div>
                <div className="w-1/2 text-white">{postulant?.profession}</div>
              </div>
              <div className="w-full border-b flex border-userInfoProfile py-4">
                <div className="w-1/2 text-grayDark text-sm">
                  Tamaño de mascota
                </div>
                <div className="w-1/2 text-white">{mapPetSize(postulant?.pet_size || '') || 'No posee'}</div>
              </div>
              <div className="w-full border-b flex border-userInfoProfile py-4">
                <div className="w-1/2 text-grayDark text-sm">Sexo</div>
                <div className="w-1/2 text-white">{mapGender(postulant?.gender || '')}</div>
              </div>
              <div className="w-full border-b flex border-userInfoProfile py-4">
                <div className="w-1/2 text-grayDark text-sm">
                  Pasatiempos
                </div>
                <div className="w-1/2 text-white">{postulant?.hobbies}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BidderCard;
