import React, { useEffect } from 'react'
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

import { LandlordOffer, LandlordOfferProperty, negociateOfferForm, NegociateOfferFormApi } from '../../models/landlordModel';
import FormControlError from '../../../../components/Controls/FormControlError';
import SelectInput from '../../../../components/Controls/SelectInput';
import Button from '../../../../components/Button';
import arrow_button from '../../../../assets/icons/arrow_button.svg';
import FormLabel from '../../../../components/Controls/FormLabel';
import TextAreaInput from '../../../../components/Controls/TextAreaInput';
import useCallApiAndLoad from '../../../../hooks/useCallApiAndLoad';
import { AppStore } from '../../../../redux/store';
import { getAuthToken } from '../../../../helpers/authHelper';
import DateInput from '../../../../components/Controls/DateInput';
import CurrencyInput from '../../../../components/Controls/CurrencyInput';
import { negociateOfferEndpoint } from '../../services/LandlordOfferService';
import { getOfferPeriods } from '../../../../helpers/OfferHelper';
import { parseNumberCurrencyValue } from '../../../../helpers/currencyHelper';
import { useNavigate } from 'react-router-dom';

interface Props {
  onAction: () => void;
  property_info: LandlordOfferProperty;
  offer_info: LandlordOffer;
}

const FormNegociate = (props: Props) => {
  const {
    offer_info, property_info, onAction
  } = props
  const { isLoading, callEndpoint } = useCallApiAndLoad()
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<negociateOfferForm>({
    resolver: yupResolver(
      yup.object().shape({
        price_to_negociate: yup
          .string()
          .required("Precio de oferta es requerido"),
        estimate_available_at: yup.string().required("Fecha es requerida"),
        estimate_period: yup.string().required("Periodo es requerido"),
      })
    ),
  });

  const doNegociateOffer = async (form: negociateOfferForm) => {
    const formApi: NegociateOfferFormApi = {
      price_to_negociate: parseNumberCurrencyValue(form.price_to_negociate, ','),
      estimate_period: parseInt(form.estimate_period),
      estimate_available_at: form.estimate_available_at,
      comment_negociate: form.comment_negociate || ''
    }
    const {
      status
    } = await callEndpoint(negociateOfferEndpoint(property_info?.property_id,
      offer_info?.id, formApi, token))
    if (status === 200) {
      onAction()
      return navigate(0)
    }
  }

  useEffect(() => {
    if(offer_info?.marketplace_type === 'on_sale') {
      setValue('estimate_period', '6')
    }
  }, [])
  return (
    <div className=" bg-white pt-2 px-5">
      <form onSubmit={handleSubmit(doNegociateOffer)}>
      <h1 className="w-full mb-4 text-blackGrayScale text-2xl lg:text-2xl font-semibold text-left tracking-wide mt-4">
               Negociar oferta
      </h1>
        <div className='my-4 mt-7'>
          <h1 className='text-2xl font-semibold text-[#212121]'>{offer_info?.offer}</h1>
          <p className="w-full text-[#757575] text-sm">
            Oferta realizada por postulante
          </p>
        </div>
        <div className='my-4'>
          <FormLabel label="Nuevo monto de oferta" />
          <CurrencyInput
            name="price_to_negociate"
            register={register}
            currency={"$"}
            thousand_separator={","}
            decimal_separator={"."}
          />
          <FormControlError message={errors.price_to_negociate?.message} />
        </div>
        <div className='my-4'>
          <FormLabel label='¿Desde cuando tendrás disponible la propiedad?' />
          <DateInput
            disablePastDates
            register={register}
            name={'estimate_available_at'} />
          <FormControlError message={errors.estimate_available_at?.message} />
        </div>
        {
          offer_info?.marketplace_type !== 'on_sale' && <div className='my-4'>
          <FormLabel label='Selecciona un periodo de contrato' />
          <SelectInput
            register={register}
            name={'estimate_period'}
            empty_value="Selecciona un periodo"
            list={getOfferPeriods()} />
          <FormControlError message={errors.estimate_period?.message} />
        </div>
        }
        
        <div className='my-4'>
          <FormLabel label='Comentarios (opcional)' />
          <TextAreaInput
            register={register}
            name={'comment_negociate'} />
        </div>
        
        <div className="bg-white w-full">
          <div className="w-full grid grid-cols-2 gap-6 mb-6 mt-6">
            <button type='button'>
              <div
                onClick={onAction}
                className="border border-[#212121] rounded-lg px-4 py-2 text-[#212121] hover:bg-[#212121] hover:text-white duration-100"
              >
                Cancelar
              </div>
            </button>
            <Button
              type="submit"
              className="px-4 py-2 rounded-lg bg-[#212121] hover:bg-primary duration-100 text-white"
            >
              Negociar
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default FormNegociate
