import { useEffect, useState, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";

import FilterBar from "./components/FilterBar/Filterbar";
import NavBar from "../../components/NavBar/NavBar";
import CardProperty from "../../components/CardProperty/Card";
import "./scss/styles.scss";
import {
  FiltersStore,
  IProperty,
  PopularCities,
} from "../../models/PropertyModel";
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import {
  getBatchesCluster,
  getPopularCities,
  getPropertiesClusterMarketplace,
  getPropertiesMarketplace,
} from "../Properties/services/PropertiesService";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";
import MapSection from "./components/Map";
import ModalFilter from "./components/ModalFilter";
import { AppStore } from "../../redux/store";

import { cleanFormParams, replaceFormParams } from "./helpers/paramsHelpers";

import styled from "styled-components";
import MainModal from "../../components/MainModal/MainModal";
import SearchMobile from "./components/SearchMobile/SearchMobile";
import { setFiltersAction } from "../../redux/features/filters";
import { useToast } from "../../components/ToastAlerts";
import NotFoundProperties from "./components/NotFoundProperties/NotFoundProperties";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { error } from "console";
import { IconListSearch, IconMap2, IconChevronDown, IconChevronRight } from "@tabler/icons-react";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import axios from "axios";
import useUserCountry from "../../hooks/useUserCountry";

export const CounterContainer = styled.div`
  align-items: center;
  background-color: #5e6a75bf;
  border-radius: 0.375rem;
  bottom: 50px;
  box-shadow: 0 0 6px -1px rgba(90, 90, 90, 0.2),
    0 0 6px -1px rgba(3, 97, 228, 0.2);
  color: var(--color-white);
  cursor: pointer;
  display: flex;
  font-family: var(--font-circular-bold);
  font-size: 1rem;
  height: 40px;
  justify-content: center;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 10px);
  width: 293px;
`;

const itemsPerPage = 15;


const Marketplace = () => {
  const { getServiceUrlCountry } = useUserCountry();
  
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loadingConfig, setLoadingConfig] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadBat, setLoadBat] = useState(false);
  const [dataPropertiesList, setDataPropertiesList] = useState([]);

  const { callEndpoint } = useCallApiAndLoad(1200);
  const toast: any = useToast();
  // CLUSTER DATA BUTCHESS
  const [dataProperties, setDataProperties] = useState<Array<IProperty>>([]);

  const [meta, setMeta] = useState({
    hasNextPage: false,
    page: 1,
  });
  const filters = useSelector((store: AppStore) => store.filters);
  const {country} = useSelector((store: AppStore) => store.country);

  const listRef = useRef<any>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [popular, setPopular] = useState<PopularCities[]>([]);

  const [marginProper, setMarginProper] = useState(false);

  //CIUDADES POPULARES
  const loadPopularCities = async () => {
    const { status, data } = await callEndpoint(getPopularCities());
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      setPopular(response.data);
    } else {
      toast.open("No fue posible cargar proyectos recientes", "error");
    }
  };

  useEffect(() => {
    loadPopularCities().catch((e) => console.error(e));
  }, []);
  

  // TESTING LOAD BATCHES
  const loadBatchesProcess = async (filters: any) => {
    const urlService = getServiceUrlCountry();
    setLoadBat(true);
    setLoadingConfig(true);
  
    let isProcessCompleted = false;
    const batchesToProcess = [];
    const cleanedFilters = cleanFormParams(filters);
  
    // Crear un CancelToken para la solicitud
    const source = axios.CancelToken.source();
  
    try {
      // Llamada para configuración inicial de lotes
      const { data } = await axios.get(`${urlService}/rs/v1/marketplace/properties/cluster/retrieval-config`, {
        cancelToken: source.token,
      });
      const response = fetchResponseAdapter(data);
      
      const marketplaceConfig = {
        batches: response.data.batches || 0,
        items_per_page: response.data.items_per_page || 0,
      };
  
      setLoadingConfig(false);
      setDataProperties([]);
  
      for (let i = 0; i < marketplaceConfig.batches; i++) {
        batchesToProcess.push({
          page: i + 1,
          take: marketplaceConfig.items_per_page,
        });
      }
  
      // Procesar cada batch
      for (const batch of batchesToProcess) {
        const strSearchParams = createSearchParams({
          ...cleanedFilters,
          page: batch.page.toString(),
          take: batch.take.toString(),
        });
  
        const { data } = await axios.get(`${urlService}/rs/v1/marketplace/properties/cluster?${strSearchParams}`, {
          cancelToken: source.token,
        });
        
        const propertiesResponse = fetchResponseAdapter(data);
        const newItems = propertiesResponse.data;
  
        // Si no hay datos, cancelar el proceso
        if (!newItems || newItems.length === 0) {
          console.log("No hay datos en esta iteración, cancelando proceso.");
          source.cancel();
          break;
        }
  
        // Agregar los nuevos elementos si hay datos
        setDataProperties((state) => [...state, ...newItems]);
      }
  
      isProcessCompleted = true;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Llamada abortada");
      } else {
        console.error("Error en la llamada:", error);
        toast.open("No fue posible cargar esta información", "error");
      }
    } finally {
      if (isProcessCompleted) {
        setLoadBat(false);
        setLoadingConfig(false);
      }
    } 
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
  
    // Cancelar cualquier solicitud en curso antes de iniciar una nueva
    source.cancel("Solicitud cancelada debido a un cambio en los filtros");
  
    // Clonamos `filters` y excluimos `sort` antes de pasar a `loadBatchesProcess`
    const { sort, ...filtersWithoutSort } = filters;
  
    if (
      filters.type !== null ||
      filters.is_new !== null ||
      filters.is_furnished !== null ||
      filters.min_price !== null ||
      filters.max_price !== null ||
      filters.min_area !== null ||
      filters.max_area !== null ||
      filters.rooms !== null ||
      filters.bathrooms !== null ||
      filters.lat !== null ||
      filters.lng !== null ||
      filters.zoom_search !== null ||
      filters.location_id !== null ||
      filters.location_type !== null ||
      filters.marketplace_type !== null
    ) {
      loadBatchesProcess(filtersWithoutSort); // Llamada a la función sin el filtro `sort`
    }
  
    // Función de limpieza para cancelar la solicitud en curso
    return () => {
      source.cancel("Solicitud cancelada debido a un cambio en los filtros");
    };
  }, [filters]);
  


  // USEEFFECT
  useEffect(() => {
    const queryParamsString = window.location.search.substr(1);
    const queryParams = queryParamsString
      .split("&")
      .reduce((accumulator: any, singleQueryParam) => {
        const [key, value] = singleQueryParam.split("=");
        accumulator[key] = decodeURIComponent(value);
        return accumulator;
      }, {});

    if (queryParams) {
      dispatch(setFiltersAction(queryParams));
      //loadBatchesProcess(queryParams);
    }
  }, [dispatch]);

  useEffect(() => {
    const queryParamsString = window.location.search.substr(1);
    const queryParams = queryParamsString
      .split("&")
      .reduce((accumulator: any, singleQueryParam) => {
        const [key, value] = singleQueryParam.split("=");
        accumulator[key] = decodeURIComponent(value);
        return accumulator;
      }, {});

    const hasActiveFilters = Object.keys(queryParams).some((key) => {
      return (
        queryParams[key] !== undefined &&
        queryParams[key] !== "" &&
        ![
          "marketplace_type",
          "lat",
          "lng",
          "zoom_search",
          "sort",
          "location_id",
          "location_type",
          "utm_id",
          "utm_source",
          "utm_medium",
          "utm_campaign",
          "utm_term",
          "utm_content",
        ].includes(key)
      );
    });

    setMarginProper(hasActiveFilters);
  }, [window.location.search]); // Cambiado a window.location.search directamente

  //Switch mobile para mapa y listado
  const [toggleState, setToggleState] = useState<boolean>(
    localStorage.getItem("option-map") === "map" ? true : false
  );

  const toggleMapList = () => {
    setToggleState(!toggleState);
    if (localStorage.getItem("option-map") === "map") {
      localStorage.removeItem("option-map");
    }
  };

  const suggestions = [
    "San Salvador",
    "San Benito",
    "Apopa",
    "San Juan Opico",
    "Nuevo Cuscatlan",
    "Zaragoza",
    "Mejicanos",
    "Ayutuxtepeque",
  ];

  // Listado
  const handleLoadProperties = async (
    page: number,
    take: number,
    filters: any
  ) => {
    setLoading(true);

    const cleanedFilters = cleanFormParams(filters);
    const strSearchParams = createSearchParams({
      ...cleanedFilters,
      page: page.toString(),
      take: take.toString(),
    });

    try {
      // Verificar si hay filtros activos antes de realizar la llamada a la API

      if (Object.keys(cleanedFilters).length > 0) {
        const { status, data } = await callEndpoint(
          getPropertiesMarketplace(strSearchParams)
        );

        if (status === 200) {
          const propertiesResponse = fetchResponseAdapter(data);
          const { meta: apiMeta = null } = propertiesResponse.data;

          if (apiMeta) {
            setPageCount(apiMeta.pageCount);
            setHasNextPage(apiMeta.hasNextPage);
          }

          setDataPropertiesList(propertiesResponse.data?.items);
          setLoading(false);
        }
      } else {
        const { status, data } = await callEndpoint(
          getPropertiesMarketplace(strSearchParams)
        );

        if (status === 200) {
          const propertiesResponse = fetchResponseAdapter(data);
          const { meta: apiMeta = null } = propertiesResponse.data;

          if (apiMeta) {
            setPageCount(apiMeta.pageCount);
            setHasNextPage(apiMeta.hasNextPage);
          }

          setDataPropertiesList(propertiesResponse.data?.items);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      handleLoadProperties(currentPage, itemsPerPage, filters);
    } else {
      isMounted.current = true;
    }
  }, [currentPage, itemsPerPage, filters]);

  //USEEFECCT


  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>("");

  const options = [
    {
      name: "Más nuevo",
      value: "+recent",
    },
    {
      name: "Menor precio",
      value: "-price",
    },
    {
      name: "Mayor precio",
      value: "+price",
    },
    {
      name: "Más vistos",
      value: "+views",
    },
  ];

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option: string, name: string) => {
    setSelectedOption(name);
    handleSortFilter(encodeURIComponent(option));
    setIsOpen(false);
  };

  const handleSortFilter = (option: any) => {
    if (option) {
      dispatch(setFiltersAction({ sort: decodeURIComponent(option) }));

      const data = {
        sort: option,
      };

      const replaceParamsObject: any = {
        ...filters,
        ...data,
      };

      if (JSON.stringify(data) !== "{}") {
        const filterDataMapped: FiltersStore =
          replaceFormParams(replaceParamsObject);
        dispatch(setFiltersAction(filterDataMapped));
        const cleanedFilters = cleanFormParams(filterDataMapped);

        if (Object.keys(cleanedFilters).length > 0) {
          navigate(`?${createSearchParams(cleanedFilters)}`);
        }
      }
    }
  };

  const goToMarket = () => {
    if (typeof window !== 'undefined') {
      window.open(`/${country}/venta/casas-y-apartamentos/proyectos?utm_source=web&utm_medium=marketplace`, "_self")
    }
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (listRef.current) {
      listRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
    setCurrentPage(value);
  };


  const handleChangeMobile = (event: React.ChangeEvent<unknown>, value: number) => {
    // Desplazamiento suave manual usando requestAnimationFrame
    const smoothScrollToTop = () => {
      const scrollHeight = document.documentElement.scrollTop || document.body.scrollTop;
      const scrollStep = scrollHeight / 15;
      const scrollInterval = setInterval(() => {
        const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
          window.scrollBy(0, -scrollStep);
        } else {
          clearInterval(scrollInterval);
        }
      }, 15);
    };
  
    smoothScrollToTop();
    setCurrentPage(value);
  };

  
  const renderSkeletons = () => {
    const skeletons = [];

    for (let i = 0; i < 15; i++) {
      skeletons.push(
        <div key={i} className="h-auto">
          <Skeleton count={1} height={280} borderRadius={10} />
          <div className="w-full flex justify-between mt-2">
            <Skeleton count={1} height={20} width={90} />
            <Skeleton count={1} height={20} width={60} />
          </div>
          <div className="mt-4 w-full">
            <Skeleton count={1} height={20} className="w-full" />
          </div>
          <div className="mt-2 w-full">
            <Skeleton count={1} height={20} className="w-full" />
          </div>
          <div className="flex justify-between mt-4">
            <div>
              <Skeleton count={1} height={20} width={20} />
            </div>
            <div className="flex gap-2">
              <Skeleton count={1} height={20} width={20} />
              <Skeleton count={1} height={20} width={20} />
              <Skeleton count={1} height={20} width={20} />
            </div>
          </div>
        </div>
      );
    }

    return skeletons;
  };

  return (
    <>
      <NavBar>
        <FilterBar showFilter={false} />
      </NavBar>
      <ModalFilter />
      <MainModal type="complete">
        <SearchMobile placeholder="" suggestions={suggestions} />
      </MainModal>

      <div className="">
        <div className="w-full h-full bg-white">
          <div
            className={`section_property w-full hidden lg:flex relative ${
              marginProper
                ? "pt-40 md:pt-48 xl:pt-60"
                : "pt-32 md:pt-40 xl:pt-44"
            }`}
          >
            {loading ? (
              <>
                <div
                  ref={listRef}
                  className="content_property w-full flex flex-wrap"
                  id="contentProperty"
                >
                  <div className="container-results w-full">
                    {renderSkeletons()}
                  </div>
                </div>
                <div className="content_map w-full order-1 lg:order-2 block">
                  <Skeleton className="w-full h-full" count={1} />
                </div>
              </>
            ) : (
              <>
                {dataPropertiesList.length > 0 ? (
                  <>
                    <div
                      ref={listRef}
                      className="content_property w-full flex flex-wrap"
                      id="contentProperty"
                    >
                      <div className="w-full px-4">
                        <h2 className="text-[#212121] text-2xl font-semibold">
                          Resultados de búsqueda
                        </h2>
                        <div className="w-full flex justify-start items-center mb-4">
                          <p className="text-sm xl:text-base text-[#212121]">
                            Ordenar:
                          </p>
                          <div className="select-order-market">
                            <div className="dropdown flex justify-start items-center ml-2">
                              <div
                                className="dropdown-selected flex justify-start items-center"
                                onClick={handleDropdownClick}
                              >
                                <p className="block text-[#212121] text-sm xl:text-base mr-2">
                                  {filters?.sort === "%2Brecent"
                                    ? "Más nuevo"
                                    : filters?.sort === "-price"
                                    ? "Menor precio"
                                    : filters?.sort === "%2Bprice"
                                    ? "Mayor precio"
                                    : filters?.sort === "%252Bviews"
                                    ? "Más vistos"
                                    : "Más vistos"}
                                </p>
                                <IconChevronDown color="#212121" width={20} />
                              </div>
                              {isOpen && (
                                <ul className="dropdown-options">
                                  {options.map((option, index) => (
                                    <li
                                      key={index}
                                      className={`dropdown-option ${
                                        selectedOption === option.name
                                          ? "selected"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleOptionSelect(
                                          option?.value,
                                          option.name
                                        )
                                      }
                                    >
                                      {option.name}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-results">
                        {dataPropertiesList.map(
                          (item: IProperty, index: number) => (
                            <Fragment key={`mk-${index}`}>
                              <div className="card_container" key={`mk-${index}`}>
                              <CardProperty
                                property_status={item.property_status}
                                property_id={item.property_id}
                                property_code={item.property_code}
                                property_name={item.property_name}
                                property_city={item.property_city}
                                property_type={item.property_type}
                                property_price={item.property_price}
                                gallery={item.gallery}
                                characteristics={item.characteristics}
                                state_name={item.state_name}
                                url_detail={item.url_detail}
                                is_showing_promotional_price_banner={
                                  item?.is_showing_promotional_price_banner
                                }
                                property_old_price={item?.property_old_price}
                                property_type_value={item?.property_type_value}
                                available_at_date={item?.available_at_date}
                                exchange_property_price={
                                  item?.exchange_property_price
                                }
                                property_price_currency_symbol={
                                  item?.property_price_currency_symbol
                                }
                                exchange_property_old_price={
                                  item?.exchange_property_old_price
                                }
                                marketplace_type={filters?.marketplace_type}
                                property_sale_status={
                                  item?.property_sale_status
                                }
                              />
                            </div>

                            {filters?.marketplace_type === 'for_sale' && index === 5 && (
                              <div className="custom_div px-6 py-2 cursor-pointer" onClick={goToMarket}>
                                <div className="w-full flex flex-wrap justify-between items-center">
                                  <div className=" w-3/4">
                                    <h1 className="text-lg font-semibold text-[#59C58E] -mb-1">
                                      ¿Estás buscando propiedades nuevas en venta?
                                    </h1>
                                    <p className="text-sm font-normal text-white">Descubre nuestro inventario de casas y apartamentos en planos</p>
                                    
                                  </div>
                                  <div className=" w-1/4 flex items-center justify-end">
                                    <img className="w-24" src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/v2/icono-presales.svg" alt="logo" />
                                  </div>
                                </div>
                                {/* Aquí puedes agregar el contenido que desees */}
                              </div>
                            )}
                            </Fragment>
                            
                            
                          )
                        )}
                      </div>
                      <div className="w-full flex justify-center">
                        <Stack
                          spacing={2}
                          direction="row"
                          justifyContent="center"
                          mt={4}
                          mb={4}
                        >
                          <Pagination
                            count={pageCount}
                            page={currentPage}
                            onChange={handleChange}
                            size="large"
                          />
                        </Stack>
                      </div>
                    </div>
                    <div className="content_map w-full order-1 lg:order-2 block">
                      <MapSection
                        pinArray={
                          dataProperties?.length > 0 ? dataProperties : []
                        }
                        loading={loadBat}
                        zoom={
                          filters?.zoom_search
                            ? Number(filters.zoom_search)
                            : 13
                        }
                        total={dataProperties && dataProperties.length}
                        marketplace_type={filters?.marketplace_type}
                      />
                    </div>
                  </>
                ) : (
                  <div className="w-full hidden sm:block px-4">
                    <NotFoundProperties
                      title="Explora otras zonas y encuentra tu propiedad ideal"
                      subtitle="Lo sentimos, no hay propiedades en esta zona "
                      citiesPopular={popular}
                    />
                  </div>
                )}
              </>
            )}
            
            
          </div>

          <div className={`w-full mobile-section relative lg:hidden block ${
              marginProper
                ? "pt-40 md:pt-48 xl:pt-60"
                : "pt-32 md:pt-40 xl:pt-44"
            }`}>
          <>
              {toggleState ? (
                <>
                  {loading ? (
                    <div className="h-[80vh] justify-center items-center w-full bg-white flex">
                      <img
                        className="w-16"
                        src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/loading-propi.gif"
                        alt="loading"
                      />
                    </div>
                  ) : (
                    <div className="content_map block relative">
                      
                        {dataProperties.length > 0 ? (
                          <div className="map flex justify-center items-center h-full">
                          <MapSection
                            pinArray={dataProperties ? dataProperties : []}
                            zoom={13}
                            loading={loadBat}
                            total={dataProperties && dataProperties.length}
                            marketplace_type={filters?.marketplace_type}
                          />
                          </div>
                        ) : (
                          <div className="w-full block px-4">
                            <NotFoundProperties
                              title="Explora otras zonas y encuentra tu propiedad ideal"
                              subtitle="Lo sentimos, no hay propiedades en esta zona "
                              citiesPopular={popular}
                            />
                          </div>
                        )}
                      
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div ref={listRef} className="container-box w-full block" id="contentProperty">
                    {loading ? (
                      <div className="content">
                        <div className="overflow-auto h-auto overflow-x-hidden grid grid-cols-1 md:grid-cols-2 gap-4">
                          {renderSkeletons()}
                        </div>
                      </div>
                    ) : (
                      <div className="content">
                        {dataPropertiesList.length > 0 && (
                          <div className="w-full px-4">
                            <h2 className="text-[#212121] text-2xl font-semibold">
                              Resultados de búsqueda
                            </h2>
                            <div className="w-full flex justify-start items-center mb-4">
                              <p className="text-sm xl:text-base text-[#212121]">
                                Ordenar:
                              </p>
                              <div className="select-order-market">
                                <div className="dropdown flex justify-start items-center ml-2">
                                  <div
                                    className="dropdown-selected flex justify-start items-center"
                                    onClick={handleDropdownClick}
                                  >
                                    <p className="block text-[#212121] text-sm xl:text-base mr-2">
                                      {filters?.sort === "%2Brecent"
                                        ? "Más nuevo"
                                        : filters?.sort === "-price"
                                        ? "Menor precio"
                                        : filters?.sort === "%2Bprice"
                                        ? "Mayor precio"
                                        : filters?.sort === "%252Bviews"
                                        ? "Más vistos"
                                        : "Más vistos"}
                                    </p>
                                    <IconChevronDown
                                      color="#212121"
                                      width={20}
                                    />
                                  </div>
                                  {isOpen && (
                                    <ul className="dropdown-options">
                                      {options.map((option, index) => (
                                        <li
                                          key={index}
                                          className={`dropdown-option ${
                                            selectedOption === option.name
                                              ? "selected"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            handleOptionSelect(
                                              option.value,
                                              option.name
                                            )
                                          }
                                        >
                                          {option.name}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                        }

                        <div>
                          <div className="overflow-auto h-auto overflow-x-hidden grid grid-cols-1 md:grid-cols-2 gap-4">
                            {dataPropertiesList.length > 0 ? (
                              <>
                                {dataPropertiesList.map(
                                  (item: IProperty, index: number) => (
                                    <Fragment key={`mk-${index}`}>
                                    <div
                                      key={`mk-${item.property_id}`}
                                      className="card_container px-3 pb-6"
                                    >
                                      <CardProperty
                                        property_status={item.property_status}
                                        property_id={item.property_id}
                                        property_code={item.property_code}
                                        property_name={item.property_name}
                                        property_city={item.property_city}
                                        property_type={item.property_type}
                                        property_price={item.property_price}
                                        gallery={item.gallery}
                                        characteristics={item.characteristics}
                                        state_name={item.state_name}
                                        url_detail={item.url_detail}
                                        is_showing_promotional_price_banner={
                                          item?.is_showing_promotional_price_banner
                                        }
                                        property_old_price={
                                          item?.property_old_price
                                        }
                                        property_type_value={
                                          item?.property_type_value
                                        }
                                        available_at_date={
                                          item?.available_at_date
                                        }
                                        exchange_property_price={
                                          item?.exchange_property_price
                                        }
                                        property_price_currency_symbol={
                                          item?.property_price_currency_symbol
                                        }
                                        exchange_property_old_price={
                                          item?.exchange_property_old_price
                                        }
                                        marketplace_type={
                                          filters?.marketplace_type
                                        }
                                        property_sale_status={
                                          item?.property_sale_status
                                        }
                                      />
                                    </div>
                                    {filters?.marketplace_type === 'for_sale' && index === 5 && (
                                      <div className="custom_div px-3 py-2 cursor-pointer" onClick={goToMarket}>
                                        <div className="w-full flex flex-wrap justify-between items-center">
                                          <div className=" w-3/4">
                                            <h1 className="text-base font-semibold text-[#59C58E] leading-4">
                                              ¿Estás buscando propiedades nuevas en venta?
                                            </h1>
                                            <p className="text-xs font-normal text-white mt-1">Descubre nuestro inventario de casas y apartamentos en planos</p>
                                            
                                          </div>
                                          <div className=" w-1/4 flex items-center justify-end">
                                            <img className="w-24" src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/v2/icono-presales.svg" alt="logo" />
                                          </div>
                                        </div>
                                        {/* Aquí puedes agregar el contenido que desees */}
                                      </div>
                                    )}
                                    </Fragment>
                                    
                                  )
                                )}

                                <Stack
                                  spacing={2}
                                  direction="row"
                                  justifyContent="center"
                                  mt={4}
                                  sx={{
                                    marginBottom: "5.5rem",
                                  }}
                                >
                                  <Pagination
                                    count={pageCount}
                                    page={currentPage}
                                    onChange={handleChangeMobile}
                                  />
                                </Stack>
                              </>
                            ) : (
                              <div className="w-full block sm:hidden">
                                <NotFoundProperties
                                  title="Explora otras zonas y encuentra tu propiedad ideal"
                                  subtitle="Lo sentimos, no hay propiedades en esta zona "
                                  citiesPopular={popular}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
            {dataProperties.length > 0 && (
              <button className="switch-btn" onClick={toggleMapList}>
                {toggleState ? (
                  <>
                    Lista
                    <IconListSearch className="ml-4" color="white" />
                  </>
                ) : (
                  <>
                    Mapa
                    <IconMap2 className="ml-4" color="white" />
                  </>
                )}
              </button>
            )}
          </div>


        </div>
      </div>
    </>
  );
};
export default Marketplace;
