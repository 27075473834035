import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { AppStore } from "../../redux/store"
//hooks - adapters - helpers
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad"
import { fetchResponseAdapter } from "../../adapters/fetchAdapter"
import { listToSelectOptionAdapter } from "../../adapters/listsAdapter"
import { getAuthToken } from "../../helpers/authHelper"
import { parseStringBooleanToBool } from '../../helpers/formHelpers'
import { 
    petSizesOptions,
    havePetsOptions,
    genderOptions
} from "./helpers/listHelpers"
// services
import { UpdateTenantProfileForm } from './models/tenantModel';
import { getCountriesEndpoint, getStatesEndpoint } from '../../services/publicService';
import { updateTenantProfileEndpoint } from './services/tenantService';
// form
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form';
//assets
import arrow_button from '../../assets/icons/arrow_button.svg';
import arrow_left from '../../assets/icons/arrow_left.svg';
import Button from "../../components/Button"
import FormControlError from "../../components/Controls/FormControlError"
import DetailFormControlSection from "../../components/General/DetailFormControlSection"

const UpdateTenantProfile = () => {
    const { country } = useSelector((store: AppStore) => store.country);
    const location = useLocation()
    const user = useSelector((store: AppStore) => store.auth)
    const token = getAuthToken(user)
    const [countries, setCountries] = useState<Array<any>>([])
    const [states, setStates] = useState<Array<any>>([])
    const [error, setError] = useState('')
    const [havePetsState, setHavePetsState] = useState(false)
    const { isLoading, callEndpoint } = useCallApiAndLoad()
    const [defaultForm, setDefaultForm] = useState<any>(location.state)
    const navigate = useNavigate()
    const {
        register, handleSubmit, formState: { errors }, reset
    } = useForm<UpdateTenantProfileForm>({
        resolver: yupResolver(yup.object({
            name: yup.string()
                .required('Nombres son requerido'),
            last_name: yup.string()
                .required('Apellidos son requerido'),
            country: yup.string()
                .required('País es requerido'),
            state: yup.string()
                .required('Departamento es requerido'),
            phone: yup.string()
                .required('Teléfono es requerido'),
            birth_date: yup.string()
                .required('Fecha de nacimiento es requerida'),
            profession: yup.string()
                .required('Profesión es requerida'),
            roommates_qty: yup.string()
                .required('Cantidad de personas es requerido'),
            hobbies: yup.string()
                .required('Cantidad de personas es requerido'),
            gender: yup.string()
                .required('Sexo es requerido'),
            social_media: yup.string()
                .required('Cantidad de personas es requerido'),
            company_name: yup.string()
                .required('Ingrese la empresa donde trabaja actualmente'),
            net_income: yup.string()
                .required('Ingrese su salario aproximado'),
            summary: yup.string()
                .required('Escriba una breve descripción de usted y su familia'),
            ...(havePetsState
                ? {
                    pet_size: yup
                        .string()
                        .required("Tamaño de mascota es requerido"),
                }
                : {})
        }).required())
    })

    useEffect(() => {
        if (defaultForm) {
            setHavePetsState(defaultForm.have_pets || false)
            reset({
                name: defaultForm.name,
                last_name: defaultForm.last_name,
                country: defaultForm.country_id,
                state: defaultForm.state_id,
                phone: defaultForm.phone,
                birth_date: defaultForm.birth_date_default,
                gender: defaultForm.gender,
                roommates_qty: defaultForm.roommates_qty,
                social_media: defaultForm.social_media,
                profession: defaultForm.profession,
                hobbies: defaultForm.hobbies,
                have_pets: defaultForm.have_pets || false,
                pet_size: defaultForm.pet_size,
                company_name: defaultForm.company_name,
                net_income: defaultForm.net_income,
                summary: defaultForm.summary,
            })
        }
    }, [])

    useEffect(() => {
        const callCatalogs = async () => {
            const countriesResponse = await callEndpoint(getCountriesEndpoint())
            if (countriesResponse.status !== 200) {
                setError('Ocurrio un error al momento de cargar los catalogos de paises')
                return;
            }
            const countriesData = fetchResponseAdapter(countriesResponse.data)
            setCountries(listToSelectOptionAdapter(countriesData.data, 'country_id', 'country_name'))
            if (defaultForm.country_id) {
                const statesResponse = await callEndpoint(getStatesEndpoint(defaultForm.country_id))
                if (statesResponse.status !== 200) {
                    setError('Ocurrio un error al momento de cargar los catalogos de estados')
                    return;
                }
                const statesData = fetchResponseAdapter(statesResponse.data)
                setStates(listToSelectOptionAdapter(statesData.data, 'state_id', 'state_name'))
            }
        }
        callCatalogs()
            .catch(e => console.error(e))
    }, [])

    const handleChangeCountry = async ({ target }: any) => {
        const { value = '' } = target
        setStates([])
        if (value) {
            const { status, data } = await callEndpoint(getStatesEndpoint(value))
            if (status === 200) {
                const statesResponse = fetchResponseAdapter(data)
                setStates(listToSelectOptionAdapter(statesResponse.data, 'state_id', 'state_name'))
                return
            }
        }
    }

    const handleChangeHavePets = (e: any) => {
        const value = e.target.value;
        let formattedValue;
        if (value && typeof value === "string") {
            formattedValue = parseStringBooleanToBool(value)
        } else {
            formattedValue = value;
        }
        setHavePetsState(formattedValue)
    }

    const doUpdate = async(form: UpdateTenantProfileForm) => {
        form.have_pets = havePetsState;
        if(!form.have_pets){
            form.pet_size = ""
        }
        setError('')
        const { status } = await callEndpoint(updateTenantProfileEndpoint(form, token))
        if (status === 200) {
            return navigate(`/app/${country}/l/tn/profile`)
        }
    }

    return (
        <form onSubmit={handleSubmit(doUpdate)}>
            <section className='pt-24' >
                <div className="container">
                    <div className="w-full text-center my-6 text-third text-lg">
                        Actualiza tus datos personales
                    </div>
                    <div className="m-auto w-full">
                        <div className="w-full my-6 text-third text-4xl font-bold text-center">
                            Edita tus datos
                        </div>
                    </div>
                </div>
            </section>
            <hr />
            <section>
                <div className="container">
                    <div className="m-auto w-full">
                        <div className="w-full my-10">
                            <FormControlError message={error} />
                            <DetailFormControlSection label="Nombres" >
                                <>
                                    <input
                                        type="text"
                                        placeholder='Ingresa tus nombres'
                                        className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3"
                                        {...register("name")}
                                    />
                                    <FormControlError message={errors.name?.message} />
                                </>
                            </DetailFormControlSection>
                            <DetailFormControlSection label="Apellidos" >
                                <>
                                    <input
                                        type="text"
                                        placeholder='Ingresa tus apellidos'
                                        className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3"
                                        {...register("last_name")}
                                    />
                                    <FormControlError message={errors.last_name?.message} />
                                </>
                            </DetailFormControlSection>
                            <DetailFormControlSection label="Correo electrónico" >
                                <input
                                    defaultValue={defaultForm?.email}
                                    readOnly
                                    disabled
                                    type="text"
                                    className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3"
                                />
                            </DetailFormControlSection>
                            <DetailFormControlSection label="País" >
                                <>
                                    <select
                                        {...register("country", {
                                            onChange: (e: Event) => handleChangeCountry(e)
                                        })}
                                        className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3">
                                        <option value="">Seleccione un país...</option>
                                        {countries.map(c => {
                                            const isSelected = c.value === defaultForm.country_id
                                            return <option key={`tc-${c.value}`} selected={isSelected} value={c.value}>{c.label}</option>
                                        })}
                                    </select>
                                    <FormControlError message={errors.country?.message} />
                                </>
                            </DetailFormControlSection>
                            <DetailFormControlSection label="Estado o departamento" >
                                <>
                                    <select
                                        {...register("state")}
                                        className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3">
                                        <option value="">Seleccione un estado...</option>
                                        {states.map(c => {
                                            const isSelected = c.value === defaultForm.state_id
                                            return <option key={`ts-${c.value}`} selected={isSelected} value={c.value}>{c.label}</option>
                                        })}
                                    </select>
                                    <FormControlError message={errors.state?.message} />
                                </>
                            </DetailFormControlSection>
                            <DetailFormControlSection label="Estado o departamento" >
                                <>
                                    <input
                                        type="text"
                                        placeholder='Ingrese su teléfono'
                                        className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3"
                                        {...register("phone")}
                                    />
                                    <FormControlError message={errors.phone?.message} />
                                </>
                            </DetailFormControlSection>
                            <DetailFormControlSection label="Fecha de nacimiento" >
                                <>
                                    <input
                                        type="date"
                                        defaultValue={defaultForm.birth_date_default}
                                        className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3"
                                        {...register("birth_date")}
                                    />
                                    <FormControlError message={errors.birth_date?.message} />
                                </>
                            </DetailFormControlSection>
                            <DetailFormControlSection label="Profesión u oficio" >
                                <>
                                    <input
                                        type="string"
                                        placeholder='Ingrese su profesión u oficio'
                                        className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3"
                                        {...register("profession")}
                                    />
                                    <FormControlError message={errors.profession?.message} />
                                </>
                            </DetailFormControlSection>
                            <DetailFormControlSection label="Mascota" >
                                {
                                    havePetsOptions && havePetsOptions.map((item: any) => (
                                        <>
                                            <br />
                                            <input
                                                type="radio"
                                                {...register("have_pets", {
                                                    onChange: (e: Event) => handleChangeHavePets(e)
                                                })}
                                                value={item.value}
                                                defaultChecked={defaultForm.have_pets === item.value}
                                                id={item.value} />
                                            <label className='' htmlFor={item.name}> {item.name} </label>
                                        </>
                                    ))
                                }
                            </DetailFormControlSection>
                            {
                                havePetsState &&
                                (<DetailFormControlSection label="Tamaño de mascota" >
                                    <>
                                        <select
                                            {...register("pet_size")}
                                            className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3">
                                            <option value="">Seleccione un estado...</option>
                                            {petSizesOptions.map((c: any) => {
                                                const isSelected = c.value === defaultForm.pet_size
                                                return <option selected={isSelected} value={c.value}>{c.name}</option>
                                            })}
                                        </select>
                                        <FormControlError message={errors.pet_size?.message} />
                                    </>
                                </DetailFormControlSection>)
                            }
                            <DetailFormControlSection label="Sexo" >
                                <>
                                    <select
                                        {...register("gender")}
                                        className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3">
                                        <option value="">Seleccione un genero...</option>
                                        {genderOptions.map((c: any) => {
                                            const isSelected = c.value === defaultForm.gender
                                            return <option selected={isSelected} value={c.value}>{c.name}</option>
                                        })}
                                    </select>
                                    <FormControlError message={errors.gender?.message} />
                                </>
                            </DetailFormControlSection>
                            <DetailFormControlSection label="Cantidad de personas con las que vives" >
                                <>
                                    <input
                                        type="number"
                                        className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3"
                                        {...register("roommates_qty")}
                                    />
                                    <FormControlError message={errors.roommates_qty?.message} />
                                </>
                            </DetailFormControlSection>
                            <DetailFormControlSection label="Pasatiempos" >
                                <>
                                    <input
                                        type="string"
                                        className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3"
                                        {...register("hobbies")}
                                    />
                                    <FormControlError message={errors.hobbies?.message} />
                                </>
                            </DetailFormControlSection>
                            <DetailFormControlSection label="Red social" >
                                <>
                                    <input
                                        type="string"
                                        className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3"
                                        {...register("social_media")}
                                    />
                                    <FormControlError message={errors.social_media?.message} />
                                </>
                            </DetailFormControlSection>
                            <DetailFormControlSection label="Compañia donde trabaja" >
                                <>
                                    <input
                                        type="string"
                                        className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3"
                                        {...register("company_name")}
                                    />
                                    <FormControlError message={errors.hobbies?.message} />
                                </>
                            </DetailFormControlSection>
                            <DetailFormControlSection label="Salario neto aproximado" >
                                <>
                                    <input
                                        type="number"
                                        className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3"
                                        {...register("net_income")}
                                    />
                                    <FormControlError message={errors.hobbies?.message} />
                                </>
                            </DetailFormControlSection>
                            <DetailFormControlSection label="Resumen de mi" >
                                <>
                                    <input
                                        type="string"
                                        className="bg-white block py-2 px-2 w-full text-sm rounded text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-3"
                                        {...register("summary")}
                                    />
                                    <FormControlError message={errors.hobbies?.message} />
                                </>
                            </DetailFormControlSection>
                        </div>
                    </div>
                </div>
            </section>
            <hr />
            <section className='my-4'>
                <div className="container">
                    <div className="flex justify-between items-center py-4 px-4">
                        <a onClick={() => navigate(-1)}
                            className='cursor-pointer flex justify-center items-center' >
                            <img className='mr-2' src={arrow_left} alt="" />
                            Regresar
                        </a>
                        <div className="w-56">
                            <Button
                                disabled={isLoading}
                                className="relative w-full bg-primary py-5 px-4 my-4 text-white text-base rounded-full flex justify-center items-center">
                                <span className="mr-2">Continuar</span>
                                <img src={arrow_button} className="vertical-absolute-center right-3" alt="" />
                            </Button>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    )
}

export default UpdateTenantProfile