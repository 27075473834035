import { useState } from "react";

import { fetchResponseAdapter } from "../../../../../adapters/fetchAdapter";
import useCallApiAndLoad from "../../../../../hooks/useCallApiAndLoad";
import {
  getBookedUsers,
  getDetailProject,
  getDetailProjectUnits,
  getDetailUserUnit,
  getInvestorByProjects,
} from "../../../services/devsServices";
import { logoutAction } from "../../../../../redux/features/auth";
import { useDispatch, useSelector } from "react-redux";
import { unsetAuth } from "../../../../../helpers/authHelper";
import { useNavigate } from "react-router-dom";
import { Paginator } from "../../../../../models/formModel";
import { AppStore } from "../../../../../redux/store";
import { InvestsUser, UserUnit } from "../../../../../models/presales";
import { logoutActionDev } from "../../../../../redux/features/authDeveloper";

export const useDetailProjectsUnit = (): any => {
  const { country } = useSelector((store: AppStore) => store.country);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [detailProject, setDetailProject] = useState([]);
  const [bookedUsers, setBookedUsers] = useState([]);
  const [investors, setInvestors] = useState<InvestsUser[]>([]);
  const [usersUnit, setUsersUnit] = useState<UserUnit[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [paginator, setPaginator] = useState<Paginator>({
    page: 0,
    take: 0,
    itemCount: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });

  const [dataDetailUnit, setDataDetailUnit] = useState({});
  const [isLoadingUnit, setIsLoadingUnit] = useState<boolean>(false);
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [isLoadingBooked, setIsLoadingBooked] = useState(false);

  const getDataDetailModel = async (token: string, id: any): Promise<void> => {
    setLoading(true);
    try {
      const { status, data } = await callEndpoint(getDetailProject(token, id));
      if (status === 401) {
        setLoading(false);
        dispatch(logoutAction({}));
        unsetAuth();
        navigate(`/app/${country}/auth/desarrolladoras/login`);
      }

      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setLoading(false);
        setDetailProject(response.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // /v1/marketplace/projects/{code}/units/{id}
  const getDataDetailUnit = async (
    token: string,
    id: string,
    filters: any
  ): Promise<void> => {
    setIsLoadingUnit(true);
    try {
      const { status, data } = await callEndpoint(
        getDetailProjectUnits(token, id, filters)
      );
      if (status === 401) {
        setLoading(false);
        dispatch(logoutAction({}));
        unsetAuth();
        navigate(`/app/${country}/auth/desarrolladoras/login`);
      }
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setIsLoadingUnit(false);
        setDataDetailUnit(response?.data?.items);
        setPaginator(response?.data?.meta);
      }
    } catch (error) {
      setIsLoadingUnit(false);
      console.log(error);
    }
  };

  const getUsersBooked = async (
    token: string,
    idModel: string,
    idProject: string
  ) => {
    setIsLoadingBooked(true);
    try {
      const { status, data } = await callEndpoint(
        getBookedUsers(token, idProject, idModel)
      );
      if (status === 401) {
        setIsLoadingBooked(false);
        dispatch(logoutAction({}));
        unsetAuth();
        navigate(`/app/${country}/auth/desarrolladoras/login`);
      }
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setIsLoadingBooked(false);
        setBookedUsers(response?.data);
      }
    } catch (error) {
      setIsLoadingBooked(false);
    }
  };

  const getDataInvestor = async (token: string, id: any): Promise<void> => {
    setLoading(true);
    try {
      const { status, data } = await callEndpoint(getInvestorByProjects(token, id));
      if (status === 401) {
        setLoading(false);
        dispatch(logoutAction({}));
        unsetAuth();
        navigate(`/app/${country}/auth/desarrolladoras/login`);
      }

      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setLoading(false);
        setInvestors(response.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };


  const getDetailUnitUser = async (token: string, idProject: string, idUnit: string): Promise<void> => {
    setLoading(true);
    try {
      const { status, data } = await callEndpoint(getDetailUserUnit(token, idProject, idUnit));
      if (status === 401) {
        setLoading(false);
        dispatch(logoutAction({}));
        unsetAuth();
        navigate(`/app/${country}/auth/desarrolladoras/login`);
      }

      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setLoading(false);
        setUsersUnit(response.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return {
    detailProject,
    usersUnit,
    investors,
    dataDetailUnit,
    bookedUsers,
    loading,
    isLoading,
    isLoadingUnit,
    isLoadingBooked,
    getDataDetailModel,
    getDataDetailUnit,
    getUsersBooked,
    getDataInvestor,
    getDetailUnitUser,
    paginator,
  };
};
