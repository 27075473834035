import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { unsetAuth } from "../../helpers/authHelper";
import useAuth from "../../hooks/useAuth";
import './scss/NavBar.scss';
import { AppStore } from "../../redux/store";
import { Divider } from "@mui/material";

const NavBarTenant = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  const { handleLogout } = useAuth()

  return (
    <nav className="dropdown-menu bg-white lg:absolute lg:mt-[45px] lg:shadow-lg lg:rounded-[5px] w-full lg:w-fit lg:right-[15px] lg:z-[100] w-max">
      <ul className="w-max">
        <li className="w-fit">
          <ul className="w-fit">
          <li className="hover:bg-green200 hover:text-green700 text-[#212121] font-medium text-base py-2 lg:pl-2 lg:pr-6 rounded-t-lg">
              <a href={`/app/${country}/l/tn/mi-cuenta`}>Mi cuenta</a>
            </li>
            {/* <li className="hover:bg-green200 hover:text-green700 text-[#212121] font-medium text-base py-2 lg:pl-2 lg:pr-6">
              <a href={`/app/${country}/l/tn/favorites`}>Favoritos</a>
            </li> */}
            <li className="hover:bg-green200 hover:text-green700 text-[#212121] font-medium text-base py-2 lg:pl-2 lg:pr-6">
              <a href={`/app/${country}/l/tn/offers`}>Ofertas realizadas</a>
            </li>
            <li className="hover:bg-green200 hover:text-green700 text-[#212121] font-medium text-base py-2 lg:pl-2 lg:pr-6">
              <a href={`/app/${country}/l/tn/visits`}>Mis visitas</a>
            </li>
            <li className="hover:bg-green200 hover:text-green700 text-[#212121] font-medium text-base py-2 lg:pl-2 lg:pr-6">
              <a href={`/app/${country}/l/tn/payments`}>Mis alquileres</a>
            </li>
            <li className="hover:bg-green200 hover:text-green700 text-[#212121] font-medium text-base py-2 lg:pl-2 lg:pr-6">
              <a href={`/app/${country}/l/tn/payments-sales`}>Mis compras</a>
            </li>
            
            {/* <li className="hover:bg-green200 hover:text-green700 text-[#212121] font-medium text-base py-2 lg:pl-2 lg:pr-6">
              Mis tarjetas
            </li> */}
            <li className="hover:bg-green200 hover:text-green700 text-[#212121] font-medium text-base py-2 lg:pl-2 lg:pr-6">
              <a href={`/${country}/ayuda`}>Obtener ayuda</a> 
            </li>
            <Divider />
            <li onClick={handleLogout}
              className="hover:bg-green200 hover:text-green700 text-[#212121] font-medium text-base py-2 lg:pl-2 lg:pr-6 rounded-b-lg">
              <button>Cerrar sesión</button>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default NavBarTenant;
