// lib
import { BrowserRouter, Routes, Route } from "react-router-dom";
// routes components
import SiteRoutes from "./SiteRoutes";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import PrivateRouteDevelopers from "./PrivateRouteDeveloper";
import PublicRouteDevelopers from "./PublicRouteDevelopers";
// hooks
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// redux
import { loginAction, logoutAction } from "../redux/features/auth";
// pages
import Marketplace from "../pages/Marketplace/Marketplace";
import AboutUs from "../pages/About/AboutUs";
// services
// import { checkAuthEndpoint } from '../services/publicService';
import { getAuth, getAuthDeveloper } from "../helpers/authHelper";
import AuthRoutes from "./AuthRoutes";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import Privacy from "../pages/TermsConditions/Privacy";
import TermsConditions from "../pages/TermsConditions/TermsConditions";
import NeedHelp from "../pages/FAQ/NeedHelp";
import ReferralsPage from "../pages/Referrals/ReferralsPage";
import GuestVisit from "../pages/GuestVisit/GuestVisit";
import GuestOffer from "../pages/GuestOffer/GuestOffer";
import StepSuccesOffer from "../pages/GuestOffer/components/StepSuccess/StepSuccessOffer";
import StepSuccesVisit from "../pages/GuestVisit/components/StepSuccesVisit/StepSuccesVisit";
import AuthDevsRoutes from "./AuthDevs";
import PrivatesDevsRoutes from "./PrivateDevs";
import PolygonMapHome from "../pages/PolygonMap/PolygonMapHome";
import SelectCountryPage from "../pages/SelectCountry/SelectCountry";
import useUserCountry from "../hooks/useUserCountry";
import axios from "axios";
import Page404 from "../pages/404/404";
import LoadingComponent from "../components/Loading/Loading";
import { setIpAction } from "../redux/features/ip";
import { loginActionDeveloper, logoutActionDev } from "../redux/features/authDeveloper";

const AppRouter = () => {
  const { setCountry } = useUserCountry();
  const [checking, setChecking] = useState(true);
  const dispatch = useDispatch();
  const tagManagerArgs = {
    gtmId: "GTM-P3P9TGL",
  };

  // const [ubicacion, setUbicacion] = useState<any>(null);

  // const getGeoCode = async (latitud: any, longitud: any) => {
  //   try {
  //     const response = await axios.get(
  //       `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitud}&lon=${longitud}`
  //     );
  //     const countryCode = response.data.address.country_code;
  //     return countryCode;
  //   } catch (error) {
  //     console.error("Error al obtener el código del país:", error);
  //     return null;
  //   }
  // };

  // // Detect country
  // useEffect(() => {
  //   let url = window.location.pathname;
  //   if (url === "/") {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         (position: any) => {
  //           setUbicacion(position?.coords);
  //         },
  //         (error) => {
  //           console.error("Error al obtener la ubicación:", error);
  //         }
  //       );
  //     } else {
  //       console.error(
  //         "La geolocalización no está disponible en este navegador."
  //       );
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   if (ubicacion) {
  //     getGeoCode(ubicacion?.latitude, ubicacion?.longitude).then(
  //       (countryCode) => {
  //         if (countryCode) {
  //           if (countryCode === "sv" || countryCode === "gt") {
  //             setCountry(countryCode);
  //             return window.open(`/${countryCode}`, "_self");
  //           } else {
  //             return window.open(`/sv`, "_self");
  //           }
  //         }
  //       }
  //     );
  //   }
  // }, [ubicacion]);

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    ReactGA.pageview(window.location.pathname + window.location.search);
    const authDataStorage = getAuth();
    if (authDataStorage) {
      const authData = JSON.parse(authDataStorage);
      dispatch(loginAction(authData));
      setChecking(false);
    } else {
      dispatch(logoutAction({}));
      setChecking(false);
    }
  }, [dispatch, setChecking]);

  

  const [countryDetected, setCountryDetected] = useState("");

  useEffect(() => {
    // Verificar si el país no está detectado
    axios
      .get(
        "https://ipapi.co/json/?key=aVwg4o2qU83rk8aligqHxIODBDBhViC9WOmGByjMr0Nd96X0O6"
      )
      .then((response) => {
        let data = response.data;
        setCountryDetected(data?.country?.toLowerCase());
        dispatch(setIpAction({ ipClient: data?.ip }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []); // <-- Dependencia countryDetected para ejecutar cuando cambia

  // useEffect(() => {
  //   // Obtener la URL actual
  //   let url = window.location.href;
  //   let urlPath = window.location.pathname;
  //   // Dividir la URL en segmentos utilizando "/"
  //   let segmentos = url.split("/");
  //   // El segundo valor estará en el índice 2 del arreglo 'segmentos'
  //   let countryPath = segmentos[3];
  //   let countryPathApp = segmentos[4];
  //   console.log({countryPath})
  //   if (countryPath === "sv" || countryPath === 'gt') {
  //     console.log(countryPath)
  //     setCountry(countryPath);
  //     // window.open(`/${countryPath}`, "_self");
  //   } else if (countryPathApp === "sv" || countryPathApp === 'gt') {
  //     console.log(countryPathApp)
  //     setCountry(countryPathApp);
  //    // window.open(`/${countryPathApp}`, "_self");
  //   } else {
  //     setCountry('sv');
  //     // window.open(`/sv`, "_self");
  //   }
  //   // No mostrar raiz

  //   // if (segundoValor === "sv" || segundoValor === "gt") {
  //   //   dispatch(setCountryAction({ country: segundoValor }));
  //   //   setCheck(false);
  //   // } else {
  //   //   dispatch(setCountryAction({ country: "" }));
  //   //   setCheck(false);
  //   //   if (window.location.pathname !== "/") {
  //   //     window.open("/", "_self");
  //   //   }
  //   // }
  // }, [dispatch, setCheck]);

  useEffect(() => {
    // Obtener la URL actual
    let url = window.location.href;
    let urlPath = window.location.pathname;
    // Dividir la URL en segmentos utilizando "/"
    let segmentos = url.split("/");
    // El segundo valor estará en el índice 2 del arreglo 'segmentos'
    let countryPath = segmentos[3];
    let countryPathApp = segmentos[4];

    // Verificar si el país es "sv" o "gt" en la ruta principal
    if (countryPath === "sv" || countryPath === "gt") {
      setCountry(countryPath);
    }
    // Verificar si el país es "sv" o "gt" en la ruta de la aplicación
    else if (countryPathApp === "sv" || countryPathApp === "gt") {
      setCountry(countryPathApp);
    }
    // Si la URL es propilatam.com y se ha detectado un país
    else if (countryPath === "") {
      if (urlPath === "/" && countryDetected !== "") {
        if (countryDetected === "sv" || countryDetected === "gt") {
          setCountry(countryDetected);
          window.open(`/${countryDetected}/`, "_self");
        } else {
          // Si el país detectado no es ni "sv" ni "gt", redirigir a "/sv"
          setCountry("sv");
          window.open("/sv/", "_self");
        }
      }
    }
    // Si la URL no contiene un país válido, redirigir a "/sv"
    else {
      if (
        urlPath === "/app/auth/desarrolladoras/login" ||
        urlPath === "/app//auth/desarrolladoras/login"
      ) {
        if (countryDetected) {
          setCountry(countryDetected);
          window.open(
            `/app/${countryDetected}/auth/desarrolladoras/login`,
            "_self"
          );
        }
      }
      // window.open("/sv/", "_self");
    }
  }, [countryDetected]);

  // Lista vacía de dependencias para ejecutar el efecto solo una vez

  if (checking) {
    return <LoadingComponent />;
  }

  return (
    <BrowserRouter>
      <Routes>
        {/* Country selector 
        <Route path="/" element={<SelectCountryPage />} /> */}
        {/* Home */}
        <Route path="/app/:country" element={<SelectCountryPage />} />
        {/* Marketplace */}
        <Route path="/app/:country/marketplace" element={<Marketplace />} />
        {/* Landings */}
        {/* <Route path="/soy-propietario" element={<OwnerPage />} /> */}
        <Route path="/app/:country/referidos" element={<ReferralsPage />} />
        <Route
          path="/app/:country/politicas-privacidad"
          element={<Privacy />}
        />
        <Route
          path="/app/:country/terminos-condiciones"
          element={<TermsConditions />}
        />
        <Route path="/app/:country/quienes-somos" element={<AboutUs />} />
        <Route path="/app/:country/ayuda" element={<NeedHelp />} />

        {/* Guest Module */}
        <Route
          path="/app/:country/invitado-visitas/*"
          element={
            <PublicRoute>
              <Routes>
                <Route path="/registrarse/:id" element={<GuestVisit />} />
                <Route
                  path="/registrarse/exito"
                  element={<StepSuccesVisit />}
                />
              </Routes>
            </PublicRoute>
          }
        />
        <Route
          path="/app/:country/invitado-oferta/*"
          element={
            <PublicRoute>
              <Routes>
                <Route
                  path="/registrarse/:propertyId"
                  element={<GuestOffer />}
                />
                <Route
                  path="/registrarse/exito"
                  element={<StepSuccesOffer />}
                />
              </Routes>
            </PublicRoute>
          }
        />

        {/* Public routes */}
        <Route
          path="/app/:country/auth/*"
          element={
            <PublicRoute>
              <AuthRoutes />
            </PublicRoute>
          }
        />

        <Route
          path="/app/:country/auth/desarrolladoras/*"
          element={
            <PublicRouteDevelopers>
              <AuthDevsRoutes />
            </PublicRouteDevelopers>
          }
        />

        <Route
          path="/app/:country/desarrolladoras/*"
          element={
            <PrivateRouteDevelopers>
              <PrivatesDevsRoutes />
            </PrivateRouteDevelopers>
          }
        />

        {/* Private routes */}
        <Route
          path="/app/:country/l/*"
          element={
            <PrivateRoute>
              <SiteRoutes />
            </PrivateRoute>
          }
        />

        {/* Desarrolladoras */}

        {/* Polygon Map */}
        <Route path="/app/:country/polygons" element={<PolygonMapHome />} />
        <Route path="/:country/*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
