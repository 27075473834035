import React, { useState } from 'react';
import './ItemNavMobile.scss';
import { Fade } from "react-awesome-reveal";
import { IconChevronDown } from "@tabler/icons-react"

const ItemNavMobile = ({ title, children }: any) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={`collapseItem ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="collapse-header flex justify-start items-center gap-2 text-base" onClick={toggleCollapse}>
        {title} <IconChevronDown color='#212121' />
      </div>
      {!isCollapsed && <Fade><div className="collapse-content">{children}</div></Fade> }
    </div>
  );
};

export default ItemNavMobile;
