import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import FormControlError from "../../../../components/Controls/FormControlError";
import { getAuthToken } from "../../../../helpers/authHelper";
import useCallApiAndLoad from "../../../../hooks/useCallApiAndLoad";
import { AppStore } from "../../../../redux/store";
import CurrencyInput from "../../../../components/Controls/CurrencyInput";
import Button from "../../../../components/Button";
import arrow_button from '../../../../assets/icons/arrow_button.svg';
import FormControlInfo from "../../../../components/Controls/FormControlInfo";
import { negociateOfferEndpoint } from "../../services/tenantService";
import { parseNumberCurrencyValue } from '../../../../helpers/currencyHelper';
import { OfferInfo, PropertyInfo } from "../../models/tenantModel";
import { IconAlertCircle } from "@tabler/icons-react";

interface Props {
  onAction: () => void;
  id: string;
  property_info: PropertyInfo | null;
  offer_info: OfferInfo | null;
}

interface FormData {
  price_to_negociate: string;
}

interface FormDataApi {
  price_to_negociate: number;
}

const FormDeal = (props: Props) => {
  const user = useSelector((store: AppStore) => store.auth);
  const { callEndpoint } = useCallApiAndLoad()
  const { onAction } = props;
  const token = getAuthToken(user)
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(
      yup.object().shape({
        price_to_negociate: yup.string()
          .required('La oferta es requerida')
      })
    )
  });

  const sendData = async (form: FormData) => {
    const formApi: FormDataApi = {
      price_to_negociate: parseNumberCurrencyValue(form.price_to_negociate, ',')
    }
    const offerId = props.offer_info?.id || ''
    const { status } = await callEndpoint(negociateOfferEndpoint(offerId, token, formApi))
    if (status === 200) {
      return navigate(0)
    }
  };

  return (
    <div className="w-full bg-white py-6 px-4">
      <div className="w-full mb-4 text-blackGrayScale text-2xl lg:text-2xl font-semibold text-left tracking-wide flex justify-start items-center">
               Negociar propiedad
      </div>
      <div className="mt-3 rounded-lg gap-2 px-3 py-2 text-[#723b13] bg-[#fdfdea] text-sm font-normal flex justify-between items-center">
       <IconAlertCircle /> <p><span className="font-semibold">Aviso!</span> El propietario desea negociar tu anterior oferta</p>
      </div>
      <div className="w-auto mx-auto px-3 my-2 text-center">
        <span className="text-sm text-[#757575]">Nuevo valor</span>
        <h2 className="text-2xl font-semibold text-[#212121]">{props.offer_info?.negociated_price}</h2>
        <h3 className="text-sm font-normal text-[#757575]">Tu oferta fue <span className="font-bold">{props?.offer_info?.offer_price}</span></h3>
      </div>
      {/* <FormControlInfo message={`El propietario desea negociar tu oferta de ${props.offer_info?.offer_price}`} /> */}

      <div className="bg-[#F5F5F5] px-4 py-2 mt-3 text-left rounded-lg">
        <h2 className="text-base text-[#212121] font-semibold ">Comentario del propietario:</h2>
        {
          props.offer_info?.landlord_comment ? <p className=" text-[#757575] text-sm">{ props.offer_info?.landlord_comment }</p> :
          <p className=" text-[#757575] text-sm">El propietario no escribió ningún comentario</p>
        }
        
      </div>

      <div className="px-3">
        {
          props.property_info?.rent_info.map((item: any) => (
            <>
              {
                item.item !== 'Mensualidad' && (
                  <div className="flex justify-between my-3 items-center">
                    <p className="text-sm text-[#757575]">{item.item}</p>
                    <p className="text-sm text-[#212121] font-bold">{item.value}</p>
                  </div>
                )
              }

              {
                item.item === 'Mensualidad' && (
                  <div className="p-3 rounded-sm bg-greenLight flex justify-between items-center">
                    <p className=" text-sm text-[#757575]">{item.item}</p>
                    <p className=" text-sm text-[#212121] font-bold">{item.value}</p>
                  </div>
                )
              }
            </>

          ))
        }
      </div>
      <hr className="my-3" />

      <form onSubmit={handleSubmit(sendData)}>
      <h3 className="text-lg font-medium text-[#212121] text-left mb-2">
          Realiza una nueva oferta
        </h3>
        <CurrencyInput
          name="price_to_negociate"
          placeholder={props.offer_info?.negociated_price}
          register={register}
          currency={"$"}
          thousand_separator={","}
          decimal_separator={"."}
        />
        <FormControlError message={errors.price_to_negociate?.message} />
        <div className="bg-white w-full mt-6">
        <div className="w-full grid grid-cols-2 gap-6">
            <button>
              <div
                onClick={onAction}
                className="border border-[#212121] rounded-lg px-4 py-2 text-[#212121] hover:bg-[#212121] hover:text-white duration-100"
              >
                Cancelar
              </div>
            </button>
            <Button
              type="submit"
              className="px-4 py-2 rounded-lg bg-[#212121] hover:bg-primary duration-100 text-white"
            >
             Ofertar
              
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default FormDeal;
