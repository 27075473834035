export const getCountry = () => {
    if (typeof window !== 'undefined') {
    return window.localStorage.getItem('country') || ''
    }
}

export const setCountry = (country: string) => {
    if (typeof window !== 'undefined') {
    window.localStorage.setItem('country', country)
    }
}
