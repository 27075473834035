import React, { memo, useEffect, useState } from "react";
import "./CardPro.scss";

import size from "../../assets/icons/size.svg";
import house from "../../assets/icons/house.svg";
import bath from "../../assets/icons/bath.svg";
import InvestorCard from "./InvestorCard";
import userInfo from "../../../../assets/icons/userInfo.svg";
import { LandlordInfo } from "../../models/Devs";
import CountdownTimer from "./CountdownTimer";
import { Box, Dialog, Grid, Modal } from "@mui/material";
import { getAuthToken } from "../../../../helpers/authHelper";
import { useSelector } from "react-redux";
import { AppStore } from "../../../../redux/store";
import { getBookedUsers } from "../../services/devsServices";
import { useDetailProjectsUnit } from "../../pages/ProjectDetail/hooks/useDetailProject";
import { getPostulantInfoEndpoint } from "../../../Landlord/services/LandlordOfferService";
import { fetchResponseAdapter } from "../../../../adapters/fetchAdapter";
import useCallApiAndLoad from "../../../../hooks/useCallApiAndLoad";

import level1 from "../../../../assets/icons/level-1.png";
import level2 from "../../../../assets/icons/level-2.png";
import level3 from "../../../../assets/icons/level-3.png";
import level4 from "../../../../assets/icons/level-4.png";
import level5 from "../../../../assets/icons/level-5.png";
import { DEFAULT_AVATAR } from "../../../../constants/ProfileConstants";
import {
  IconBath,
  IconDimensions,
  IconDoor,
  IconInfoCircle,
} from "@tabler/icons-react";
import ColorfulNameComponent from "../ColorfulName/ColorfulName";

interface ProjectProps {
  status: string;
  area: string;
  base_price: string;
  bathrooms: string;
  bedrooms: string;
  cover: string;
  id: string;
  idProject: string;
  name: string;
  landlordInfo: LandlordInfo | null;
  pre_booking_date: string;
  pre_booking_disable_at: string;
  price_by_area: string;
  project_supports_multiple_bookings: boolean;
  landlords_booked: number;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: 800,
  bgcolor: "background.paper",
  borderRadius: "20px",
  p: 4,
  "@media(max-width: 768px)": {
    p: 2,
  },
};

const CardPro = memo(
  ({
    status,
    area,
    base_price,
    bathrooms,
    cover,
    id,
    name,
    bedrooms,
    landlordInfo,
    pre_booking_date,
    pre_booking_disable_at,
    price_by_area,
    project_supports_multiple_bookings,
    landlords_booked,
    idProject,
  }: ProjectProps) => {
    const [userSelected, setUserSelected] = useState("");
    const [landlordInfoSelected, setLandlordInfo] = useState<LandlordInfo>();
    const user = useSelector((store: AppStore) => store.auth);
    const token = getAuthToken(user);
    const [showBidder, setShowBidder] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const { isLoading, callEndpoint } = useCallApiAndLoad();

    const { getUsersBooked, isLoadingBooked, bookedUsers } =
      useDetailProjectsUnit();

    // Abrir el modal y primer llamado a informacion de usuarios
    const handleOpen = (idModel: string, idPro: string) => {
      setOpen(true);
      if (idModel && idPro) {
        getUsersBooked(token, idPro, idModel);
      }
    };

    // cerrar modal
    const handleClose = () => setOpen(false);

    // obtener primer valor seleccionado

    useEffect(() => {
      if (bookedUsers) {
        const dataBookedUser = (bookedUsers || [])[0];
        setUserSelected(dataBookedUser?.id);
        setLandlordInfo(dataBookedUser);
      }
    }, [bookedUsers]);

    useEffect(() => {
      if (userSelected) {
        const dataBookedUser = bookedUsers || [];
        const filterSelected = dataBookedUser?.filter(
          (item: any) => item?.id === userSelected
        );
        setLandlordInfo(filterSelected[0]);
      }
    }, [userSelected]);

    const toggleBidder = () => {
      try {
        setShowBidder((old) => !old);
        document.body.style.overflowY = "scroll";
      } catch (error) {
        console.log("something went wrong with bidder info");
      }
    };
    const renderStatus = (param: string) => {
      switch (param) {
        case "available":
          return (
            <span className="bg-[#D5F0E3] text-primary px-2 py-1 text-xs font-medium rounded-full">
              Disponible
            </span>
          );
        case "pre_booked":
          return (
            <span className="bg-[#FEE3D5] text-orange px-2 py-1 text-xs font-medium rounded-full">
              Pre-reservado
            </span>
          );
        case "taken":
          return (
            <span className="bg-[#DDDEE4] text-[#757575] px-2 py-1 text-xs font-medium rounded-full">
              Reservada
            </span>
          );
        case "assigned":
          return (
            <span className="bg-blue700 px-2 py-1 text-xs font-medium text-white rounded-full">
              Asignada
            </span>
          );
        case "disabled":
          return (
            <span className="bg-[#DDDEE4] text-[#757575] px-2 py-1 text-xs font-medium rounded-full">
              Desactivada
            </span>
          );
        case "created":
          return (
            <span className="bg-blue700 px-2 py-1 text-xs font-medium text-white rounded-full">
              Creada
            </span>
          );
          case "signing_promise":
            return (
              <span className="bg-[#DDDEE4] text-[#757575] px-2 py-1 text-xs font-medium rounded-full">
                Firma de contrato
              </span>
            );
        default:
          return "";
      }
    };

    return (
      <>
        <div className="cardPro">
          <Dialog
            open={showBidder}
            onClose={() => toggleBidder()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <InvestorCard
              onAction={toggleBidder}
              landlordInfo={landlordInfo || null}
              landlordId={landlordInfo?.id || ""}
              pre_booking_date={pre_booking_date}
              idUnit={id}
            />
          </Dialog>

          <div className="w-full flex">
            <img
              className=" object-cover object-center h-[100px] w-[100px] rounded-md"
              src={cover}
              alt=""
            />
            <div className="px-4">
              <h1 className=" text-lg font-semibold text-[#212121] ">{name}</h1>
              <div className="w-full pb-2">{renderStatus(status)}</div>
              {status === "pre_booked" ? (
                <>
                  {project_supports_multiple_bookings && (
                    <p className="font-medium text-[#212121]">
                      {landlords_booked} pre-reservas
                      <button
                        onClick={() => handleOpen(idProject, id)}
                        className="mx-1"
                      >
                        <img src={userInfo} alt="" className="pt-1" />
                      </button>
                    </p>
                  )}
                </>
              ) : (
                <>
                  {(status === "taken" || status === "assigned" || status === 'signing_promise') && (
                    <>
                      {landlordInfo && landlordInfo.id && (
                        <>
                          <p className="font-semibold text-[#212121] text-sm flex justify-start items-center">
                            {landlordInfo.name}{" "}
                            <button onClick={toggleBidder} className="mx-1">
                              <IconInfoCircle color="#212121" />
                            </button>
                          </p>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {/* {status === "pre_booked" && (
                <div className="w-full">
                  <p className="text-sm font-medium text-blueForm">
                    Tiempo restante de reserva:
                  </p>
                  <CountdownTimer targetDate={pre_booking_disable_at} />
                </div>
              )} */}
            </div>
          </div>
          <hr className="my-3" />
          <div className="w-full">
            <p className="text-sm lg:text-base font-semibold text-[#212121] ">
              Precio:{" "}
              <span className="font-normal text-[#757575]">{base_price}</span>
            </p>
            <p className="text-sm lg:text-base font-semibold text-[#212121]">
              Precio por m²:{" "}
              <span className="font-normal text-[#757575]">
                {price_by_area}
              </span>
            </p>
          </div>
          <hr className="my-3" />
          <div className="w-full flex flex-wrap">
            <div className="w-1/2 py-1 flex justify-start items-center">
              <IconDimensions color="#757575" width={18} />
              <p className="text-sm lg:text-base font-normal text-[#757575] ml-2">
                {area}
              </p>
            </div>
            <div className="w-1/2 py-1 flex justify-start items-center">
              <IconDoor color="#757575" width={18} />
              <p className="text-sm lg:text-base font-normal text-[#757575] ml-2">
                {bedrooms}
              </p>
            </div>
            <div className="w-1/2 py-1 flex justify-start items-center">
              <IconBath color="#757575" width={18} />
              <p className="text-sm lg:text-base font-normal text-[#757575] ml-2">
                {bathrooms}
              </p>
            </div>
          </div>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h1 className=" text-xl font-semibold mb-3">
              Pre-reservas activas
            </h1>
            {isLoadingBooked ? (
              <Box sx={{ p: 4 }}>
                <img
                  src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/loading_search.gif"
                  alt=""
                  style={{ margin: "auto" }}
                  width={70}
                />
              </Box>
            ) : (
              <Grid sx={{ height: 600 }} container spacing={2}>
                <Grid item xs={12} md={4} sx={{ zIndex: 1 }}>
                  <div className="cardBooked-container">
                    {!isLoadingBooked && (
                      <>
                        {bookedUsers.map((item: any, index: number) => (
                          <>
                            <div
                              key={index}
                              className={`option-booked ${
                                userSelected === item?.id
                                  ? "selected"
                                  : "not-selected"
                              }`}
                              onClick={() => setUserSelected(item?.id)}
                            >
                              <h2>{item?.name}</h2>
                              <p className=" truncate">{item?.email}</p>
                              <p>{item?.phone}</p>
                            </div>
                          </>
                        ))}
                      </>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} md={8}>
                  <div className="bg-white rounded-lg h-[435px] lg:h-[600px] overflow-y-auto lg:mx-2">
                    <div className="container_id w-full flex flex-wrap bg-primary px-6 py-6 sticky top-0 z-10">
                      <div className="w-full lg:w-1/4 flex justify-center items-center">
                        {landlordInfoSelected &&
                        landlordInfoSelected?.avatar ? (
                          <img
                            src={landlordInfoSelected?.avatar}
                            className="object-cover object-center rounded-full w-24 border-4 border-primary mx-auto"
                            alt=""
                          />
                        ) : (
                          <ColorfulNameComponent
                            firstName={
                              landlordInfoSelected?.first_name as string
                            }
                            lastName={landlordInfoSelected?.last_name as string}
                          />
                        )}
                      </div>
                      <div className="w-full lg:w-3/4">
                        <p className=" text-sm text-white text-center lg:text-left mt-2 lg:mt-0">
                          Postulante
                        </p>
                        <h1 className=" text-lg font-semibold text-white mb-2 text-center lg:text-left">
                          {landlordInfoSelected?.first_name}{" "}
                          {landlordInfoSelected?.last_name}
                        </h1>
                        <div className="w-full flex bg-[#2C2E2B] rounded-xl py-2 px-4 items-center">
                          <div className="w-full ">
                            <p className="text-sm text-white font-semibold">
                              Nivel de riesgo
                            </p>
                          </div>
                          <div className="w-full text-white text-right">
                            <>
                              {landlordInfoSelected?.credit_score ? (
                                (() => {
                                  switch (true) {
                                    case landlordInfoSelected?.credit_score <=
                                      100:
                                      return (
                                        <span className="text-sm">
                                          Muy Alto{" "}
                                          <img
                                            style={{ display: "inherit" }}
                                            src={level1}
                                          />
                                        </span>
                                      );
                                    case landlordInfoSelected?.credit_score <=
                                      300:
                                      return (
                                        <span className="text-sm">
                                          Alto{" "}
                                          <img
                                            style={{ display: "inherit" }}
                                            src={level2}
                                          />
                                        </span>
                                      );
                                    case landlordInfoSelected?.credit_score <=
                                      600:
                                      return (
                                        <span className="text-sm">
                                          Medio{" "}
                                          <img
                                            style={{ display: "inherit" }}
                                            src={level3}
                                          />
                                        </span>
                                      );
                                    case landlordInfoSelected?.credit_score <=
                                      800:
                                      return (
                                        <span className="text-sm">
                                          Bueno{" "}
                                          <img
                                            style={{ display: "inherit" }}
                                            src={level4}
                                          />
                                        </span>
                                      );
                                    case landlordInfoSelected?.credit_score <
                                      1000:
                                      return (
                                        <span className="text-sm">
                                          Excelente{" "}
                                          <img
                                            style={{ display: "inherit" }}
                                            src={level5}
                                          />
                                        </span>
                                      );
                                    default:
                                      return (
                                        <span className="text-sm">
                                          No disponible{" "}
                                        </span>
                                      );
                                  }
                                })()
                              ) : (
                                <span>No disponible.</span>
                              )}
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-4 w-full h-86 scrollbar relative">
                      <div className="w-full flex py-2">
                        <p className="w-1/2 text-[#212121] font-semibold text-sm lg:text-base">
                          Nombre
                        </p>
                        <p className="w-1/2 text-[#757575] text-sm lg:text-base font-normal text-right whitespace-nowrap overflow-ellipsis overflow-hidden">
                          {landlordInfoSelected?.first_name} {landlordInfoSelected?.last_name}
                        </p>
                      </div>
                      <hr className="my-2" />
                      <div className="w-full flex py-2">
                        <p className="w-1/2 text-[#212121] font-semibold text-sm lg:text-base">
                          Correo
                        </p>
                        <p className="w-1/2 text-[#757575] text-sm lg:text-base font-normal text-right whitespace-nowrap overflow-ellipsis overflow-hidden">
                          {landlordInfoSelected?.email}
                        </p>
                      </div>
                      <hr className="my-2" />
                      <div className="w-full flex py-2">
                        <p className="w-1/2 text-[#212121] font-semibold text-sm lg:text-base">
                          Teléfono
                        </p>
                        <p className="w-1/2 text-[#757575] text-sm lg:text-base font-normal text-right whitespace-nowrap overflow-ellipsis overflow-hidden">
                          {landlordInfoSelected?.phone}
                        </p>
                      </div>
                      <hr className="my-2" />

                      {pre_booking_date !== null && (
                        <div className="w-full flex py-2">
                          <p className="w-1/2 text-[#212121] font-semibold text-sm lg:text-base">
                            Fecha pre reserva
                          </p>
                          <p className="w-1/2 text-[#757575] text-sm lg:text-base font-normal text-right whitespace-nowrap overflow-ellipsis overflow-hidden">
                            {pre_booking_date}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
          </Box>
        </Modal>
      </>
    );
  }
);

CardPro.displayName = "CardPro";
export default CardPro;
