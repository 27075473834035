import React from 'react'
import Button from '../../../../components/Button';
import arrow_button from '../../../../assets/icons/arrow_button.svg';
import { LandlordOffer, LandlordOfferProperty } from '../../models/landlordModel';
import useCallApiAndLoad from '../../../../hooks/useCallApiAndLoad';
import { acceptOfferEndpoint } from '../../services/LandlordOfferService';
import { useSelector } from 'react-redux';
import { getAuthToken } from '../../../../helpers/authHelper';
import { AppStore } from '../../../../redux/store';
import { useNavigate } from 'react-router-dom';

interface Props {
  onAction: () => void;
  property_info: LandlordOfferProperty;
  offer_info: LandlordOffer;
}

const FormAccept = (props: Props) => {
  const { offer_info, property_info, onAction } = props
  const { callEndpoint } = useCallApiAndLoad()
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const navigate = useNavigate()

  const acceptOffer = async() => {
    const {
      status
    } = await callEndpoint(acceptOfferEndpoint(property_info?.property_id,
      offer_info?.id, token))
    if (status === 200) {
      onAction()
      return navigate(0)
    }
  }

  return (
    <div className="w-full bg-white pt-2 px-5">
      
      <div className=''>
      <h1 className="w-full mb-4 text-[#212121] text-2xl lg:text-2xl font-semibold text-left tracking-wide mt-4">
               Aceptar oferta
      </h1>
        <p className=" text-[#757575] text-base my-2">
        ¿Estás seguro de aceptar la oferta por <span className='font-bold text-[#212121]'>{offer_info?.offer}</span>?
        </p>
      </div>
      <div className="bg-white w-full">
        <div className="w-full grid grid-cols-2 gap-6 mb-6 mt-6">
          <button type='button'>
            <div
              onClick={onAction}
              className="border border-[#212121] rounded-lg px-4 py-2 text-[#212121] hover:bg-[#212121] hover:text-white duration-100"
            >
              Cancelar
            </div>
          </button>
          <Button
            onClick={acceptOffer}
            className="px-4 py-2 rounded-lg bg-[#212121] hover:bg-primary duration-100 text-white"
          >
            <span className="mr-2">Confirmar</span>
            <img
              src={arrow_button}
              className="vertical-absolute-center right-3"
              alt=""
            />
          </Button>
        </div>
      </div>

    </div>
  )
}

export default FormAccept

