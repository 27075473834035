import { useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { toogleAction } from "../redux/features/navigation";


const useIsVisibleComponent = () => {

    const dispatch = useDispatch()
    const ref = useRef<any>(null);

    const handleHideDropdown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            // document.body.style.overflow = "scroll";
            dispatch(toogleAction(false))
        }
    };

    const handleClickOutside = (e: any) => {
        if (ref.current && !ref.current.contains(e.target)) {
            // document.body.style.overflow = "scroll";
            dispatch(toogleAction(false))
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleHideDropdown, true);
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("keydown", handleHideDropdown, true);
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    return { ref };
}

export default useIsVisibleComponent
