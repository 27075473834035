import background from '../../assets/images/password_background.png';
import arrow_button from '../../assets/icons/arrow_button.svg';
import logo from '../../assets/images/logo_propi_white.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppStore } from '../../redux/store';

const ChangePasswordRequested = () => {
  const {country} = useSelector((state: AppStore) => state.country);

    return (
        <section
            className="overflow-hidden bg-cover bg-center h-screen"
            style={{ "backgroundImage": `url(${background})` }}>

            <div className="flex flex-col items-center justify-center h-screen">
                <div className="text-center mb-6">
                    <img className="inline-block mb-5" src={logo} alt="Logo PropiLatam" />
                </div>
                <h2 className="text-white font-semibold text-4xl text-center mb-6">
                    ¡Estás por iniciar!
                </h2>
                <h3 className="text-white w-3/12 font-regular text-l text-center mb-12">
                    Hemos enviado un email a tu cuenta de correo para que puedas recuperar tu acceso.
                </h3>
                <div className="flex space-x-2">
                    <a
                        href={`/${country}/`}
                        className="bg-orange py-2 px-4 text-white text-base rounded-full flex justify-start items-center">
                        <span className="mr-2 ml-8 ">Continuar</span>
                        <img src={arrow_button} alt="" />
                    </a>
                </div>
            </div>

        </section>
    )
}

export default ChangePasswordRequested
